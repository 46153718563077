import React, { useState } from "react";
import { useEffect } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import SaleRevenue from "../components/partials/default/sale-revenue/SaleRevenue";
import ActiveSubscription from "../components/partials/default/active-subscription/ActiveSubscription";
import AvgSubscription from "../components/partials/default/avg-subscription/AvgSubscription";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import TransactionTable from "../components/partials/default/transaction/Transaction";
import RecentActivity from "../components/partials/default/recent-activity/Activity";
import NewsUsers from "../components/partials/default/new-users/User";
import Support from "../components/partials/default/support-request/Support";
import Notifications from "../components/partials/default/notification/Notification";
import { MdGroup } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { MdOutlinePaid } from "react-icons/md";
import { MdUnsubscribe } from "react-icons/md";
import { MdOutlineSubscriptions } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa"
import { GiReceiveMoney } from "react-icons/gi"
import { GiProfit } from "react-icons/gi"
import moment from "moment";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
  TooltipComponent,
  BlockBetween,
} from "../components/Component";
import { makePostRequest, getHeaders } from "../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { URLS } from "../constants/urls";
import ResourceData from "../components/partials/default/cust-retention/ResourceData";
import UnSubscribeRate from "../components/partials/default/unsubscriibe/UnSubscribeRate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line, ReferenceArea } from "recharts";
import SubscribeRate from "../components/partials/default/unsubscriibe/SubscribeRate";
import Highcharts, { chart } from 'highcharts';
import { CardTitle, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import HighchartsReact from 'highcharts-react-official';
import exporting from "highcharts/modules/exporting";
import { FaUserAltSlash } from "react-icons/fa";
import { MdSubscriptions } from "react-icons/md";
const Homepage = () => {


  /*======get current year==============*/

  let current_year = new Date().getFullYear();


  const [sm, updateSm] = useState(false);
  const [totalData, setTotalData] = useState([]);

  const [revenuGroup, setrevenuGroup] = useState([])
  const [resourceSale, setResourceSale] = useState([])
  const [resourceSaleAdmin, setResourceSaleAdmin] = useState([])
  const [mentoringSale, setMentoringSale] = useState([])
  const [mentoringSaleAdmin, setMentoringSaleAdmin] = useState([])
  const [freePaidSubscriptionSale, setFreePaidSubscriptionSale] = useState([])
  const [customerdataretentionreport, setCustomerdataretentionreport] = useState([]);
  const [unsubscribedatareport, setUnsubscribedatareport] = useState([]);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [selectyeargroup, setSelectyeargroup] = useState(new Date().getFullYear());
  const [selectyearresource, setSelectyearresource] = useState(new Date().getFullYear());
  const [selectyearresourceAdmin, setSelectyearresourceAdmin] = useState(new Date().getFullYear());
  const [selectyearmentoring, setSelectyearmentoring] = useState(new Date().getFullYear());
  const [selectyearmentoringAdmin, setSelectyearmentoringAdmin] = useState(new Date().getFullYear());
  const [loader, setLoader] = useState(false)
  const [showchart, setshowchart] = useState([])
  const [showchart1, setshowchart1] = useState([])
  const [showchartgroup, setshowchartgroup] = useState([])
  const [showchartresourceadmin, setshowchartresourceadmin] = useState([])
  const [showchartresourcesale, setshowchartresourcesale] = useState([])
  const [showchartmentoringsale, setshowchartmentoringsale] = useState([])
  const [showchartmentoringadmin, setshowchartmentoringadmin] = useState([])
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);


  let chartdata = []
  let chartdata1 = []
  let chartdatagroup = []
  let chartdataresourceadmin = []
  let chartdataresourcesale = []
  let chartdatamentoringsale = []
  let chartdatamentoringadmin = []




  const handleYear = (e) => {
    setSelectyear(e.target.value);
  }
  const handleYeargroup = (e) => {
    setSelectyeargroup(e.target.value);
  }
  const handleYearresource = (e) => {
    setSelectyearresource(e.target.value);
  }
  const handleYearresourceAdmin = (e) => {
    setSelectyearresourceAdmin(e.target.value);
  }
  const handleYearmentoring = (e) => {
    setSelectyearmentoring(e.target.value);
  }

  const handleYearmentoringAdmin = (e) => {
    setSelectyearmentoringAdmin(e.target.value);
  }


  /*============================Quick Dashbord Data Api ==========================*/


  const GetAllQuickDashboardData = () => {
    let data = {
    };
    makePostRequest(URLS.GETDASHBOARDQUICKDATA, null, data, null)
      .then((res) => {
        console.log(res, "dashboard")
        if (res.code == 200) {
          setTotalData(res?.data)
          // console.log(res, "dashboard")

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };





  /*===================================Graph Data Api===========================*/

  const GetAllQuickDashboardGraph = () => {
    let data = {
      "year": current_year,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        // console.log(res, "dashboardgraph")
        if (res.code == 200) {
          setrevenuGroup(res.data.group_data)
          setResourceSale(res?.data?.resource_sale_data)

          setMentoringSale(res.data.sessions_data)
          setFreePaidSubscriptionSale(res.data.subscription_free_paid_data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAllQuickDashboardGraph1 = () => {
    setLoader(true)
    let data = {
      "year": selectyear,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        setLoader(false)
        if (res.code == 200) {
          setFreePaidSubscriptionSale(res.data.subscription_free_paid_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraph1();
  }, [selectyear])


  const GetAllQuickDashboardGraphgroup = () => {
    setLoader(true)
    let data = {
      "year": selectyeargroup,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        setLoader(false)
        if (res.code == 200) {
          setrevenuGroup(res.data.group_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraphgroup();
  }, [selectyeargroup])

  const GetAllQuickDashboardGraphresource = () => {
    setLoader(true)
    let data = {
      "year": selectyearresource,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        // console.log(res,"responsegraph")
        setLoader(false)
        if (res.code == 200) {
          setResourceSale(res?.data?.resource_sale_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraphresource();
  }, [selectyearresource])


  const GetAllQuickDashboardGraphresourceAdmin = () => {
    setLoader(true)
    let data = {
      "year": selectyearresourceAdmin,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        // console.log(res,"responsegraph")
        setLoader(false)
        if (res.code == 200) {
          setResourceSaleAdmin(res?.data?.resource_sale_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraphresourceAdmin();
  }, [selectyearresourceAdmin])

  const GetAllQuickDashboardGraphmentoring = () => {
    setLoader(true)
    let data = {
      "year": selectyearmentoring,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        setLoader(false)
        if (res.code == 200) {
          setMentoringSale(res.data.sessions_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraphmentoring();
  }, [selectyearmentoring])




  const GetAllQuickDashboardGraphmentoringAdmin = () => {
    setLoader(true)
    let data = {
      "year": selectyearmentoringAdmin,
    };
    makePostRequest(URLS.GET_DASHBOARD_GRAPH, null, data, null)
      .then((res) => {
        setLoader(false)
        if (res.code == 200) {
          setMentoringSaleAdmin(res.data.sessions_data)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    GetAllQuickDashboardGraphmentoringAdmin();
  }, [selectyearmentoringAdmin])

  /*====================================Customer Retention data Api=======================*/

  const GetCustomerRetentionData = () => {
    setLoader(true)
    makePostRequest(URLS.GET_CUSTOMER_RETENTIONRATIO_REPORTS, null, null, null)
      .then((res) => {

        if (res.code === 200) {
          //console.log(res, "retentionres");
          setCustomerdataretentionreport(res?.data?.user_data);
          setLoader(false)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*======================unsubscribe Rate ==================*/




  const UnsubscribeRate = () => {
    setLoader(true)
    makePostRequest(URLS.GET_CUSTOMER_UNSUBSCRIBED_REPORTS, null, null, null)
      .then((res) => {
        console.log(res, "unsubs")
        if (res.code === 200) {

          setUnsubscribedatareport(res?.data?.subscribe_data);
          setLoader(false)


        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };




  let newarr = [];
  if (revenuGroup) {
    let i;
    for (i = 0; i < revenuGroup.length; i++) {
      if (revenuGroup[i].month === "01") {
        newarr.push("Jan")
      }
      if (revenuGroup[i].month === "02") {
        newarr.push("Feb")
      }
      if (revenuGroup[i].month === "03") {
        newarr.push("Mar")
      }
      if (revenuGroup[i].month === "04") {
        newarr.push("Apr")
      }
      if (revenuGroup[i].month === "05") {
        newarr.push("May")
      }
      if (revenuGroup[i].month === "06") {
        newarr.push("Jun")
      }
      if (revenuGroup[i].month === "07") {
        newarr.push("Jul")
      }
      if (revenuGroup[i].month === "08") {
        newarr.push("Aug")
      }
      if (revenuGroup[i].month === "09") {
        newarr.push("Sep")
      }
      if (revenuGroup[i].month === "10") {
        newarr.push("Oct")
      }
      if (revenuGroup[i].month === "11") {
        newarr.push("Nov")
      }
      if (revenuGroup[i].month === "12") {
        newarr.push("Dec")
      }
      // console.log(revenuGroup[i].month, "newarr1")
    }

  }
  // console.log(newarr, "newarr")

  useEffect(() => {

    GetAllQuickDashboardData();
    GetAllQuickDashboardGraph();
    GetCustomerRetentionData();
    UnsubscribeRate();

    //GetAllQuickDashboardGraph1();
  }, [])
  useEffect(() => {

    getchartdata();



  }, [freePaidSubscriptionSale])

  const getchartdata = () => {
    freePaidSubscriptionSale?.map((val) => {
      chartdata.push(val.total_member_free)
      chartdata1.push(val.total_member_paid)


    })
    setshowchart(chartdata)
    setshowchart1(chartdata1)
  }

  useEffect(() => {

    getchartdatagroup();



  }, [revenuGroup])

  const getchartdatagroup = () => {
    revenuGroup?.map((val) => {
      chartdatagroup.push(val.total_group)



    })
    setshowchartgroup(chartdatagroup)

  }
  useEffect(() => {
    getchartdataresource();
  }, [resourceSale])

  useEffect(() => {
    getchartdataresourceAdmin();
  }, [resourceSaleAdmin])



  const getchartdataresource = () => {
    resourceSale?.map((val) => {
      // chartdataresourceadmin.push(parseFloat(val.admin_revenue))
      chartdataresourcesale.push(parseFloat(val.amount))
    })
    // setshowchartresourceadmin(chartdataresourceadmin)
    setshowchartresourcesale(chartdataresourcesale)

  }

  const getchartdataresourceAdmin = () => {
    resourceSaleAdmin?.map((val) => {
      chartdataresourceadmin.push(parseFloat(val.admin_revenue))
      // chartdataresourcesale.push(parseFloat(val.amount)) 
    })
    setshowchartresourceadmin(chartdataresourceadmin)
    // setshowchartresourcesale(chartdataresourcesale)

  }


  useEffect(() => {

    getchartdatamentoring();

  }, [mentoringSale])

  const getchartdatamentoring = () => {
    mentoringSale?.map((val) => {
      // chartdatamentoringadmin.push(parseFloat(val.admin_revenue))
      chartdatamentoringsale.push(parseFloat(val.amount))

    })
    // setshowchartmentoringadmin(chartdatamentoringadmin)
    setshowchartmentoringsale(chartdatamentoringsale)

  }

  useEffect(() => {

    getchartdatamentoringAdmin();

  }, [mentoringSaleAdmin])

  const getchartdatamentoringAdmin = () => {
    mentoringSaleAdmin?.map((val) => {
      chartdatamentoringadmin.push(parseFloat(val.admin_revenue))
      // chartdatamentoringsale.push(parseFloat(val.amount))



    })
    setshowchartmentoringadmin(chartdatamentoringadmin)
    // setshowchartmentoringsale(chartdatamentoringsale)

  }

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {loader == true ?
                  <div className="overflowloader" id="preload">
                    <div className="circle-square" style={{ marginTop: "0" }} >
                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                      <div className="blue" style={{ background: "#265472" }}></div>
                      <div className="green" style={{ background: "#F19357" }}></div>
                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                    </div>
                  </div>
                  : ""}
                Dashboard

              </BlockTitle>
              <BlockDes className="text-soft">
                {/* <p>Welcome to DashLite Dashboard Template</p> */}
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>

            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>

          {totalData.length == 0 ? (<div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "0" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>) :

            <Row className="g-gs">
              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Active Members</h6>
                    </div>

                  </div>




                  <div className="card-amount">
                    <span className="amount redColor">
                      <strong >{totalData.total_members ? totalData.total_members : "0"} </strong>
                    </span>

                  </div>

                  {/* <div className="invest-data">
                  <div className="invest-data-amount g-2">
							<div className="invest-data-history">
							  <div className="title">Active Member</div>
							  <span className="amount redColor">
								<strong >{ customerdataretentionreport[0]?.total_members ? customerdataretentionreport[0]?.total_members :"0"}  </strong> 
							  </span>
							</div>
							<div className="invest-data-history">
							  <div className="title">Inactive Member</div>
							  <span className="amount">
								<strong >{ customerdataretentionreport[1]?.total_members ? customerdataretentionreport[1]?.total_members :"0"}</strong >
							  </span>
							</div>
                  </div>
                  
                </div> */}

                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Groups</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount greenColor">
                      <strong >{totalData?.total_groups ? totalData?.total_groups : "0"} </strong>
                    </span>

                  </div>

                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">Active Group</div>
                        <span className="amount">
                          {totalData?.groups[0]?.total_groups ? totalData?.groups[0]?.total_groups : "0"}
                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">Inactive Group</div>
                        <span className="amount">
                          {totalData?.groups[1]?.total_groups ? totalData?.groups[1]?.total_groups : "0"}
                        </span>
                      </div>
                    </div>

                  </div>

                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Subscriptions</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount yellowColor">
                      {/* <strong >{ unsubscribedatareport[1]?.total_members ? unsubscribedatareport[1]?.total_members :"0"} </strong> */}
                      <strong >{totalData?.subscribe[1]?.total_members ? totalData.total_paid_subscription + totalData.total_free_subscription : "0"} </strong>
                    </span>

                  </div>

                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">Paid Subscriptions</div>
                        <span className="amount">
                          <strong >{totalData.total_paid_subscription ? totalData.total_paid_subscription : "0"}   </strong>
                        </span>
                      </div>
                      <div className="invest-data-history" style={{ marginRight: "0" }}>
                        <div className="title">Free Subscription</div>
                        <span className="amount">
                          <strong > {totalData.total_free_subscription ? totalData.total_free_subscription : "0"} </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                </PreviewAltCard>
              </Col>






              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Resource Purchased Sale</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount bluelightColor">
                      <strong> {totalData?.sale_data[1]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(totalData?.sale_data[1]?.total_payment_amount) : "$0.00"}    </strong>
                    </span>
                  </div>
                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">Total Amount</div>
                        <span className="amount">
                          <strong>     {totalData?.sale_data[1]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.sale_data[1]?.total_payment_amount) : "$0.00"}  </strong>

                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">Admin revenue</div>
                        <span className="amount">
                          <strong>{totalData?.sale_data[1]?.total_admin_fee ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.sale_data[1]?.total_admin_fee) : "$0.00"}  </strong>
                        </span>
                      </div>
                    </div>

                  </div>
                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Scheduled Session Sale</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount pinkColor">
                      <strong > {totalData?.sale_data[2]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format((totalData?.sale_data[2]?.total_payment_amount)) : "$0.00"}  </strong>
                    </span>

                  </div>

                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">Total Amount</div>
                        <span className="amount">
                          <strong>  {totalData?.sale_data[2]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.sale_data[2]?.total_payment_amount) : "$0.00"} </strong>

                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">Admin Revenue</div>
                        <span className="amount">
                          <strong>  {totalData?.sale_data[2]?.total_admin_fee ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.sale_data[2]?.total_admin_fee) : "$0.00"} </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                </PreviewAltCard>
              </Col>

              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Subscription Purchased Sale</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount brownColor">
                      <strong > {totalData?.sale_data[0]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(totalData?.sale_data[0]?.total_payment_amount) : "$0.00"}    </strong>
                    </span>

                  </div>

                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history">
                        <div className="title">New Subscription</div>
                        <span className="amount">
                          <strong >{totalData?.subscription_payments[0]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.subscription_payments[0]?.total_payment_amount) : "$0.00"} </strong>
                        </span>
                      </div>
                      <div className="invest-data-history">
                        <div className="title">Renew Subscription</div>
                        <span className="amount">
                          <strong > {totalData?.subscription_payments[1]?.total_payment_amount ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(totalData?.subscription_payments[1]?.total_payment_amount) : "$0.00"} </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                </PreviewAltCard>
              </Col>

              {/* 			
			<Col md="4">
              <PreviewAltCard className="card-full card-fullCustome">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Retention Rate</h6>
                  </div>
                 </div>
				 <div className="card-amount">
                  <span className="amount orangeColor">
                    <strong >  { customerdataretentionreport[1] ?  (customerdataretentionreport[1]?.total_members / (customerdataretentionreport[0]?.total_members + customerdataretentionreport[1]?.total_members) * 100).toFixed(2):"0"}%  </strong>
                  </span>
                </div>
			  
			  <div className="invest-data">
                  <div className="invest-data-amount g-2">
							<div className="invest-data-history">
							  <div className="title">Total Members</div>
							  <span className="amount">
                <strong >{totalData.total_members ? totalData.total_members :"0"} </strong>
							  </span>
							</div>
							<div className="invest-data-history">
							  <div className="title">Inactive Member </div>
							  <span className="amount">
								<strong>{ customerdataretentionreport[1]?.total_members ? customerdataretentionreport[1]?.total_members :"0"}</strong>
							  </span>
							</div>
                  </div>
                  
                </div>
		     </PreviewAltCard>
            </Col> */}


              <Col md="4">
                <PreviewAltCard className="card-full card-fullCustome">
                  <div className="card-title-group align-start mb-0">
                    <div className="card-title">
                      <h6 className="subtitle">Unsubscribe Rate</h6>
                    </div>
                  </div>
                  <div className="card-amount">
                    <span className="amount orangelightColor">
                      <strong > {unsubscribedatareport[1] ? (unsubscribedatareport[1]?.total_members / (unsubscribedatareport[0]?.total_members + unsubscribedatareport[1]?.total_members) * 100).toFixed(2) : "0"}%  </strong>
                    </span>
                  </div>

                  <div className="invest-data">
                    <div className="invest-data-amount g-2">
                      <div className="invest-data-history" style={{ marginRight: "6" }}>
                        <div className="title">Total Subscription</div>
                        <span className="amount">
                          <strong >{(unsubscribedatareport[0]?.total_members + unsubscribedatareport[1]?.total_members)} </strong>
                        </span>
                      </div>
                      <div className="invest-data-history" style={{ marginRight: "0" }}>
                        <div className="title">Inactive Subscription</div>
                        <span className="amount">
                          <strong > {unsubscribedatareport[1]?.total_members ? unsubscribedatareport[1]?.total_members : "0"} </strong>
                        </span>
                      </div>
                    </div>

                  </div>

                </PreviewAltCard>
              </Col>
            </Row>
          }

          <br />
          {/* <h6 className="title">Quick Overview</h6> */}

          {/* {totalData.length == 0 ? (<div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "0" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>) :
          
            <div className="row g-4 mb-5">
              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#265472", borderRadius: "10px", width: "50px" }}>
                      <FaRegUser style={{ fontSize: "25px", marginLeft: "13px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Members

                      </div>
                      <div>  <strong >{totalData.total_members ? totalData.total_members :"0"} </strong> </div>
                    </div>
                  </div>


                </div></div>
               
              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F19357", borderRadius: "10px", width: "50px" }}>
                      <MdGroup style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Groups

                      </div>
                      <div >  <strong >{totalData.total_groups ? totalData.total_groups :"0"} </strong> </div>
                    </div>
                  </div>

                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F0B285", borderRadius: "10px", width: "50px" }}>
                      <MdOutlinePaid style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Paid Subscriptions

                      </div>
                      <div>  <strong >{totalData.total_paid_subscription ? totalData.total_paid_subscription :"0"} </strong> </div>
                    </div>
                  </div>


                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#AAC0CE", borderRadius: "10px", width: "50px" }}>
                      <MdOutlineSubscriptions style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Free Subscriptions

                      </div>
                      <div>  <strong >{totalData.total_free_subscription ? totalData.total_free_subscription :"0"} </strong> </div>
                    </div>
                  </div>
                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F0B285", borderRadius: "10px", width: "50px" }}>
                      <FaDollarSign style={{ fontSize: "17px", marginLeft: "15px", color: "white" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Sales

                      </div>
                      <div>  <strong > ${totalData?.sale_data[0]?.total_payment_amount ? totalData?.sale_data[0]?.total_payment_amount?.toFixed(2) : "0"} </strong> </div>
                    </div>
                  </div>
                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#AAC0CE", borderRadius: "10px", width: "50px" }}>
                      <GiProfit style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Revenue

                      </div>
                      <div >  <strong >${totalData?.sale_data[0]?.total_admin_fee ? totalData?.sale_data[0]?.total_admin_fee?.toFixed(2) :"0"} </strong> </div>
                    </div>
                  </div>
                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F19357", borderRadius: "10px", width: "50px" }}>
                      <GiReceiveMoney style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} /></div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Retention Rate

                      </div>
                      <div >  <strong >  { customerdataretentionreport[1] ?  (customerdataretentionreport[1]?.total_members / (customerdataretentionreport[0]?.total_members + customerdataretentionreport[1]?.total_members) * 100).toFixed(2):"0"}%  </strong> </div>
                    </div>
                  </div>
                </div></div>

              <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#265472", borderRadius: "10px", width: "50px" }}>
                      <MdUnsubscribe style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">UnSubscribe Rate

                      </div>
                      <div >  <strong > { unsubscribedatareport[2] ? (unsubscribedatareport[2]?.total_members / (unsubscribedatareport[1]?.total_members + unsubscribedatareport[2]?.total_members) * 100).toFixed(2) :"0"}%  </strong> </div>
                    </div>
                  </div>
                </div></div>

                <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">
                  <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#AAC0CE", borderRadius: "10px", width: "50px" }}>
                  
                    <MdSubscriptions style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Active Subscription

                      </div>
                      <div >  <strong >{ unsubscribedatareport[1]?.total_members ? unsubscribedatareport[1]?.total_members :"0"}  </strong> </div>
                    </div>
                  </div>
                </div></div>

                <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                  <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F0B285", borderRadius: "10px", width: "50px" }}>
                    <MdUnsubscribe style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Inactive Subscription

                      </div>
                      <div >  <strong > { unsubscribedatareport[2]?.total_members ? unsubscribedatareport[2]?.total_members :"0"} </strong> </div>
                    </div>
                  </div>
                </div></div>
                <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                    <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#265472", borderRadius: "10px", width: "50px" }}>
                      <FaRegUser style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Active Member

                      </div>
                      <div >  <strong > { customerdataretentionreport[0]?.total_members ? customerdataretentionreport[0]?.total_members :"0"} </strong> </div>
                    </div>
                  </div>
                </div></div>
                <div className="col-sm-3 col-lg-3 col-xxl-3 ">
                <div className="nk-order-ovwg-data">
                  <div className="d-flex">

                  <div style={{ fontSize: "30px", height: "54px", color: "#265472", backgroundColor: "#F19357", borderRadius: "10px", width: "50px" }}>
                    <FaUserAltSlash style={{ fontSize: "30px", marginLeft: "10px", color: "#ffffff" }} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div className="amount">Inactive Member

                      </div>
                      <div >  <strong > { customerdataretentionreport[1]?.total_members ? customerdataretentionreport[1]?.total_members :"0"}  </strong> </div>
                    </div>
                  </div>
                </div></div>

            </div>

          } */}


          <Row className="g-gs">
            <Col xxl="12">
              <Row className="g-gs">
                <Col lg="6" xxl="6">

                  <PreviewCard>

                    <div className=" bg-white d-sm-inline-flex yearsSelections" >
                      <select className="form-control" value={selectyear} onChange={handleYear} >
                        {
                          years.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                          })
                        }
                      </select>
                    </div>




                    <ResponsiveContainer width="100%" aspect={2}>


                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: 'column',


                          },
                          title: {
                            useHtml: true,
                            text: "<b>Monthly Subscription (Free & Paid)</b>",

                            style: { "color": "#003C71", "fontSize": "15px" }
                          },

                          xAxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }
                          },
                          plotOptions: {
                            column: {
                              dataLabels: {
                                enabled: true,
                                formatter: function () {
                                  return (this.y != 0) ? this.y : "";
                                }
                              },
                              enableMouseTracking: true
                            }
                          },

                          credits: {
                            enabled: false
                          },

                          yAxis: {
                            title: {
                              text: 'Number of Subscrptions',
                              style: {
                                color: '#364a63'
                              }
                            },
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }


                          },
                          series: [{
                            name: "Free",
                            data: showchart,
                            color: "rgb(38, 84, 114)"


                          },
                          {
                            name: "Paid",
                            data: showchart1,
                            color: "#f19357"
                          }

                          ]
                        }}
                      />
                    </ResponsiveContainer>
                  </PreviewCard>
                </Col>
                <Col lg="6" xxl="6">
                  <Row className="g-gs">
                    <Col sm="6" lg="12" xxl="12">
                      <PreviewCard>




                        <div className=" bg-white d-sm-inline-flex yearsSelections" >
                          <select className="form-control" value={selectyeargroup} onChange={handleYeargroup} >
                            {
                              years.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                              })
                            }
                          </select>
                        </div>


                        <ResponsiveContainer width="100%" aspect={2}>

                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'column',

                              },
                              title: {
                                text: 'Monthly Group Chart',
                                style: { "color": "#003C71", "fontSize": "15px" }
                              },

                              xAxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }
                              },
                              plotOptions: {
                                column: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return (this.y != 0) ? this.y : "";
                                    }
                                  },
                                  enableMouseTracking: true
                                }
                              },
                              credits: {
                                enabled: false
                              },

                              yAxis: {
                                title: {
                                  text: 'Number of Groups',
                                  style: {
                                    color: '#364a63'
                                  }
                                },
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }


                              },
                              series: [{
                                name: "Month",
                                data: showchartgroup,
                                color: "rgb(38, 84, 114)"


                              },


                              ]
                            }}
                          />


                        </ResponsiveContainer>
                      </PreviewCard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>


            {/* <Row className="g-gs"> */}
            <Col lg="6" xxl="6">
              <PreviewCard>




                <div className=" bg-white d-sm-inline-flex yearsSelections" >
                  <select className="form-control" value={selectyearresource} onChange={handleYearresource} >
                    {
                      years.map((year, index) => {
                        return <option key={`year${index}`} value={year}>{year}</option>
                      })
                    }
                  </select>
                </div>



                <ResponsiveContainer width="100%" aspect={2}>



                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'line',

                      },
                      title: {
                        useHtml: true,

                        text: "<h1>" + "Resource Sales" + "</h1>",
                        style: { "color": "#003C71", "fontSize": "15px" }

                      },

                      xAxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        labels: {
                          style: {
                            color: '#364a63'
                          }
                        }
                      },
                      plotOptions: {
                        line: {
                          dataLabels: {
                            enabled: true,
                            formatter: function () {
                              return (this.y != 0) ?
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(this.y) : "";
                            }
                          },
                          enableMouseTracking: true
                        }
                      },
                      credits: {
                        enabled: false
                      },

                      yAxis: {
                        title: {
                          text: 'Resource Sales',
                          style: {
                            color: '#364a63'
                          }
                        },
                        labels: {
                          style: {
                            color: '#364a63'
                          }
                        }


                      },
                      series: [{
                        name: "Month",
                        data: showchartresourcesale,
                        color: "rgb(38, 84, 114)"


                      },


                      ]
                    }}
                  />



                </ResponsiveContainer>
              </PreviewCard>

            </Col>


            <Col lg="6" xxl="6">
              <PreviewCard>




                <div className=" bg-white d-sm-inline-flex yearsSelections" >
                  <select className="form-control" value={selectyearresourceAdmin} onChange={handleYearresourceAdmin} >
                    {
                      years.map((year, index) => {
                        return <option key={`year${index}`} value={year}>{year}</option>
                      })
                    }
                  </select>
                </div>



                <ResponsiveContainer width="100%" aspect={2}>



                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'line',

                      },
                      title: {
                        useHtml: true,

                        text: "<h1>" + "Resource Sales Admin Revenue" + "</h1>",
                        style: { "color": "#003C71", "fontSize": "15px" }

                      },

                      xAxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        labels: {
                          style: {
                            color: '#364a63'
                          }
                        }
                      },
                      plotOptions: {
                        line: {
                          dataLabels: {
                            enabled: true,
                            formatter: function () {
                              return (this.y != 0) ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.y) : "";
                            }
                          },
                          enableMouseTracking: true
                        }
                      },
                      credits: {
                        enabled: false
                      },

                      yAxis: {
                        title: {
                          text: 'Resource Sales Admin Revenue',
                          style: {
                            color: '#364a63'
                          }
                        },
                        labels: {
                          style: {
                            color: '#364a63'
                          }
                        }


                      },
                      series: [

                        {
                          name: "Month",
                          data: showchartresourceadmin,
                          color: '#364a63'
                        }

                      ]
                    }}
                  />

                </ResponsiveContainer>
              </PreviewCard>

            </Col>



            <Col lg="6" xxl="6">
              <Row className="g-gs">
                <Col sm="6" lg="12" xxl="12">
                  <PreviewCard>




                    <div className=" bg-white d-sm-inline-flex yearsSelections" >
                      <select className="form-control" value={selectyearmentoring} onChange={handleYearmentoring} >
                        {
                          years.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                          })
                        }
                      </select>
                    </div>

                    <ResponsiveContainer width="100%" aspect={2}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: 'line',

                          },
                          title: {

                            text: 'Scheduled Sessions Sales',
                            style: { "color": "#003C71", "fontSize": "15px" }
                          },

                          xAxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }
                          },
                          plotOptions: {
                            line: {
                              dataLabels: {
                                enabled: true,
                                formatter: function () {
                                  return (this.y != 0) ? new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(this.y) : "";
                                }
                              },
                              enableMouseTracking: true
                            }
                          },
                          credits: {
                            enabled: false
                          },

                          yAxis: {
                            title: {
                              text: 'Scheduled Sessions Sales',
                              style: {
                                color: '#364a63'
                              }
                            },
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }


                          },
                          series: [{
                            name: "Month",
                            data: showchartmentoringsale,
                            color: "rgb(38, 84, 114)"


                          },
                            // {
                            //     name:"Admin Revenue",
                            //     data: showchartmentoringadmin,
                            //     color:"#f19357"
                            // }

                          ]
                        }}
                      />



                    </ResponsiveContainer>
                  </PreviewCard>
                </Col>
              </Row>
            </Col>

            <Col lg="6" xxl="6">
              <Row className="g-gs">
                <Col sm="6" lg="12" xxl="12">
                  <PreviewCard>




                    <div className=" bg-white d-sm-inline-flex yearsSelections" >
                      <select className="form-control" value={selectyearmentoringAdmin} onChange={handleYearmentoringAdmin} >
                        {
                          years.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                          })
                        }
                      </select>
                    </div>

                    <ResponsiveContainer width="100%" aspect={2}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: 'line',

                          },
                          title: {

                            text: 'Scheduled Sessions Sales Admin Revenue ',
                            style: { "color": "#003C71", "fontSize": "15px" }
                          },

                          xAxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }
                          },
                          plotOptions: {
                            line: {
                              dataLabels: {
                                enabled: true,
                                formatter: function () {
                                  return (this.y != 0) ? new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(this.y) : "";
                                }
                              },
                              enableMouseTracking: true
                            }
                          },
                          credits: {
                            enabled: false
                          },

                          yAxis: {
                            title: {
                              text: 'Scheduled Sessions Sale Admin Revenue',
                              style: {
                                color: '#364a63'
                              }
                            },
                            labels: {
                              style: {
                                color: '#364a63'
                              }
                            }


                          },
                          series: [
                            //   {
                            //     name:"Mentoring  Sale",
                            //     data: showchartmentoringsale,
                            //     color:"rgb(38, 84, 114)"


                            // },
                            {
                              name: "Month",
                              data: showchartmentoringadmin,
                              color: '#364a63'
                            }

                          ]
                        }}
                      />
                    </ResponsiveContainer>
                  </PreviewCard>
                </Col>
              </Row>
            </Col>
            {/* </Row> */}
            <Col xxl="6" md="6">
              <Card className="card-bordered card-full">
                <RecentActivity />
              </Card>
            </Col>
            <Col xxl="6" md="6">
              <Card className="card-bordered card-full">
                <NewsUsers />
              </Card>
            </Col>
            <Col lg="6" xxl="6">
              <Card className="card-bordered h-100">
                <Support />
              </Card>
            </Col>
            <Col lg="6" xxl="6">
              <Card className="card-bordered h-100">
                <Notifications />
              </Card>
            </Col>
            <Col lg="12" xxl="12">
              <Card className="card-bordered h-100">
                <ResourceData />
              </Card>
            </Col>
            <Col lg="12" xxl="12">
              <Card className="card-bordered card-full">
                <TransactionTable />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
