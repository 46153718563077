import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest,makeGetRequest } from "../../../service/api";

import ReactPaginate from "react-paginate";
import { AreaChart,Area, BarChart, Bar ,ResponsiveContainer,CartesianGrid,XAxis ,YAxis,Tooltip,Legend, Label, LineChart,Line} from "recharts";

import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";


function FriendsListReport() {
	
    let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
//   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [friendlistdata,setFriendListData]=useState([]);
  const [friendlistdatachart,setFriendListDatachart]=useState([]);
  const [loader, setLoader] = useState(false);
  const [selectyear,setSelectyear]=useState( new Date().getFullYear());
 const [displayvalue,setDisplayValue]=useState("1");
 const [childId,setChildId]=useState("");
  const [userName,setUserName]=useState("");
  const [childName,setChildName]=useState("");
  const [userNameData,setUserNameData]=useState([])
  const [childNameData,setChildNameData]=useState([])
  const [userId,setUserId]=useState("");
  const [selectedfromdate,setSelectedFromDate]=useState("");
  const [selectedtodate,setSelectedToDate]=useState("");
  const [limitvalue,setlimitvalue]=useState("")
  const [currentPage, setCurrentPage] = useState(1);

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
  }

  const HandleToDate = (e) => {
    setSelectedToDate(e);
  }
  const HandleUserName = (e) => {
    setUserId(e.value.toString());
    setUserName(e.label);
    
  }
  const HandleLimitValue=(e)=>{
  
    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleChildName = (e) => 
    {
      setChildId(e.value);
      setChildName(e.label);
    }
  const handlesearch = () => {
   
    getFriendListData();
    setCurrentPage(1)

    // setUserId("")
    //  setChildId("")
    

  }

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        console.log(res)
        if (res.code == 200) {
          let option=[];
          res.data.length > 0 && res.data.map((val)=>{
        option.push({value: val.id, label:val.firstName+" "+val.lastName})
          }) 
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getFriendListData = () => {
    setLoader(true);
    let data = {
        "start_date":selectedfromdate?moment(selectedfromdate).format("YYYY-MM-DD"):"2023-08-01",
        "end_date":selectedtodate?moment(selectedtodate).format("YYYY-MM-DD"):moment(new Date()).format("YYYY-MM-DD"),
        "user_id":userId?userId:"",
        // "page_number":1,
        // "item_per_page":limitvalue?parseInt(limitvalue):5,
        "sort_by":""
    };    
   console.log("friend list payload",data)
    makePostRequest(URLS.GET_FRIEND_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "friendlistdata");
          setFriendListData(res?.data?.list_data);
         setLoader(false);
         
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

 

   useEffect(() => {
    getUserName();
    getFriendListData();

  }, []);

  useEffect(() => {
    getFriendListData();
  }, [selectyear,limitvalue]);
  


  const columns = [


    {
      name: "Invite Date",
      selector: (row) => "",
      grow: 0,
      minWidth: "170px",
      style: { minWidth: "170px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">{row?.invited_date ? row?.invited_date :"--"}{" "} 
            <br/>
           
          </div>
        </>
      ),
    },
    {
      name: "Total Invites",
      
      grow: 0,
      minWidth: "140px",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_invites?row.total_invites:"--"} </div>
      ),
    },
    // {
    //   name: "Total Searches",
      
    //   grow: 0,
    //   minWidth: "140px",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2"> {row.tbl_resource_searches?row.tbl_resource_searches.length:"--"} </div>
    //   ),
    // },

  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  return (
   
      <React.Fragment>
            
       
      <Head title="Friend List"></Head>
      <Content>
        
        <h3 className="nk-block-title page-title mb5 pb-4"> Friend Detail Report</h3>
  
        {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : (
  <>
  <Card className="card-bordered">
     <div className="card-inner relativeClass">
     
     
       
         
  
  
            <div className="card-inners relativeClass">
              <BlockHeadContent>
                <BlockHead size="lg">
  
  
                {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="row">
                    <div className="col-lg-12 d-flex  justify-content-end">
    
    <select  className="form-select" style={{width:"170px"}} >
               <option value="">Sort By</option> 
               <option value="ASCDATE">Date Ascending</option>
               <option value="DESCDATE">Date Descending</option> 
               <option value="ASCTITLE">Title Ascending</option>
               <option value="DESCTITLE">Title Descending</option>
               <option value="ASCBUYER">Buyer Ascending</option>
               <option value="DESCBUYER">Buyer Descending</option>
               <option value="ASCSELLER">Seller Ascending</option>
               <option value="DESCSELLER">Seller Descending</option>


      </select>
     
      <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
            <div><select  value={limitvalue} className="form-select" style={{width: "85px"}} onChange={HandleLimitValue}>
            <option value="5">5</option>
                <option value="10">10</option>
                             <option value="20">20</option>
                 <option value="30">30</option>
                 <option value="50">50</option> 
                 <option value="100">100</option>
              </select>
        </div>    
        <div>
        &nbsp; &nbsp;
        <button className="btn btn-primary " >
                 <em className="icon ni ni-download-cloud"></em><span>Export</span>
                </button>
          </div>    
     </div>
        </div>
                        

                       
               <div className="row pb-2">
               <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>User Name</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <Select
                    defaultvalue={userId}  
                    options={userNameData}
                   
                    onChange={HandleUserName}
                  />
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}
                        
                          <div className="form-control-wrap">
                                <div className="form-icon form-icon-left">
                                  <Icon name="calendar"></Icon>
                                </div>
                                <DatePicker
                                 placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate}
                                  onChange={HandleFromDate}
                                  className="form-control date-picker"
                                />
                              </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}
                        
                          <div className="form-control-wrap">
                                <div className="form-icon form-icon-left">
                                  <Icon name="calendar"></Icon>
                                </div>
                                <DatePicker
                                 placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate}
                                  onChange={HandleToDate}
                                  className="form-control date-picker"
                                />
                              </div>
                        </div>
                      </div>

                         
                         
                          
                          </div>
                          <div className="row">
                          <div className="col-lg-2 d-flex ">
                          <div className="outerTypeBox mb-3">
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>

                            {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                          </div>
                          </div>
                          <div className="col-lg-2 d-flex ">
                          <div className="outerTypeBox mb-3">
                          {/* <button className="btn btn-primary">
			      <em className="icon ni ni-download-cloud"></em><span>Export</span>
			     </button> */}
                          </div>
                          </div>
                        </div>
                
               {friendlistdata&&friendlistdata?.length>0?  (
                <div>
                
                    <div className="color">
                    <ReactDataTable
                      data={friendlistdata}
                      columns={columns}
                      className="nk-tb-list"
                      // searchable={true}
                      // setPageCount={setPageCount}
                  />
                  </div>
                  </div>
                  ):<p>No Data Found</p>}
                  
                  
                  
                 
                  
                  
                  
                  
                  
                 
  
                  {/* {customerloginreport?.length > 0 ? 
  <div>
      <ReactPaginate
       previousLabel={"<<"}
       nextLabel={">>"}
        // pageCount={count}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        // onPageChange={handlePageChange}
        containerClassName={'pagination'}
        pageClassName={"page-item"}
        activeClassName={'active'}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
      />
     </div> 
     :""} */}
         </BlockHead>
              </BlockHeadContent>
            </div>
       
        </div>
        </Card>
        </>  )}
      </Content>
    </React.Fragment>
  );
}
export default FriendsListReport;
