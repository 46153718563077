import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { ReactDataTable, Icon } from "../../../components/Component";
import { Card, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { S3_BUCKET_URL } from "../../../constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { FaFilter } from "react-icons/fa";
const ExcelJS = require("exceljs");

function ListMentor() {
  const [mentordata, setmentordata] = useState([]);
  const [cancelCount, setCancelCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPayOpen, setPayOpen] = useState(false);
  const [childsessiondetail, setchildsessiondetail] = useState([]);
  const [childparentdata, setchildparentdata] = useState([]);
  const [childparentdata2, setchildparentdata2] = useState([]);
  const [mentorsolddata, setmentorsolddata] = useState([]);
  const [childdata, setchilddata] = useState([]);
  const [parentid, setparentid] = useState("");
  const [mentorid, setmentorid] = useState("");
  const [childid, setchildid] = useState("");
  const [getCustomer, setGetCustomer] = useState("");
  const [alldata, setalldata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState();
  const [pageCount, setPageCount] = useState(null);
  const [search, setSearch] = useState("");
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [selectedResourse, setselectedResourse] = useState();
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [sortmentoring, setSortMentoring] = useState("");
  const [limitvalue, setlimitvalue] = useState("");
  const [scheduletype, setScheduleType] = useState("");
  const [bookBalance, setBookBalance] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [transferamount, setTransferAmount] = useState([]);
  const [errorpay, setErrorPay] = useState(false);
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [clearState, setClearState] = useState(false);
  const [displayvalueSelectFilter, setDisplayValueSelectFilter] = useState(true);

  const [userName, setUserName] = useState(true);
  const [mentorName, setMentorName] = useState(true);

  const getResourceDropdown = () => {
    makePostRequest(URLS.GET_RESOURCE_MENTOR, null, { page_number: dropdownPage }, null)
      .then((res) => {
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    document.body.classList.add("list-mentor");
    return () => {
      document.body.classList.remove("list-mentor");
    };
  });

  // let Getdata = alldata;
  // const state = Getdata?.tbl_user_mentoring_session_datetimes?.map((val) => {
  //   if (val?.cancel_by === "3") {
  //     if (val?.status === "Cancelled") {
  //       return "Full Refund";
  //     } else {
  //       return val?.status === "Partial" ? "Partial Refund" : val?.status;
  //     }
  //   } else {
  //     if (val?.stripe_refund_id) {
  //       return "Full Refund";
  //     } else {
  //       return val?.status === "Partial" ? "Partial Refund" : val?.status;
  //     }
  //   }
  // });
 console.log(alldata, "Alldata view");

  useEffect(() => {
    getResourceDropdown();
  }, []);

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };

  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const openpaymentModal = (pay) => {
    gettransactions(pay);
    setPayOpen(!isPayOpen);
    setPayAmount(pay);
  };

  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const gettransactions = (pay) => {
    let data = {
      payment_id: pay.tbl_payment_history?.id ? pay.tbl_payment_history.id : "",
      session_id: pay.id ? pay.id : "",
    };
    makePostRequest(URLS.GET_TRANSFER_HISTORY, null, data, null).then((res) => {
      if (res.code == 200) {
        setTransferAmount(res.data);
      }
    });
  };
  const handleamount = (e) => {
    setAmount(e.target.value);
    setErrorPay(false);
  };

  const handlePayment = () => {
    if (amount == "") {
      setErrorPay(true);
    } else {
      let data = {
        session_id: payAmount.id ? payAmount.id : "",
        trasfer_amount: parseFloat(amount),
      };
      makePostRequest(URLS.SESSION_TRANSFER, null, data, null).then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          setTimeout(() => {
            setPayOpen(!isPayOpen);
            getallmentordata();
            getbookbalance();
          }, 6000);
        } else {
          toast.error(res.message);
        }
      });
    }
  };
  const falsyToZero = (value) => {
    let result = Number(value);
    if (Number.isNaN(result)) {
      result = 0;
    }

    return result;
  };

  const [show, setShow] = useState(false);
  const ShowHideSelect = () => {
    setShow(!show);
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSortMentoring = (e) => {
    setSortMentoring(e.target.value);
    setCurrentPage(1);
  };

  // function getTime(time) {
  //   let [h, m] = time.split(":");

  //   let res, prefix;
  //   let date = new Date();
  //   if (h > 12) {
  //     h = h - 12;
  //     prefix = "PM";
  //   } else {
  //     prefix = "AM";
  //   }
  //   date.setHours(h, m, 0);
  //   date.toString();
  //   if (date.getHours() < 10 && date.getMinutes() < 10) res = `0${date.getHours()}:0${date.getMinutes()}`;
  //   else if (date.getHours() < 10 && date.getMinutes() > 10) res = `0${date.getHours()}:${date.getMinutes()}`;
  //   else if (date.getMinutes() < 10 && date.getHours() > 10) res = `${date.getHours()}:0${date.getMinutes()}`;
  //   else res = `${date.getHours()}:${date.getMinutes()}`;
  //   res = res + " " + prefix;
  //   return res;
  // }

  function getTime(time) {
    let [h, m] = time.split(":");
   
    h = parseInt(h, 10);
    m = parseInt(m, 10);
    
    let prefix;
    if (h >= 12) {
        prefix = "PM";
        if (h > 12) {
            h = h - 12;
        }
    } else {
        prefix = "AM";
        if (h === 0) {
            h = 12; 
        }
    }
    
    let formattedHours = h < 10 ? `0${h}` : `${h}`;
    let formattedMinutes = m < 10 ? `0${m}` : `${m}`;
    
    return `${formattedHours}:${formattedMinutes} ${prefix}`;
}


  useEffect(() => {
    getallmentordata();
  }, [sortmentoring, limitvalue, currentPage]);

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      user_id: parentid.value ? parentid.value : "",
      child_id: childid ? childid : "",
      mentor_id: mentorid.value ? mentorid.value : "",
      page: currentPage,
      limit: limitvalue ? limitvalue : 10,
      searchKey: search,
      resource_id: selectedResourse?.value ?? "",
      start_date: selectedfromdate ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      sortby: sortmentoring ? sortmentoring : "",
      schedule_type: scheduletype ? scheduletype : "",
      key: "export",
    };
    makePostRequest(URLS.GET_ALL_MENTORSESSION_EXPORT, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("MentoringData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "BOOKED DATE",
              key: "BOOKED_DATE",
              width: 15,
            },
            {
              header: "PARENT",
              key: "PARENT",
              width: 15,
            },
            {
              header: "PARENT EMAIL",
              key: "PARENT_EMAIL",
              width: 15,
            },
            {
              header: "PARENT PHONE",
              key: "PARENT_PHONE",
              width: 15,
            },
            {
              header: "Student",
              key: "CHILD",
              width: 15,
            },
            {
              header: "MENTOR",
              key: "MENTOR",
              width: 15,
            },
            {
              header: "MENTOR EMAIL",
              key: "MENTOR_EMAIL",
              width: 15,
            },
            {
              header: "MENTOR PHONE",
              key: "MENTOR_PHONE",
              width: 15,
            },
            {
              header: "RESOURCE",
              key: "RESOURCE",
              width: 20,
            },

            {
              header: "Schedule Type",
              key: "Schedule_Type",
              width: 20,
            },
            {
              header: "DURATION",
              key: "DURATION",
              width: 18,
            },
            {
              header: "($)PRICE PER DURATION",
              key: "PRICE_PER_DURATION",
              width: 18,
            },

            {
              header: "($)TAX AMOUNT",
              key: "TAX_AMOUNT",
              width: 18,
            },
            {
              header: "($)Grand Total",
              key: "Grand_Total",
              width: 15,
            },

            {
              header: "($)Mentor Payment",
              key: "Mentor_Payment",
              width: 15,
            },
            {
              header: "Booked Session Type",
              key: "Booked_Session_Type",
              width: 15,
            },
            {
              header: "DAY",
              key: "DAY",
              width: 15,
            },
            {
              header: "Frequency",
              key: "Frequency",
              width: 15,
            },
            {
              header: "Time",
              key: "TIme",
              width: 15,
            },
            {
              header: "Start Date",
              key: "Start_Date",
              width: 15,
            },
            {
              header: "End Date",
              key: "End_Date",
              width: 15,
            },

            {
              header: "Invoice Number",
              key: "Invoice_Number",
              width: 15,
            },
            {
              header: "($)Transfer To Mentor",
              key: "Transfer_To_Mentor",
              width: 15,
            },
          ];

          res.data?.data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              BOOKED_DATE: moment(val.created_at).format("MM-DD-YYYY"),
              PARENT: val.purchased_user.firstName + " " + val.purchased_user.lastName,
              PARENT_EMAIL: val.purchased_user.email ? val.purchased_user.email : "--",
              PARENT_PHONE: val.purchased_user.phone ? val.purchased_user.phone : "--",
              CHILD: val.tbl_child.firstName + " " + val.tbl_child.lastName,
              MENTOR: val.sold_by_user.firstName + " " + val.sold_by_user.lastName,
              MENTOR_EMAIL: val.sold_by_user.email ? val.sold_by_user.email : "--",
              MENTOR_PHONE: val.sold_by_user.phone ? val.sold_by_user.phone : "--",

              RESOURCE:
                val.tbl_user_resource.resource_title && val.tbl_user_resource.resource_title
                  ? val.tbl_user_resource.resource_title
                  : "--",
              Schedule_Type: val?.schedule_type && val?.schedule_type == "1" ? "Group Session" : "Individual Session",

              DURATION: val.duration && val.duration ? val.duration + "min" : "--",
              PRICE_PER_DURATION:
                val.price_per_duration && val.price_per_duration ? "$" + val.price_per_duration.toFixed(2) : "--",

              TAX_AMOUNT:
                val.tbl_payment_history.tax_amount && val.tbl_payment_history.tax_amount
                  ? "$" + val.tbl_payment_history.tax_amount.toFixed(2)
                  : "--",
              Grand_Total: val.total_amount && val.total_amount ? "$" + val.total_amount.toFixed(2) : "--",
              Mentor_Payment: val.mentor_amount && val.mentor_amount ? "$" + val.mentor_amount.toFixed(2) : "--",
              Booked_Session_Type: val.session_type == "manual" ? "Single booking" : "Reoccuring booking",
              DAY: val.day && val.day ? val.day : "--",
              Frequency: val.frequency && val.frequency ? val.frequency : "--",
              TIme: val.timeslot && val.timeslot ? val.timeslot : "--",
              Start_Date: val.start_date && val.start_date ? val.start_date : "--",
              End_Date: val.end_date && val.end_date ? val.end_date : "--",
              // Transaction_Id: val.tbl_payment_history.transaction_id && val.tbl_payment_history.transaction_id ? val.tbl_payment_history.transaction_id: "--",
              Invoice_Number: val.invoice_number && val.invoice_number ? val.invoice_number : "--",
              Transfer_To_Mentor: val?.transferred_amount ? val.transferred_amount.toFixed(2) : "--",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Mentoring.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const columns = [
    {
      name: "#",
      selector: (row) => "1",
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Booking Date</br>Schedule Type" }} />,
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 0,
      minWidth: "180px",
      style: { minWidth: "180px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span> {moment(row.created_at).format("MM-DD-YYYY")}</span>
              <br />
              <span>{row.schedule_type == "1" ? "Group Session" : "Individual Session"}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Customer Name/Student",
      selector: (row) => row.purchased_user.firstName,
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,

      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.purchased_user.firstName} {row.purchased_user.lastName}
            </span>
            <span className="sub-text">
              {row.tbl_child.firstName} {row.tbl_child.lastName}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Vendor/Resource",
      selector: (row) => "Akash yadav",
      minWidth: "120px",
      style: { minWidth: "120px" },
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.sold_by_user.firstName} {row.sold_by_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>
          </div>
        </div>
      ),
    },

    {
      name: <div dangerouslySetInnerHTML={{ __html: "Vendor's Payments" }} />,
      selector: (row) => row.tbl_payment_history.seller_remaining_amount,
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div>
          <span className="sub-text">
            Total Amount:{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(row.mentor_amount)}{" "}
          </span>

          <span className="sub-text">
            Transferred Amount:{" "}
            {row.transferred_amount != null
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row.transferred_amount)
              : "$0.00"}{" "}
          </span>
          <span className="sub-text">
            Balance Amount:{" "}
            {row?.mentor_amount != null
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row.mentor_amount - falsyToZero(row.transferred_amount))
              : "$0.00"}{" "}
          </span>
          {(row?.is_type === "pay" && (row.mentor_amount === "0.00" || row.mentor_amount === "0")) ||
          row.status === "Cancelled" ? (
            <span className="tb-lead">
              <span className="start-color">Session Status:{"Cancelled"}</span>
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      name: "View",
      selector: (row) => "",
      grow: 0,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item btn btn-md"
            onClick={() => {
              handleCustomerlistId(row);
            }}
          >
            <em className="icon ni ni-eye"></em>
          </button>
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1}</div>
        </>
      ),
    },
    {
      name: "Session date/time",
      selector: (row) => row.session_date,
      minWidth: "140px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{moment(row.session_date).format("MM-DD-YYYY")}</span>
            <span className="sub-text">
              {getTime(row.session_time)}-{getTime(row.session_end_time)}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Session Status",
      selector: (row) => "Attend",
      minWidth: "140px",
      style: { minWidth: "140px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.is_cancelled === "yes"
                ? "Cancelled"
                : moment(row.session_date).format("MM-DD-YYYY") >= date
                ? "Upcoming"
                : "Attended"}
              {row.is_cancelled === "yes" && row.cancel_by == 1
                ? " by Vendor"
                : row.is_cancelled === "yes" && row.cancel_by == 2
                ? " by Customer"
                : ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Payment Status",
      selector: (row) => "Attend",
      minWidth: "140px",
      style: { minWidth: "140px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
             {/* {state} */}
              {row?.cancel_by === "3"? row?.status === "Cancelled" ? "Full Refund": row?.status === "Partial"? "Partial Refund": row?.status : row?.stripe_refund_id? "Full Refund" : row?.status === "Partial"  ? "Partial Refund" : row?.status}
          </span>
          </div>
        </div>
      ),
    },
  ];
  const current = new Date();
  const date = moment(current).format("MM-DD-YYYY");

  let selectparentid;
  let selectchildid;

  const handleChildID = (e) => {
    selectchildid = e.target.value;
    setchildid(selectchildid);
  };

  const handleCustomer = (e) => {
    setGetCustomer(e.target.value);
  };
  const HandleScheduleType = (e) => {
    setScheduleType(e.target.value);
  };
  const getbookbalance = () => {
    makePostRequest(URLS.GET_BOOK_BALANCE, null, null, null).then((res) => {
      if (res.code == 200) {
        setBookBalance(res.data.total_amount);
      }
    });
  };

  const getallmentordata = () => {
    setLoader(true);
    let data = {
      user_id: parentid.value ? parentid.value : "",
      child_id: childid ? childid : "",
      mentor_id: mentorid.value ? mentorid.value : "",
      page: currentPage,
      limit: limitvalue ? limitvalue : 10,
      searchKey: search,
      resource_id: selectedResourse?.value ?? "",
      start_date: selectedfromdate ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      sortby: sortmentoring ? sortmentoring : "",
      schedule_type: scheduletype ? scheduletype : "",
      key: "",
    };
    makePostRequest(URLS.MENTORSESSION_GETADMINUSERDATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setmentordata(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    getcustomerdata2();
    getmentordata();
    getbookbalance();
    setLoader(true);
  }, []);

  useEffect(() => {
    getchilddata();
  }, [parentid.value]);

  const handleSearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getallmentordata();
      setCurrentPage(1);
    }
  };

  const clearfilterData = () => {
    setScheduleType("");
    setmentorid("");
    setparentid("");
    setchildid("");
    setlimitvalue(10);
    setGetCustomer("");
    setSearch("");
    setselectedResourse("");
    setSelectedToDate("");
    setSelectedfromDate("");
    setSortMentoring("");
    getallmentordata();
    setCurrentPage(1);

    setClearState(!clearState);
  };

  const clearfilter = () => {
    clearfilterData();
  };
  useEffect(() => {
    getallmentordata();
  }, [clearState]);

  const handleCustomerlistId = (sessiondetail) => {
    setchildsessiondetail(sessiondetail.tbl_user_mentoring_session_datetimes);
    setalldata(sessiondetail);
    setIsOpen(true);
  };
  const getcustomerdata = () => {
    setLoader(true);
    makePostRequest(URLS.MENTOR_CUSTOMER, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setchildparentdata(res.data?.data);
          setLoader(false);
          setparentid("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomerdata2 = () => {
    setLoader(true);
    makePostRequest(URLS.MENTOR_CUSTOMER, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setchildparentdata2((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getchilddata = () => {
    let data = {
      user_id: parentid.value,
    };

    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setchilddata(res.data);
          setchildid("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getmentordata = () => {
    setLoader(true);
    makePostRequest(URLS.MENTOR_PERANT, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setmentorsolddata((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefFilter = useRef(null);
  const selectRefUserName = useRef(null);
  const selectRefMentor = useRef(null);

  const handleMentor = () => {
    if (selectRefMentor.current) {
      setMentorName(!mentorName);
      selectRefMentor.current.focus();
    }
  };
  const handleuserName = (e) => {
    if (selectRefUserName.current) {
      setUserName(!userName);
      selectRefUserName.current.focus();
    }
  };
  const displaySelectFilter = (e) => {
    if (selectRefFilter.current) {
      setDisplayValueSelectFilter(!displayvalueSelectFilter);
      selectRefFilter.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (events) => {
    if (newRef1.current && !newRef1.current.contains(events.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Scheduled Sessions Purchased List"></Head>
      <Content>
        <ToastContainer />

        <div className="row">
          <div className="col-lg-6">
            <h3 className="nk-block-title page-title mb5 pb-4">Scheduled Sessions Purchased List</h3>
          </div>
        </div>

        <Card className="card-bordered">
          <>
            <Modal isOpen={isPayOpen} toggle={openpaymentModal}>
              <ModalHeader
                toggle={openpaymentModal}
                close={
                  <button className="close" onClick={openpaymentModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Transfer Payment History
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        {payAmount.mentor_amount > payAmount.transferred_amount ? (
                          <div>
                            <div>
                              <label>Enter Amount</label>
                              <input
                                type="Number"
                                className="form-control"
                                placeholder="enter amount"
                                onChange={handleamount}
                              />
                              <div style={{ color: "red" }}>{errorpay ? "Please enter amount" : ""}</div>
                              <button className="btn btn-primary mt-3" onClick={handlePayment}>
                                Pay
                              </button>
                            </div>

                            <hr></hr>

                            <table>
                              <tr className="border-bottom">
                                <th width="200px">Date</th>
                                <th width="200px">Transfer ID</th>
                                <th align="right">($)Amount</th>
                              </tr>
                              {transferamount &&
                                transferamount.map((val, i) => {
                                  return (
                                    <tr className="border-bottom">
                                      <td>{moment(val.created_at).format("MM-DD-YYYY")}</td>
                                      <td>{val.balance_transaction}</td>
                                      <td align="right">{val.trasfer_amount.toFixed(2)}</td>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                        ) : (
                          <div>
                            <table>
                              <tr className="border-bottom">
                                <th width="200px">Date</th>
                                <th width="200px">Transfer ID</th>
                                <th align="right">($)Amount</th>
                              </tr>
                              {transferamount &&
                                transferamount.map((val, i) => {
                                  return (
                                    <tr className="border-bottom">
                                      <td>{moment(val.created_at).format("MM-DD-YYYY")}</td>
                                      <td>{val.balance_transaction}</td>
                                      <td align="right">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(val.trasfer_amount)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td>Payment completed / Amount entered more than balance</td>
                              </tr>
                            </table>
                          </div>
                        )}
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Scheduled Session Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {alldata.session_type == "auto" ? (
                            <div>
                              <Card className="card-bordered mb-5">
                                <ul className="data-list is-compact">
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Total Amount</div>
                                      <div className="data-value">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(alldata.total_amount)}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Transaction ID</div>
                                      <div className="data-value" style={{ width: "50%" }}>
                                        {" "}
                                        {alldata.tbl_payment_history.payment_intent
                                          ? alldata.tbl_payment_history.payment_intent
                                          : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Mentor Invoice number</div>
                                      <div className="data-value">{alldata.invoice_number}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Mentor Invoice pdf link</div>
                                      <div className="data-value invoicePDF">
                                        <a href={S3_BUCKET_URL + alldata.mentor_invoice_pdf_link} target="_blank">
                                          View Invoice
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          ) : alldata.session_type == "manual" ? (
                            <div>
                              <Card className="card-bordered mb-5">
                                <ul className="data-list is-compact">
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Total Amount</div>
                                      <div className="data-value">
                                        {alldata?.total_amount
                                          ? new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            }).format(alldata?.total_amount)
                                          : "$0.00"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Tax Amount</div>
                                      <div className="data-value">
                                        {alldata?.tax_amount
                                          ? new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            }).format(alldata?.tax_amount)
                                          : "$0.00"}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Mentor Amount</div>
                                      <div className="data-value">
                                        {alldata?.mentor_amount
                                          ? new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                            }).format(alldata.mentor_amount)
                                          : "$0.00"}
                                      </div>
                                    </div>
                                  </li>

                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Transaction ID</div>
                                      <div className="data-value" style={{ width: "50%" }}>
                                        {" "}
                                        {alldata.tbl_payment_history.payment_intent
                                          ? alldata.tbl_payment_history.payment_intent
                                          : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Mentor Invoice number</div>
                                      <div className="data-value">{alldata.invoice_number}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Mentor Invoice pdf link</div>
                                      <div className="data-value invoicePDF">
                                        <a href={S3_BUCKET_URL + alldata.mentor_invoice_pdf_link} target="_blank">
                                          View Invoice
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          ) : null}

                          {loader ? (
                            <div className="overflowloader" id="preload">
                              <div className="circle-square" style={{ marginTop: "250px" }}>
                                <div className="red" style={{ background: "#AAC0CE" }}></div>
                                <div className="blue" style={{ background: "#265472" }}></div>
                                <div className="green" style={{ background: "#F19357" }}></div>
                                <div className="yellow" style={{ background: "#F0B285" }}></div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {childsessiondetail && (
                                <ReactDataTable data={childsessiondetail} columns={columns1} className="nk-tb-list" />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
          </>
          {loader ? (
            <div className="overflowloader" id="preload">
              <div className="circle-square" style={{ marginTop: "250px" }}>
                <div className="red" style={{ background: "#AAC0CE" }}></div>
                <div className="blue" style={{ background: "#265472" }}></div>
                <div className="green" style={{ background: "#F19357" }}></div>
                <div className="yellow" style={{ background: "#F0B285" }}></div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-inner relativeClass">
                {/* <h4 onClick={ShowHideSelect} className="cursorPointer">Filters <i className="fa fa-filter" aria-hidden="true"></i></h4> */}

                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 ">
                    <div className="filtersidebutton">
                      <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                        Filters
                        <FaFilter />
                      </h5>
                      <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}>
                        Clear Filters
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 d-flex  justify-content-end">
                    <select
                      value={sortmentoring}
                      className="form-select"
                      style={{ width: "170px" }}
                      onChange={HandleSortMentoring}
                    >
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCTITLE">Title Ascending</option>
                      <option value="DESCTITLE">Title Descending</option>
                    </select>

                    <div>
                      <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                    </div>
                    <div>
                      <select
                        value={limitvalue}
                        className="form-select"
                        style={{ width: "80px" }}
                        onChange={HandleLimitValue}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      &nbsp; &nbsp;
                      <button className="btn btn-primary " onClick={ExportEcelFile}>
                        <em className="icon ni ni-download-cloud"></em>
                        <span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
                <hr></hr>
                {show && (
                  <div>
                    <div className="row pb-2">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleuserName}
                            ></i>

                            <Select
                              className="form-select form-selectparent"
                              autosize={true}
                              onChange={(e) => setparentid(e)}
                              defaultValue={parentid}
                              options={[
                                { value: "", label: "All" },
                                ...(childparentdata2?.map((val) => ({
                                  value: val.user_id,
                                  label: val.purchased_user?.firstName + " " + val.purchased_user?.lastName,
                                })) || []),
                              ]}
                              openMenuOnFocus={userName}
                              ref={selectRefUserName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Student Name</strong>
                          </label>
                          <select
                            value={childid}
                            className="form-select"
                            onChange={(e) => {
                              handleChildID(e);
                            }}
                          >
                            <option value="">Select Student</option>
                            {childdata &&
                              childdata.map((val) => {
                                return (
                                  <>
                                    <option value={val.id}>
                                      {val.firstName} {val.lastName}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Schedule Type</strong>
                          </label>
                          <select value={scheduletype} className="form-select" onChange={HandleScheduleType}>
                            <option value="">Select Type</option>
                            <option value="2">Individual Session</option>
                            <option value="1">Group Session</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Vendor Name</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleMentor}
                            ></i>

                            <Select
                              className="form-select"
                              autosize={true}
                              onChange={(e) => setmentorid(e)}
                              defaultValue={mentorid}
                              // onChange={HandleCustomer}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={[
                                { value: "", label: "All" },
                                ...(mentorsolddata?.map((val) => ({
                                  value: val.mentor_id,
                                  label: val.sold_by_user?.firstName + " " + val.sold_by_user?.lastName,
                                })) || []),
                              ]}
                              openMenuOnFocus={mentorName}
                              ref={selectRefMentor}
                            />
                          </div>
                        </div>
                      </div>
                      {/* </div>
                    <div className="row pb-2"> */}

                      <div className="col-lg-3">
                        <div className="form-control startDate border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          {/* <input type="date" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon(() => setDatePickerShow(!datepickershow))}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              className="form-control date-picker"
                              open={datepickershow}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control endDate border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span
                                onClick={() => handleclickdatepickericon1(() => setDatePickerShow1(!datepickershow1))}
                              >
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              className="form-control date-picker"
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource</strong>
                          </label>

                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={displaySelectFilter}
                            ></i>
                            <Select
                              className="form-select"
                              autosize={true}
                              defaultValue={selectedResourse}
                              // value={selectedResourse}
                              onChange={(e) => setselectedResourse(e)}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={[
                                { value: "", label: "All" },
                                ...(resourceDropdown?.map((v) => ({
                                  value: v?.tbl_user_resource.id,
                                  label: v?.tbl_user_resource.resource_title,
                                })) || []),
                              ]}
                              openMenuOnFocus={displayvalueSelectFilter}
                              ref={selectRefFilter}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="outerTypeBox mb-3">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary" type="submit" onClick={handleSearch}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-inners relativeClass">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="clearboth"></div>

                      {mentordata.length > 0 ? (
                        <ReactDataTable
                          data={mentordata}
                          columns={columns}
                          className="nk-tb-list"
                          searchable={false}
                          setPageCount={setPageCount}
                          // pagination
                        />
                      ) : (
                        ""
                      )}

                      {mentordata?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </div>
            </>
          )}
        </Card>
      </Content>
    </React.Fragment>
  );
}
export default ListMentor;
