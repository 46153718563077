export const URLS = {
  LOGIN: "login",
  LOGOUT: "logout",
  USER_GET_ALL: "userGetAll",
  USER_GET: "userGet",
  CHANGE_PASSWORD: "changePassword",
  FORGET_PASSWORD: "forgetPassword",
  FORGET_CHANGE_PASSWORD: "forgetChangePassword",
  USER_UPDATE: "updateProfile",
  USER_GET_ALL_ROLE: "getAllRole",
  ADD_USER: "addUser",
  UPDATE_ROLE: "updateRole",
  ADD_ROLE: "addRole",
  ADD_TAX: "addTax",
  UPDATE_TAX: "updateTAX",
  GET_ALL_TAX: "getAllTax",
  GET_PERMISION: "getPermission?status=enable",
  UPDATE_PERMISION: "updatePermission",
  ADD_PERMISION: "addPermission",
  FILE_IMG_UPLOAD: "profileUpload",
  GET_ALL_GRADE_LEVEL: "getAllGradeLevel",
  UPDATE_GRADE_LEVEL: "updateGradeLevel",
  ADD_GRADE_LEVEL: "addGradeLevel",
  ADD_TOPIC_CATEGORY: "addTopicCategory",
  UPDATE_TOPIC_CATEGORY: "updateTopicCategory",
  GET_ALL_TOPIC_CATEGORY: "getAllTopicCategory",
  ADD_TOPIC_SUBJECT: "addTopicSubject",
  UPDATE_TOPIC_SUBJECT: "updateTopicSubject",
  GET_ALL_TOPIC_SUBJECT: "getAllTopicSubject",
  ADD_TOPIC: "addTopic",
  UPDATE_TOPIC: "updateTopic",
  GET_ALL_TOPIC: "getAllTopic",
  ADD_LEARNING_STYLE: "addLearningStyle",
  UPDATE_LEARNING_STYLE: "updateLearningStyle",
  GET_ALL_LEARNING_STYLE: "getAllLearningStyle",
  ADD_EDUCATIONAL_COURSE: "addEducationalCourses",
  UPDATE_EDUCATIONAL_COURSE: "updateEducationalCourses",
  GET_ALL_EDUCATIONAL_COURSE: "getAllEducationalCourses",
  ADD_SPECIAL_LEARNING: "addSpecialLearning",
  UPDATE_SPECIAL_LEARNING: "updateSpecialLearning",
  GET_ALL_SPECIAL_LEARNING: "getAllSpecialLearning",
  ADD_INSTRUCTION_STYLE: "addInstructionalStyle",
  UPDATE_INSTRUCTION_STYLE: "updateInstructionalStyle",
  GET_ALL_INSTRUCTION_STYLE: "getAllInstructionalStyle",
  ADD_STATES: "addStates",
  UPDATE_STATES: "updateStates",
  GET_ALL_STATES: "getAllStates",
  ADD_RESOURCE_TYPE: "addResourceType",
  UPDATE_RESOURCE_TYPE: "updateResourceType",
  GET_ALL_RESOURCE_TYPE: "getAllResourceType",
  ADD_LEARNING_PILLER: "addLearningPillar",
  UPLOADIMAGE_PILLER:"learningPillerUploadImage",
  UPDATE_LEARNING_PILLER: "updateLearningPillar",
  GET_ALL_LEARNING_PILLER: "getLearningPillar",
  ADD_PROMO_CODE: "addPromoCode",
  UPDATE_PROMO_CODE: "updatePromoCode",
  GET_ALL_PROMO_CODE: "getPromoCode",
  ADD_SUBSCRIPTION_PLAN: "addSubscriptionPlan",
  UPDATE_SUBSCRIPTION_PLAN: "updateSubscriptionPlan",
  GET_ALL_SUBSCRIPTION_PLAN: "getAllSubscriptionPlan",
  ADD_SYSTEM_SETTING: "addSystemSetting",
  UPDATE_SYSTEM_SETTING: "updateSystemSetting",
  GET_SYSTEM_SETTING: "getSystemSetting",
  ADD_USER_ROLE_PERMISSION: "addUserRolePermission",
  UPDATE_USER_ROLE_PERMISSION: "updateUserRolePermission",
  GET_ALL_USER_ROLE_PERMISSION: "getAllUserRolePermission",
  GET_ALL_CUSTOMER: "custmerGetAll",
  GET_ROLE_PERMISSIONS: "getRolePermission", //post
  ADD_ROLE_PERMISSION: "addRolePermission", //post
  UPDATE_ROLE_PERMISSION: "updateRolePermission", //Post
  DELETE_ROLE_PERMISSION: "deleteRolePermission", //Post
  CUSTOMER_GET_ONE: "custmerGetOne", //Post
  GET_ALL_RESOURCES: "resource/getAllResource",
  GetOne: "/resource/getOneResource",
  USER_ACTIVE_DEACTIVE: "userActiveDective",
  UPDATE_ADMIN_RESOURCE: "resource/updateAdminResource",
  GET_ALL_FACILITY_TYPE: "getAllFacilityType",
  UPDATE_FACILITY_TYPE: "updateFacilityType", 
  GET_ALL_FUNDING_TYPE: "getAllFundingType",
  ADD_FACILITY: "addFacilityType",
  ADD_FUNDING_TYPE: "addFundingType",
  UPDATE_FUNDING_TYPE: "updateFundingType",
  ADD_SUPPORT_TYPE: "addTypeSupport",
  GET_ALL_SUPPORT_TYPE: "getAllTypeSupport",
  UPDATE_SUPPORT_TYPE: "updateTypeSupport",
  ADD_EXPERIENCE_TYPE: "addTypeExperience",
  GET_ALL_EXPERIENCE_TYPE: "getAllTypeExperience",
  UPDATE_EXPERIENCE_TYPE: "updateTypeExperience",
  ADD_ORGANISATION_TYPE: "addTypeOrganisation",
  GET_ALL_ORGANISATION_TYPE: "getAllTypeOrganisation",
  UPDATE_ORGANISATION_TYPE: "updateTypeOrganisation",
  GET_ALL_RESOURCES_OFFENSIVE: "/resource/getAllResourceOffensive",
  UPDATE_RESOURCES_OFFENSIVE: "/resource/updateResourceOffensive",
  MENTORSESSION_GETADMINUSERDATA: "/mentorSession/getMSAdminUser",
  GETALL_CHILD:"/admin/getAllChildList",
  GET_ALL_SUBSCRIPTION_HISTORY:"getAllSubscriptionHistory",
  GET_SELECTED_DATA:"getOneSubscriptionHistory",
  CUSTOMER_FILTER:"/custmerGetAll",
  GET_USER_TYPE:"/getAllUserRole",
  userroleget:"/getAllUserRole",
  GET_ALL_ONLY_CUSTOMER:"custmer/getAll",
  GET_ALL_PURCHASEHISTORY:"/resource/getAllResourcePurchase",
  GET_ALL_KEYWORDS:"/getAllUniqueKeywords",
  getResourceById: "/resource/getOneResource",
//  getResourceById: "/resource/getOneResourceNew",
uploadContentSave: "/admin/resource/fileUploadSave",
deleteResourceContentFile: "/admin/resource/deleteResourceFile",
saveResources: "/admin/resource/add",
updateResources:"admin/resource/update",  
updateResourceStatus:"/admin/resource/resourceStatus",
contentRead:"/admin/resource/content",
GET_ALL_CONTENT_TYPE:"/getAllContentType",
ADD_CONTENT_TYPE:"/addContentType",
UPDATE_CONTENT_TYPE:"/updateContentType",
GET_ALL_CHECKLIST:"/getAllChecklist",
GET_MEMBERSHIP_TYPE:"/getAllHeading",
ADD_CHECK_LIST:"/addChecklist",
GET_SUB_HEADING:"/getAllSubheading",
GET_ALL_SUBSCRIPTION:"/getAllSubscriptionList", 
DELETE_CHECKLIST:"/deleteChecklist",
UPDATE_CHECKLIST:"/updateChecklist",
GET_ALL_HEADING:"/getAllHeading",
  GET_ALL_MASTERLIST:"/getAllSubscriptionlist",
  GET_ALL_SUBHEADING:"/getAllSubheading",
   GET_RESOURCE: "/admin/resource/get",
  GET_PAYMENT_HISTORY : "/paymentHistory",
  GET_COMMENTS_DETAILS:"/admin/resource/commentResource",
  GET_SHARED_DETAILS_RES:"/admin/resource/sharedResource",
  GET_LIKE_DETAILS:"/admin/resource/likeResource",
  GET_OFFENSIVE_DETAILS:"/admin/resource/getAllResourceOffensive",
  GET_PURCHASE_DETAILS_RES:"/admin/resource/getAllResourcePurchase",
  GET_REVIEW_DETAILS:"/admin/resource/getAllResourceReviews",
  GET_SAVED_DETAILS:"/admin/resource/getAllResourceSaved",
  GET_MENTORING_DETAILS_RESOURCE:"/admin/resource/mentoringResource",
  GET_RESOURCE_PURCHASE:"/customer/getAllResourcePurchase",
  GET_SAVED_RESOURCELIST:"/customer/savedResource",
  GET_SHARED_RESOURCELIST:"/customer/sharedResource",
  GET_CHILD_PERMISSION_SHARE:"/customer/sharePermissionShare",
  GET_REVIEW:"/customer/Review",
  GET_CUSTOMER_COMMENT:"/customer/Comment",
  GET_CUSTOMER_LIKE:"/customer/Like",
  GET_SUBSCRIPTION_PURCHASE:"/customer/SubscriptionPurcahse",
  GET_FIENDLIST:"/FriendList",
  GET_NON_MATCHED_FRIEND:"FriendRequestPending",
  GET_MENTORING_SESSION:"/MentoringSession",
  GET_NOTIFICATION : "/admin/notification/getAllMasterNotification",
  UPDATE_NOTIFICATION : "/admin/notification/updateMasterNotification",
  GET_SEARCH_HISTORY : "/admin/resource/getSearchHistory",
  GET_RESOURCE_INFORMATION:"/customer/getAllResource",
  RESOURCE_USER : "/admin/resource/resourceUser",
  RESOURCE_BUYER : "/admin/resource/resourceBuyer",
  RESOURCE_SELLER : "/admin/resource/resourceSeller",
  SEARCH_ANALYTICS_USER : "searchAnalyticsUser",
  GET_RESOURCE_OFFENSIVE : "getAllResourceOffensiveReport",
  GET_REPORTED_BY : "getAllOffensiveReportedBy",
  RESOURCE_PUR : "/admin/resource/resourcePurchase",
  MENTOR_CUSTOMER : "MentoringSessionCustomer",
  MENTOR_PERANT : "MentoringSessionMentor",
  GET_CHILD_DATA:"resourceSavedChild",
  CUSTOMER_EDIT:"/customer/update",
  GET_SHARE_LIST:"admin/resource/shareList",
  GET_ALLMASTERIMAGE:"/getAllMasterImage",
  UPDATE_MASTER_IMAGE:"/updateImage",
  UPDATE_MASTER_IMAGESS:"/updateImage",
  REVIEW_RESOURCE:"/admin/resource/resource/reviews",
  GET_ALL_GROUP:"/group/admin/getAllGroup",
  GET_USER_LIST:"/group/admin/getAlluserList",
  GETALL_USERLIST:"group/admin/getAllWithUserList",
  GET_ONE_GROUP:"/group/admin/getOneGroup",
  GET_MEMBER_GROUPLIST:"/group/admin/getMemberGroupList",
  GET_GROUPADMIN_LIST:"/group/admin/getMemberPendingAdmin",
  DELETE_GROUPMEMBERS:"/group/admin/removeGroupMember",
  DELETE_ADMIN_FROM_ACTIVEMEMBER:"group/admin/removeAdminGroupMember",
  DELETE_SUBMAINGROUP:"/group/admin/removeGroupToMain",
  DELETE_PENDING_ADMINS:"/group/admin/removeMemberPendingAdmin",
  PENDING_GROUPMEMBERS:"/group/admin/getMemberPending",
  GET_MEMBER_REQUEST:"/group/admin/getMemberRequest",
  GET_GROUP_TO_GROUP:"/group//admin/getGroupToGroup",
  GET_RESOURCE_MENTOR:"resourceget",
  friendList:"/friend/myFriendlist",
  getGroupList:"/group/admin/getListGroup",
  UPDATE_GROUP_STATUS:"group/admin/updateGroupStatus",
  GET_GROUP_FRIEND:"group/shareListGroup",
  GET_GROUP_FRIENDRESOURCE: "admin/resource/shareListResource",
  GET_ALL_GROUPRESOURCE:"group/admin/getAllGroupToResource",
  GROUPSALEHISTORY:"group//admin/getAdminGroupResourcesPurchase",
  GET_SUBSCRIPTION_PURCHASE_CUSTOMER:"subscriptionpurchase/Customer",
  timeZone:"master/getTimeZone",
  GET_CUSTOMER_UPDATE:"/customer/update",
  updateMyGroup:"/group/admin/updateGroup",
  groupImage:"/group//admin/imageGroupUpload",
  GET_GROUP_RESOURCE1:"group/admin/groupAdminResources",
  GET_GROUP_EVENT:"/group/admin/getListGroupEvent",
  GET_GROUP_EVENT_OPTIONS:"/group/admin/GroupEventSharedbyFilter",
  GET_GROUP_FEED:"/group//admin/getListGroupFeed",
  GET_ALL_RESOURCES_EXPORT:"resource/getAllResourceExport",
  GET_ALL_BUYERS_PAYMENT:"paymentHistory/Buyer",
  GET_ALL_SELLERS_PAYMENT:"paymentHistory/Seller",
  GET_BOOK_BALANCE:"getOutStandingBalanceMentoringSession",
  GET_RESOURCE_BALANCE:"getOutStandingBalanceResource",
  DO_STRIPE_TRANSFER:"transferStripeAmount",
  GET_TRANSFER_HISTORY:"/admin/getAllTrasfer",
  GETGROUPLIST_CUSTOMER:"/group/admin/getListGroupCustomer",
  GETGROUPLIST_RESOURCE:"/group/admin/getAllGroupToAdminResource",
  GET_CHAT_LIST:"group/admin/getSharedAdminChat",
  GET_BULLETIN:"group/admin/getAllGroupToAnnouncement",
  GET_ALL_CUSTOMER_EXPORT:"/custmerGetAllExport",
  GET_ALL_RESOURCES_OFFENSIVE_EXPORT:"/resource/getAllResourceOffensiveExport",
  GET_ALL_RESOURCES_PURCHASE_EXPORT:"/resource/getAllResourcePurchaseExport",
  GET_ALL_MENTORSESSION_EXPORT:"/mentorSession/getMSAdminUserExport",
  GET_ALL_SUBSCRIPTION_HISTORY_EXPORT:"getAllSubscriptionHistoryExport",
  GET_ALL_GROUP_EXPORT:"/group/admin/getAllGroupExport",
  GET_ALL_PAYMENT_HISTORY_EXPORT:"/paymentHistoryExport",
  GET_ALL_SEARCH_ANALYSTICS_EXPORT:"/admin/resource/getSearchHistoryExport",
  GETGROUPLIST_CUSTOMER:"/group/admin/getListGroupCustomer",
  GETGROUPLIST_RESOURCE:"/group/admin/getAllGroupToAdminResource",
  GETDASHBOARDQUICKDATA:"/reports/getDashboardQuickData",
  GET_DASHBOARD_GRAPH :"/reports/getDashboardGraph",
  GET_DASHBOARD_TODAY_DATA:"reports/getDashboardDataByDate",
  GET_DASHBOARD_RENEWALDATA:"/reports/getDashboardRenewalData",
  GET_CUSTOMER_DETAILS_REPORTS:"reports/getUserDataForeReport",
  GET_CUSTOMER_RETENTIONRATIO_REPORTS:"reports/getUserRetentionRatio",
  GET_CUSTOMER_UNSUBSCRIBED_REPORTS:"/reports/getUnsubscribeRates",
  GET_CUSTOMER_LOGGEDIN_CHARTS:"/reports/getUserLoggedInData",

  GET_CUSTOMER_LOGGEDIN_REPORTSLIST:"/reports/getUserLoggedInDataForList",
  SESSION_TRANSFER:"transferSessionAmount",
  RESOURCE_TRANSFER:"transferResourceAmount",
  GET_CUSTOMER_SEARCH_REPORTSLIST:"/reports/getSearchDataForList",
  GET_CUSTOMER_SEARCH_REPORTSCHART:"/reports/getSearchDataForGraph",
  GET_CUSTOMER_STORED_REPORTSLIST:"/reports/getStoredDataList",
  GET_CUSTOMER_STORED_REPORTSCHART:"/reports/getStoredDataGraph",
  GET_ALL_CHILD:"child/getAllChild",
  GET_CUSTOMER_SEARCH_REPORTUSER:"/reports/getSearchDataByUser",
  GET_CUSTOMER_PURCHASED_REPORTSCHART:"/reports/getPurchaseDataGraph",
  GET_PURCHASE_REPORTLIST:"/reports/getPurchaseDataList",
  GET_CUSTOMER_SHARED_REPORTSCHART:"reports/getShareDataGraph",
  GET_CUSTOMER_SHARED_REPORTLIST:"/reports/getShareDataList",
GET_FRIEND_REPORTSLIST:"/reports/getInvitedFriendDataList",
  GET_FRIEND_REPORTSCHART:"/reports/getInvitedFriendDataGraph",
  GET_USERDATA_GRAPH:"/reports/getUserDataGraphReport",
  GET_USERDATA_LIST:"/reports/getUserDataForeReport",
  GET_ALL_RESOURCESREPORT:"reports/getResourceReportData",
  GET_CUSTOMER_SAPCE_CHARTS:"/reports/getStoredDataUsedSpaceForGraph",
  GET_CUSTOMER_SPACE_REPORTSLIST:"/reports/getStoredDataUsedSpaceForList",
  TRANSFER_RESOURCE_AMOUNT:"transferResourceAmount",
  GET_LOGGING_DETAILS:"/reports/getUserLoggedInViewData",
  GET_SEARCHING_DETAILS:"/reports/getSearchDetails",
  GET_SAVING_DETAILS:"/reports/getStoreDetails",
  GET_SPACE_DETAILS:"reports/getStoredDataUsedSpaceDetails",
  GET_PURCHASE_DETAILS:"/reports/getPurchaseDataDetails",
  GET_SHARED_DETAILS:"/reports/getShareDataDetails",
  GET_SUBSCRIPTION_SALE_GRAPH:"reports/getSubscriptionPurchaseDataGraph",
  GET_SUBSCRIPTION_SALE_LIST:"/reports/getSubscriptionPurchaseDataList",
  GET_MENTORING_SALE_GRAPH:"reports/getMSPurchaseDataGraph",
  GET_RESOURCE_SALE_GRAPH:"/reports/getResourcePurchaseDataGraph",
  GET_MENTORING_SALE_LIST:"/reports/getMSPurchaseDataList",
  GET_RESOURCE_SALE_LIST:"/reports/getResourcePurchaseDataList",
  GET_RESOURCE_PAYMENT_DETAILS:"reports/getResourcePurchaseDataDetails",
  GET_ALL_GROUP_LIST:"group/admin/resourcePurchasedGroups",
GET_INVITED_FRIEND:"reports/getInvitedFriendDetails",
GET_MENTORING_DETAILS:"reports/getMSPurchaseDataDetails",
SORTFACILITY:"updateMasterFacility",
SORTSTATES:"updateMasterStates",
SORTFUNDING:"updateMasterFunding",
SORTSUPPORT:"updateMasterSupport",
SORTORGANISATION:"updateMasterOrganisation",
SORTEXPERIENCE:"updateMasterExperience",
SORTSUPPORT:"updateMasterSupport",
SORTINSTRUCTIONALSTYLE:"updateMasterInstructionalStyle",
SORTSPECIALLEARNING:"updateMasterSpecialLearning",
SORTLEARNINGSTYLE:"updateMasterLearningStyle",
SORTGRADELEVEL:"updateMasterGradeLevel",
SORTRESOURCETYPE:"updateMasterResourceType",
SORTLEARNINGPILLAR:"updateMasterLearningPillar",
SORTCONTENT:"updateMasterContent",
SORTROLE:"MasterupdateRole",
SORTSYSTEMSETTING:"MasterupdateSystemSetting",
SORTSUBSCRIPTIONPLAN:"updateMasterSubscriptionPlan",
SORTEDUCATIONALCOURSES:"updateMasterEducationalCourses",
GETALLNOTIFICATIONREPORT:"/admin/notification/getAllReportNotification",
UPDATENOTIFICATIONSTATUS:"/admin/notification/updateStatusNotification",
UPDATENOTIFICATIONREPORT:"/admin/notification/updateReportNotification",

GETALLSTRIPE:"/getAllStripe",
GETALLRESOURCEINFO:"/admin/resource/getAllMoreInfo",
GETALLRESOURCEENROLL:"/admin/resource/getAllEnroll",
GETAFFILIATEDSIGNUP:"/mentorSession/admin/getAllMentoringSignUp",

GETRESOURCEENROLLGRAPH:"/reports/getResourceEnrollDataGraph",
GETRESOURCEENROLLLIST:"/reports/getResourceEnrollDataList",

GETRESOURCEMOREINFOGRAPH:"/reports/getResourceGetMoreInfoDataGraph",
GETRESOURCEMOREINFOLIST:"/reports/getResourceGetMoreInfoDataList",

GETRESOURCESIGNUPGRAPH:"/reports/getResourceSignupDataGraph",
GETRESOURCESIGNUPLIST:"/reports/getResourceSignupDataList",

GETALLRESOURCE_MORE_INFO_FILTER:"/admin/resource/getAllResourceMoreInfo",
GETALLRESOURCE_ENROLLMENT_FILTER:"/admin/resource/getAllResourceEnroll",
GETALLRESOURCE_SIGNUP_FILTER:"/mentorSession/admin/getAllResourceMS",

GETALLCUSTOMER_MS_SIGNUP:"/mentorSession/admin/getAllCustomerMS",
GETALLMENTOR_MS_SIGNUP:"/mentorSession/admin/getAllMentorMS",
GETALLCHILD_MS_SIGNUP:"/mentorSession/admin/getAllChildMS",

GETALL_GROUP_OR_USER:"/reports/getAllResourceUserOrGroupMoreInfo",
GET_ALL_GROUPORUSER_ENROLLMENT:"/reports/getAllResourceUserOrGroupEnroll",
GET_ALL_GROUPORUSER_SIGNUP:"/reports/getAllResourceUserOrGroupSignUp",

FILE_SAVE:"/admin/resource/fileUploadSave",
REMOVE_FILE:"/admin/resource/removefile",
UPLOAD_SAVE:"/admin/resource/file/UploadSave",

SIDEBARIMAGE:"updateImage",
GET_ADMIN_USER:"/group/admin/getGroupAdmin",
VIDEO_UPLOAD:"/homePageVideo",
TEMP_UPLOAD:"/homePageVideoTemp",
GET_VIDEO:"/user/getHomePageVideo",

GROUP_IMAGE:"group/admin/imageGroupUpload",
DELETE_RESOURCE:"/admin/resource/deleteResource",

GROUP_DATA_GRAPH:"/reports/getResourceGroupDataGraph",
GROUP_DATA_LIST:"/reports/getResourceGroupDataList",
GET_GROUP_RESOURCE:"/reports/getResourceGroupClickList",
GET_GROUPCLICK_LIST:"/reports/getGroupClickList",
GET_GROUP_DETAIL:"/reports/getResourceGroupFilterDataList",

GROUP_RESOURCE_CLICK:"/reports/getGroupResourceClickGroup",
INDIVIDUAL_RESOURCE_CLICK:"reports/getResourceClickIndividual",
VIEW_GROUP_RESOURCE:"/reports/getGroupResourceClickGroupFilter",
GET_INDIVIDUAL_RESOURCE_USER:"/reports/getResourceCustomerFilter",
VIEW_INDIVIDUAL_RESOURCE:"/reports/getResourceClickIndividualFilter",
// CUSTOMER_FILTERDATA:"/reports/getResourceCustomerFilter",
CUSTOMER_FILTERDATA:"/reports/getResourceCustomerFilterGroup",
RESOURCE_FILTERDATA:"/reports/getResourceFilter",
STUDENT_FILTER_PERMISSIONSHARE:"/customer/ChildPermissionShare",
RESOURCE_PURCHASE_FILTER:"reports/ResourcePurchaseFilter",
RESOURCE_PURCHASE_CUSTOMER : "/admin/resource/resourceBuyer",
GET_ALL_MOBILE_VERSION:"/master/getAllMobileVersion",
UPDATE_VERSION:"/master/updateMobileVersion",
}



