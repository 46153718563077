export const dndDataSet1 = [
  { id: "1", text: "You can move these elements between these two containers." },
  { id: "2", text: "Moving them anywhere else isn't quite possible." },
  { id: "3", text: "Anything can be moved around." },
  { id: "4", text: "More interactive use cases lie ahead." },
];

export const dndDataSet2 = [
  {
    id: "5",
    text: "There's also the possibility of moving elements around in the same container, changing their position.",
  },
  { id: "6", text: "This is the default use case. You only need to specify the containers you want to use." },
  { id: "7", text: "Moving elements works just fine. You can still focus them, too. " },
];
