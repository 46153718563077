import React, { useEffect, useState } from "react";
import { Bar, HorizontalBar, Line } from "react-chartjs-2";
import {
  saleRevenue,
  activeSubscription,
  salesOverview,
  coinOverview,
  coinOverviewSet1,
  coinOverviewSet2,
  coinOverviewSet3,
  userActivity,
  orderOverviewSet1,
  orderOverviewSet2,
  orderOverviewSet3,
  userActivitySet2,
  userActivitySet3,
  userActivitySet4,
} from "./Data";

export const BarChart = (props ) => {
  console.log("data for chart",props.bardata);
  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      data={{
            // Name of the variables on x-axies for each bar
            labels: props.barlabel,
            datasets: [
              {
                // Label for bars
                // label: props.bartitle,
                // Data or value of your each variable
                data: props.bardata,
                // Color of each bar
                backgroundColor: ["cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "cyan", "magenta", "lime", "indigo", "teal", "maroon", "navy", "olive", "silver", "skyblue", "violet", "wheat", "salmon", "plum", "orchid", "khaki", "indianred", "gold", "fuchsia", "darkgreen", "darkblue", "coral", "chocolate", "cadetblue", "burlywood", "aquamarine", "aliceblue", "yellowgreen", "tomato", "thistle", "steelblue", "slateblue", "sienna", "seagreen", "sandybrown", "rosybrown", "royalblue", "powderblue", "palevioletred", "palegreen", "palegoldenrod", "orangered", "orange", "olivedrab", "navajowhite", "mediumvioletred", "mediumturquoise", "mediumspringgreen", "mediumslateblue", "mediumseagreen", "mediumorchid", "mediumpurple", "mediumblue", "mediumaquamarine", "maroon", "magenta", "limegreen", "lightyellow", "lightskyblue", "lightseagreen", "lightsalmon", "lightpink", "lightgrey", "lightgreen", "lightcoral", "lightblue", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightsteelblue", "lightyellow"],
                // Border color of each bar
                borderColor: ["cyan", "cyan", "cyan", "cyan"],
                borderWidth: 0.5,
              },
            ],
          }}
          
      options={{
        legend: {
          display: false,
        },
        title: {
              display: true,
              text: props.bartitle,
            },
        scales: {
          yAxes: [
            {
              display: true,
            },
          ],
          xAxes: [
            {
              display: true,
            },
          ],
        },
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "#eff6ff",
          titleFontSize: 11,
          titleFontColor: "#6783b8",
          titleMarginBottom: 4,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 10,
          bodySpacing: 3,
          yPadding: 8,
          xPadding: 8,
          footerMarginTop: 0,
          displayColors: false,
        },
      }}
    />
  );
};

export const LineChart = (props) => {
  // console.log("line",props.linedata)
  // console.log("line",props.linelabel)
  return (
    <Line
      className="sales-overview-chart"
      data={{
            // Name of the variables on x-axies for each bar
            labels: props.linelabel,
            datasets: [
              {
                // Label for bars
                label: "Sales overview",
                // Data or value of your each variable
                data: props.linedata,
                // Color of each bar
                // backgroundColor: ["aqua", "green", "red", "yellow", "blue", "orange", "purple", "pink", "brown", "grey", "black", "cyan", "magenta", "lime", "indigo", "teal", "maroon", "navy", "olive", "silver", "skyblue", "violet", "wheat", "salmon", "plum", "orchid", "khaki", "indianred", "gold", "fuchsia", "darkgreen", "darkblue", "coral", "chocolate", "cadetblue", "burlywood", "aquamarine", "aliceblue", "yellowgreen", "tomato", "thistle", "steelblue", "slateblue", "sienna", "seagreen", "sandybrown", "rosybrown", "royalblue", "powderblue", "palevioletred", "palegreen", "palegoldenrod", "orangered", "orange", "olivedrab", "navajowhite", "mediumvioletred", "mediumturquoise", "mediumspringgreen", "mediumslateblue", "mediumseagreen", "mediumorchid", "mediumpurple", "mediumblue", "mediumaquamarine", "maroon", "magenta", "limegreen", "lightyellow", "lightskyblue", "lightseagreen", "lightsalmon", "lightpink", "lightgrey", "lightgreen", "lightcoral", "lightblue", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightsteelblue", "lightyellow"],
                // // Border color of each bar
                // borderColor: ["aqua", "green", "red", "yellow"],
                // borderWidth: 0.5,
              },
            ],
          }}
      // data={salesOverview}
      options={{
        legend: {
          display: false,
        },
        
        title: {
              display: true,
              text: "Sales Overview",
            },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem["index"]];
            },
          },
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontColor: "#9eaecf",
                callback: function (value, index, values) {
                  return "$ " + value;
                },
                padding: 10,
                min: 0,
                stepSize: 20,
              },
              gridLines: {
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: 9,
                fontColor: "#9eaecf",
                source: "auto",
                padding: 10,
              },
              gridLines: {
                color: "transparent",
                tickMarkLength: 0,
                zeroLineColor: "transparent",
              },
            },
          ],
        },
      }}
    />
  );
};

export const DoubleBar = ({ state }) => {
  let object;
  const setData = (state) => {
    switch (state) {
      case "set2":
        object = orderOverviewSet2;
        break;
      case "set3":
        object = orderOverviewSet3;
        break;
      default:
        object = orderOverviewSet1;
    }
    return object;
  };
  return (
    <Bar
      className="chartjs-render-monitor"
      data={setData(state)}
      options={{
        legend: {
          display: false,
          labels: {
            boxWidth: 30,
            padding: 20,
            fontColor: "#6783b8",
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontColor: "#9eaecf",
                padding: 10,
                callback: function (value, index, values) {
                  return "$ " + value;
                },
                min: 100,
                max: 5000,
                stepSize: 1200,
              },
              gridLines: {
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: 9,
                fontColor: "#9eaecf",
                source: "auto",
                padding: 10,
                stepSize: 2400,
              },
              gridLines: {
                color: "transparent",
                tickMarkLength: 0,
                zeroLineColor: "transparent",
              },
            },
          ],
        },
      }}
    />
  );
};

export const HorizontalBarChart = ({ state }) => {
  const [data, setData] = useState(coinOverview);
  useEffect(() => {
    let object;
    if (state === "day") {
      object = coinOverviewSet3;
    } else if (state === "month") {
      object = coinOverviewSet2;
    } else {
      object = coinOverviewSet1;
    }
    setData(object);
  }, [state]);
  return (
    <HorizontalBar
      data={data}
      className="coin-overview-chart"
      options={{
        legend: {
          display: false,
          labels: {
            boxWidth: 30,
            padding: 20,
            fontColor: "#6783b8",
          },
        },
        indexAxis: "y",
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              stacked: true,
              ticks: {
                beginAtZero: true,
                padding: 0,
              },
              gridLines: {
                tickMarkLength: 0,
              },
            },
          ],
          xAxes: [
            {
              display: false,
              stacked: true,
              ticks: {
                fontSize: 9,
                fontColor: "#9eaecf",
                source: "auto",
                padding: 0,
              },
              gridLines: {
                color: "transparent",
                tickMarkLength: 0,
                zeroLineColor: "transparent",
              },
            },
          ],
        },
      }}
    />
  );
};

export const StackedBarChart = ({ state }) => {
  const [data, setData] = useState(userActivity);
  useEffect(() => {
    let object;
    if (state === "day") {
      object = userActivitySet2;
    } else if (state === "month") {
      object = userActivitySet3;
    } else {
      object = userActivitySet4;
    }
    setData(object);
  }, [state]);
  return (
    <Bar
      className="usera-activity-chart"
      data={data}
      options={{
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          backgroundColor: "#eff6ff",
          titleFontSize: 13,
          titleFontColor: "#6783b8",
          titleMarginBottom: 6,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 12,
          bodySpacing: 4,
          yPadding: 10,
          xPadding: 10,
          footerMarginTop: 0,
          displayColors: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              display: false,
              stacked: true,
            },
          ],
        },
      }}
    />
  );
};
