import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import {
  PreviewCard,
} from "../../../components/Component";
import bnb from "../../../images/coins/bnb.svg";
import { Card, Badge } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { S3_BUCKET_URL } from "../../../constants";
import { useHistory } from "react-router-dom";


function ViewUserDetails() {
	
  let history = useHistory();
  const params = useParams();
  let subscription_id = params.id;
  const [subscriptiondata, setSubscriptiondata] = useState("");
  const [loader, setLoader] = useState(false);
  const Handleselecteddata = () => {
    setLoader(true);
    let data = {
      id: subscription_id,
    };
    makePostRequest(URLS.GET_SELECTED_DATA, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setLoader(false);
          setSubscriptiondata(res.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Handleselecteddata();
  }, []);

  return (
    <>
      <Head title="Customer Details"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Details 
		 <button onClick={() => history.goBack()} className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end">
		   <em className="icon ni ni-arrow-left"></em><span>Back</span></button>
		</h3>
                  {loader ? (
                    <div className="sharepopupOuter backgroundOpacity1">
                      <ThreeDots
                        height="100%"
                        width="100%"
                        radius="9"
                        color="#08147e"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          margin: "200px auto",
                          width: "90px",
                          height: "90px",
                        }}
                        wrapperClassName="loader"
                        visible={true}
                      />
                    </div>
                  ) : (
   
                    <Card className="card-bordered">

<PreviewCard>	
		
    <div className="user-card">
    <div className="imguploadBox">
   
  {subscriptiondata.tbl_user && subscriptiondata.tbl_user.photo
   ? <img className="customerImg" src={S3_BUCKET_URL+subscriptiondata.tbl_user.photo}/>
   : <img src={bnb} alt="image" />}
 
    </div>
    
      <div className="user-info"> 
    
      <span className="lead-text">
      {subscriptiondata.tbl_user && subscriptiondata.tbl_user.firstName
                               ? subscriptiondata.tbl_user.firstName
                               : ""} {" "}{subscriptiondata.tbl_user && subscriptiondata.tbl_user.lastName
                                 ? subscriptiondata.tbl_user.lastName
                                 : ""}
      </span>
      <span className="sub-text">
      {subscriptiondata.tbl_user && subscriptiondata.tbl_user.email
                               ? subscriptiondata.tbl_user.email
                               : "--"}
                               </span>
      </div>
    </div>
   </PreviewCard> 
                      <ul className="data-list is-compact">
                        {/* <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Name</div>
                            <div className="data-value">
                              {" "}
                              {subscriptiondata.tbl_user && subscriptiondata.tbl_user.firstName
                                ? subscriptiondata.tbl_user.firstName
                                : ""} {" "}{subscriptiondata.tbl_user && subscriptiondata.tbl_user.lastName
                                  ? subscriptiondata.tbl_user.lastName
                                  : ""}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Email</div>
                            <div className="data-value"> {subscriptiondata.tbl_user && subscriptiondata.tbl_user.email
                                ? subscriptiondata.tbl_user.email
                                : "--"}</div>
                          </div>
                        </li> */}
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Phone Number</div>
                            <div className="data-value">
                              {" "}
                              {subscriptiondata.tbl_user && subscriptiondata.tbl_user.phone
                                ? subscriptiondata.tbl_user.phone
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">User Type</div>
                            <div className="data-value">
                              {" "}
                              {subscriptiondata.tbl_user && subscriptiondata.tbl_user.user_type
                                ? subscriptiondata.tbl_user.user_type
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Any Additional Job</div>
                            <div className="data-value text-break">
                              {subscriptiondata.tbl_user && subscriptiondata.tbl_user.any_additional_job
                                ? subscriptiondata.tbl_user.any_additional_job
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Faith Affiliation</div>
                            <div className="data-value text-soft">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.faith_affiliation
                                ? subscriptiondata.tbl_user.faith_affiliation
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Faith Background</div>
                            <div className="data-value">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.faith_affiliation
                                ? subscriptiondata.tbl_user.faith_affiliation
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Family Faith Background</div>
                            <div className="data-value">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.family_faith_background
                                ? subscriptiondata.tbl_user.family_faith_background
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Ideal Home School</div>
                            <div className="data-value">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.ideal_home_school
                                ? subscriptiondata.tbl_user.ideal_home_school
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Number Of Student</div>
                            <div className="data-value">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.number_of_child
                                ? subscriptiondata.tbl_user.number_of_child
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Stripe AccountId</div>
                            <div className="data-value">{subscriptiondata.tbl_user && subscriptiondata.tbl_user.stripe_accountId
                                ? subscriptiondata.tbl_user.stripe_accountId
                                : "--"}</div>
                          </div>
                        </li>
                      </ul>
                    </Card>
                  )}
                
      </Content>
    </>
  );
}
export default ViewUserDetails;
