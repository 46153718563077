import React, { useState,useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar
} from "../../../components/Component";

import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { BASE_URL, getHeaders, makePostRequest,makeFormDateRequset } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import axios from 'axios';
import { S3_BUCKET_URL } from "../../../constants";
import bnb from '../../../images/coins/bnb.svg';
import Switch from "react-switch";
import { findUpper } from "../../../utils/Utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
//import "bootstrap/dist/css/bootstrap.min.css";

function ManageLearningPiller() {


	
	const [name, setName] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [errorfile, setErrorFile] = useState('');
	const [rolesData, setRolesData] = useState([]);
	const [loader, setLoader] = useState(false);
	// const [sort, setSort] = useState('');
	//  const [blankError,setBlankError]=useState("")
	// const [valueerror, setValueError] = useState('');
	// const [errorsort, setErrorSort] = useState('');

	const [learningPiller, setLearningPiller] = useState({});
    const [expressionTextError, setErrorExpressionText] = useState('');
	const [switchState, setSwitchState] = useState("");
	const [imageId,setImageId]=useState("")
	
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]

	// const columns =  useMemo(()=> { return [
		
	// 	{
	// 		name: '#',
	// 		selector: row => row.id,
	// 		grow: 0,
	//         minWidth: "50px",
	// 		style: {minWidth: "50px" },
	// 		borderRadius: "4px",
	// 		cell: (row,index) => (
	// 			<>
	// 			<div className="user-card mt-2 mb-2"> {index+1} </div>

	// 		   </>
	// 		)
	// 	},
	// 	{
	// 		name: 'Name',
	// 		selector: row => row.name,
	// 		grow: 3,
	// 		sortable: true,
	// 		cell: (row) => (
	// 			<div className="user-card mt-2 mb-2">
	// 				<UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.name)} ></UserAvatar>
	// 				<div className="user-info">
	// 					<span className="tb-lead">
	// 						{row.name}{" "}
	// 					</span>
	// 				</div>
	// 			</div>
	// 		)
	// 	}, 
	// 	{
	// 		name: 'Image',
	// 		selector: row => row.image,
	// 		grow: 2,
	// 		cell: (row) => (
	// 			<div className="user-card mt-2 mb-2">
	// 			<div className="user-info">
	// 					<span className="tb-lead">
	// 					<img style={{width:"50px", display:"block"}} src={S3_BUCKET_URL+row.image}  alt="" />
	// 					</span>
	// 				</div>
	// 			</div>
	// 		)
	// 	},
						
	// 	{
	// 		name: 'Status',
	// 		selector: row => row.status,
	// 		cell: (row, index) => (
	// 			<>
	// 			{
	// 				row.status == "enable" &&
	// 				<div className="custom-control custom-switch">
	// 					<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
	// 					<label className="custom-control-label" htmlFor={row.id}>
	// 					</label>
	// 				</div>

	// 			}
	// 			{row.status == "disable" &&
	// 				<div className="custom-control custom-switch">
	// 					<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
	// 					<label className="custom-control-label" htmlFor={row.id}>
	// 					</label>
	// 				</div>
	// 			}

	// 		</>
	// 		)
	// 	},
		
	// 	{
	// 		name: 'Action',
	// 		selector: row => row.status,
	// 		cell: (row) => (
	// 			<div className="user-card mt-2 mb-2">
	// 				<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
	// 					<Icon name="edit"></Icon>
	// 				</button>
	// 			</div>
	// 		)
	// 	},
	// ]},[rolesData]);
	
	const handleswitch=(id, isSelected, index)=>{
		setLoader(true);
		let data = {
			id: id,
			status: isSelected,
			
		}
		switchState?setSwitchState("enable"):setSwitchState("disable");
		const newArray = Array.from(rolesData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setRolesData(newArray);
	
		makePostRequest(URLS.UPDATE_LEARNING_PILLER, null, data, null)
			.then(res => {
				if(res.code === 200){
					setName('');
					toast.success("Status updated successfully.");
				     setLoader(false); 
					getAllRoles();
			 	   setIsUpdate(false);
					
				}
				else  {
					 toast.warn("Status disable successfully.")
				}
			})
	}

	 //console.log("button",switchState)
 
	const handleName = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...learningPiller };
		let str = e.target.value;
		obj.name = str;
		setLearningPiller({ ...obj })
	}
	// const handleSort = (e) => {
	// 	setSort(e.target.value);
	// 	setErrorSort("");
	// 	setBlankError("");
	// 	setValueError("");
	// }

	const [payloadarraypass, setPayloadarraypass] = useState([]);
	let payloadarray=[];
   let tempData=[];

	const handleDragEnd = (e) => {
	   
	   if (!e.destination) return;
		tempData = Array.from(rolesData);
	   let [source_data] = tempData.splice(e.source.index, 1);
	   tempData.splice(e.destination.index, 0, source_data);
	   setRolesData(tempData);

	   console.log(e,"result",tempData,"updateddata")


	   tempData.length>0  && tempData.map((val,index) => {
		   payloadarray.push({"sort":tempData.length-index ,"id":val.id})
		   
		 })
		 setPayloadarraypass(payloadarray)
	 };

	  console.log(payloadarraypass,"payloadarraypass")





    let char=(/^[a-zA-Z\s]+$/); 
	
let num=(/^[0-9]+$/);

	const Addlearningpiller = () => {
    
		if (name == "") {
			setErrorName('Please enter learning pillar name.');
		}
		// else if(sort== ""){
		// 	setBlankError("Please enter sort number.")
		// }
		else if(!name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
		// else if(!sort.match(num)){
		// 	setErrorSort("please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }
		else if (file == null) {
			setErrorFile('Please upload image.');
		}	

		else {

			
				 //addImage();
			 addNewPillar();
			setLoader(true);
			
		}
	}

	const updatelearningpiller=()=>{
		if (learningPiller.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorName('Please enter learning pillar name.');
		}
		
// else if(sort== ""){
// 	setBlankError("Please enter sort number.")
// }
		else if(!learningPiller.name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
		else if (learningPiller.file == "") {
			setErrorFile('Please upload image.');
		}	
		// else if(isNaN(sort)){
		// 	setErrorSort("please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }
 
		else if (isUpdate) {
			// handleupdateImage();	
			updateNewPillar();
		}
	}

    const [thumbnail, setThumbnail] = useState(null);
	const [file, setFile] = useState(null);
	//console.log(file,"file");
		 const handleFileUpload = (event) => {
			//console.log(event.target.files[0],"event")
				setFile(event.target.files[0]);
				//setFile(URL.createObjectURL(event.target.files[0]));

				setThumbnail( URL.createObjectURL(event.target.files[0]));
				 
				 setErrorFile('');
								
			  }

	useEffect(() => {
		getAllRoles();
		
	}, []);
    
	const getAllRoles = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, {}, null)
			.then(res => {
				if (res.code == 200) {
					let img  = res.data.image ? S3_BUCKET_URL+res.data.image : '';
                      setThumbnail(img);					
					setRolesData(res.data);
					//console.log("key",res.data);
				   setLoader(false);
				  // console.log("keysdasd",res);
				   
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}
	// console.log("Images" , thumbnail);

	 const selectedLearningPillerData = () => {
      let obj = {id:'',name:'',image:''}
	    setLearningPiller(obj);
     }
   useEffect(()=> {
	  	  selectedLearningPillerData();
		                 
   },[])
  
	const handleEditButton = (val) => {
		setErrorName('');
		setErrorFile('');
		setIsUpdate(true);
		let obj = {id:val.id,name:val.name,image:val.image}
		setLearningPiller(obj);
		let img = val.image ? S3_BUCKET_URL + val.image : "";
        setThumbnail(img);
		// setSort(val.sort)
		//console.log("Test",val);
         window.scroll({
			top:0,
			behavior:"smooth"
		})		
	}
//console.log(imageId,"imageid");

	const addNewPillar = () => {
		let data = {	
		   name: name,
		//    sort:sort,
		}
		makePostRequest(URLS. ADD_LEARNING_PILLER, null, data, null)
			.then(res => {
			//console.log(res,"resid")
			 if(res.code==201) {

				if(file){

					let formData = new FormData();

			formData.append("id",res.data.id );
			formData.append("image",file);
			//console.log("formdata add",formData)
			// for(var pair of formData.entries()) {
			// 	console.log(`${pair[0]}: ${pair[1]}`,"Addform");
			//   }

              makeFormDateRequset(URLS.UPLOADIMAGE_PILLER, formData)
                .then((res) => {
                 // console.log("resimg::", res)
			
				})
			

				}


				setImageId(res.data.id)
			  setName('');	
			//   setSort("");
					let boj = {name:'', file:'',image:''};
				 setLearningPiller(boj);
				 toast.success(res.message);	
				 getAllRoles();
				 setIsUpdate(false);
				 setLearningPiller({});
				}
				else if (res.code == 400) {
					toast.error(res.message)
					setLoader(false)
				}
			})

	}



		 const updateNewPillar = () => {
			let data = {
				id:learningPiller.id,
			   name:learningPiller.name,
			//    sort:sort,
			}
			//console.log("update payload",data)
			makePostRequest(URLS.UPDATE_LEARNING_PILLER, null, data, null)
				.then(res => {
					
					//console.log(res,"ress:")
				 if(res.code == 200) {	
					if(file){

						let formData = new FormData();
	
				formData.append("id",res.data.id );
				formData.append("image",file);
				//console.log("formdata add",formData)
				// for(var pair of formData.entries()) {
				// 	console.log(`${pair[0]}: ${pair[1]}`,"Addform");
				//   }
	
				  makeFormDateRequset(URLS.UPLOADIMAGE_PILLER, formData)
					.then((res) => {
					//  console.log("resimg::", res)
					})
				
	
					}

					let boj = {name:'', file:'',image:''};
					 setLearningPiller(boj);
					 toast.success(res.message);	
					 getAllRoles();
					//  setName('');	
				//   setSort("");
					 setIsUpdate(false);
					 setLearningPiller({});
					}
					else if (res.code == 400) {
						toast.error(res.message)
						setLoader(false)
					}
				})
		}

		const SortLearningPiller = () => {
			let data = 
			  payloadarraypass?payloadarraypass:[]
				
			
		 //console.log(data," payload data")
			makePostRequest(URLS.SORTLEARNINGPILLAR, null, data, null)
				.then(res => {
					//console.log(res)
					if (res.code == 200) {
						
						toast.success(res.message);
						getAllRoles();
					}
					else if (res.code == 400) {
						setLoader(false);
						toast.error(res.message);
					}
				})
	
		}
	
		useEffect(() => {
			if(payloadarraypass.length > 0){
			SortLearningPiller();
			}
		}, [payloadarraypass]);

	return (
		<React.Fragment>
			<Head title="Learning Pillar"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Manage Learning Pillars</h3>
				{ loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ):(
			  <Card className="card-bordered">	
				<div className="card-inner-lg">
					<BlockHeadContent>
					
					
						<BlockHead size="lg">
							<div className="">
								<div className="row">
								   
								   <div className="col-lg-4">
									   <div className="form-group">
									{isUpdate===false?<Label className="form-label">
										{/* Add Learning Pillar */}{""}
										</Label>:
										<Label className="form-label">
									 Edit Learning Pillar
										</Label>
                                     }	

                                 {isUpdate===true ?
										<div className="form-control-wrap">
											<Input name="name" placeholder="Learning Pillar Name" type="text" value={learningPiller.name} onChange={handleName} />
											<span className="error"> {errorname} </span>
                    						<span className="error"> {expressionTextError} </span>			
									     </div>
                                          :""}
										</div> 
										</div> 

										{isUpdate===true ?
			                           <div className="col-lg-4">
										  <div className="form-group">
										<Label className="form-label">
										  Image Upload
										</Label>
										  <div className="clear"></div>
										  <div className="imguploadBox float-start">
							  
							  <img src={thumbnail ? thumbnail : bnb} alt="images" />
							  	   
								</div>
	<input className="float-start pillerImgupload" type="file" onChange={handleFileUpload} accept="image/png, image/jpeg ,image/gif" />
				<div className="clearNone"> </div>
									<span className="error"> {errorfile} </span>					
									</div>
									
									
									</div>
									:""}

									{isUpdate===false?
                                    //  <ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3">
									// 	<li><button className="btn btn-primary btn-md" type="submit" onClick={Addlearningpiller}>Add </button></li>

									// </ul>
									""
									:
									<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={updatelearningpiller}>Update </button></li>
                           
									</ul>
	                              }
								</div>
								

							</div>
         
                     {/* {isUpdate===false&&
				     <div className="outerBox">
						 {rolesData.length &&
								<ReactDataTable
									data={rolesData}
									columns={columns}
									className="nk-tb-list"
                                    searchable={true}
									pagination
								/>
							}
                      </div>} */}

{isUpdate === false &&(
<DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr className="p-3 bg-white border border-light round-lg mb-3">
				
              <th />
			  <th>#</th>
              <th>Name</th>
			  <th>Image</th>
              <th>Status</th>
			  <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody 
                 className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {rolesData?.map((user, index) => (
                  <Draggable
                    key={user.name}
                    draggableId={user.name}
                    index={index}
                  >
                    {(provider) => (
                      <tr {...provider.draggableProps} ref={provider.innerRef}  className="p-3 bg-white border border-light round-lg mb-3">
                        <td {...provider.dragHandleProps}><div className="user-card mt-3 mb-2"> <FontAwesomeIcon icon={faGripVertical} /> </div></td>
						<td><div className="user-card mt-3 mb-2">{index+1}</div></td>
                        <td>
						
						{	/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(user.name)} ></UserAvatar> */ }
					<div className="user-card mt-3 mb-2">
					<div className="user-info">
						<span className="userName">
							{user.name}{" "}
						</span>
					</div>
				</div>
					</td>
                       

<td>
					<div className="user-card mt-2 mb-2">
	 			<div className="user-info">
	 					<span className="tb-lead">
	 					<img style={{width:"50px", display:"block"}} src={S3_BUCKET_URL+user.image}  alt="" />
	 					</span>
	 				</div>
	 			</div>
	</td>
                        <td>
						<>
	 			{
	 				user.status == "enable" &&
					<div className="user-card mt-3 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "disable", index)} checked />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>

	 			}
	 			{user.status == "disable" &&
				<div className="user-card mt-3 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "enable", index)} />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>
	 			}

	 		</>
	</td>
					<td>
					<div className="user-card mt-2 mb-2">
				<button className="dropdown-item" onClick={() => { handleEditButton(user) }}>
					<Icon name="edit"></Icon>
				</button>
			</div>
					</td>

                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
)}


	 
						</BlockHead>
					</BlockHeadContent>
				</div>
				</Card>
		  )}
			</Content>

		</React.Fragment>
	);
};

export default ManageLearningPiller;
