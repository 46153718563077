
import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { ToastContainer, toast } from "react-toastify";
import { BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import { Card, Collapse, Input } from "reactstrap";
import { useEffect } from "react";
import { makeGetRequest, makePostRequest } from "../../../service/api";
import { URLS } from "../../../constants";

const SystemDefaultSetting = () => {

  const [notificationData, setNotificationData] = useState([]);
  const [currentFocusId, setcurrentFocusId] = useState("");
  const [loader, setLoader] = useState(false)

  // console.log(currentFocusId,"currentFocusId");

  const getNotification = (data) => {
    if(data) setLoader(true)
    makeGetRequest(URLS.GETALLNOTIFICATIONREPORT, null, null)
      .then((res) => {
        console.log("response", res);
        if (res.code == 200) {
          setNotificationData(res?.data);
        }
        setLoader(false)
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };

  const onUpdate = (v) => {
    const body = {
    };
    // setLoader(true)
    console.log(body,"data")
    makePostRequest(URLS.UPDATENOTIFICATIONREPORT, null, v, null)
      .then((res) => {
        console.log("response", res);
        if (res.code == 200) {
          toast.success(res?.message);
          getNotification(false);
          handleStatus();
        }
      })
      .catch((error) => {
        // setLoader(false)
        console.log(error);
      });
  };

  useEffect(() => {
    getNotification(true);
  }, []);





  const handleStatus = (p_id,isSelect, name) => {
    const body = {
      "id":p_id,
    "message_email_status":isSelect == true ? "enable":"disable",
    };
    // setLoader(true)
    console.log(body,"data") 
    console.log("name", p_id, name);
    const data = [...notificationData];
    const newData = data?.map((c) => {
     
          if (c?.id == p_id) {
            return { ...c, [name]: c[name] == "enable" ? "disable" : "enable" };
          } else {
            return c;
          }
        });
       
    console.log("newData", newData);
    setNotificationData(newData);

    makePostRequest(URLS.UPDATENOTIFICATIONSTATUS, null, body, null)
      .then((res) => {
        console.log("response", res);
        if (res.code == 200) {
          //toast.success(res?.message);
          // getNotification(false);
        }
      })
      .catch((error) => {
        // setLoader(false)
        console.log(error);
      });
  };

  const selectDynamic = (p_id,name) => {
    const inputField = document.getElementById(currentFocusId);
    if (inputField) {
      inputField.focus();
      let id = currentFocusId?.split(".")[1];
      var cursorPosition = inputField.selectionStart;
      var currentValue = inputField.value;
      var updatedValue = currentValue.substring(0, cursorPosition) + name + currentValue.substring(cursorPosition);

      const data = [...notificationData];
      let newData = data?.map((c) => {
        if (c?.id == p_id) {
          return { ...c, [id]:updatedValue };
        } else {
          return c;
        }
      });
      
  //console.log("newData", newData);
  setNotificationData(newData);
    }
  };

  const toggleOuter = (id) => {
    let data = [...notificationData];
    let newData = data?.map((v) => {
      if (v?.id == id) {
        return { ...v, isOpen: !v?.isOpen };
      } else {
        return v;
      }
    });

    setNotificationData(newData);
  };

  // const toggleInner = (p_id, c_id) => {
   
  //    const data = [...notificationData];
  //   const newData = data?.map((v) => {
  //     if (v?.id == p_id) {
  //       let dd = v?.tbl_notification_masters?.map((c) => {
  //         if (c?.id == c_id) {
  //           return { ...c, isOpen: !c?.isOpen };
  //         } else {
  //           return c;
  //         }
  //       });
  //       return { ...v, tbl_notification_masters: dd };
  //     } else {
  //       return v;
  //     }
  //   });
  //   //console.log("newData", newData);
  //   setNotificationData(newData);
  // };

  const onMessageChange = (index, event,v_id) => {
    let id = currentFocusId?.split(".")[1];
    let data=[...notificationData];
    // let updatevalueddata = event.target.name;
    // data[index][updatevalueddata] = event.target.value;
        let newData = data?.map((c) => {
          if (c?.id == v_id) {
            return { ...c, [id]: event.target.value };
          } else {
            return c;
          }
        });
    // console.log("newData", newData);
    setNotificationData(newData);
  }
 

  return (
    <React.Fragment>
      <Head title="Notification Setting"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">System Default Notification</h3>
        <Card className="card-bordered">
        {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : (
<>
          <div className="card-inner">
            <BlockHead size="lg">
              <BlockHeadContent>
                <div className="accordion">
                  {notificationData?.map((v,index) => (
                    <div className="accordion-item">
                      <div className="accordion-head collapsed cursorPointer" data-bs-toggle="collapse" onClick={() => toggleOuter(v?.id)}>
                        <h6 className="title">{v?.meassge_type}</h6>
                        <span className="accordion-icon"></span>
                      </div>
                      <Collapse className="accordion-body" isOpen={v?.isOpen}>
                        <div className="accordion-inner">
                          {/* ------------------------------------------- */}

                          {/* <div className="accordion">
                            {notificationData?.map((g) => (
                              <div className="accordion-item"> */}
                                {/* <div className="accordion-head collapsed cursorPointer"  onClick={() => toggleInner(v?.id, g?.id)}>
                                  <h6 className="title">{g?.meassge_type}</h6>
                                  <span className="accordion-icon"></span>
                                </div> */}
                                {/* <Collapse className="accordion-body" isOpen={g?.isOpen}> */}
                                  {/* <div className="accordion-inner"> */}
                                   
                                  <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                    <div style={{ width: "20%"}}>
                                       <h6 className="title m-0"> Email Subject</h6> 
                                       </div>
                                      <div style={{ width: "70%"}}>
                                        <Input
                                          name="name"
                                          placeholder="search..."
                                          id={v?.id + ".meassge_type_subject"}
                                          type="textarea"
                                          value={v?.meassge_type_subject}
                                          onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                          onChange={(event) =>
                                            onMessageChange(index, event,v.id)
                                          }
                                        />
                                      </div>
                                      <div className="" style={{ width: "10%"}}>
                                        {/* <input
                                          className="form-check-input cursorPointer"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                          checked={v?.message_email_status == "enable" ? true : false}
                                          onChange={(e) => handleStatus(v?.id, e.target.checked ,"message_email_status")}
                                        /> */}
                                      </div>
                                    </div>


                                    <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                      <div style={{ width: "20%"}}>
                                      <h6 className="title m-0">Email</h6>
                                      </div>
                                      <div style={{ width: "70%"}}>
                                        <Input
                                          name="name"
                                          placeholder="search..."
                                          id={v?.id + ".meassage_email"}
                                          type="textarea"
                                          value={v?.meassage_email}
                                          onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                          onChange={(event) =>
                                            onMessageChange(index, event,v.id)
                                          }
                                        />
                                      </div>
                                      <div className="notification-switch form-check form-switch form-switch-sm" style={{ width: "10%"}}>
                                        <input
                                        style={{margin:"0 auto"}}
                                          className="form-check-input cursorPointer"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                          checked={v?.message_email_status == "enable" ? true : false}
                                          onChange={(e) => handleStatus(v?.id, e.target.checked ,"message_email_status")}
                                        />
                                      </div>
                                    </div>

                                    {/* <div className="mt-3 ms-3 text-bold">
                                     
                                      Message parameter -{" "}
                                      {v?.dynamice_parmeter?.split(",")?.map((a) => (
                                        <span className="cursorPointer" onClick={(e) => selectDynamic(v?.id, a)}>{a}{" "}</span>
                                      ))}
                                    </div> */}


                                    <div className="mt-3 ms-3 text-bold">
  {v?.dynamice_parmeter ? (
    <>
      Message parameter -{" "}
      {v?.dynamice_parmeter?.split(",")?.map((a) => (
        <span key={a} className="cursorPointer" onClick={(e) => selectDynamic(v?.id, a)}>
          {a}{" "}
        </span>
      ))}
    </>
  ) : null}
</div>


                                    <div className="text-center">
                                      <button
                                        className="btn btn-md btn-primary mt-2"
                                       
                                        onClick={()=>onUpdate(v)}
                                      >
                                        {" "}
                                        Submit{" "}
                                      </button>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            ))} 
                          </div>

                          {/* ------------------------------------------- */}
                        {/* </div> */}
                      {/* </Collapse> */}
                    {/* </div> */}
                  {/* ))} */}
                {/* </div> */}
              </BlockHeadContent>
            </BlockHead>
          </div>
          </>  )}
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default SystemDefaultSetting;