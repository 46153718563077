import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
	Block,
	BlockTitle,
	BlockBetween,
	BlockDes,
	Icon,
	Row,
	Col,
	Button,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	ReactDataTable,
	UserAvatar
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge, Form } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import { findUpper } from "../../../utils/Utils";
import Switch from "react-switch";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
//import "bootstrap/dist/css/bootstrap.min.css";

function TypeofFacility() {

	const [getAllFacility, setGetAllFacility] = useState([]);
	const [selectedFacility, setSelectedFacility] = useState({});
	// const [sort, setSort] = useState('');
	// const [blankError,setBlankError]=useState("")
	// const [valueerror, setValueError] = useState('');
	// const [errorsort, setErrorSort] = useState('');

	const [name, setName] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);

	const [expressionTextError, setErrorExpressionText] = useState('');
	const [errorname, setErrorName] = useState('');
	const [switchState, setSwitchState] = useState("");

	const [loader, setLoader] = useState(false);
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]

	let payloadarray=[];
	let tempData=[]

	const [payloadarraypass, setPayloadarraypass] = useState([]);
	// View Facility Column Calling Here ....

	// const columnsFace = useMemo(() => {
	// 	return [
	// 		{
	// 			name: "#",
	// 			selector: (row) => row.id,
	// 			grow: 0,
	// 			minWidth: "50px",
	// 			style: { minWidth: "50px" },
			
	// 			cell: (row, index) => <div className="user-card mt-2 mb-2"> {index + 1} </div>,
	// 		},
	// 		{
	// 			name: "Name",
	// 			selector: (row) => row.name,
	// 			grow: 2,
	// 			sortable: true,
	// 			cell: (row) => (
	// 				<div className="user-card mt-2 mb-2">
	// 					<UserAvatar
	// 						theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
	// 						text={findUpper(row.name)}
	// 					></UserAvatar>
	// 					<div className="user-info">
	// 						<span className="tb-lead">{row.name} </span>
	// 					</div>
	// 				</div>
	// 			),
	// 		},
	// 		{
	// 			name: 'Status',
	// 			selector: row => row.status,
	// 			cell: (row, index) => (
	// 				<>
	// 					{
	// 						row.status == "enable" &&
	// 						<div className="custom-control custom-switch">
	// 							<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
	// 							<label className="custom-control-label" htmlFor={row.id}>
	// 							</label>
	// 						</div>

	// 					}
	// 					{row.status == "disable" &&
	// 						<div className="custom-control custom-switch">
	// 							<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
	// 							<label className="custom-control-label" htmlFor={row.id}>
	// 							</label>
	// 						</div>
	// 					}

	// 				</>
	// 			)
	// 		},

	// 		{
	// 			name: "Action",
	// 			selector: (row) => row.status,
	// 			cell: (row) => (
	// 				<div className="user-card mt-2 mb-2">
	// 					<button
	// 						className="dropdown-item"
	// 						onClick={() => {
	// 							handleFacilityEditButton(row);
	// 						}}
	// 					>
	// 						<Icon name="edit"></Icon>
	// 					</button>
	// 				</div>
	// 			),
	// 		},
	// 	]g
	// }, [getAllFacility]);

	// View Facility API Calling Here ....


	const handleswitch = (id, isSelected, index) => {
		setLoader(true);
		let data = {
			id: id,
			status: isSelected,

		}
		switchState ? setSwitchState("enable") : setSwitchState("disable");
		const newArray = Array.from(getAllFacility);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setGetAllFacility(newArray);

		makePostRequest(URLS.UPDATE_FACILITY_TYPE, null, data, null)
			.then(res => {
				if (res.code === 200) {
					setName('');
					toast.success("Status updated successfully.");
					setLoader(false);
					handlViewFacility();
					setIsUpdate(false);
					setGetAllFacility({});

				}
				else {
					toast.warn("Status disable successfully.")
				}
			})
	}

	//console.log("button",switchState)


	const handleDragEnd = (e) => {
		
		if (!e.destination) return;
		 tempData = Array.from(getAllFacility);
		let [source_data] = tempData.splice(e.source.index, 1);
		tempData.splice(e.destination.index, 0, source_data);
		setGetAllFacility(tempData);

		//console.log(e,"result",tempData,"updateddata")


		tempData.length>0  && tempData?.map((val,index) => {
			payloadarray.push({"sort":tempData.length-index ,"id":val.id})
			
		  })
		  setPayloadarraypass(payloadarray)
	  };

      // console.log(payloadarraypass,"payloadarraypass")


	const handleName = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedFacility };
		let str = e.target.value;
		obj.name = str;
		setSelectedFacility({ ...obj })
	}

	// const handleSort = (e) => {
	// 	setSort(e.target.value);
	// 	setErrorSort("");
	// 	setBlankError("");
	// 	setValueError("");
	// }
	let char = (/^[a-zA-Z\s]+$/);
	let num=(/^[0-9]+$/);

	const AddFacilityHandler = () => {

		if (name == "") {
			setErrorName('Please enter type of facility.');
		}
		// else if(sort== ""){
		// 	setBlankError("Please enter sort number.")
		// }
		else if (!name.match(char) && !name.match(char)) {
			setErrorExpressionText("Please enter character only.")
		}
		// else if(!sort.match(num)){
		// 	setErrorSort("Please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }

		else {
			addNewFacility();
			setLoader(true);
		}
	}

	const HandleButtonUpdateFacility = () => {

		if (selectedFacility.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorName('Please enter type of facility.');
		}
		// else if(sort== ""){
		// 	setBlankError("Please enter sort number.")
		// }
		else if (!selectedFacility.name.match(char)) {
			setErrorExpressionText("Please enter character only.")
		}
		// else if(isNaN(sort)){
		// 	setErrorSort("Please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }

		else if (isUpdate) {
			handleupdateFacility();
			setLoader(true);

		}

	}

	// [
	// 	{
	// 		"sort":7,
	// 		"id":2
	// 	},
	// 	{
	// 		"sort":8,
	// 		"id":3
	// 	}
	// ]
	
	
	
	const SortFacility = () => {
		let data = 
      	payloadarraypass?payloadarraypass:[]
			
		
     //console.log(data," payload data")
		makePostRequest(URLS.SORTFACILITY, null, data, null)
			.then(res => {
				//console.log(res)
				if (res.code == 200) {
					
					toast.success(res.message);
					handlViewFacility();
				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}
			})

	}

	useEffect(() => {
		if(payloadarraypass.length >0){
			SortFacility();
		}
	}, [payloadarraypass]);


	const addNewFacility = () => {
		let data = {
			name: name,
			status: "enable",
			// sort: sort,
		}

		makePostRequest(URLS.ADD_FACILITY, null, data, null)
			.then(res => {
				if (res.code == 201) {
					setName('');
					// setSort("");
					setSelectedFacility({ ...selectedFacility, name: "" });
					toast.success(res.message);
					setLoader(false);
					setIsUpdate(false);
					handlViewFacility();
					setSelectedFacility({});
				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}
			})

	}
	useEffect(() => {
		//addNewFacility();
		handlViewFacility();
	}, []);

	const handlViewFacility = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_ALL_FACILITY_TYPE, {}, null)
			.then((res) => {
				if (res.code === 200) {

					setGetAllFacility(res.data);
					setLoader(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleFacilityEditButton = (val) => {
		setIsUpdate(true);
		let obj = { id: val.id, name: val.name }
		// setSort(val.sort)
		setSelectedFacility(obj);
		//setLoader(true);
		window.scroll({
			top: 0,
			behavior: "smooth"
		})
		if (errorname) {
			setErrorName('');
		}
	}

	const handleupdateFacility = () => {
		let data = {
			id: selectedFacility.id,
			name: selectedFacility.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
			// sort: sort,
		}
		makePostRequest(URLS.UPDATE_FACILITY_TYPE, null, data, null)
			.then(res => {
				if (res.code == 200) {
					setName('');
					// setSort("");
					setSelectedFacility({ ...selectedFacility, name: "" })
					toast.success(res.message);
					setLoader(false);
					handlViewFacility();
					setIsUpdate(false);

				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}

			})
	}

	//Block Resource

	




	return (
		<React.Fragment>
			<Head title="Facility Setting"></Head>
			<Content>
			{/* <Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Type of Facility</h3>
				
				{loader ? (
					<div className="overflowloader" id="preload">
						<div className="circle-square" style={{ marginTop: "250px" }}>
							<div className="red" style={{ background: "#AAC0CE" }}></div>
							<div className="blue" style={{ background: "#265472" }}></div>
							<div className="green" style={{ background: "#F19357" }}></div>
							<div className="yellow" style={{ background: "#F0B285" }}></div>
						</div>
					</div>
				) : (
					<Card className="card-bordered">
						<div className="card-inner">
							<BlockHeadContent>


								<BlockHead size="lg">


									<div className="row">




										<div className="col-lg-4">
											{isUpdate === false ? <Label className="form-label">
												Add Type of Facility
											</Label> :
												<Label className="form-label">
													Edit Type of Facility
												</Label>
											}


											<div className="form-control-wrap">
												<Input id="space" name="name" placeholder="Type of Facility Name" type="text" value={selectedFacility.name} onChange={handleName} />
												<span className="error"> {errorname} </span>
												<span className="error"> {expressionTextError} </span>

											</div>

										</div>

										<div className="col-lg-4">
											<div className="form-control-wrap">
												<Label className="form-label">
													Sort
												</Label>
												<Input name="name" placeholder="Sort" type="text" value={sort} onChange={handleSort} />
												<span className="error"> {errorsort} </span>
											<span className="error"> {valueerror} </span>
											<span className="error"> {blankError} </span>

											</div>

										</div>
										<div className="col-lg-4">
											<Label className="form-label">
												&nbsp;
											</Label>
											{isUpdate === false ?
												<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
													<li><button className="btn btn-primary btn-md" type="submit" onClick={AddFacilityHandler}>Add </button></li>

												</ul>
												:
												<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
													<li><button className="btn btn-primary btn-md" type="submit" onClick={HandleButtonUpdateFacility}>Update </button></li>

												</ul>
											}
										</div>
									</div>


									{isUpdate === false && <div className="outerBox pt-4">
										{getAllFacility.length && (
											<ReactDataTable
												data={getAllFacility}
												columns={columnsFace}
												className="nk-tb-list"
												searchable={true}
												pagination
											/>
										)}
									</div>}







								</BlockHead>
							</BlockHeadContent>
						</div>
					</Card>
				)}
			</Content> */}




			{/* <div class="card-inner">
				<div class="nk-block-head-content">
					<div class="nk-block-head nk-block-head-lg"> */}


					<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Type of Facilities</h3>

{loader ? (
					<div className="overflowloader" id="preload">
						<div className="circle-square" style={{ marginTop: "250px" }}>
							<div className="red" style={{ background: "#AAC0CE" }}></div>
							<div className="blue" style={{ background: "#265472" }}></div>
							<div className="green" style={{ background: "#F19357" }}></div>
							<div className="yellow" style={{ background: "#F0B285" }}></div>
						</div>
					</div>
				) : (
					// <div className="card card-bordered p-4 h-100">

                    <Card className="card-bordered">
						<div className="card-inner">
							<BlockHeadContent>
								<BlockHead size="lg">
                                  <div className="row mb-4">
										<div className="col-lg-4">
											{isUpdate === false ? <Label className="form-label">
												Add Type of Facility
											</Label> :
												<Label className="form-label">
													Edit Type of Facility
												</Label>
											}


											<div className="form-control-wrap">
												<Input id="space" name="name" placeholder="Type of Facility Name" type="text" value={selectedFacility.name} onChange={handleName} />
												<span className="error"> {errorname} </span>
												<span className="error"> {expressionTextError} </span>

											</div>

										</div>
										{/* {isUpdate ===true ? "":
										<div className="col-lg-4">
											<div className="form-control-wrap">
												<Label className="form-label">
													Sort
												</Label>
												<Input name="name" placeholder="Sort" type="text" value={sort} onChange={handleSort} />
												<span className="error"> {errorsort} </span>
											<span className="error"> {valueerror} </span>
											<span className="error"> {blankError} </span>

											</div>

										</div>
} */}
										<div className="col-lg-4">
											<label>&nbsp;</label>
											{isUpdate === false ?
												<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
													<li><button className="btn btnspacE btn-primary btn-md" type="submit" onClick={AddFacilityHandler}>Add </button></li>

												</ul>
												:
												<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
													<li><button className="btn btn-primary btn-md" type="submit" onClick={HandleButtonUpdateFacility}>Update </button></li>

												</ul>
											}
										</div>
									</div>

									{isUpdate === false &&(	
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr className="p-3 bg-white border border-light round-lg mb-3">
              
			  
			  <th />
			  <th>#</th>
              <th>Name</th>
              <th>Status</th>
			  <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody 
                 className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {getAllFacility?.length > 0 && getAllFacility?.map((user, index) => (
                  <Draggable
                    key={user.name}
                    draggableId={user.name}
                    index={index}
					// draggableSort={user.sort}
                  >
                    {(provider) => (
                      <tr {...provider.draggableProps} ref={provider.innerRef}  className="p-3 bg-white border border-light round-lg mb-3">
                       
					  
					    <td {...provider.dragHandleProps}><div className=" mt-2 mb-2"> 
						<FontAwesomeIcon icon={faGripVertical} /> </div></td>
						<td><div className=" mt-2 mb-2">{index+1}</div></td>
                        <td>
							<div className="user-card mt-2 mb-2">
						{/* <UserAvatar
							theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
							text={findUpper(user.name)}
						></UserAvatar> */}
						<div className="user-info">
							<span className="userName">{user.name} </span>
						</div>
					</div>
					</td>
                       
                        <td><>
						{
							user.status == "enable" &&
							<div className="custom-control custom-switch">
								<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "disable", index)} checked />
								<label className="custom-control-label" htmlFor={user.id}>
								</label>
							</div>

						}
						{user.status == "disable" &&
							<div className="custom-control custom-switch">
								<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "enable", index)} />
								<label className="custom-control-label" htmlFor={user.id}>
								</label>
							</div>
						}

					</></td>
					<td><div className="user-card mt-2 mb-2">
						<button
							className="dropdown-item"
							onClick={() => {
								handleFacilityEditButton(user);
							}}
						>
							<Icon name="edit"></Icon>
						</button>
					</div></td>

                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
									)}
    {/* </div> */}
				
	{/* </div>
	</div>
	</div> */}

</BlockHead>
</BlockHeadContent>
</div>
</Card>
)}
	</Content>

		</React.Fragment>
	);
};

export default TypeofFacility;
