import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
	Block,
	BlockTitle,
	BlockBetween,
	BlockDes,
	Icon,
	Row,
	Col,
	Button,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	PreviewCard,
	ReactDataTable,
	UserAvatar
} from "../../../components/Component";

import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { findUpper } from "../../../utils/Utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
//import "bootstrap/dist/css/bootstrap.min.css";

function ManageResourceType() {


	const [selectedresourcetype, setSelectedResourceType] = useState({});
	const [name, setName] = useState('');
	// const [sort, setSort] = useState('');
	// const [blankError,setBlankError]=useState("")
	// const [valueerror, setValueError] = useState('');
	// const [errorsort, setErrorSort] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [rolesData, setRolesData] = useState([]);
	const [resetname, setResetName] = useState('');
	const [expressionTextError, setErrorExpressionText] = useState('');

	const [loader, setLoader] = useState(false);
	const [switchState, setSwitchState] = useState("");
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
	// const columns = useMemo(() => {
	// 	return [
	// 		{
	// 			name: '#',
	// 			selector: row => row.id,
	// 			grow: 0,
	// 			minWidth: "50px",
	// 			style: { minWidth: "50px" },
	// 			borderRadius: "4px",
	// 			cell: (row, index) => (
	// 				<div className="user-card mt-2 mb-2"> {index + 1} </div>
	// 			)
	// 		},
	// 		{
	// 			name: 'Title',
	// 			selector: row => row.name,
	// 			grow: 3,
	// 			sortable: true,
	// 			cell: (row) => (
	// 				<div className="user-card mt-2 mb-2">
	// 					<UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.name)} ></UserAvatar>
	// 					<div className="user-info">
	// 						<span className="tb-lead">
	// 							{row.name}{" "}
	// 						</span>
	// 					</div>
	// 				</div>
	// 			)
	// 		},

	// 		{
	// 			name: 'Status',
	// 			grow: 1,
	// 			selector: row => row.status,
	// 			cell: (row, index) => (
	// 				<>
	// 					{
	// 						row.status == "enable" &&
	// 						<div className="custom-control custom-switch">
	// 							<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
	// 							<label className="custom-control-label" htmlFor={row.id}>
	// 							</label>
	// 						</div>

	// 					}
	// 					{row.status == "disable" &&
	// 						<div className="custom-control custom-switch">
	// 							<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
	// 							<label className="custom-control-label" htmlFor={row.id}>
	// 							</label>
	// 						</div>
	// 					}

	// 				</>
	// 			)
	// 		},

	// 		{
	// 			name: 'Action',
	// 			grow: 0,
	// 			selector: row => row.status,
	// 			cell: (row) => (
	// 				<div className="user-card mt-2 mb-2">
	// 					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
	// 						<Icon name="edit"></Icon>
	// 					</button>
	// 				</div>
	// 			)
	// 		},
	// 	]
	// }, [rolesData]);

	const handleswitch = (id, isSelected, index) => {
		setLoader(true);
		let data = {
			id: id,
			status: isSelected,

		}
		switchState ? setSwitchState("enable") : setSwitchState("disable");
		const newArray = Array.from(rolesData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setRolesData(newArray);

		makePostRequest(URLS.UPDATE_RESOURCE_TYPE, null, data, null)
			.then(res => {
				if (res.code === 200) {
					setName('');
					toast.success("Status updated successfully.");
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
					setSelectedResourceType({});

				}

				else {
					toast.warn("Status disable successfully.")
				}
			})
	}

	//console.log("button",switchState)

	const handleName = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedresourcetype };
		let str = e.target.value;
		obj.name = str;
		setSelectedResourceType({ ...obj })
	}
	// const handleSort = (e) => {
	// 	setSort(e.target.value);
	// 	setErrorSort("");
	// 	setBlankError("");
	// 	setValueError("");
	// }

	const [payloadarraypass, setPayloadarraypass] = useState([]);
	let payloadarray=[];
   let tempData=[];

	const handleDragEnd = (e) => {
	   
	   if (!e.destination) return;
		tempData = Array.from(rolesData);
	   let [source_data] = tempData.splice(e.source.index, 1);
	   tempData.splice(e.destination.index, 0, source_data);
	   setRolesData(tempData);

	   //console.log(e,"result",tempData,"updateddata")


	   tempData.length>0  && tempData.map((val,index) => {
		   payloadarray.push({"sort":tempData.length-index ,"id":val.id})
		   
		 })
		 setPayloadarraypass(payloadarray)
	 };

	 // console.log(payloadarraypass,"payloadarraypass")




	let char = (/^[a-zA-Z\s]+$/);
	let num=(/^[0-9]+$/);


	const AddResourceType = () => {

		if (name == "") {
			setErrorName('Please enter resource type.');
		}
		// else if(sort== ""){
		// 	setBlankError("Please enter sort number.")
		// }
		else if (!name.match(char)) {
			setErrorExpressionText("Please enter character only.")
		}
		// else if(!sort.match(num)){
		// 	setErrorSort("Please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }
		else {
			addNewRole();
			setLoader(true);
		}
	}

	const UpdateResourceType = () => {

		if (selectedresourcetype.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorName('Please enter resource type.');
		}
		// else if(sort== ""){
		// 	setBlankError("Please enter sort number.")
		// }
		else if (!selectedresourcetype.name.match(char)) {
			setErrorExpressionText("Please enter character only.")
		}
		// else if(isNaN(sort)){
		// 	setErrorSort("Please enter number only.")
			
		// }
		// else if(sort < 1){
		// 	setValueError("Number must be greater than zero.")
			
		// }
		else if (isUpdate) {
			handleupdateUser();

			//  setName('');
			// setSelectedResourceType({...selectedresourcetype, name:""});

		}
	}



	const addNewRole = () => {
		let data = {
			name: name,
			status: "enable",
			// sort: sort,
		}


		makePostRequest(URLS.ADD_RESOURCE_TYPE, null, data, null)
			.then(res => {
				if (res.code == 201) {
					setName('');
					// setSort("");
					setSelectedResourceType({ ...selectedresourcetype, name: "" });
					toast.success(res.message);
					getAllRoles();
					setIsUpdate(false);
					setSelectedResourceType({});
				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}
			})

	}
	useEffect(() => {
		getAllRoles();
	}, []);

	const getAllRoles = () => {
		setLoader(true);

		makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
			.then(res => {
				if (res.code == 200) {

					setRolesData(res.data);
					setLoader(false);

				}
			})
			.catch((error) => {
				console.log(error);
			})

	}


	const handleEditButton = (val) => {
		setErrorName('');
		setIsUpdate(true);
		let obj = { id: val.id, name: val.name }
		// setSort(val.sort)
		setSelectedResourceType(obj);
		//setLoader(true);
		window.scroll({
			top: 0,
			behavior: "smooth"
		})

	}

	const handleupdateUser = () => {
		let data = {
			id: selectedresourcetype.id,
			name: selectedresourcetype.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
			// sort: sort,
		}
		makePostRequest(URLS.UPDATE_RESOURCE_TYPE, null, data, null)
			.then(res => {
				if (res.code == 200) {
					setName('');
					// setSort("");
					setSelectedResourceType({ ...selectedresourcetype, name: "" })
					toast.success(res.message);
					setLoader(true);
					getAllRoles();
					setIsUpdate(false);
					setSelectedResourceType({});

				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}

			})
	}

	const SortResourceType = () => {
			let data = 
			  payloadarraypass?payloadarraypass:[]
				
			
		 //console.log(data," payload data")
			makePostRequest(URLS.SORTRESOURCETYPE, null, data, null)
				.then(res => {
					//console.log(res)
					if (res.code == 200) {
						
						toast.success(res.message);
						getAllRoles();
					}
					else if (res.code == 400) {
						setLoader(false);
						toast.error(res.message);
					}
				})
	
		}
	
		useEffect(() => {
			if(payloadarraypass.length > 0){
			SortResourceType();
			}
		}, [payloadarraypass]);

	return (
		<React.Fragment>
			<Head title="Resource Type"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Manage Resource Type</h3>
				{/* <BlockTitle tag="h4">Manage Resource Type</BlockTitle>  */}
				{loader ? (
					<div className="overflowloader" id="preload">
						<div className="circle-square" style={{ marginTop: "250px" }}>
							<div className="red" style={{ background: "#AAC0CE" }}></div>
							<div className="blue" style={{ background: "#265472" }}></div>
							<div className="green" style={{ background: "#F19357" }}></div>
							<div className="yellow" style={{ background: "#F0B285" }}></div>
						</div>
					</div>
				) : (
					<Card className="card-bordered">
						<div className="card-inner">
							<BlockHeadContent>


								<BlockHead size="lg">
									<div className="row">


										<div className="col-lg-4">
											{isUpdate === false ? <Label className="form-label">
												{/* Add Resource Type */} {""}
											</Label> :
												<Label className="form-label">
													Edit Resource Type
												</Label>
											}

                                          {isUpdate === true ? 
											<div className="form-control-wrap">
												<Input name="name" placeholder="Resource Type " type="text" value={selectedresourcetype.name} onChange={handleName} />
												<span className="error"> {errorname} </span>
												<span className="error"> {expressionTextError} </span>

											</div>
											:""}
										</div>

										{/* {isUpdate ===true ? "":
										<div className="col-lg-4">
											<div className="form-control-wrap">
												<Label className="form-label">
													Sort
												</Label>
												<Input name="name" placeholder="Sort" type="text" value={sort} onChange={handleSort} />
										  <span className="error"> {errorsort} </span>
											<span className="error"> {valueerror} </span>
											<span className="error"> {blankError} </span>
											</div>
										</div>
} */}

                                    <div className="col-lg-4">
									<Label className="form-label">
													&nbsp;
												</Label>
										{isUpdate === false ?
											// <ul className="">
											// 	<li><button className="btn btn-primary btn-md" type="submit" onClick={AddResourceType}>Add </button></li>

											// </ul>
											""
											:
											<ul className="">
												<li><button className="btn btn-primary btn-md" type="submit" onClick={UpdateResourceType}>Update </button></li>

											</ul>
										}
                                       </div>


									</div>

									{isUpdate === false &&(

									<DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr className="p-3 bg-white border border-light round-lg mb-3">
              <th />
			  <th>#</th>
              <th>Title</th>
              <th>Status</th>
			  <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody 
                 className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {rolesData?.map((user, index) => (
                  <Draggable
                    key={user.name}
                    draggableId={user.name}
                    index={index}
                  >
                    {(provider) => (
                      <tr {...provider.draggableProps} ref={provider.innerRef}  className="p-3 bg-white border border-light round-lg mb-3">
                        <td {...provider.dragHandleProps}><div className="user-card mt-3 mb-2"> <FontAwesomeIcon icon={faGripVertical} /> </div></td>
						<td><div className="user-card mt-3 mb-2">{index+1}</div></td>
                        <td>
						<div className="user-card mt-3 mb-2">
						{	/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(user.name)} ></UserAvatar> */ }
						<div className="user-info">
							<span className="userName">
								{user.name}{" "}
							</span>
						</div>
					</div>
					</td>
                       


                        <td>
						<>
	 			{
	 				user.status == "enable" &&
					<div className="user-card mt-3 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "disable", index)} checked />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>

	 			}
	 			{user.status == "disable" &&
				<div className="user-card mt-3 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "enable", index)} />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>
	 			}

	 		</>
	</td>
					<td>
					<div className="user-card mt-2 mb-2">
						<button className="dropdown-item" onClick={() => { handleEditButton(user) }}>
							<Icon name="edit"></Icon>
						</button>
					</div>
					</td>

                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
									)}
									
									{/* <h4 className="nk-block-title page-title mb5 ">Resource Type</h4> */}



									{/* {isUpdate === false && <div className="outerBox pt-4">
										{rolesData && rolesData.length &&
											<ReactDataTable
												data={rolesData}
												columns={columns}
												className="nk-tb-list"
												searchable={true}
												pagination

											/>
										}
									</div>} */}



								</BlockHead>
							</BlockHeadContent>
						</div>
					</Card>
				)}
			</Content>

		</React.Fragment>
	);
};

export default ManageResourceType;
