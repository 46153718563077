import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// this style is use for model nox
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 6,
};
const Publishpopup = (props) => {
  return (
    <>
     <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            <h1>{props.msg} </h1>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            {
              props.value ? <>
                <button className="child-delete" onClick={() => { props?.handleYes(); props?.handleClose() }}>
                  Yes
                </button>{" "}
                <button className="child-cancel" onClick={() => { props?.handleNo(); props?.handleClose() }}>
                  No
                </button> </> : <>
                <button className="child-delete" onClick={() => { props?.handleNo(); props?.handleClose() }}>
                  Yes
                </button>{" "}
                <button className="child-cancel" onClick={() => { props?.handleYes(); props?.handleClose() }}>
                  No
                </button></>
            }
             </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default Publishpopup;
