import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import {BASE_URL, getHeaders, imageUrl, makePostRequest } from "../../../service/api";

import { URLS } from "../../../constants/urls";
import { Link } from "react-router-dom";
import { getUserDetailsFromToken } from '../../../service/auth'
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { S3_BUCKET_URL } from "../../../constants";
import axios from 'axios';
//import { ThreeDots } from "react-loader-spinner";

import {
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
   Col,
   UserAvatar,
   PreviewCard,
   Button,
  BlockTitle
} from "../../../components/Component";

import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Progress,
  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge
} from "reactstrap";
import bnb from '../../../images/coins/bnb.svg';
import { Label, Input } from "reactstrap";

const UserProfileRegularPage = (props) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  
  const [currentUserAuthData, setCurrentUserAuthData] = useState({});  
  const [data, setData] = useState([]);

  const [name, setName] = useState('');
  const [email, setEmail]=useState('');
  const [phone, setPhone]=useState('');
  const [errorname, setErrorName] = useState('');
  const [erroremail, setErrorEmail] = useState('');
  const [errorphone, setErrorPhone] = useState('');
  const [errorfile, setErrorFile] = useState('');
  
  let Emailvalidation=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let userAuthData = getUserDetailsFromToken();
    let data = {
      id: userAuthData.id,
      name: userAuthData.name,
      phone: userAuthData.phone,
      email: userAuthData.email,
      status: userAuthData.status
    }
    if (userAuthData['tbl_role']['role_name'] === "super_admin") {
      setIsUserSuperAdmin(true);
    }
    setSelectedUser({ ...data });
    getAllUsers();
	//console.log("Auth",userAuthData );
  }, []);

 
 
  const handleNameUpdate = (e) => {
	setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));  
	setErrorName('');  
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.name = str;
    setSelectedUser({ ...obj })
  }
  const handlePhoneUpdate = (e) => {
	setPhone(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "")); 
	setErrorPhone('');
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.phone = str;
    setSelectedUser({ ...obj })
  }
  const handleEmailUpdate = (e) => {
	setEmail(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));  
	setErrorEmail('');
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.email = str;
    setSelectedUser({ ...obj })
  }


  const getAllUsers = () => {
    makePostRequest(URLS.USER_GET_ALL, null, {}, null)
      .then(res => {
        console.log("user data",res);
        let { data } = res;
        setAllUsers(data);
      })
  }

  const handleEditButton = (val) => {
    setSelectedUser(val);
  }
  
  const handleupdateUser = () => {
   /*  alert("Hello");*/
   console.log("Test", selectedUser.name);
    if (selectedUser.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorName('Please enter name.');
	}
	else if (selectedUser.email.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorEmail('Please enter your email id.');
	}
	else if(!Emailvalidation.test(selectedUser.email))
		{
			setErrorEmail("The email address you entered is not valid")
		}
	else if (selectedUser.phone.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
			setErrorPhone('Please enter phone no.');
	}
	else if (selectedUser.phone.length < 10 || selectedUser.phone.length >12) {
			setErrorPhone('Please enter a vaild mobile number between 10 and 12 digit long.');
		}
	else if (file == null) {
			setErrorFile('Please upload image.');
		}	
	else {
		
		
    let data = {
      // id: selectedUser.id,
      // name: selectedUser.name,
      // phone: selectedUser.phone,
      // email: selectedUser.email,
      
        id:selectedUser.id,
        
        name:selectedUser.name,
        phone:selectedUser.phone,
    
    }

    console.log("update data", data)
   
    makePostRequest(URLS.USER_UPDATE, null, data, null)
      .then(res => {
            if(res.code==200){
              //setLoader(false);
              toast.success("Profile updated successfully."); 
              console.log(res);
             // handleSubmit();
              
            }
            else {
              
            }			  
        
      })
	  
     }
	 
  }

  const addModalShow=()=>{
     setModal({ add: true })
  }

  /*============= Add user API calling Here ===============*/
   
	 const getCurrentUserData = () => {
    let userAuth = getUserDetailsFromToken();
      if (userAuth && userAuth.id && userAuth.name && userAuth.email) {
        setCurrentUserAuthData(userAuth);
      }
      //console.log(userAuth)
    let data = {
      id: userAuth.id
    }
    makePostRequest(URLS.USER_GET, null, data, null)
      .then(res => {
        console.log("current user data",res.data)
        setData(res.data);
        setName(res.data.name);
        setPhone(res.data.phone);
        setEmail(res.data.email);
        setFile(imageUrl+res.data.photo);
      })
      .catch((error) => {
        console.log(error);
      })
  }

 
  useEffect(() => {
    getCurrentUserData();
  }, [])
	
   /* const [image, setImage] = useState({ photo: ""});	
   const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        photo: URL.createObjectURL(e.target.files[0]),
        
      });
    }
  };
  const handleUpload = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);

  }; */



	const  formData = new FormData();
  const [file, setFile] = useState(null);
  

  function handleFileUpload  (event)  {
    console.log("File", event.target.files[0]);
    setFile((URL.createObjectURL(event.target.files[0])));
    var file1=event.target.files[0];
    
    formData.append("id", currentUserAuthData.id);
	 setErrorFile('');
    formData.append("photo", file1);

    console.log("Form Data", formData);
    //axios.post("https://api.matchedcommunity.com/api/v1/profileUpload", formData, { headers })
  //         .then(response => {
  //           if (response.data.code === 200) {
  //             toast.success("Profile image uploaded successfully.");
  //             setTimeout(() => {
  //             }, 2000)

  //           }
  //           else {
  //             toast.error(response.data.message);
  //           }
      axios.post( BASE_URL+URLS.FILE_IMG_UPLOAD, formData, {
      headers: getHeaders({"Content-Type": "multipart/form-data"})})
      .then(res => 
        {console.log(res)
	
    if (res.status == 200) {
      //success
      
      toast.success("Profile image uploaded successfully.");
      setTimeout(() => { window.location.reload(); }, 6000);
      
    } else {
      //failed
    }
  })
  }
  

  function handleSubmit(event) {
    //event.preventDefault();
    
    const formData = new FormData();
    formData.append("id", currentUserAuthData.id);
	 setErrorFile('');
    formData.append("photo", file);

    console.log("Form Data", formData);
    // let response = await axios.post(REACT_APP_BASE_URL + URLS.FILE_IMG_UPLOAD, formData, {
    //   headers: getHeaders({"Content-Type": "multipart/form-data"})
      
    // })
	
    // if (response.status == 200) {
    //   //success
      
    //   console.log("fileupload response = = \n", response)
    // } else {
    //   //failed
    // }
  }
  
  
  

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockHeadContent>
		<ToastContainer />
		 		  
		 
		  {  /* <button onClick={handleSubmit} >Upload</button>   
		 <PreviewCard className="mb-4">   </PreviewCard> */ }
		  		  
          <div className="row">
        
              <div className="col-lg-6">
                <div className="form-group">
                  <Label className="form-label">
                    Name
                  </Label>
                  <div className="form-control-wrap">
                    <Input name="name" placeholder="Name" type="text" value={name} onChange={handleNameUpdate} />
					<span className="error"> {errorname} </span>
                  </div>
                </div>
                <div className="form-group">
                  <Label className="form-label">
                    Phone No.
                  </Label>
                  <div className="form-control-wrap">
                    <Input placeholder="Phone No." type="text" value={phone} onChange={handlePhoneUpdate} />
					<span className="error"> {errorphone} </span>
                  </div>
                </div>
              </div>
         
           
              <div className="col-lg-6">
                <div className="form-group">
                  <Label className="form-label">
                    Email
                  </Label>
                  <div className="form-control-wrap">
                    <Input placeholder="Email" type="text" value={email} onChange={handleEmailUpdate} />
					<span className="error"> {erroremail} </span>
                  </div>
                </div>
				
				
				<div className="form-group">
                  <Label className="form-label">
                    Image Upload
                  </Label>
                  <div className="form-control-wrap">
				  
					  <div className="user-card-img">
						  <div className="profileImgBox float-start ">
						   <img src={file} alt="Profile images" width="50" height="50"/>
						 </div>
						 
		<input className="float-start mx-2 pt-2" type="file" onChange={handleFileUpload} accept="image/png, image/jpeg ,image/gif" />
			{  /* <button className="btn prifileImgUploadBtn" 
			style={{position: "absolute", right: "40%",top: "6px"}} onClick={handleSubmit} >Upload</button>  */ }
                    
					
					
					{ /* <label htmlFor="upload-button">
					{image.photo ? (
					  <img src={image.photo} alt="dummy" width="40" height="40" />
					) : (
					  <>
						 <h5 className="text-center"> </h5>
					  </>
					)}
				  </label>
					<input type="file" id="upload-button" onChange={handleChange}  /> */ } 
			  
						<div className="clearfix"></div>							 
					    <span className="error"> {errorfile} </span>
					  </div>
			
                  </div>
                </div>
				
				
              </div>
           

            <div className="col-12 mt-4 mb-4 pb-3">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li><button className="btn btn-primary btn-md" type="submit" onClick={()=> {handleupdateUser()}}>Update User</button></li> 
                {/*<li><Link to={`${process.env.PUBLIC_URL}/`}>Cancel</Link></li>
                 <li><a href="#" className="link link-light">Cancel</a></li> */}
              </ul>
            </div>
          </div>
		  
		  
		  
          <div className="row datahide">
            {isUserSuperAdmin &&
              <div className="col-lg-12">
                <h3 className="float-start">Profile All Users</h3>   
				<button onClick={()=>addModalShow()}  className="btn btn-primary float-end mb-4">
				
				<em className="icon ni ni-plus"></em><span>Add User</span></button>
			  
			
				
                <table className="table table-bordered mb-4">
                  <thead>
                    <tr>
                      <th scope="col">ID </th>
                      <th scope="col">Role_id </th>
                      <th scope="col">Name </th>
                      <th scope="col">Phone </th>
                      <th scope="col">Email Id </th>
                      <th scope="col">Status</th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers?.map((val, index) => {
                      return (<>
                        <tr key={index}>
                          <td>{val.id}</td>
                          <td>{val.tbl_role.role_name}</td>
                          <td>{val.name}</td>
                          <td>{val.phone}</td>
                          <td>{val.email}</td>
                          <td>{val.status}</td>
                          <td><button className="btn btn-md btn-primary" onClick={() => handleEditButton(val)}>Edit</button></td>
                        </tr>
                      </>)
                    })
                    }
                  </tbody>
                </table>
              </div>}
          </div>
         
          {
			   loader && 
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
}

         
		 
        </BlockHeadContent>
      </BlockHead>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
