import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";

import { Link } from "react-router-dom";

import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";




function LoginWithAdmin() {
 

  return (
    <React.Fragment>
			<Head title="Login Admin"></Head>
			<Content>

				<ToastContainer />
				<div className="card card-inner-lg">
					<BlockHeadContent>
						<BlockHead size="lg">
							<h3 className="nk-block-title page-title mb5 "> Login With Admin</h3>
	                       
		
                      </BlockHead>
					</BlockHeadContent>
				</div>
			</Content>

		</React.Fragment>
  );
};

export default LoginWithAdmin;
