import React, { useState, useEffect } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import { CardTitle } from "reactstrap";
import { makePostRequest, getHeaders } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import { Link } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  Row,
  Col,
  Button,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
import { findUpper, capital } from "../../../../utils/Utils";

const Notifications = () => {
  let todaysDate = new Date();
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  let curretdate = moment(todaysDate).format("YYYY-MM-DD");
  const [resourceData, setresourceData] = useState([]);

  const GetAllQuickDashboardTodayData = () => {
    let data = {
      selected_date: curretdate,
    };
    makePostRequest(URLS.GET_DASHBOARD_TODAY_DATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setresourceData(res.data.resource_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllQuickDashboardTodayData();
  }, []);

  const columns = [
    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 4,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))}
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">
                {" "}
                {row.tbl_user.firstName} {row.tbl_user.lastName}{" "}
              </span>
              <span className="sub-text">{row.tbl_user.email}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Resource Detail",

      grow: 3,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row?.tbl_user_resource.tbl_user.firstName} {row?.tbl_user_resource.tbl_user.lastName}{" "}
            </span>
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>
          </div>
        </div>
      ),
    },

    {
      name: "View",

      grow: 1,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-resource/${row.resource_id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="newregisterGrouptable">
        <CardTitle className="mb-0 borderBottomTitle">
          <h6 className="title p-4 px-2">
            <span className="me-0">Today's Purchased Resource</span>{" "}
          </h6>
        </CardTitle>
        <>
          <div className="card-inners relativeClass">
            <BlockHeadContent>
              <BlockHead size="lg">
                {resourceData && resourceData?.length > 0 ? (
                  <div>
                    <div className="color">
                      <ReactDataTable data={resourceData} columns={columns} className="nk-tb-list" />
                    </div>
                  </div>
                ) : (
                  <p className="p-3">No Data Found</p>
                )}
              </BlockHead>
            </BlockHeadContent>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};
export default Notifications;
