import React from "react";
import UserAvatar from "../../../user/UserAvatar";
import Icon from "../../../icon/Icon";
import { newUserData } from "./UserData";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, CardTitle, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { makePostRequest, getHeaders } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  Row,
  Col,
  Button,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
import { findUpper, capital } from "../../../../utils/Utils";

const NewsUsers = () => {
  let todaysDate = new Date();
  let curretdate = moment(todaysDate).format("YYYY-MM-DD");
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const [userData, setUserData] = useState([]);

  const GetAllQuickDashboardTodayData = () => {
    let data = {
      selected_date: curretdate,
    };
    makePostRequest(URLS.GET_DASHBOARD_TODAY_DATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setUserData(res.data.user_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllQuickDashboardTodayData();
  }, []);

  const columns = [
    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 2,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={findUpper(capital(row.firstName + " " + row.lastName))}
            ></UserAvatar>

            <div className="user-info">
              <span className="lead-text">
                {row.firstName} {row.lastName}
              </span>
              <span className="sub-text">{row.email}</span>
              <span className="sub-text">{row.phone}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Zip Code",
      selector: (row) => "",
      grow: 1,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="lead-text">{row?.zipcode ? row.zipcode : "--"} </span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: "View",

      grow: 0,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-customer-details/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="newregisterGrouptable">
        <CardTitle className="mb-0 borderBottomTitle">
          <h6 className="title p-4 px-2">
            <span className="me-0">Today's New Registerd Customer</span>{" "}
          </h6>
        </CardTitle>
        <>
          <div className="card-inners relativeClass">
            <div className="card-inners-scroll">
              <BlockHead size="lg">
                {userData && userData?.length > 0 ? (
                  <div>
                    <div className="color">
                      <ReactDataTable data={userData} columns={columns} className="nk-tb-list" />
                    </div>
                  </div>
                ) : (
                  <p className="p-3">No Data Found</p>
                )}
              </BlockHead>
            </div>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};
export default NewsUsers;
