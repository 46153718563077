import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import { BASE_URL, getHeaders, makePostRequest } from "../../../service/api";
import { S3_BUCKET_URL } from "../../../constants";
import bnb from '../../../images/coins/bnb.svg';
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth';
//import { makePostRequest } from "../../../service/api";
import axios from 'axios';
import { ThreeDots } from "react-loader-spinner";
import { findUpper } from "../../../utils/Utils";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";



function ManageCheckList() {

	const [selectedSubscriptionplan, setselectedSubscriptionplan] = useState({});
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [rolesData, setRolesData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [title, setTitle] = useState('');
	const [instruction, setInstruction] = useState("");
	const [price, setPrice] = useState('');
	const [spaceAllocated, setSpaceAllocated] = useState('');
	const [description, setDescription] = useState('');
	const [paymentFrequencyType, setPaymentFrequencyType] = useState('');
	const [duration, setDuration] = useState('');
	
	const [stripeProductId, setStripeProductId] = useState('');
	const [status, setStatus] = useState('');
    const [instructionError, setInstructionError] = useState("");
	const [priceError, setPriceError] = useState('');
	const [spaceAllocatedError, setSpaceAllocatedError] = useState('');
	const [descriptionError, setDescriptionError] = useState('');
	const [paymentFrequencyTypeError, setPaymentFrequencyTypeError] = useState('');
	const [durationError, setDurationError] = useState('');
	const [membershipTypeError, setMembershipTypeError] = useState('');
    const [expressionTextError, setErrorExpressionText] = useState('');
	const [addinstruction,setAddinstruction]=useState([{ subscription_plan_subheading_id:"",heading:"",tooltip:"" }]);
	const [showinstruction,setShowinstruction]=useState([]);
	const [updateinstruction,setUpdateinstruction]=useState([]);
	const [selectesHeading,setSelectedHeading]=useState("");
    const [selectedTitle,setSelectedTitle]=useState("");
    const [selectedSubTitle,setSelectedSubTitle]=useState("")
    const [tableShow,setTableShow]=useState(false)
	const [instructionData,setInstructionData]=useState([])
	const [dynamicError, setDynamicError] = useState({})
    const [membershipType, setMembershipType] = useState([]);
    const [membershipSelectedType,setMembershipSelectedType]=useState("");
    const [subHeading,setSubHeading]=useState([]);
	const [heading,setheading]=useState([]);
	const [updateform,setUpdateform]=useState(false);
	const [editchecklistdata,setEditchecklistdata]=useState([]);
  const [selectedvalue,setSelectedvalue]=useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [index,setIndex]=useState("");
  const [checkid,setCheckid]=useState("");
  const [inputlist, setinputlist] = useState([{ subscription_plan_subheading_id:"",heading:"",tooltip:"" }]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "16px",
    boxShadow: 24,
    p: 8,
  };
	


let selectdata=[];

const heandleError = () => {
	let errors = {}
	    if (selectedTitle == "") {
			errors["selectedTitle"] = 'Please  add title.'
		}
	     if (selectesHeading == ""  && membershipType == "community_membership") {
			errors["selectesHeading"] = 'Please select heading.'
			
		}
	    
		setDynamicError(errors)
		if(Object.keys(errors).length > 0){

			return false
		}else{
			return true
		}
}
	const handleAddinstruction =()=>{
		
       // if(heandleError()){
		const values = [...addinstruction];
		values.push({
			 subscription_plan_subheading_id:"",heading:"",tooltip:"" });
		setAddinstruction(values);

			//   setAddinstruction((insdata)=>{
			// 	 let selectdata=({subscription_plan_subheading_id:parseInt(selectesHeading),heading:selectedTitle,tooltip:selectedSubTitle})
			// 		return[...insdata,selectdata]
			// 	  })

		setSelectedTitle("");
		setSelectedHeading("");
		setSelectedSubTitle("");	
		//}
			 
		}
		const handleAddinstruction2 =()=>{
		
			// if(heandleError()){
			 const values = [...addinstruction];
			 values.push({
				  subscription_plan_subheading_id:7,heading:"",tooltip:"" });
			 setAddinstruction(values);
	 
				 //   setAddinstruction((insdata)=>{
				 // 	 let selectdata=({subscription_plan_subheading_id:parseInt(selectesHeading),heading:selectedTitle,tooltip:selectedSubTitle})
				 // 		return[...insdata,selectdata]
				 // 	  })
	 
			 setSelectedTitle("");
			 setSelectedHeading("");
			 setSelectedSubTitle("");	
		}

	 const handledelete=(index,checkid)=>{
   
		showinstruction.splice(index,1)
		setShowinstruction([...showinstruction])

		let data={ "checklist_id":checkid}
		makePostRequest(URLS.DELETE_CHECKLIST,null,data,null)
		.then((response)=>{
			if(response.code==200)
			{
				if(response.message!="Ok")
				toast.success(response.message)
				
			}
		})

		
	 }

	 const actualdelete=()=>{
		
	 }
  
	 const handleadd = () => {
		const values = [...inputlist];
		values.push({
			subscription_plan_subheading_id:"",heading:"",tooltip:""
		});
		setinputlist(values);
	  };
	  const handleremove = (index) => {
		const values = [...inputlist];
		values.splice(index, 1);
		setinputlist(values);
	  };
	// const test=()=>{


	// 	let parsedJSON = JSON.parse(rolesData.instruction);
	// 	for (var i=0;i<parsedJSON.length;i++) {
	// 		 alert(parsedJSON[i].title);
	// 		 console.log(parsedJSON[i].title,"title")
	// 	  }
	// 	}

	// 	useEffect(()=>{
	// 		test();
	// 	},[])


console.log("instruction data")
console.log(addinstruction,"addins",)

	const [switchState, setSwitchState] = useState("");
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
	const columns = [
		
		{
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {index+1} </div>

			   </>
			)
		},
		{
			name: 'Title',
			selector: row => row.title==null?<div>N/A</div>:row.title,
			 minWidth: "190px",
			style: {minWidth: "190px" },
			grow: 4,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
	{/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.title==null?<div>N/A</div>:row.title)} ></UserAvatar>  */}
					<div className="user-info">
						<span className="tb-lead">
							{row.title==null?<div>N/A</div>:row.title}{""}
						</span>
					</div>
				</div>
			)
		},
		
				{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleeditchecklist(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	
	];
	
	const handleswitch=(id, isSelected, index)=>{
		const formData = new FormData();
		formData.append("id",id);
		formData.append("status", isSelected);

		switchState?setSwitchState("enable"):setSwitchState("disable");
		const newArray = Array.from(rolesData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setRolesData(newArray);
	
		makePostRequest(URLS.UPDATE_SUBSCRIPTION_PLAN, null,formData, null)
			.then(res => {
					toast.success("Status updated successfully.");
				     setLoader(false); 
					getAllChecklist();
			 	   setIsUpdate(false);
					setselectedSubscriptionplan({});
			})
	}

	const HandleSelectedHeading=(e)=>{
		setSelectedHeading(e.target.value)
		if(e.target.value !== ""){
			setDynamicError({...dynamicError, selectesHeading : ""})
		}
	}
	const handleInsTitle=(index,event)=>{
		const values = [...addinstruction];
		const updatedValue = event.target.name;
		values[index][updatedValue] = event.target.value;
		setAddinstruction(values);
		
		// // if(event.target.value !== ""){
		// // 	setDynamicError({...dynamicError, selectedTitle : ""})
		// }
	}
	const handleInsTitle1=(index,event)=>{
		const values = [...showinstruction];
		const updatedValue = event.target.name;
		values[index][updatedValue] = event.target.value;
		setShowinstruction(values);
		
		// // if(event.target.value !== ""){
		// // 	setDynamicError({...dynamicError, selectedTitle : ""})
		// }
	}
	const handleInsSubtitle=(e)=>{
		setSelectedSubTitle(e.target.value)
		if(e.target.value !== ""){
			setDynamicError({...dynamicError, selectedSubTitle : ""})
		}
	}

  const handleTitle = (e) => {
	setTitle(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
	setErrorName('');
	setErrorExpressionText('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.title = str;
	setselectedSubscriptionplan({ ...obj })
}


   const handleInstruction = (e) => {
    setInstruction(e.target.value);
	setInstructionError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	 obj.instruction = str;
	 setselectedSubscriptionplan({ ...obj })
  }
   const handlePrice = (e) => {
    setPrice(e.target.value);
	setPriceError('');
	let obj = { ...selectedSubscriptionplan };
	let int = e.target.value;
	obj.price = int;
	setselectedSubscriptionplan({ ...obj })
  }
   const handleSpaceAllocated = (e) => {
    setSpaceAllocated(e.target.value);
	setSpaceAllocatedError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.spaceAllocated = str;
	setselectedSubscriptionplan({ ...obj })
  }
   const handleDescription = (e) => {
    setDescription(e.target.value);
	setDescriptionError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.description = str;
	setselectedSubscriptionplan({ ...obj })
  }
   const handlePaymentFrequencyType = (e) => {
    setPaymentFrequencyType(e.target.value);
	setPaymentFrequencyTypeError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.paymentFrequencyType = str;
	setselectedSubscriptionplan({ ...obj })
  }
   const handleDuration = (e) => {
    setDuration(e.target.value);
	setDurationError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.duration = str;
	setselectedSubscriptionplan({ ...obj })
  }
   const handleMembershipType = (e) => {
   
    setMembershipSelectedType(e.target.value);

	setMembershipTypeError('');
	let obj = { ...selectedSubscriptionplan };
	let str = e.target.value;
	obj.membershipType = str;
	setselectedSubscriptionplan({ ...obj })
  }

  let char=(/^[a-zA-Z\s]+$/); 
	
	const AddSubscriptionHandler = () => {
		
				addcheckList();	
			
	  }
	  const UpdateSubscriptionHandler = () => {


		upadatecheckList();
		// if (selectedSubscriptionplan.title == "") {
		// 	   setErrorName('Please enter your name.');
		//    }
		//   else if (selectedSubscriptionplan.price == "") {
		// 	   setPriceError('Please enter price.');
		//    } 
		//   else if (selectedSubscriptionplan.spaceAllocated == "") {
		// 	   setSpaceAllocatedError('Please enter space allocated.');
		//    } 
		// //   else if (selectedSubscriptionplan.description == "") {
		// // 	   setDescriptionError('Please enter description.');
		// //    }
		//   else if (selectedSubscriptionplan.paymentFrequencyType == "") {
		// 	   setPaymentFrequencyTypeError('Please enter paymet frequence type.');
		//    } 

		//    else if (selectedSubscriptionplan.duration == "" && selectedSubscriptionplan.paymentFrequencyType !='free' ) {
		// 	   setDurationError('Please enter duration.');
		//    } 

		//   else if (selectedSubscriptionplan.membershipType == "") {
		// 	   setMembershipTypeError('Please enter membership type.');
		//    }  
		// //   else if (addinstruction.length == 0) {
		// // 	   setInstructionError('Please enter instruction.');
		// //    } 			
		// 	else if(!selectedSubscriptionplan.title.match(char)){
		//    setErrorExpressionText("Please enter character only.")
		//    }	
		//    else if (isUpdate) {
		// 	   handleupdateUser();
		//    }
	 }
	 
	 function upadatecheckList() {
		let finalarray=[],i;
		console.log("showinstruction",showinstruction)
		console.log("addinstruction",addinstruction)
		if(membershipSelectedType==2)
		{
			for(i=0;i<addinstruction.length;i++)
			{
				if(addinstruction[i].subscription_plan_subheading_id=="")
				{
					addinstruction[i].subscription_plan_subheading_id="7"
				}
			}
		}

		if(membershipSelectedType==3)
		{
			for(i=0;i<addinstruction.length;i++)
			{
				if(addinstruction[i].subscription_plan_subheading_id=="")
				{
					addinstruction[i].subscription_plan_subheading_id="8"
				}
			}
		}
		if(addinstruction[0].heading!="")
		finalarray=showinstruction.concat(addinstruction)
		else
		finalarray=showinstruction
		console.log("finalarray",finalarray)
		
	

			let data=
        	
		finalarray
		

makePostRequest(URLS.UPDATE_CHECKLIST,null, data, null)
.then(res => {
	console.log("UpDate checklist",res);
 if(res.code==200) {
//
  toast.success(res.message)
  setTimeout(() => { window.location.reload(); }, 6000);
//   setTimeout(window.location.reload(false),5000);

} else if(res.code == 400){
	toast.error(res.message)
 }
 })

	 }

  function addcheckList() {
	let i;
      if(membershipSelectedType==2)
	  {
		for(i=0;i<addinstruction.length;i++)
		{
			addinstruction[i].subscription_plan_subheading_id="7"
		}
	  }
	  else if(membershipSelectedType==3)
	  {
		for(i=0;i<addinstruction.length;i++)
		{
			addinstruction[i].subscription_plan_subheading_id="8"
		}
	}
	let data=
        
            addinstruction
        
            
	
	// console.log("data ",data)
    console.log("payload",addinstruction)
   
	makePostRequest(URLS.ADD_CHECK_LIST,null, data, null)
	.then(res => {
        console.log("ADD checklist",res);
	 if(res.code==201) {
	// 	let Objects ={title:'',price:'',spaceAllocated:'',description:'',duration:'',membershipType:'',addinstruction:'',paymentFrequencyType:''}
	// 	setselectedSubscriptionplan(Objects);
	// 	setPaymentFrequencyType("")
	// 	setMembershipType("")
	//   getAllChecklist();
	// setIsUpdate(false);
	//   setselectedSubscriptionplan({})
	//   setAddinstruction([])
	  toast.success(res.message)
     setAddinstruction([{ subscription_plan_subheading_id:"",heading:"",tooltip:"" }])
    
    } else if(res.code == 400){
		toast.error(res.message)
     }
	 })
	}
  	
	useEffect(() => {
		getAllChecklist();
        getmembershipType();
       
	}, []);

    useEffect(() => {
        getheadingings();
    }, [membershipSelectedType]);
    

    const getheadingings = () => {

        let data={

            subscription_heading_id:membershipSelectedType

        };
        makePostRequest(URLS.GET_SUB_HEADING, null, data,null)
        .then(res => {
            
            if (res.code == 200) {

                setSubHeading(res.data)
               
            }
        }).catch((error) => {
            console.log(error);
        })
    
    }


	const getAllChecklist = () => {
		// setLoader(true);
       
        console.log(URLS.GET_ALL_CHECKLIST)
		makePostRequest(URLS.GET_ALL_CHECKLIST, null, null,null)
			.then(res => {
                console.log("response checklist",res)
				if (res.code == 200) {
					setheading(res.data)
					setRolesData(res.data);
					setInstructionData(res.data.instruction)
				   setLoader(false);
				   
				}
			}).catch((error) => {
				console.log(error);
			})

	}

    const getmembershipType = () => {
		// setLoader(true);
		makeGetRequest(URLS.GET_MEMBERSHIP_TYPE, {}, null)
			.then(res => {
                console.log("response MEMEBERSHIP ",res)
				if (res.code == 200) {
                
                setMembershipType(res.data)
					
				   
				}
			}).catch((error) => {
				console.log(error);
			})

	}

	const handleeditchecklist = (val) => {
		
		setMembershipSelectedType(val.id)
		
      
		let data={
			"subscription_heading_id": val.id
				}
				makePostRequest(URLS.GET_ALL_SUBSCRIPTION,null, data, null)
				.then(res => {
					console.log("response from checklist data",res)
					if (res.code == 200) {

						// setEditchecklistdata(res.data)
						// setUpdateform(true)
						// setUpdateinstruction(res.data)	
						setShowinstruction(res.data)
						setTableShow(true)
						setIsUpdate(true);

					}
				
			})

	}
	console.log("membershipSelectedType",membershipSelectedType)
	
	const handleEditButton = (val) => {
		console.log("addinstruction", val)
		setErrorName('');
		setPriceError('');
		setDescriptionError('');
		setSpaceAllocatedError('');
		setInstructionError('');
		setDurationError('');
		setPaymentFrequencyTypeError('');
		setMembershipTypeError('');
		setIsUpdate(true);
		window.scroll({
			top:0,
			scroll:"smooth",
		})
		let obj = {id:val.id,title:val.title,instruction:val.instruction,price:val.price,spaceAllocated:val.space_allocated,description:val.description,paymentFrequencyType:val.payment_frequency_type,duration:val.duration
			,membershipType:val.membership_type,stripeProductId:val.stripe_product_id}
		setselectedSubscriptionplan(obj);
		setMembershipType(val.membership_type)

		const convertToArray = (ddd) => {
			if(Array.isArray(ddd)) {
				return setAddinstruction(ddd)
			}else{
				let neww = JSON.parse(ddd)
				convertToArray(neww)
			}
		}

		convertToArray(val.instruction) 
 console.log("convertToArray(val.instruction)",convertToArray(val.instruction))

		// setAddinstruction(JSON.parse(JSON.parse(JSON.parse(JSON.parse(val.instruction)))))
		setTableShow(true)
		console.log("addinstruction json", typeof JSON.parse(JSON.parse(JSON.parse(val.instruction))));	
		console.log("addinstruction json",  JSON.parse(JSON.parse(JSON.parse(val.instruction))));	
	}

	
 function handleupdateUser() {
	let data={
		"id":selectedSubscriptionplan.id,
		"title":selectedSubscriptionplan.title,
		// "instruction": selectedSubscriptionplan.instruction,
		"instruction": addinstruction,
		"price": selectedSubscriptionplan.price,
		 "space_allocated": selectedSubscriptionplan.spaceAllocated,
		"description":selectedSubscriptionplan.description,
		"payment_frequency_type": selectedSubscriptionplan.paymentFrequencyType,
		"duration": selectedSubscriptionplan.duration,
		 "membership_type":selectedSubscriptionplan.membershipType,	
	};

	console.log("addinstruction data", data)

	makePostRequest(URLS.UPDATE_SUBSCRIPTION_PLAN,null, data, null)
	.then(res => {
	 if(res.code==200) {
		
		let Objects ={title:'',price:'',spaceAllocated:'',description:'',paymentFrequencyType:'',duration:'',membershipType:''}
		setselectedSubscriptionplan(Objects);
		  toast.success(res.message)
		  setIsUpdate(false);
		  setselectedSubscriptionplan({})
		  setPaymentFrequencyType("")
		  setMembershipType("")
		  setAddinstruction([])
		  getAllChecklist()
		 let obj = {name:''}
	      setselectedSubscriptionplan({...obj});
		  } 
		  else if(res.code==400){
			toast.success(res.message)
		  }
	 })
	}
	console.log("dynamicError",dynamicError)

	return (
		<React.Fragment>
			<Head title="Manage Checklist"></Head>
           
			<Content>
			
				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4"> Manage Check List </h3>
			  <Card className="card-bordered">	
				<div className="card-inner">
					<BlockHeadContent>
						<BlockHead size="lg">
							<div className="row">
								         
                            				
								 
								 <div className="col-lg-4">								
									<div className="form-group mb-2">
										<Label className="form-label">
										  Membership Type
										</Label>
                                       
										 <select  value={membershipSelectedType} className="form-select" onChange={handleMembershipType}>
											  <option value=""> Membership Type</option>
                                           {membershipType && membershipType.map((val)=>{
                                             return(
                                                <option value={val.id}>{val.title}</option>


                                             )

                                           })
                                           }
											  
								    </select>
									<span className="error"> {membershipTypeError} </span>	
									</div>
							     </div>		
								
								   		


								 { membershipSelectedType==1?<>
									{addinstruction.map((val, index) => {
                    return (
								 <div className="row pb-4">
								    <div className="col-lg-3">
								        <select className="form-select" name="subscription_plan_subheading_id" onChange={(event) =>handleInsTitle(index, event)} value={val.subscription_plan_subheading_id}>
											  <option value=""> Select Type</option>
											  {
												subHeading.map((val)=>{
                                                            
													return(<>
													 <option value={val.id}>{val.title}</option>
													</>)
												})
											  }
											  
									    </select>

										<span className="error"> {dynamicError?.selectesHeading} </span>
									</div>
                                      
                                      <div className="col-lg-3">									
										<input type="text"  className="form-control"  id="heading" name="heading"  placeholder="title" onChange={(event) =>handleInsTitle(index, event)} value={val.heading}/>
										<span className="error"> {dynamicError?.selectedTitle} </span>
										</div>
										<div className="col-lg-3">
										<input type="text"  className="form-control"  id="tooltip" name="tooltip" placeholder="sub title" onChange={(event) =>handleInsTitle(index, event)} value={val.tooltip}/>
										<span className="error"> {dynamicError?.selectedSubTitle} </span>
										</div>
										<div className="col-lg-3">
										<button className="btn btn-md btn-primary" onClick={handleAddinstruction}>+</button>
										
										
										
										</div>
								 </div>)})}

							 </>:"" }

								 {
									membershipSelectedType==2 || membershipSelectedType==3?<>
									
									{addinstruction.map((val, index) => {
                    return (	
						<div className="row pb-4">
                                       <div className="col-lg-4">									
									   <input type="text"  className="form-control"  id="heading" name="heading"  placeholder="title" onChange={(event) =>handleInsTitle(index, event)} value={val.heading}/>
										<span className="error"> {dynamicError?.selectedTitle} </span>
										</div>
										<div className="col-lg-4">
										<input type="text"  className="form-control"  id="heading" name="tooltip" placeholder="sub title" onChange={(event) =>handleInsTitle(index, event)} value={val.tooltip}/>
										<span className="error"> {dynamicError?.selectedSubTitle} </span>
										</div>
										<div className="col-lg-4">
										<button className="btn btn-md btn-primary" onClick={handleAddinstruction}>+</button>
										</div> 
									</div> )	})}		
									
				</>						
			:""}
			

			{tableShow ==true?
			    membershipSelectedType==1?
				showinstruction.map((val, index) => {
                    return (
								 <div className="row pb-4">
								    <div className="col-lg-3">
								        <select className="form-select" id="sunscrptionsubscription_plan_subheading_id1" name="subscription_plan_subheading_id" onChange={(event) =>handleInsTitle1(index, event)} value={val.subscription_plan_subheading_id}>
											  <option value=""> Select Type</option>
											  {
												subHeading.map((val)=>{
                                                            
													return(<>
													 <option value={val.id}>{val.title}</option>
													</>)
												})
											  }
											  
									    </select>

										<span className="error"> {dynamicError?.selectesHeading} </span>
									</div>
                                      
                                      <div className="col-lg-3">									
										<input type="text"  className="form-control"  id="heading1" name="heading"  placeholder="title" onChange={(event) =>handleInsTitle1(index, event)} value={val.heading}/>
										<span className="error"> {dynamicError?.selectedTitle} </span>
										</div>
										<div className="col-lg-3">
										<input type="text"  className="form-control"  id="tooltip1" name="tooltip" placeholder="sub title" onChange={(event) =>handleInsTitle1(index, event)} value={val.tooltip}/>
										<span className="error"> {dynamicError?.selectedSubTitle} </span>
										</div>
										<div className="col-lg-3">
										<button className="btn mx-3 btn-danger float-start" onClick={()=>handledelete(index,val.id)}  ><em className="icon ni ni-delete"></em>
										
										
										</button>
										
										
										</div>
								 </div>)}):
								 membershipSelectedType==2 || membershipSelectedType==3?
								 
								 showinstruction.map((val, index) => {
									return (<> 
										<div className="row pb-4">
										<div className="col-lg-4">									
										<input type="text"  className="form-control"  id="heading1" name="heading"  placeholder="title" onChange={(event) =>handleInsTitle1(index, event)} value={val.heading}/>
										<span className="error"> {dynamicError?.selectedTitle} </span>
										</div>
										<div className="col-lg-4">
										<input type="text"  className="form-control"  id="tooltip1" name="tooltip" placeholder="sub title" onChange={(event) =>handleInsTitle1(index, event)} value={val.tooltip}/>
										<span className="error"> {dynamicError?.selectedSubTitle} </span>
										</div>
										<div className="col-lg-4">
										<button className="btn mx-3 btn-danger float-start" onClick={()=>handledelete(index,val.id)}  ><em className="icon ni ni-delete"></em>
										
										
										</button>
										
										
										</div>
										</div>
										
										
										</>
										)

								 }):"":""

								 }
			
			{isUpdate===false?
                                     <ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={AddSubscriptionHandler}>Add </button></li>

									</ul>
									:
									<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={UpdateSubscriptionHandler}>Update </button></li>
                           
									</ul>
	                              }
								</div>
	   { loader ? (
                        <div className="sharepopupOuter backgroundOpacity1">
							<ThreeDots
								height="100%"
								width="100%"
								radius="9"
								color="#08147e"
								ariaLabel="three-dots-loading"
								wrapperStyle={{
								    margin: "200px auto",
									width: "90px",
									height: "90px",
								}}
								wrapperClassName="loader"
								visible={true}
							/>
						</div>
			        ):(
                        
             <div className="subscriptionplanCustome mt-4">     
					   {heading && heading.length==0?<div>Loading...</div>: 
								<ReactDataTable
									data={heading}
									columns={columns}
									className="nk-tb-list subscriptionplanCustomeInner"
                                    searchable={true}
									pagination
								/>
							}
          </div>
		  
	   )}
						</BlockHead>
					</BlockHeadContent>
				</div>
				</Card>
			</Content>

		</React.Fragment>
	);
};

export default ManageCheckList;
