import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const imageUrl = process.env.REACT_APP_IMAGE_URL;
import CryptoJS from "crypto-js";

export const isProduction = process.env.REACT_APP_IS_PRODUCTION
 export const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY


export const makeGetRequest = async (url, queryParams = {},headers={}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.get(BASE_URL+url,{
      params:queryParams,
      headers: requestHeaders
    })
    if(isProduction == "true"){
      let decryptdata = CryptoJS.AES.decrypt(response.data?.data, secretKey).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }
    return response.data
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const makePostRequest = async (url, queryParams, body, headers = {}) => {
  // console.log("123", url, body)
  const encriptionBody = {
    data :  CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString()
  }
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(BASE_URL+url, isProduction == "true" ? encriptionBody : body,{
      params:queryParams,
      headers: requestHeaders
    })
    if(isProduction == "true"){
      let decryptdata = CryptoJS.AES.decrypt(response.data?.data, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptdata);
    }
    return response.data
  } catch (error) {
    //console.error(error);
    throw error;
  }
};

// let my_token = localStorage.getItem("auth-token");
// let headers = {
//   Authorization: "Bearer " + my_token,
//};
// export const makePostRequestForAll = async (url, queryParams, body, headers = {}) => {
//   console.log("postreqAll", url, body)
//   const encriptionBody = {
//     data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
//   };
//   try {
//     let requestHeaders = getHeaders(headers);
//     const response = await axios.post(
//       BASE_URL + url,
//       isProduction == "true" ? encriptionBody : body,
//       { headers: requestHeaders,
//         // params: queryParams,
//       }
//     );
//     if (isProduction == "true") {
//       let decryptdata = CryptoJS.AES.decrypt(
//         response.data?.data,
//         secretKey
//       ).toString(CryptoJS.enc.Utf8);
//       return JSON.parse(decryptdata);
//     }
//     //console.log(response.data, "dattaaaa");
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// comman funcation for api calling

let my_token = sessionStorage.getItem("auth-token");
let headers = {
  Authorization: "Bearer " + my_token,
};
export const makePostRequestForAll = async (url, body) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(
      BASE_URL + url,
      isProduction == "true" ? encriptionBody : body,
      { headers: requestHeaders }
    );
    if (isProduction == "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }
    //console.log(response.data, "dattaaaa");
    return response.data;
  } catch (error) {
    throw error;
  }
};





export const makePutRequest = async (url,queryParams, body, headers = {}) => {
  const encriptionBody = {
    data :  CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString()
  }
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.put(BASE_URL+url, isProduction == "true" ? encriptionBody : body,{
      params:queryParams,
      headers: requestHeaders
    })
    if(isProduction == "true"){
      let decryptdata = CryptoJS.AES.decrypt(response.data?.data, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptdata);
    }
    return response.data
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const makeDeleteRequest = async (url, queryParams,body, headers = {}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.delete(BASE_URL+url,{
      params:queryParams,
      headers: requestHeaders
    })
    if(isProduction == "true"){
      let decryptdata = CryptoJS.AES.decrypt(response.data?.data, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptdata);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getHeaders = (headers={})=>{
  const token = sessionStorage.getItem('auth-token');
  let headerObj= {
    'Content-Type': 'application/json',
    ...headers
  };
  if(token){
    headerObj.Authorization = `Bearer ${token}`;
  }
  return headerObj;
}


// for formdata
let my_tokens = sessionStorage.getItem("auth-token");
let headerss = {
  Authorization: "Bearer " + my_tokens,
};
export const makeFormDateRequset = async (url, body) => {
  try {
    const res = await axios.post(BASE_URL + url, body, { headerss });
    console.log("res:::", res);
    let decryptdata = CryptoJS.AES.decrypt(res.data?.data, secretKey).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptdata);
  } catch (error) {
    if (error?.response?.status == 403||error?.response?.status == 401) {
      let data = localStorage.getItem("rememberMe") || []
localStorage.clear();
localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    console.log("error:::", error);
  }
};