import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
	Block,
	BlockTitle,
	BlockBetween,
	BlockDes,
	Icon,
	Row,
	Col,
	Button,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	PreviewCard,
	ReactDataTable,
	UserAvatar
} from "../../../components/Component";
import { TextData } from "../user-manage/TextData";
import { DataTableColumns2 } from "../user-manage/DataTableColumns2";
//import { DataTableData, dataTableColumns, dataTableColumns2, userData } from "../../../pages/components/table/TableData";

import { Link } from "react-router-dom";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { findUpper } from "../../../utils/Utils";

function TaxManagement() {


	const [selectedTax, setSelectedTax] = useState({});
	const [name, setName] = useState('');
	const [taxRate, setTaxRate] = useState('');
	const [stateId, setStateId] = useState('');
	const [status, setStatus] = useState('');

	const [getStateID, setgetStateID] = useState([]);

	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [errortaxRate, setErrorTaxRate] = useState('');
	const [errorstateId, setErrorStateId] = useState('');

    const [expressionTextError, setErrorExpressionText] = useState('');
    
	const [taxData, setTaxData] = useState([]);
	const [loader, setLoader] = useState(false);

	const [switchState, setSwitchState] = useState("");
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
	const columns = [
		
		{
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {index+1} </div>

			   </>
			)
		},
		{
			name: 'Title',
			selector: row => row.name,
			grow: 2,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.name)} ></UserAvatar>
					<div className="user-info">
						<span className="tb-lead">
							{row.name}{" "}
						</span>
					</div>
				</div>
			)
		},
		{
			name: 'State',
			selector: row => row.tbl_state?row.tbl_state.name:"",
			sortable: true,
		},
		{
			name: 'Tax Rate',
			selector: row => row.tax_rate,
			sortable: true,
		},
		{
			name: 'Status',
			selector: row => row.status,
			cell: (row, index) => (
				<>
				{
					row.status == "enable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>

				}
				{row.status == "disable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>
				}

			</>
			)
		},
		
		{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	];
	const handleswitch = (id, isSelected, index) => {

		let data = {
			id: id,
			status: isSelected,

		}
		switchState ? setSwitchState("enable") : setSwitchState("disable");
		const newArray = Array.from(taxData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setTaxData(newArray);

		makePostRequest(URLS.UPDATE_TAX, null, data, null)
			.then(res => {
				if (res.code === 200) {
					toast.success("Status updated successfully.");
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);

				}
				else {
					toast.error("Status disable successfully.")
				}
			})
	}
	//console.log("button",switchState)

	const handleGST = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.name = str;
		setSelectedTax({ ...obj })

	}

	const handleStateID = (e) => {
		setStateId(e.target.value);
		setErrorStateId('')


		//console.log("TEST", e.target.value);
	}

	const handletaxRate = (e) => {
		setTaxRate(e.target.value);
		setErrorTaxRate('');
		let obj = { ...selectedTax };
		let int = e.target.value;
		obj.taxRate = int;
		setSelectedTax({ ...obj })

	}

    let char=(/^[a-zA-Z\s]+$/); 
	// let taxratec=(/^[0-9.%]+$/);
	let taxratec=(/^[0-9.%]+$/);
	const AddRoleHandler = () => {

		if (name == "") {
			setErrorName('Please enter title.');
		}
		else if(!name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
		else if (stateId == "") {
			setErrorStateId('Please select state.');
		}
		else if (taxRate == "") {
			setErrorTaxRate('Please enter tax rate.');
		}
		else if (!taxRate.match(taxratec)) {
			setErrorTaxRate('Please enter number only.');
		}
		else if (taxRate > 100) {
			setErrorTaxRate('Please enter valid tax rate less than 100.');
		}
		
		else {
			addNewRole();
			setLoader(true);
		}
	}
	const updatetaxHandler = () => {
		
			if (selectedTax.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
				setErrorName('Please enter title.');
			}
			else if(!selectedTax.name.match(char)){
				setErrorExpressionText("Please enter character only.")
			}
			else if (selectedTax.stateId == "") {
				setErrorStateId('Please select state.');
			}
			else if (selectedTax.taxRate == "") {
				setErrorTaxRate('Please enter tax rate.');
			}
			else if (!selectedTax.taxRate.match(taxratec)) {
				setErrorTaxRate('Please enter number only.');
			}
			else if (selectedTax.taxRate > 100) {
				setErrorTaxRate('Please enter valid tax rate less than 100.');
			}
		else if (isUpdate) {
			handleupdateUser();
            // setStateId("");
			// setName("");

		}
	}


	const addNewRole = () => {
		let data = {
			name: name,
			tax_rate: taxRate,
			state_id: stateId,
			status: "enable",
		}
   
		makePostRequest(URLS.ADD_TAX, null, data, null)
			.then(res => {
				if (res.code === 201) {
					let boj = { name: '', taxRate: '' }
					setSelectedTax(boj);
					
                    setStateId("");
					setName("");
					toast.success(res.message);
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
                    setSelectedTax({});
				}
				else if (res.code == 400) {
					toast.warn(res.message)
					setLoader(false)
				}
			})
	}
	useEffect(() => {
		getAllRoles();
		getStateIDS();
	}, []);

	const getAllRoles = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_ALL_TAX, {}, null)
			.then(res => {
				if (res.code == 200) {
					setTaxData(res.data);
					setLoader(false);	
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}

	const getStateIDS = () => {
		makeGetRequest(URLS.GET_ALL_STATES, {}, null)
			.then(res => {
				if (res.code == 200) {

					setgetStateID(res.data);
					setLoader(false);

				}
			})
			.catch((error) => {
				console.log(error);
			})

	}

	const handleEditButton = (val) => {
		setErrorName('');
		setErrorStateId('');
		setErrorTaxRate('');
		setIsUpdate(true);
		let obj = { id: val.id, name: val.name, taxRate: val.tax_rate, state_id:val.stateId}
		setSelectedTax(obj);
		
		 setStateId(val.state_id);
		//setLoader(true);
		window.scroll({
			top: 0,
			behavior: "smooth"
		})

	}

	const handleupdateUser = () => {
		let data = {
			id: selectedTax.id,
			name: selectedTax.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
			tax_rate: selectedTax.taxRate,
			state_id: stateId,
			
		}

		makePostRequest(URLS.UPDATE_TAX, null, data, null)
			.then(res => {
				if (res.code === 200) {
					let boj = { name: '', taxRate: ''}
					setSelectedTax(boj);
					setTaxRate("");
					setStateId("");
					setName("");
					toast.success(res.message);
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
					setSelectedTax({});
		
				}
				else if (res.code == 400) {
					toast.warn(res.message)
					setLoader(false)
				}
			})
	}

	//console.log("Tax Data", taxData);
	return (
		<React.Fragment>
			<Head title="Tax Management"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Manage Tax</h3>
				{/* <BlockTitle tag="h4">Tax Management</BlockTitle> */}

				{ loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ):(

				<div className="card-bordered card card-inner-lg">
					<BlockHeadContent>


						<BlockHead size="lg">
							{/*isUpdate === false ?
								<h3 className="nk-block-title page-title mb5"> Add Tax Name</h3>
								:
								<h3 className="nk-block-title page-title mb5"> Edit Tax Name</h3>

							*/}

							<div className="pb-5 asd">


								<div className="row">

									<div className="col-lg-6">
										<div className="form-group">
									
											
											<Label className="form-label">
												Title
											</Label>
											<div className="form-control-wrap">
												<Input placeholder="Title" type="text" value={selectedTax.name} onChange={handleGST} />
												<span className="error"> {errorname} </span>
                                                <span className="error"> {expressionTextError} </span>
											</div>

										</div>

										<div className="form-group">
											<Label className="form-label">
												Tax Rate
											</Label>
											<div className="form-control-wrap">
												<Input placeholder="Tax Rate" value={selectedTax.taxRate} onChange={handletaxRate} />
												<span className="error"> {errortaxRate} </span>

											</div>

										</div>
									</div>



									<div className="form-group col-lg-6">
										<div className="form-group">
											<Label className="form-label">
											Select State
											</Label>
											<div className="form-control-wrap">
												{	/* <Input  placeholder="State id"  value={selectedTax.stateId} onChange={handletaxId} /> */}

												<select value={stateId} className="form-select" onChange={handleStateID}>
													<option value="">Select State</option>
													{
														getStateID.map((val, index) => {
															return (<>
																<option value={val.id}>{val.name}</option>
															</>)
														})
													}
												</select>
												<span className="error"> {errorstateId} </span>

											</div>

										</div>



									</div>


									{isUpdate === false ?
										<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
											<li><button className="btn btn-primary btn-md" type="submit" onClick={AddRoleHandler}>Add </button></li>

										</ul>
										:
										<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
											<li><button className="btn btn-primary btn-md" type="submit" onClick={updatetaxHandler}>Update </button></li>

										</ul>
									}
								</div>


							</div>



							{/* <h4 className="nk-block-title page-title mb5 "> Manage Tax List</h4> */ }



                        <div className="outerBox">
							{taxData && taxData.length &&
								<ReactDataTable
									data={taxData}
									columns={columns}
									className="nk-tb-list"
									searchable={true}
									pagination
								/>
							}
							</div>
                    	

							
						</BlockHead>
					</BlockHeadContent>
				</div>
		  )}
			</Content>

		</React.Fragment>
	);
};

export default TaxManagement;
