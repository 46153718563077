import React, { useState ,useRef} from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import {
  ReactDataTable,
} from "../../../components/Component";
import { Card, Input, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { FaFilter } from "react-icons/fa";
import Select from "react-select";
const ExcelJS = require('exceljs');

function ListSubscription() {
  const [purchaseData, setPurchaseData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show,setShow]=useState(false);
  const [search, setSearch] = useState("")

  const [selectedCustomer,setSelectedCustomer]=useState("")
  const [selectedPlan,setSelectedPlan]=useState("")
  const [selectedStatus,setSelectedStatus]=useState("")
  const[selectedfromdate,setSelectedfromDate]=useState(null)
  const[selectedtodate,setSelectedToDate]=useState(null)
  const [customerData,setCustomerData]=useState([])
  const[formDateError,setformDateError]=useState("")
  const[toDateError,settoDateError]=useState("")
  let [count,setCount]=useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null)
  const [limitvalue,setlimitvalue]=useState("")
  const [sortsubscription,setSortSubscription]=useState("")
//console.log(selectedCustomer,"selectedCustomer")
  
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [customerDropdown, setCustomerDropdown] = useState([]);
  const [clearState,setClearState]=useState(false);

  const [customerFilter,setcustomerFilter] = useState(true);

  
const ShowHideSelect=()=>{
setShow(!show)
}
// const HandleCustomer=(e)=>{
//   setSelectedCustomer(e.value)
// }
const HandleSubscriptionPlan=(e)=>{
  setSelectedPlan(e.target.value)
}
const HandleStatus=(e)=>{
  setSelectedStatus(e.target.value)
}
const HandleFromDate=(e)=>{
  setSelectedfromDate(e)
  setDatePickerShow(!datepickershow);
  setformDateError("")
}
const HandleToDate=(e)=>{
  setSelectedToDate(e)
  setDatePickerShow1(!datepickershow1);
  settoDateError("")
}

const HandleLimitValue=(e)=>{
  
  setlimitvalue(e.target.value)
  // sessionStorage.setItem("limitvaluesubscription",e.target.value)
  setCurrentPage(1)
}
const HandleSortSubscription=(e)=>{
  setSortSubscription(e.target.value)
  // sessionStorage.setItem("sortvaluesubscription",e.target.value)
  setCurrentPage(1)
 
 }
 useEffect(() => {
  getPurchaseHistoryData();
 }, [sortsubscription,limitvalue])



useEffect(() => {
  getPurchaseHistoryData();
 },[sortsubscription,limitvalue])

 function handleclickdatepickericon() {
  setDatePickerShow(!datepickershow);
}
function handleclickdatepickericon1() {
  setDatePickerShow1(!datepickershow1);
}

const ExportEcelFile=()=>{
  setLoader(true);
  let data={
    user_id:selectedCustomer?.value ? selectedCustomer?.value :"",
    subscription_plan:selectedPlan ? selectedPlan :"",
    start_date:selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD"):"",
    end_date:selectedtodate? moment(selectedtodate).format("YYYY-MM-DD"):"",
    status:selectedStatus ? selectedStatus :"",
    page: currentPage,
    limit: limitvalue?limitvalue:10,
    searchKey : search,
    sortby:sortsubscription?sortsubscription:"",
  key : "export"
  }
  makePostRequest(URLS.GET_ALL_SUBSCRIPTION_HISTORY_EXPORT, null, data, null)
    .then((res) => {
      //console.log("export subscription",res)
      if (res.code == 200) {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet ("SubscriptionPurchaseData");
        sheet.properties.defaultRowHeight= 30;
        
      
      sheet.getRow(1).font = {
       
        size:10,
        bold:true,
      }
      
        sheet.columns= [
          {
            header:"S.No",
          key:'S_No',
          width:5
        },
       
      {
         header:"SUBSCRIPTION DATE",
      key:'SUBSCRIPTION_DATE',
      width:15
      },
      {
        header:"SUBSCRIPTION ID",
      key:'SUBSCRIPTION_NUMBER',
      width:15
    },
        {
          header:"CUSTOMER",
        key:'CUSTOMER',
        width:15
         },
         {
          header:"CUSTOMER EMAIL",
        key:'CUSTOMER_EMAIL',
        width:15
         },{
          header:"CUSTOMER PHONE",
        key:'CUSTOMER_PHONE',
        width:15
         },
         {
          header:"SUBSCRIPTION TYPE",
       key:'SUBSCRIPTION_TYPE',
       width:15
       },
       {
        header:"PLAN NAME",
     key:'PLAN_NAME',
     width:15
     },
     {
      header:"PRICE TYPE",
   key:'PRICE_TYPE',
   width:15
   },
             
        {
          header:"($)PRICE",
        key:'PRICE',
        width:15
         },
         {
          header:"PAYMENT FREQUENCY",
        key:'PAYMENT_FREQUENCY',
        width:15
         },
         {
          header:"SAPCE USED",
        key:'SPACE_USED',
        width:15
         },

       {
        header:"VALID UNTIL",
      key:'VALID_UPTO',
      width:15
      },
      {
        header:"CURRENT STATUS",
      key:'CURRENT_STATUS',
      width:15
       },
       {
      header:"RENEWAL DATE",
      key:'RENEWAL_DATE',
      width:15
       },
      
        ];
        res?.data?.data?.map((val,i)=>{
          sheet.addRow({
            S_No:(i+1),
            SUBSCRIPTION_DATE:moment(val.subscription_date).format("MM-DD-YYYY"),
            SUBSCRIPTION_NUMBER:val.subscription_no && val.subscription_no ?  val.subscription_no  : "--",
            CUSTOMER: val?.tbl_user?.firstName?val.tbl_user.firstName +" " +val.tbl_user.lastName:"--",
            CUSTOMER_EMAIL:val.tbl_user.email,
            CUSTOMER_PHONE:val.tbl_user.phone,
            SUBSCRIPTION_TYPE:val?.tbl_subscription_plan?.tbl_subscription_heading?.title? val.tbl_subscription_plan?.tbl_subscription_heading?.title : "--",
            PLAN_NAME:val.tbl_subscription_plan.title? val.tbl_subscription_plan.title : "--",
            PRICE_TYPE:val.tbl_subscription_plan.price_type? val.tbl_subscription_plan.price_type : "--",
            PRICE: "$" + val.tbl_subscription_plan.price? val.tbl_subscription_plan.price.toFixed(2) : "--",
            PAYMENT_FREQUENCY:val.tbl_subscription_plan.payment_frequency_type? val.tbl_subscription_plan.payment_frequency_type : "--",
            SPACE_USED:val.space_used? val.space_used : "--",
            VALID_UPTO:val.tbl_subscription_plan.payment_frequency_type!="free"? moment(val.valid_upto).format("MM-DD-YYYY") :"Life Time",
            CURRENT_STATUS:val.status? val.status : "--",
            RENEWAL_DATE:val.tbl_subscription_plan.payment_frequency_type!="free"? moment(val.valid_upto).format("MM-DD-YYYY") :"Life Time",

           
          })
        })
      
        workbook.xlsx.writeBuffer().then( data => {
          const blob = new Blob([data],{
            type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
          })
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = 'SubscriptionPurchase.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url)
        })
        setLoader(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }
  const columns = [
     {
			name: '#',
			selector: row =>"1",
			// grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
			   </>
			)
		},
    {
			name: 'Subscription Date',
			selector: row =>"1",
			// grow: 0,
	        minWidth: "150px",
			style: {minWidth: "150px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {moment(row.subscription_date).format("MM-DD-YYYY")} </div>
			   </>
			)
		},
    {
      name: "Customer Name",
      selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
        <div className="user-info">
          <p className="mb-1"><span className="tb-lead float-start" > {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
             {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}</span> 
             <Link to={`/view-customer-details/${row?.tbl_user?.id}`}><button
            className="dropdown-item btn btn-md"
          >
            <em className="icon ni ni-eye"></em>
          </button>
           {/* {sessionStorage.setItem("tabcust","")} */}
           {/* {sessionStorage.setItem("limitvaluesubscription",limitvalue)}
          {sessionStorage.setItem("pagenumbersubscription",currentPage)} */}
          </Link> 
 </p>
    </div>
      </div>
      ),
    },
    {
      name: "Subscription Details",
      selector: (row) => ("Akash yadav"),
       grow: 4,
      minWidth: "150px",
      style: { minWidth: "150px" },
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
          <span className="sub-text">{row.subscription_no?row.subscription_no:"--"}</span>
            <span className="sub-text">{row?.tbl_subscription_plan?.payment_frequency_type?row?.tbl_subscription_plan?.payment_frequency_type:"--"}</span>
            
            

         </div>
         </div>
       ),
     },
    // {
    //   name: "Price",
    //   selector: (row) => (""),
    //    grow: 2,
    //   // sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //        {row?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.total_amount && <span className="sub-text">
    //          $ {row?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.total_amount?.toFixed(2)}/
    //          {row?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.temp_request_data && JSON.parse(row?.tbl_subscription_payment_histories?.[0]?.tbl_payment_history?.temp_request_data)?.subscription_list?.[0]?.payment_frequency_type == "monthly" ? "mo" : "yr"}
    //         </span>}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Status",
      selector: (row) => (""),
       grow: 3,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
            { row.status === "active" ? "active" : row.status === "pending" ? "upcoming" :  row.status === "deactive" ? "deactive" :"--" }
            </span>
          </div>
        </div>
      ),
    },
 {
    name: "Valid Until",
    selector: (row) =>"",
    grow: 3,
    // sortable: true,
    cell: (row) =><><span className="sub-text">{row.tbl_subscription_plan.payment_frequency_type!="free"?moment(row.valid_upto).format("MM-DD-YYYY"):"Life Time"}&nbsp;</span> 
   </>,
  },
    {
      name: "View",
      selector: (row) =>"",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
       <Link to={`/view-subscriptionplan/${row.id}`}>  <button
            className="dropdown-item btn btn-md"
          >
            <em className="icon ni ni-eye"></em>
          </button>
           {/* {sessionStorage.setItem("tabcust","")} */}
           {/* {sessionStorage.setItem("limitvaluesubscription",limitvalue)}
          {sessionStorage.setItem("pagenumbersubscription",currentPage)} */}
          </Link> 
        </div>
      ),
    },
   ];
  const getPurchaseHistoryData = () => {
    setLoader(true);
    let data={
      user_id:selectedCustomer.value ? selectedCustomer.value :"",
    subscription_plan:selectedPlan ? selectedPlan :"",
    start_date:selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD"):"",
    end_date:selectedtodate? moment(selectedtodate).format("YYYY-MM-DD"):"",
    status:selectedStatus ? selectedStatus :"",
    page: currentPage,
    limit: limitvalue?limitvalue:10,
    searchKey : search,
    sortby:sortsubscription?sortsubscription:"",
    key : ""
    }
    //console.log("purchaseData",data)
    makePostRequest(URLS.GET_ALL_SUBSCRIPTION_HISTORY, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setPurchaseData(res.data.data);
          //console.log("count", res)
          setCount(Math.ceil(res.data.count/parseInt(limitvalue?limitvalue:10)));
          //console.log("data subscription",res)
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomerdata = () => {
    setLoader(true);
    makePostRequest(URLS.GET_SUBSCRIPTION_PURCHASE_CUSTOMER, null, {page_number :2}, null)
      .then((res) => {
        if (res.code == 200) {
          setCustomerData(res.data.data);
          setLoader(false);
          //console.log(res,"response a")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomerdropdown = () => {
    makePostRequest(URLS.GET_SUBSCRIPTION_PURCHASE_CUSTOMER, null, { page_number: dropdownPage }, null)
      .then((res) => {
        console.log("responsecustomer", res);
        if (res.code == 200) {
          setCustomerDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleMenuScroll = (event) => {
    const { target } = event;
         getcustomerdropdown();
  };

  const handleSearch=()=>{
    if(selectedfromdate!=null && selectedtodate==null){
      settoDateError("please select valid end date")
    }
    else if(selectedtodate!=null && selectedfromdate==null){
      setformDateError("please select valid start date ")
    }
    else if(selectedfromdate>selectedtodate){
  toast.error("end date should be greater than start date")
    } else {
    getPurchaseHistoryData();
    setCurrentPage(1)
}
  }
  useEffect(() => {
    // getPurchaseHistoryData();
    getcustomerdropdown();
  }, []);

 

  const handleClearData=()=>{
    setSelectedCustomer("")
    setSelectedStatus("")
    setSelectedPlan("")
    setSelectedToDate("")
    setSelectedfromDate("")
    setSearch("")
    setSortSubscription("")
    setCurrentPage(1)
	setlimitvalue("10");
    setClearState(!clearState)
    getPurchaseHistoryData()

  }
  const clearfilter=()=>{
    handleClearData()
  }
  useEffect(()=>{
    getPurchaseHistoryData();
  },[clearState])

   // Handle page change event
 const handlePageChange = (selectedPage) => {
  setCurrentPage(selectedPage.selected+1);
  // sessionStorage.setItem("pagenumbersubscription",selectedPage.selected+1)
  // fetchData()
};
const fetchData = () => {

  let data={
    user_id:selectedCustomer ? selectedCustomer :"",
  subscription_plan:selectedPlan ? selectedPlan :"",
  start_date:selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD"):"",
  end_date:selectedtodate? moment(selectedtodate).format("YYYY-MM-DD"):"",
  page:currentPage,
  limit: limitvalue?limitvalue:10,
  searchKey : search,
  sortby:sortsubscription?sortsubscription:"",
  key : ""
  }
  //console.log("purchaseDatasearch",data)
  makePostRequest(URLS.GET_ALL_SUBSCRIPTION_HISTORY, null, data, null)
    .then((res) => {
      if (res.code == 200) {
        setPurchaseData(res.data.data);
        setCount(Math.ceil(res.data.count/parseInt(limitvalue?limitvalue:10)));
        //console.log("data",res.data)
        // setLoader(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
useEffect(()=>{
  fetchData()
},[currentPage])
// Render your component with pagination
// Apply code for click outside and close popup
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const newRefCustomer = useRef(null);

 const handlecustomerFilter = ()=> {
    if(newRefCustomer.current) {
      setcustomerFilter(!customerFilter);
      newRefCustomer.current.focus();
    }
 }

  const handleOutSideClick = (e) => {
	if(newRef.current && !newRef.current.contains(e.target)){
        setDatePickerShow(false);
	}	  
  }
  const handleOutSideClick1 = (events) =>{
	if(newRef1.current && !newRef1.current.contains(events.target)){
  		setDatePickerShow1(false);
	}	  
  }
  useEffect(()=> {
	  document.addEventListener("mousedown", handleOutSideClick );
	  document.addEventListener("mousedown", handleOutSideClick1 );
	  return () => {
	   document.removeEventListener("mousedown", handleOutSideClick );
	   document.removeEventListener("mousedown", handleOutSideClick1 );
	  }
  });


  return (
    <React.Fragment>
      <Head title="Subscriptions Purchased"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Subscriptions Purchased List</h3>
        {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : (
        <Card className="card-bordered">
        <div className="card-inner relativeClass">
        <div className="row pb-2 pt-2  align-items-center">
		    <div className="col-lg-4 ">
        <div className="filtersidebutton">
     <h5  style={{cursor: "pointer"}} onClick={ShowHideSelect}>Filters<FaFilter/></h5>
     <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}> Clear Filters </button>
     </div>
     </div>
     
      <div className="col-lg-8 d-flex  justify-content-end">
    
     <select  value={sortsubscription} className="form-select" style={{width:"170px"}} onChange={HandleSortSubscription}>
                <option value="">Sort By</option> 
                <option value="ASCDATE">Date Ascending</option>
                <option value="DESCDATE">Date Descending</option>
                <option value="ASCNAME">User Ascending</option>
                <option value="DESCNAME">User Descending</option>


                <option value="ASCVALIDTO">Valid Until Ascending</option>
                <option value="DESCVALIDTO">Valid Until Descending</option>
                
       </select>
      
       <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
			 <div><select  value={limitvalue} className="form-select" style={{width: "85px"}} onChange={HandleLimitValue}>
          
				 <option value="10">10</option>
						      <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option> 
                  <option value="100">100</option>
			   </select>
         </div>     
         <div>
         &nbsp; &nbsp;
         <button className="btn btn-primary " onClick={ExportEcelFile}>
			      <em className="icon ni ni-download-cloud"></em><span>Export</span>
			     </button>
           </div>   
      </div>
      </div>
      <hr></hr>
<>
           
		  {show && 
      <div>
		  <div className="row pb-2">
		   
          <div className="col-lg-4">			
                     <div className="form-control border-0 px-0">
                    <label><strong>Customer Name</strong></label>    
                     
                   
					 <div   className="form-control-wrap paymetsession">
                <i className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList" onClick={handlecustomerFilter}></i> 
                    <Select
                    className="form-select"
                     autosize={true}
                     onChange={(e)=> setSelectedCustomer(e)}
        defaultValue={selectedCustomer}
        // onChange={HandleCustomer}
        // onMenuScrollToBottom={handleMenuScroll}
        options = {[
                                { value: "", label: "All" }, 
                                ...(customerDropdown?.map(val => ({ value: val.tbl_user.id, label: val.tbl_user?.firstName+" "+val.tbl_user?.lastName})) || [])
                               ]}
                                openMenuOnFocus={customerFilter}
                               ref={newRefCustomer}
      />
                     </div>
                     </div></div>

         <div className="col-lg-4">			
			   <div className="form-control border-0 px-0">
			  <label><strong>Subscription Plan</strong></label>
			   <select  value={selectedPlan} className="form-select" onChange={HandleSubscriptionPlan}>
			    <option value="">Subscription Plan</option>
              {/* <option value={"day"}>Day</option> */}
              <option value={"free"}>Free</option>
              <option value={"monthly"}>Monthly</option>
              <option value={"yearly"}>yearly</option>
			   </select>
			   </div>
         </div>


         {/* <div className="row pb-2"> */}
         <div className="col-lg-4 mt-0">
         <div className="form-control border-0 px-0">
			  <label><strong>Status</strong></label>
			   <select  value={selectedStatus} className="form-select" onChange={HandleStatus}>
			    <option value="">Status</option>
              {/* <option value={"day"}>Day</option> */}
              <option value="active">Active</option>
              <option value="deactive">Deactive</option>
              <option value="pending">Upcoming</option>
			   </select>
			   </div>

           {/* <label><strong>Search by Customer Name</strong></label>
           <Input name="name" placeholder="search..." type="text" value={search} onChange={(e) => setSearch(e.target.value)} /> */}
           </div>
		  {/* </div> */}



         </div>
         <div className="row pb-2">
			   <div className="col-lg-4">	 
			   <div className="form-control border-0 px-0">
			     <label><strong>Start Date</strong></label>
             
           <div className="form-control-wrap"  ref={newRef}>
                                <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate}
                                  onChange={HandleFromDate}
                                  className="form-control date-picker"
                                  open={datepickershow}
                                  onFocus={() => setDatePickerShow(!datepickershow)}
                                />
                              </div>   {/* <input type="date" name ="formdate" value={selectedfromdate} className="form-control" onChange={HandleFromDate} />   */}
			   </div>
         <span className="error">{formDateError}</span>
		       </div>
			    <div className="col-lg-4">	 
					   <div className="form-control border-0 px-0">
					   <label><strong> End Date</strong></label>
					   {/* <input type="date"  name ="todate" value={selectedtodate} className="form-control" onChange={HandleToDate}></input> */}
					  
             <div className="form-control-wrap" ref={newRef1}>
                                <div className="form-icon form-icon-left" >
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate}
                                  onChange={HandleToDate}
                                  className="form-control date-picker"
                                  open={datepickershow1}
                                  onFocus={() => setDatePickerShow1(!datepickershow1)}
                                />
                              </div>
             </div>
              <span className="error">{toDateError}</span>
			   </div>  
			   
			
                <div className="col-lg-4">
				 <div className="outerTypeBox mb-3"> 
				 <label className="w-100 mb-0">&nbsp;</label>
				   <button className="btn btn-md btn-primary" type="submit" onClick={handleSearch}> Search </button>
         

         

				 </div>
			
       
         </div>   
			   
			   
         </div>

         {/* <div className="row pb-2">
         <div className="col-lg-4">
           <label><strong>Search by Customer Name</strong></label>
           <Input name="name" placeholder="search..." type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
           </div>
		  </div> */}
   
</div>}  
            <div className="card-inners relativeClass">
              <BlockHeadContent>
                <BlockHead size="lg">
                 <div className="clearboth"></div>
                   {purchaseData?.length==0?<div>No Data Found</div>:( 
                    <ReactDataTable
                      data={purchaseData}
                      columns={columns}
                      className="nk-tb-list"
                      searchable={false}
                      setPageCount={setPageCount}
                      // pagination
                    />
                   )} 
                </BlockHead>
              </BlockHeadContent>
            </div>
        </> 
        {purchaseData?.length > 0 ? 
        
        <div>
      <ReactPaginate
       previousLabel={"<<"}
       nextLabel={">>"}
       pageCount={count}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        pageClassName={"page-item"}
        activeClassName={'active'}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        forcePage = {currentPage-1}
      />
     </div> 
:""}

        </div>
        </Card>
         )}
      </Content>
    </React.Fragment>
  );
}
export default ListSubscription;
