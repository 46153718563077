import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import { makePostRequest,makeGetRequest } from "../../../service/api";

import ReactPaginate from "react-paginate";




function CustomerRetentionRatio() {
	
  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [loader, setLoader] = useState(false);
//   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [currentPage1,setCurrentPage1]=useState(1);
  const [count1,setCount1]=useState();
  
  const [customerdataretentionreport,setCustomerdataretentionreport]=useState([]);
  const getcustomerretentionratioreport = () => {
    // setLoader(true);
    
   
    makePostRequest(URLS.GET_CUSTOMER_RETENTIONRATIO_REPORTS, null, null, null)
      .then((res) => {
        setLoader(true)
        if (res.code === 200) {
          console.log(res, "retention  data");
          setCustomerdataretentionreport(res?.data?.user_data);
         setLoader(false);
         
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handlePageChange1 = (selectedPage) => {
		setCurrentPage1(selectedPage.selected+1)

	  };

    useEffect(() => {
      getcustomerretentionratioreport();
      }, []);
      const columns = [
        {
          name: "Total Members",
          selector: (row) => row.total_members,
          grow: 0,
          minWidth: "170px",
          style: { minWidth: "170px" },
          borderRadius: "4px",
          cell: (row, index) => (
            <>
              {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
              <div className="user-card mt-2 mb-2"> {row.total_members} </div>
            </>
          ),
        },
        {
          name: "Status",
          
          grow: 0,
          minWidth: "140px",
          sortable: true,
          cell: (row) => (
            <div className="user-card mt-2 mb-2"> {row.status} </div>
          ),
        },
      ]

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  return (
    <React.Fragment>
      	  
		 
    <Head title="Customer List"></Head>
    <Content>
      
      <h3 className="nk-block-title page-title mb5 pb-4"> Customer Retention Data</h3>

      {loader ? (
         <div className="overflowloader" id="preload">
         <div className="circle-square" style={{marginTop:"250px"}} >
        <div className="red" style={{background:"#AAC0CE"}}></div>
        <div className="blue" style={{background:"#265472"}}></div>
        <div className="green" style={{background:"#F19357"}}></div>
        <div className="yellow" style={{background:"#F0B285"}}></div>
        </div>
         </div>
        ) : (
<>
<Card className="card-bordered">
   <div className="card-inner relativeClass">
   
   
     
       


          <div className="card-inners relativeClass">
            <BlockHeadContent>
              <BlockHead size="lg">


              {/* <div class="d-flex justify-content-end g-2">
                <div class="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                  <div class="form-control-select">
                     <select name="DataTables_Table_0_length" class="custom-select custom-select-sm form-control form-control-sm">
                      <option value="10">10</option><option value="25">25</option><option value="40">40</option><option value="50">50</option></select> </div></label></div></div> */}

                      
                {customerdataretentionreport.length==0?<>No Data Found</> : (
                  <div className="color">
                  <ReactDataTable
                    data={customerdataretentionreport}
                    columns={columns}
                    className="nk-tb-list"
                    // searchable={true}
                    // setPageCount={setPageCount}
                />
                </div>
                )}

                {/* {customerdataretentionreport?.length > 0 ? 
<div>
    <ReactPaginate
     previousLabel={"<<"}
     nextLabel={">>"}
      // pageCount={count}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      // onPageChange={handlePageChange}
      containerClassName={'pagination'}
      pageClassName={"page-item"}
      activeClassName={'active'}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
    />
   </div> 
   :""} */}
       </BlockHead>
            </BlockHeadContent>
          </div>
     
      </div>
      </Card>
      </>  )}
    </Content>
  </React.Fragment>
);
}
export default CustomerRetentionRatio;
