import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { ThreeDots } from "react-loader-spinner";


import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle, 
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { Link,useParams} from "react-router-dom"
import { URLS } from "../../constants/urls";
import { makePostRequest } from "../../service/api";
import {useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Updatepass = () => {
   
     
   let GetId = useParams();
  
    const [passState, setPassState] = useState(false);
  
  const [newpassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmpass] = useState('');
  const [errornewpassword, setErrorNewpassword] = useState('');
  const [errorconfirmpass, setErrorsConfirmNewPass] = useState('');
  const [bothcheckerror, setbothcheckerror] = useState('');
  
  const [loader, setLoader] = useState(false);
  const [passicon, setpassicon] = useState(false);
  
  const onChangeNewPass = (e)=> {
	setNewPassword(e.target.value);
	setErrorNewpassword('');
  }

  const onChangeConpass = (e)=> {
    setConfirmpass(e.target.value);
    setErrorsConfirmNewPass('');
	
	  
  }
   const showicon = () => {
		setpassicon(!passicon)
	}

  const onFormSubmit = () => {
    if (newpassword === "") {
		setErrorNewpassword("Please enter your newpassword.");
		}
	else if (confirmPassword === "") {
		setErrorsConfirmNewPass("Please enter your confirmpassword.");
		}
     else if (newpassword.length < 6) {
		setErrorNewpassword('Password should be 6 Digit.');
		}
		else if (confirmPassword.length < 6) {
			setErrorsConfirmNewPass('Password should be 6 Digit.');
			}	
			else if(newpassword!=confirmPassword)	{
				setErrorsConfirmNewPass('Password not match.');
			}
	 else {	 
		
    let data = {
		"user_id":GetId.id,
		"newPassword":newpassword,
		"confirmPassword":confirmPassword
	}
			 makePostRequest(URLS.FORGET_CHANGE_PASSWORD, null,data, null)
			 .then(res => {
			if(res.code === 200){
				setLoader(true);
			 toast.success("password update successfully.");	
				setTimeout(() => {
				  window.history.pushState(
					`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
					"auth-login",
					`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
				  );
             window.location.reload();
            }, 1000);
				 
				}else{
				  toast.warn("This link has been expired.");
				}
			 })
			}
  };


  
  
  
  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
	  <ToastContainer />
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Reset Password</BlockTitle>
                
              </BlockContent>
            </BlockHead>
           
            <div className="is-alter" >
			<div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    New Password             
					     </label>
                </div>
                <input
                    type={passState ? "text" : "password"}
                    name="passcode"
                    placeholder="New password"
                    onChange={onChangeNewPass}
                    className={`form-control-lg form-control`}
                  />
				  <span className="error">{errornewpassword}</span>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Confirm Password
                  </label>
                  
                </div>
                <div className="form-control-wrap">
                 
                  <input
                    type={passicon == false ? "password" : "text"}
                    name="passcode"
                    required= "This field is required"
                    placeholder="Confirm password"
                    onChange={onChangeConpass}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
				  <span className="error"> { errorconfirmpass }</span>
				  <span className="eyeIconstyle" onClick={showicon} >
						{passicon == true ?
				<Icon name="eye" className="passcode-icon passcode-icons icon-show"></Icon> :<Icon name="eye-off" className="passcode-icons icon-hide"></Icon>}
					</span>
                 
                </div>
              </div>
              <div className="form-group">
                <Button size="lg" className="btn-block"  onClick={onFormSubmit} color="primary">
                 Update Password
                </Button>
              </div>
            </div>
            {
			   loader && 
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
}
	
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Updatepass;



























// import React, { useEffect, useState } from "react";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import axios from "axios";
// import "./SignIn.css";


// const Updatepass=(props)=>{
// let[data,setData]=useState([])
// let[currentUserAuthData,setCurrentUserAuthData]=useState([])
// let [newpassword,setnewpasswords]=useState("")
// let[confirmpass,setConfirmpass]=useState("")

// console.log(props)
// const handlepass=(e)=>{
// 	setnewpasswords(e.target.value)
// }
// const handleconfirmpass=(e)=>{
// 	setConfirmpass(e.target.value)
// }


// const updatefun=()=>{

// if(newpassword==""){
// 	toast.warn("please enter newpassword")
// }
// else if(confirmpass==""){
// 	toast.warn("please enter confirmpassword")
// }

// 	else if(newpassword!=confirmpass){
// toast.warn("password dosen't match")
// 	}
// 	else{
// 		console.log("iddd",currentUserAuthData)

// 	var payload={
// 		"id":currentUserAuthData.id,
// 		"newPassword":newpassword,
// 		"confirmPassword":confirmpass,
// 	}
//  axios.post("https://api.matchedcommunity.com/api/v1/user/forgetChangePassword",payload)
//  .then((res)=>{
// console.log(res.data)
// if(res.data.code==200){
// 	toast.success("password genrated successfully")
	
// }
// else{
// 	toast.warn("This link has expired.")
// }

//  })
//  .catch((err)=>{
// console.log(err)
//  })
// 	}
// }

// useEffect(()=>{
// 	let token = localStorage.getItem('auth-token');
// 	console.log("iddd",token)
//     if(token){
//       const tokenParts = token.split('.');
// 	  console.log("iddd1",tokenParts)
//       const tokenPayload = JSON.parse(atob(tokenParts[1]));
//       let {userAuth} = tokenPayload;
// 	  console.log("iddd2payload",tokenPayload)
// 	  console.log("iddd2userauth",userAuth)
//       if(userAuth && userAuth.id){
//        setCurrentUserAuthData(userAuth);
//       }

//      }

// },[])
//     return(<>
// 	<ToastContainer/>
//     <form className="frm">
// 			<div className="login">
// 				<div className="loginouter">
					

// 					<h1>Change Password</h1>
// 					<div className="form-group">
// 						<input type="email" className="form-control" id="inputEmail" placeholder="Password" onChange={handlepass} />
// 						<span className="error"></span>
// 					</div>

// 					<div className="form-group">
// 						<input type="password" className="form-control" id="inputEmail" placeholder="Comfirm Password" onChange={handleconfirmpass}/>
// 						<span className="error"> </span>
// 					</div>
// 					<button type="button"className="btn11" onClick={updatefun}>Submit</button>
					
// 				</div>
// 			</div>
// 		</form>
    
//     </>);
// }
// export default Updatepass