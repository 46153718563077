import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";




function UserActivity() {
	
    let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
//   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  return (
    <>
      <Head title="User Activity"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> User Activities 
		 {/* <button onClick={() => history.goBack()} className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end">
		   <em className="icon ni ni-arrow-left"></em><span>Back</span></button> */}
		</h3>
    
        <PreviewCard>
          <Row className="g-gs">
            <Col md={4}>
              <ul className="nav link-list-menu border border-light round mt-0">
                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "1",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("1");
                    }}
                  >
                    <Icon name="user"></Icon>
                    <span>Login Frequency</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "2",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("2");
                    }}
                  >
                    <Icon name="lock-alt"></Icon>
                    <span>Search</span>
                  </a>
                </li>

                 <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "3",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("3");
                    }}
                  >
                    <Icon name="save"></Icon>
                    <span>Stored</span>
                  </a>
                </li>

                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "4",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("4");
                    }}
                  >
                    <Icon name="file-docs"></Icon>
                    <span>Friends</span>
                  </a>
                </li>

                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "5",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("5");
                    }}
                  >
                    <Icon name="link"></Icon>
                    <span>Purchases</span>
                  </a>
                </li> 

                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "6",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("6");
                    }}
                  >
                    <Icon name="link"></Icon>
                    <span>Shares</span>
                  </a>
                </li> 

                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "7",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("7");
                    }}
                  >
                    <Icon name="user-list-fill"></Icon>
                    <span>Serves</span>
                  </a>
                </li> 
              </ul>
            </Col>
            <Col md={8}>
              <TabContent activeTab={verticalIconTab}>
              <TabPane tabId="1">
                {/* <p>default tab-1 login per month</p> */}
                <div className="row d-flex justify-content-start">
                <div className="col-md-6">
                <select className="form-select">
                  <option value="">Select frequency</option>
                  <option value="1">Date wise</option>
                  <option value="2">Month Wise</option>
                 

                </select>
                </div>
                <div className="col-md-6 d-flex gx-1">
                    <div>
                      <button className="btn btn-primary">List View</button>
                      </div>
                      <div>
                    <button className="btn btn-primary">Chart View</button>
                    </div>
                    <div>
                    <button className="btn btn-primary">Export</button>
                    </div>
                </div>
                </div>
              
              

                </TabPane>
                <TabPane tabId="2">
                <div className="row d-flex justify-content-start">
                <div className="col-md-6">
                <select className="form-select">
                  <option value="">Select Search Reports</option>
                  <option value="1">Results Per Search per user</option>
                  <option value="2">Search per user per month</option>
                  <option value="3">Search per Child per month</option>
                  <option value="4">Total searches since joining per user</option>
                  <option value="5">Total searches current year per user</option>


                </select>
                </div>
                <div className="col-md-6 d-flex gx-1">
                    <div>
                      <button className="btn btn-primary">List View</button>
                      </div>
                      <div>
                    <button className="btn btn-primary">Chart View</button>
                    </div>
                    <div>
                    <button className="btn btn-primary">Export</button>
                    </div>
                </div>
                </div>
                </TabPane>
                <TabPane tabId="3">
                <p>Tab 3</p>
                </TabPane>
                <TabPane tabId="4">
           	 <p>Tab 4</p>
                </TabPane>
                <TabPane tabId="5">
                <p>Tab 5</p>
              </TabPane>
              <TabPane tabId="6">
                <p>Tab 6</p>
              </TabPane>
              <TabPane tabId="7">
                <p>Tab 7</p>
              </TabPane>
              <TabPane tabId="8">
                <p>Tab 8</p>
              </TabPane>
              </TabContent>
            </Col>
          </Row>
        </PreviewCard>
      </Content>
    </>
  );
}
export default UserActivity;
