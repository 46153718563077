import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LineChart,
  Line,
} from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import { findUpper, capital } from "../../../utils/Utils";

exporting(Highcharts);
const ExcelJS = require("exceljs");

function CustomerPurchaseReport() {
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [graphReportchart, setGraphReportchart] = useState([]);
  const [userNameData, setUserNameData] = useState([]);
  const [userNameUsers, setNameUsers] = useState("");
  const [purchaseListData, setPurchaseListData] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [showchart1, setshowchart1] = useState([]);
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [loader1, setLoader1] = useState(false);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, setToDateError] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [customerPurchaseData, setCustomerPurchaseData] = useState([]);
  const [parentid, setparentid] = useState("");
  const [childparentdata2, setchildparentdata2] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedResourse, setselectedResourse] = useState();
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [displayvalueSelectListview, setDisplayvalueSelectListview] = useState(true);
  const [displayvalueSelectListResource, setDisplayvalueSelectListResource] = useState(true);
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);

  let chartdata = [];
  let chartdata1 = [];

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const HandleUserName = (e) => {
    setNameUsers(e.value);
    setUserName(e.label);
  };
  const HandleUserNameList = (e) => {
    setUserId(e.value);
  };
  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setFromDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    setToDateError("");
  };

  const handlesearch = (e) => {
    getPurchaseReportChart();
    setNameUsers("");
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const handlesearchList = (e) => {
    if (selectedfromdate != null && selectedtodate == null) {
      setToDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setFromDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getPurchaseListData();
      setCurrentPage(1);
    }
  };
  const handleparentname = (e) => {
    setparentid(e.value);
  };

  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1);
  };

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setUserName("");
    setparentid("");
    selectRefGraphvendor.current.select.setValue("", "select-option");
    setselectedResourse("");
    setSelectyear(new Date().getFullYear());
    selectRefGraph.current.select.setValue("", "select-option");
    selectRef.current.select.setValue("", "select-option");
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setUserId("");
    setUserName("");
    setparentid("");
    selectRefListvendor.current.select.setValue("", "select-option");
    setselectedResourse("");
    setCurrentPage(1);
    setlimitvalue("");
    setSortValue("");
    selectRefList.current.select.setValue("", "select-option");
    selectRefListResource.current.select.setValue("", "select-option");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  };

  const ExportEcelFile = () => {
    setLoader1(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      sort_by: sortvalue ? sortvalue : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      export_data: "yes",
    };
    makePostRequest(URLS.GET_PURCHASE_REPORTLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("PurchaseData");
          sheet.properties.defaultRowHeight = 30;
          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Purchased Date",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },
            {
              header: "($)Total Purchase Amount",
              key: "purchaseamount",
              width: 20,
            },
            {
              header: "Total Purchase Count",
              key: "purchasecount",
              width: 20,
            },
          ];
          res.data.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: val.purchased_date,
              USER_NAME: val?.tbl_user.firstName + " " + val?.tbl_user.lastName,
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              purchaseamount: val?.total_purchased_amount?.toFixed(2),
              purchasecount: val?.total_purchased_count,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "PurchaseReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };
  const getcustomerdata2 = () => {
    makePostRequest(URLS.RESOURCE_SELLER, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setchildparentdata2(res.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //user type get api GET_USER_TYPE
  const getUserName = () => {
    makePostRequest(URLS.RESOURCE_PURCHASE_CUSTOMER, null, {}, null)
      .then((res) => {
        console.log(res,"rescustomer Purchase")
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" });
          res.data.data.length > 0 &&
            res.data.data.map((val) => {
              option.push({ value: val?.tbl_user?.id, label: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPurchaseReportChart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userNameUsers ? userNameUsers : "",
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
    };
    //console.log("data chart:::", data)
    makePostRequest(URLS.GET_CUSTOMER_PURCHASED_REPORTSCHART, null, data, null)
      .then((res) => {
        setLoader(true);
        if (res.code === 200) {
          //console.log(res, "graphdata");
          setGraphReportchart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const viewPurchaseDetails = (row) => {
  
    let data = {
      purchase_date: row?.purchased_date,
      user_id: row?.tbl_user.id,
      seller_id: "",
      resource_id: row?.resource_id,
    };
   
    makePostRequest(URLS.GET_PURCHASE_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        //console.log("purchase details", res)
        setCustomerPurchaseData(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };

  /*============================= purchase list api ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getPurchaseListData = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      sort_by: sortvalue ? sortvalue : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
    };
    //console.log("friend list payload", data)
    makePostRequest(URLS.GET_PURCHASE_REPORTLIST, null, data, null)
      .then((res) => {
        setLoader1(true);
        if (res.code === 200) {
          //console.log(res, "purchaselistdata");
          setPurchaseListData(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getResourceDropdown = () => {
    makePostRequest(URLS.RESOURCE_PURCHASE_FILTER, null, { page_number: dropdownPage }, null)
      .then((res) => {
        //console.log("response1", res)
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  
  useEffect(() => {
    getPurchaseListData();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getUserName();
    getPurchaseReportChart();
    getPurchaseListData();
    getcustomerdata2();
    getResourceDropdown();
  }, []);

  useEffect(() => {
    getchartdata();
  }, [graphReportchart]);

  useEffect(() => {
    getPurchaseReportChart();
  }, [clearStateGraph]);

  useEffect(() => {
    getPurchaseListData();
  }, [clearStateList]);

  const getchartdata = () => {
    graphReportchart?.map((val) => {
      chartdata.push(val.total_purchased_count);
      chartdata1.push(parseFloat(val.total_purchased_amount));
    });
    setshowchart(chartdata);
    setshowchart1(chartdata1);
  };
  console.log(showchart1);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      grow: 6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row.tbl_user.firstName ? row.tbl_user.firstName : "--"}{" "}
              {row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
            </span>
            <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Purchased Date",
      selector: (row) => "",
      grow: 4,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row?.purchased_date ? moment(row?.purchased_date).format("MM-DD-YYYY") : "--"} <br />
          </div>
        </>
      ),
    },

    {
      name: "Amount($)",
      selector: (row) => "",
      grow: 4,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.total_purchased_amount
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(row?.total_purchased_amount)
                : "$0.00"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Purchases",
      selector: (row) => "",
      grow: 4,
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.total_purchased_count ? row.total_purchased_count : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Purchase Details",
      grow: 3,
      sortable: true,
      minWidth: "150px",
      style: { minWidth: "150px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewPurchaseDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];

  const columnsshow = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      grow: 6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row.tbl_user.firstName ? row.tbl_user.firstName : "--"}{" "}
              {row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
            </span>
            <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Purchased Date",
      selector: (row) => "",
      grow: 4,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row?.purchased_date ? moment(row?.purchased_date).format("MM-DD-YYYY") : "--"} <br />
          </div>
        </>
      ),
    },
    {
      name: "Seller Details",
      grow: 6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row.tbl_user_resource?.tbl_user?.firstName ? row.tbl_user_resource?.tbl_user?.firstName : "--"}{" "}
              {row.tbl_user_resource?.tbl_user?.lastName ? row.tbl_user_resource?.tbl_user?.lastName : ""}{" "}
            </span>
            <span className="sub-text">
              {row.tbl_user_resource?.tbl_user?.email ? row.tbl_user_resource?.tbl_user?.email : ""}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Amount($)",
      selector: (row) => "",
      grow: 4,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.total_purchased_amount
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(row?.total_purchased_amount)
                : "$0.00"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Purchases",
      selector: (row) => "",
      grow: 4,
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.total_purchased_count ? row.total_purchased_count : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Purchase Details",
      grow: 3,
      sortable: true,
      minWidth: "150px",
      style: { minWidth: "150px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewPurchaseDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];

  const [vendorname, setVendorname] = useState(true);
  const [vendornames, setVendornames] = useState(true);
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);
  const selectRefList = useRef(null);
  const selectRefListResource = useRef(null);
  const selectRefGraphvendor = useRef(null);
  const selectRefListvendor = useRef(null);

  const handleVendorList = () => {
    if (selectRefListvendor.current) {
      setVendornames(!vendornames);
      selectRefListvendor.current.focus();
    }
  };

  const handleVendor = () => {
    if (selectRefGraphvendor.current) {
      setVendorname(!vendorname);
      selectRefGraphvendor.current.focus();
    }
  };

  const displaySelectUserList = (e) => {
    if (selectRefList.current) {
      setDisplayvalueSelectListview(!displayvalueSelectListview);
      selectRefList.current.focus();
    }
  };

  const displaySelectUserListResource = (e) => {
    if (selectRefListResource.current) {
      setDisplayvalueSelectListResource(!displayvalueSelectListResource);
      selectRefListResource.current.focus();
    }
  };

  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelect(!displayvalueSelect);
      selectRef.current.focus();
    }
  };

  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Purchase Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4">Customer Purchased Report</h3>

        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Purchase Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="purchase-report">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table style={{ width: "100%" }}>
                            <tr className="border-bottom">
                              <th>#</th>
                              <th>Time of Purchase</th>
                              <th>Resource Title </th>
                            </tr>
                            {customerPurchaseData &&
                              customerPurchaseData.map((val, i) => {
                                return (
                                  <tr className="border-bottom">
                                    <td>{i + 1}</td>
                                    <td>{moment(val?.created_at).format("hh:mm a")}</td>
                                    <td>{val?.tbl_user_resource.resource_title}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userName}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectGraph}
                                onChange={HandleUserName}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendor}
                              ></i>

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handleparentname}
                                defaultValue={parentid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(childparentdata2?.map((val) => ({
                                    value: val.tbl_user_resource.tbl_user.id,
                                    label:
                                      val.tbl_user_resource.tbl_user?.firstName +
                                      " " +
                                      val.tbl_user_resource.tbl_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendorname}
                                ref={selectRefGraphvendor}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i>

                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                // value={selectedResourse}
                                onChange={(e) => setselectedResourse(e)}
                                ref={selectRef}
                                //onMenuScrollToBottom={handleMenuScroll}
                                options={[
                                  { value: "", label: "All Resource" },
                                  ...resourceDropdown?.map((v) => ({
                                    value: v?.tbl_user_resource.id,
                                    label: v?.tbl_user_resource.resource_title,
                                  })),
                                ]}
                                openMenuOnFocus={displayvalueSelect}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              View{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relativeClass" style={{ minHeight: "100px" }}>
                      <div className="p-4">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square">
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-lg-6">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    type: "line",
                                  },
                                  title: {
                                    text: "Monthly Purchase Count Chart",
                                    style: { color: "#003C71", fontSize: "20px" },
                                  },

                                  xAxis: {
                                    categories: [
                                      "Jan",
                                      "Feb",
                                      "Mar",
                                      "Apr",
                                      "May",
                                      "Jun",
                                      "Jul",
                                      "Aug",
                                      "Sep",
                                      "Oct",
                                      "Nov",
                                      "Dec",
                                    ],
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  plotOptions: {
                                    line: {
                                      dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                          return this.y != 0 ? this.y : "";
                                        },
                                      },
                                      enableMouseTracking: true,
                                    },
                                  },
                                  credits: {
                                    enabled: false,
                                  },

                                  yAxis: {
                                    title: {
                                      text: "No. of Purchases",
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  series: [
                                    {
                                      name: "Month",
                                      data: showchart,
                                      color: "rgb(38, 84, 114)",
                                    },
                                  ],
                                }}
                              />
                            </div>
                            <div className="col-lg-6">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    type: "line",
                                  },
                                  title: {
                                    text: "Monthly Purchase Amount  Chart",
                                    style: { color: "#003C71", fontSize: "20px" },
                                  },

                                  xAxis: {
                                    categories: [
                                      "Jan",
                                      "Feb",
                                      "Mar",
                                      "Apr",
                                      "May",
                                      "Jun",
                                      "Jul",
                                      "Aug",
                                      "Sep",
                                      "Oct",
                                      "Nov",
                                      "Dec",
                                    ],
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  plotOptions: {
                                    line: {
                                      dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                          return this.y != 0
                                            ? new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              }).format(this.y)
                                            : "$0.00";
                                        },
                                      },
                                      enableMouseTracking: true,
                                    },
                                  },
                                  credits: {
                                    enabled: false,
                                  },

                                  yAxis: {
                                    title: {
                                      text: "Purchased Amount",
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  series: [
                                    {
                                      name: "Month",
                                      data: showchart1,
                                      color: "rgb(38, 84, 114)",
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="p-4">
                      <div className="row">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="filtersidebutton">
                            <h3 className="graphheading mb-0 mt-1">List View</h3>
                            <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                              Clear Filters
                            </button>
                          </div>
                          <div className=" d-flex  justify-content-end">
                            <select
                              value={sortvalue}
                              className="form-select"
                              style={{ width: "190px" }}
                              onChange={HandleSort}
                            >
                              <option value="">Sort By</option>
                              <option value="ASCDATE">Date Ascending</option>
                              <option value="DESCDATE">Date Descending</option>
                              <option value="ASCCOUNT">Purchase Count Ascending</option>
                              <option value="DESCCOUNT">Purchase Count Descending</option>
                              <option value="ASCAMOUNT">Purchase Amount Ascending</option>
                              <option value="DESCAMOUNT">Purchase Amount Descending</option>
                            </select>
                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                {/* <option value="5">5</option> */}
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row relativewithzindex">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap saveZindex paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectUserList}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userId}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectListview}
                                onChange={HandleUserNameList}
                                ref={selectRefList}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendorList}
                              ></i>

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handleparentname}
                                defaultValue={parentid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(childparentdata2?.map((val) => ({
                                    value: val.tbl_user_resource.tbl_user.id,
                                    label:
                                      val.tbl_user_resource.tbl_user?.firstName +
                                      " " +
                                      val.tbl_user_resource.tbl_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendorname}
                                ref={selectRefListvendor}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectUserListResource}
                              ></i>
                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                // value={selectedResourse}
                                openMenuOnFocus={displayvalueSelectListResource}
                                onChange={(e) => setselectedResourse(e)}
                                //onMenuScrollToBottom={handleMenuScroll}
                                options={[
                                  { value: "", label: "All Resource" },
                                  ...resourceDropdown?.map((v) => ({
                                    value: v?.tbl_user_resource.id,
                                    label: v?.tbl_user_resource.resource_title,
                                  })),
                                ]}
                                ref={selectRefListResource}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{fromDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlesearchList}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relativeClass newregisterGrouptable">
                      {loader1 == true ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {purchaseListData && purchaseListData?.length > 0 ? (
                        <div>
                          {purchaseListData?.some((obj) => Object.keys(obj.tbl_user_resource).includes("tbl_user")) ? (
                            <div className="color">
                              <ReactDataTable data={purchaseListData} columns={columnsshow} className="nk-tb-list" />
                            </div>
                          ) : (
                            <div className="color">
                              <ReactDataTable data={purchaseListData} columns={columns} className="nk-tb-list" />
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="p-4" style={{ minHeight: "100px" }}>
                          No Data Found
                        </p>
                      )}
                    </div>

                    <div className="p-4">
                      {purchaseListData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default CustomerPurchaseReport;
