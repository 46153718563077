import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { Icon, ReactDataTable } from "../../../components/Component";
import { Label, Input } from "reactstrap";
import { makeGetRequest, makePostRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetailsFromToken } from "../../../service/auth";
// import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";


function ForceUpdate() {
    const [selectedVersionData, setSelectedVersionData] = useState({});
    const [loader, setLoader] = useState(false);
    const [mobileVersionData, setMobileVersionData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);

    let userAuth = getUserDetailsFromToken();

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            cell: (row, index) => <div className="user-card mt-2 mb-2">{index + 1}</div>,
        },
        {
            name: "Update Date",
            selector: (row) => "",
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <span className="sub-text">{moment(row.updated_at).format("MM-DD-YYYY")} </span>
                </div>
            ),
        },
        {
            name: "Current Version",
            selector: (row) => row.current_version,
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <span className="sub-text">{row.current_version}</span>
                </div>
            ),
        },
        {
            name: "Previous Version",
            selector: (row) => row.previous_version,
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <span className="sub-text">{row.previous_version}</span>
                </div>
            ),
        },

        {
            name: "Type",
            selector: (row) => "",
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <span className="sub-text">{row.id === 1 ? "Ios" :"Android"}</span>
                </div>
            ),
        },
        {
            name: "Force Update",
            selector: (row) => row.force_update,
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <span className="sub-text">{row.force_update ? "Yes" : "No"}</span>
                </div>
            ),
        },
        // {
        //     name: "Enabled",
        //     selector: (row) => row.is_enabled,
        //     cell: (row) => (
        //         <div className="user-card mt-2 mb-2">
        //             <span className="sub-text">{row.is_enabled ? "Yes" : "No"}</span>
        //         </div>
        //     ),
        // },
        {
            name: "Action",
            selector: (row) => row.status,
            cell: (row) => (
                <div className="user-card mt-2 mb-2">
                    <button className="dropdown-item" onClick={() => handleEditButton(row)}>
                        <Icon name="edit"></Icon>
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        getAllMobileVersion();
    }, []);

    const getAllMobileVersion = () => {
        setLoader(true);
        makeGetRequest(URLS.GET_ALL_MOBILE_VERSION, {}, null)
            .then((res) => {
                console.log(res,"res..................")
                if (res.code === 200) {
                    setMobileVersionData(res.data);
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                toast.error("Failed to load mobile version data.");
            });
    };

    const handleEditButton = (val) => {
        setIsUpdate(true);
        setSelectedVersionData({
            id: val.id,
            currentVersion: val.current_version,
            previousVersion: val.previous_version,
            forceUpdate: val.force_update,
            // isEnabled: val.is_enabled,
        });
        window.scroll({ top: 0, behavior: "smooth" });
    };

    const toggleForceUpdate = () => {

       const a = setSelectedVersionData((prev) => ({
            ...prev,
            forceUpdate: !prev.forceUpdate,
        }));
    };

    const toggleIsEnabled = () => {
        setSelectedVersionData((prev) => ({
            ...prev,
            isEnabled: !prev.isEnabled,
        }));
    };

    const handleCurrentVersionChange = (e) => {
        setSelectedVersionData((prev) => ({
            ...prev,
            currentVersion: e.target.value,
        }));
    };

    const handlePreviousVersionChange = (e) => {
        setSelectedVersionData((prev) => ({
            ...prev,
            previousVersion: e.target.value,
        }));
    };


    const updateMobileVersion = () => {

        const { currentVersion, previousVersion } = selectedVersionData;

        
        if (!currentVersion.trim()) {
            toast.error("Current Version should not be blank.");
            return;
        }
        if(!previousVersion.trim()){
            toast.error("Previous Version should not be blank.");
            return;
        }
        const data = {
            id: selectedVersionData.id,
            current_version: selectedVersionData.currentVersion,
            previous_version: selectedVersionData.previousVersion,
            force_update: selectedVersionData.forceUpdate,
            // is_enabled: selectedVersionData.isEnabled,
            updated_at: new Date(),
        };
console.log(data,"paylooad.......")
        makePostRequest(URLS.UPDATE_VERSION, null, data, null)
            .then((res) => {
                if (res.code === 200) {
                    toast.success(res.message);
                    resetForm();
                    getAllMobileVersion();
                } else {
                    toast.error(res.message);
                }
            });
    };

    const resetForm = () => {
        setSelectedVersionData({});
        setIsUpdate(false);
    };

    return (
        <React.Fragment>
            <Head title="Force Update" />
            <Content>
                <ToastContainer />
                <h3 className="nk-block-title page-title mb5 pb-4">Force Update</h3>
                {loader ? (
                    <div className="overflowloader" id="preload">
                        <div className="circle-square" style={{ marginTop: "250px" }}>
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                        </div>
                    </div>
                ) : (
                    <div className="card-bordered card card-inner-lg">
                        <BlockHeadContent>
                            <BlockHead size="lg">
                                {isUpdate ? (
                                    <div className="pb-5">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <Label className="form-label">Current Version</Label>
                                                    <Input
                                                        placeholder="Current Version"
                                                        type="text"
                                                        value={selectedVersionData.currentVersion}
                                                        onChange={handleCurrentVersionChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <Label className="form-label">Previous Version</Label>
                                                    <Input
                                                        placeholder="Previous Version"
                                                        value={selectedVersionData.previousVersion}
                                                        onChange={handlePreviousVersionChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    checked={selectedVersionData.forceUpdate}
                                                    onChange={toggleForceUpdate}
                                                    id="forceUpdateCheckbox"
                                                />
                                                <Label className="form-check-label" htmlFor="forceUpdateCheckbox">
                                                    Enable Force Update
                                                </Label>
                                            </div>
                                            {/* <div className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    checked={selectedVersionData.isEnabled}
                                                    onChange={toggleIsEnabled}
                                                    id="isEnabledCheckbox"
                                                />
                                                <Label className="form-check-label" htmlFor="isEnabledCheckbox">
                                                    Enable
                                                </Label>
                                            </div> */}
                                        </div>
                                        <div className="align-center flex-sm-nowrap mt-4 pt-1 px-3">
                                            <button className="btn btn-primary btn-md" onClick={updateMobileVersion}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <ReactDataTable
                                            columns={columns}
                                            data={mobileVersionData}
                                            searchable={true}
                                        />
                                    </div>
                                )}
                            </BlockHead>
                        </BlockHeadContent>
                    </div>
                )}
            </Content>
        </React.Fragment>
    );
}

export default ForceUpdate;
