import React, { useState, useEffect } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import { CardTitle } from "reactstrap";
import { makePostRequest, getHeaders } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import { Link } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  Row,
  Col,
  Button,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
import { findUpper, capital } from "../../../../utils/Utils";

const ResourceData = () => {
  let todaysDate = new Date();
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  let curretdate = moment(todaysDate).format("YYYY-MM-DD");

  const [subscriptionData, setSubscriptionData] = useState([]);

  const GetAllQuickDashboardTodayData = () => {
    let data = {
      selected_date: curretdate,
    };
    makePostRequest(URLS.GET_DASHBOARD_TODAY_DATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setSubscriptionData(res.data.subscription_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllQuickDashboardTodayData();
  }, []);

  const columns = [
    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 5,
      // minWidth: "170px",
      // style: { minWidth: "170px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))}
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">
                {" "}
                {row.tbl_user.firstName} {row.tbl_user.lastName}{" "}
              </span>
              <span className="sub-text">{row.tbl_user.email}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Title",

      grow: 6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text"> {row.tbl_subscription_plan.title} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Valid Until",

      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {row.tbl_subscription_plan.payment_frequency_type == "free" ? (
            <div className="fetimesubscriptiontext">Life Time</div>
          ) : (
            <div className="validdatetext">{moment(row.valid_upto).format("MM-DD-YYYY")}</div>
          )}{" "}
        </div>
      ),
    },
    {
      name: "Space Allocated(MB)",

      grow: 4,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {row.space_allocated ? parseFloat(row.space_allocated / 1024).toFixed(2) : "--"}{" "}
        </div>
      ),
    },

    {
      name: "View",

      grow: 2,
      // minWidth: "140px",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-subscriptionplan/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="newregisterGrouptable">
        <CardTitle className="mb-0 borderBottomTitle">
          <h6 className="title p-4 px-2">
            <span className="me-0">Today's Subscription</span>{" "}
          </h6>
        </CardTitle>
        <>
          <div className="card-inners relativeClass">
            <div className="card-inners-scroll">
              <BlockHeadContent>
                <BlockHead size="lg">
                  {subscriptionData && subscriptionData?.length > 0 ? (
                    <div>
                      <div className="color">
                        <ReactDataTable data={subscriptionData} columns={columns} className="nk-tb-list" />
                      </div>
                    </div>
                  ) : (
                    <p className="p-3">No Data Found</p>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};
export default ResourceData;
