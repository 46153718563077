import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";

import Homepage from "../pages/Homepage";
import Crypto from "../pages/Crypto";
import Analytics from "../pages/Analytics";
import Invest from "../pages/Invest";

import Component from "../pages/components/Index";
import Accordian from "../pages/components/Accordions";
import Alerts from "../pages/components/Alerts";
import Avatar from "../pages/components/Avatar";
import Badges from "../pages/components/Badges";
import Breadcrumbs from "../pages/components/Breadcrumbs";
import ButtonGroup from "../pages/components/ButtonGroup";
import Buttons from "../pages/components/Buttons";
import Cards from "../pages/components/Cards";
import Carousel from "../pages/components/Carousel";
import Dropdowns from "../pages/components/Dropdowns";
import FormElements from "../pages/components/forms/FormElements";
import FormLayouts from "../pages/components/forms/FormLayouts";
import FormValidation from "../pages/components/forms/FormValidation";
import DataTablePage from "../pages/components/table/DataTable";
import Modals from "../pages/components/Modals";
import Pagination from "../pages/components/Pagination";
import Popovers from "../pages/components/Popovers";
import Progress from "../pages/components/Progress";
import Spinner from "../pages/components/Spinner";
import Tabs from "../pages/components/Tabs";
import Toast from "../pages/components/Toast";
import Tooltips from "../pages/components/Tooltips";
import Typography from "../pages/components/Typography";
import CheckboxRadio from "../pages/components/forms/CheckboxRadio";
import AdvancedControls from "../pages/components/forms/AdvancedControls";
import InputGroup from "../pages/components/forms/InputGroup";
import FormUpload from "../pages/components/forms/FormUpload";
import NumberSpinner from "../pages/components/forms/NumberSpinner";
import NouiSlider from "../pages/components/forms/nouislider";
import WizardForm from "../pages/components/forms/WizardForm";
import UtilBorder from "../pages/components/UtilBorder";
import UtilColors from "../pages/components/UtilColors";
import UtilDisplay from "../pages/components/UtilDisplay";
import UtilEmbeded from "../pages/components/UtilEmbeded";
import UtilFlex from "../pages/components/UtilFlex";
import UtilOthers from "../pages/components/UtilOthers";
import UtilSizing from "../pages/components/UtilSizing";
import UtilSpacing from "../pages/components/UtilSpacing";
import UtilText from "../pages/components/UtilText";

import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import BasicTable from "../pages/components/table/BasicTable";
import SpecialTablePage from "../pages/components/table/SpecialTable";
import ChartPage from "../pages/components/charts/Charts";
import EmailTemplate from "../pages/components/email-template/Email";
import NioIconPage from "../pages/components/crafted-icons/NioIcon";
import SVGIconPage from "../pages/components/crafted-icons/SvgIcons";

import ProjectCardPage from "../pages/pre-built/projects/ProjectCard";
import ProjectListPage from "../pages/pre-built/projects/ProjectList";
/*========== Add custome page link ===========*/
import ManageRole from "../pages/pre-built/projects/ManageRole";
import ManagePermision from "../pages/pre-built/projects/ManagePermision";
import MapRolePermision from "../pages/pre-built/projects/MapRolePermision";
import ManageUser from "../pages/pre-built/projects/ManageUser";
import LoginWithAdmin from "../pages/pre-built/projects/LoginWithAdmin";
import SystemSettings from "../pages/pre-built/projects/SystemSettings";
import TaxManagement from "../pages/pre-built/projects/TaxManagement";
import ManageLearningPiller from "../pages/pre-built/projects/ManageLearningPiller";
import ManageResourceType from "../pages/pre-built/projects/ManageResourceType";
import ManageGradeLevel from "../pages/pre-built/projects/ManageGradeLevel";
import ManageTopicSubject from "../pages/pre-built/projects/ManageTopicSubject";
import ManageTopicCategory from "../pages/pre-built/projects/ManageTopicCategory";
import ManageTopic from "../pages/pre-built/projects/ManageTopic";

import ManageLearningStyle from "../pages/pre-built/projects/ManageLearningStyle";
import ManageEducationCourse from "../pages/pre-built/projects/ManageEducationCourse";
import ManageSpecialLearningCourse from "../pages/pre-built/projects/ManageSpecialLearningCourse";
import ManageInstructionStyle from "../pages/pre-built/projects/ManageInstructionStyle";
import ManageState from "../pages/pre-built/projects/ManageState";
//import ManagePromoCode from "../pages/pre-built/projects/ManagePromoCode";
import ManageSubscriptionPlan from "../pages/pre-built/projects/ManageSubscriptionPlan";
import CustomerList from "../pages/pre-built/customer/CustomerList";
import ViewCustomerDetails from "../pages/pre-built/customer/ViewCustomerDetails";
import ListResource from "../pages/pre-built/resource/ListResource";
import ViewResource from "../pages/pre-built/resource/ViewResource";
import EditResource from "../pages/pre-built/resource/EditResource";
import EditCustomer from "../pages/pre-built/customer/EditCustomer";
import ChildCard from "../pages/pre-built/resource/ChildCard";
import PurchaseHistory from "../pages/pre-built/purchase-history/PurchaseHistory";
import PdfViewer from "../pages/pre-built/resource/PdfViewer";

import TypeofFacility from "../pages/pre-built/projects/TypeofFacility";
import Image from "../pages/pre-built/projects/Image";
import VideoUpload from "../pages/pre-built/projects/VideoUpload";
import TypeofFunding from "../pages/pre-built/projects/TypeofFunding";
import TypeofSupport from "../pages/pre-built/projects/TypeofSupport";
import TypeofExperience from "../pages/pre-built/projects/TypeofExperience";
import TypeofOrganization from "../pages/pre-built/projects/TypeofOrganization";
import ListOffensive from "../pages/pre-built/offensive/ListOffensive";
import ListMentor from "../pages/pre-built/mentor/ListMentor";
import ListSubscription from "../pages/pre-built/subscription/ListSubscription";
import ViewSubscriptionPlan from "../pages/pre-built/subscription/ViewSubscriptionPlan";
import ViewUserDetails from "../pages/pre-built/subscription/ViewUserDetails";


import UserListRegularPage from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCardPage from "../pages/pre-built/user-manage/UserContactCard";
import UserDetailsPage from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";

import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";
import InvoiceList from "../pages/pre-built/invoice/InvoiceList";
import InvoiceDetails from "../pages/pre-built/invoice/InvoiceDetails";
import PricingTable from "../pages/pre-built/pricing-table/PricingTable";
import GalleryPreview from "../pages/pre-built/gallery/GalleryCardPreview";
import ReactToastify from "../pages/components/misc/ReactToastify";

import AppMessages from "../pages/app/messages/Messages";
import Chat from "../pages/app/chat/ChatContainer";
import Calender from "../pages/app/calender/Calender";
import FileManager from "../pages/app/file-manager/FileManager";
import Inbox from "../pages/app/inbox/Inbox";
import Kanban from "../pages/app/kanban/Kanban";
import DateTimePicker from "../pages/components/forms/DateTimePicker";
import CardWidgets from "../pages/components/widgets/CardWidgets";
import ChartWidgets from "../pages/components/widgets/ChartWidgets";
import RatingWidgets from "../pages/components/widgets/RatingWidgets";
import SlickPage from "../pages/components/misc/Slick";
import SweetAlertPage from "../pages/components/misc/SweetAlert";
import BeautifulDnd from "../pages/components/misc/BeautifulDnd";
import DualListPage from "../pages/components/misc/DualListbox";
import GoogleMapPage from "../pages/components/misc/GoogleMap";
import JsTreePreview from "../pages/components/misc/JsTree";
import QuillPreview from "../pages/components/forms/rich-editor/QuillPreview";
import TinymcePreview from "../pages/components/forms/rich-editor/TinymcePreview";
import KnobPreview from "../pages/components/charts/KnobPreview";
import { FileManagerContextProvider } from "../pages/app/file-manager/FileManagerContext";
import TypeofContent from "../pages/pre-built/projects/TypeofContent";
import ManageCheckList from "../pages/pre-built/projects/ManageCheckList";
import PaymentReport from "../pages/pre-built/projects/PaymentReport/PaymentReport";
import TaxReport from "../pages/pre-built/projects/TaxReport/TaxReport";
import NotificationSettings from "../pages/pre-built/projects/NotificationSettings";
import SearchAnalytics from "../pages/pre-built/projects/SearchAnalytics/SearchAnalytics";
import GroupList from "../pages/pre-built/Group/GroupList";
import ViewGroupDetails from "../pages/pre-built/Group/ViewGroupDetails";
import EditGroup from "../pages/pre-built/Group/EditGroup";
import UserActivity from "../pages/pre-built/reports/UserActivity";


import UserPlatform from "../pages/pre-built/reports/UserPlatform";

import UserRetentionRate from "../pages/pre-built/reports/UserRetentionRate";
//import UnSubscribeRate from "../pages/pre-built/reports/UnsubScribeRate";
// import UnSubscribeRate from "../pages/pre-built/reports/UnSubscribeRate";
import PlatformCostPerUser from "../pages/pre-built/reports/PlatformCostPerUser";
import MonthlyRevenue from "../pages/pre-built/reports/MonthlyRevenue";
import MonthlyPlatformCost from "../pages/pre-built/reports/MonthlyPlatformCost";
import MonthlyMaintenanceCost from "../pages/pre-built/reports/MonthlyMaintenanceCost";
import CustomerReport from "../pages/pre-built/reports/CustomerReport";
import CustomerRetentionRatio from "../pages/pre-built/reports/CustomerRetentionRatio";

import CustomerLoginReport from "../pages/pre-built/reports/CustomerLoginReport";
import CustomerSearchReport from "../pages/pre-built/reports/CustomerSearchReport";
import FriendsReport from "../pages/pre-built/reports/FriendsReport";
import FriendsListReport from "../pages/pre-built/reports/FriendsListReport";
import CustomerPurchaseReport from "../pages/pre-built/reports/CustomerPurchaseReport";
import CustomerShareReport from "../pages/pre-built/reports/CustomerShareReport";
import CustomerSearchResultReport from "../pages/pre-built/reports/CustomerSearchResultReport";
import CustomerStoredResultReport from "../pages/pre-built/reports/CustomerStoredResultReport";
import CustomerStoredReport from "../pages/pre-built/reports/CustomerStoredReport";
import ResourceReport from "../pages/pre-built/reports/ResourceReport";
import CustomerSpaceReport from "../pages/pre-built/reports/CustomerSpaceReport";
import SubscriptionPaymentReport from "../pages/pre-built/reports/SubscriptionPaymentReport";
import MentoringSessionPaymentReport from "../pages/pre-built/reports/MentoringSessionPaymentReport";
import PurchaseResourcePaymentReport from "../pages/pre-built/reports/PurchaseResourcePaymentReport";
import SystemDefaultSetting from "../pages/pre-built/systemdefaultsetting/SystemDefaultSetting";

import ResourceGetMoreInfo from "../pages/pre-built/reports/ResourceGetMoreInfo";
import ResourceEnrollment from "../pages/pre-built/reports/ResourceEnrollment";
import ResourceSignupReport from "../pages/pre-built/reports/ResourceSignupReport";
import GroupClickReport from "../pages/pre-built/reports/GroupClickReport";
import GroupResourceClickReport from "../pages/pre-built/reports/GroupResourceClickReport";
import IndividualResourceClickReport from "../pages/pre-built/reports/IndividualResourceClickReport";
import ForceUpdate from "../pages/pre-built/projects/ForceUpdate";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route exact path={`${process.env.PUBLIC_URL}/crypto`} component={Crypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/analytics`} component={Analytics}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invest`} component={Invest}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>

        {/*Pre-built Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/project-card`} component={ProjectCardPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/project-list`} component={ProjectListPage}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/manage-role`} component={ManageRole}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-permision`} component={ManagePermision}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/map-role-permision`} component={MapRolePermision}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-user`} component={ManageUser}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/login-admin`} component={LoginWithAdmin}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/system-setting`} component={SystemSettings}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/force-update`} component={ForceUpdate}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/notification-setting`} component={NotificationSettings}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/tax-management`} component={TaxManagement}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-learning-piller`} component={ManageLearningPiller}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-resource-type`} component={ManageResourceType}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-grade-level`} component={ManageGradeLevel}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-topic-subject`} component={ManageTopicSubject}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-topic-category`} component={ManageTopicCategory}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-topic`} component={ManageTopic}></Route>
		
		
		<Route exact path={`${process.env.PUBLIC_URL}/facility-type`} component={TypeofFacility}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/funding-type`} component={TypeofFunding}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/support-type`} component={TypeofSupport}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/experience-type`} component={TypeofExperience}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/organization-type`} component={TypeofOrganization}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/offensive-list`} component={ListOffensive}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/mentor-list`} component={ListMentor}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/subscription-list`} component={ListSubscription}></Route>
    <Route exact path={`${process.env.PUBLIC_URL}/image`} component={Image}></Route>
    <Route exact path={`${process.env.PUBLIC_URL}/video-upload`} component={VideoUpload}></Route>
		
    <Route exact path={`${process.env.PUBLIC_URL}/view-groupdetail/:id`} component={ViewGroupDetails}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/view-subscriptionplan/:id`} component={ViewSubscriptionPlan}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/view-userdetails/:id`} component={ViewUserDetails}></Route>
		
		<Route exact path={`${process.env.PUBLIC_URL}/purchase-list`} component={PurchaseHistory}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/payment-report`} component={PaymentReport}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/search-analytics`} component={SearchAnalytics}></Route>
		<Route exact path={`${process.env.PUBLIC_URL}/tax-report`} component={TaxReport}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/manage-learning-style`} component={ManageLearningStyle}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage-education-course`}
          component={ManageEducationCourse}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage-special-learning-course`}
          component={ManageSpecialLearningCourse}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage-instruction-style`}
          component={ManageInstructionStyle}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/manage-state`} component={ManageState}></Route>
        {/*<Route exact path={`${process.env.PUBLIC_URL}/manage-promo-code`} component={ManagePromoCode}></Route> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage-subscription-plan`}
          component={ManageSubscriptionPlan}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-list`} component={CustomerList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/systemdefault-setting`} component={SystemDefaultSetting}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/group-list`} component={GroupList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-activity`} component={UserActivity}></Route>
       
        <Route exact path={`${process.env.PUBLIC_URL}/user-platform`} component={UserPlatform}></Route>
        
        <Route exact path={`${process.env.PUBLIC_URL}/user-retention`} component={UserRetentionRate}></Route>
       
        <Route exact path={`${process.env.PUBLIC_URL}/platform-costperuser`} component={PlatformCostPerUser}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/monthly-revenue`} component={MonthlyRevenue}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/monthly-platformcost`} component={MonthlyPlatformCost}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/monthly-Maintenancecost`} component={MonthlyMaintenanceCost}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-report`} component={CustomerReport}></Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/unsubscribe-rate`} component={UnSubscribeRate}></Route> */}
        <Route exact path={`${process.env.PUBLIC_URL}/customer-retention-ratio`} component={CustomerRetentionRatio}></Route>
        

        <Route exact path={`${process.env.PUBLIC_URL}/customer-login-report`} component={CustomerLoginReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-search-report`} component={CustomerSearchReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/friends-report`} component={FriendsReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/friends-list-report`} component={FriendsListReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-purchase-report`} component={CustomerPurchaseReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-share-report`} component={CustomerShareReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-search-result-report`} component={CustomerSearchResultReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-stored-result-report`} component={CustomerStoredResultReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-stored-report`} component={CustomerStoredReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-resource-report`} component={ResourceReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/customer-space-report`} component={CustomerSpaceReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/subscription-payment-report`} component={SubscriptionPaymentReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/mentoring-session-payment-report`} component={MentoringSessionPaymentReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/purchase-resource-payment-report`} component={PurchaseResourcePaymentReport}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/resource-getmoreinfo-report`} component={ResourceGetMoreInfo}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/resource-enrollment-report`} component={ResourceEnrollment}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/resource-signup-report`} component={ResourceSignupReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/group-click-report`} component={GroupClickReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/group-resource-click-report`} component={GroupResourceClickReport}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/individual-click-report`} component={IndividualResourceClickReport}></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/view-customer-details/:id`}
          component={ViewCustomerDetails}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/view-resource/:id`} component={ViewResource}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/resource-list`} component={ListResource}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editResource-page/:id`} component={EditResource}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editCustomer-page/:id`} component={EditCustomer}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editGroup-page/:id`} component={EditGroup}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/childCard`} component={ChildCard}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/PdfViewer`} component={PdfViewer}></Route>
        
        

        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-regular/`} component={UserProfileLayout}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-activity/`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-setting/`} component={UserProfileLayout}></Route>
        <Route //Context api added
          exact
          path={`${process.env.PUBLIC_URL}/user-contact-card`}
          render={() => (
            <UserContextProvider>
              <UserContactCardPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-list-regular`} component={KycListRegular}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/kyc-details-regular/:id`} component={KycDetailsRegular}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transaction-basic`} component={TransListBasic}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/transaction-crypto`} component={TransListCrypto}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/product-list`} component={ProductList}></Route>
		
		<Route exact path={`${process.env.PUBLIC_URL}/content-type`} component={TypeofContent}></Route>

        <Route // context api added
          exact
          path={`${process.env.PUBLIC_URL}/product-card`}
          render={(props) => (
            <ProductContextProvider>
              <ProductCard />
            </ProductContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/product-details/:id`}
          render={(props) => (
            <ProductContextProvider>
              <ProductDetails {...props} />
            </ProductContextProvider>
          )}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoice-list`} component={InvoiceList}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/invoice-details/:id`} component={InvoiceDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/image-gallery`} component={GalleryPreview}></Route>

        {/*Demo Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v1`} component={Regularv1}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v2`} component={Regularv2}></Route>

        {/*Application*/}
        <Route exact path={`${process.env.PUBLIC_URL}/app-messages`} component={AppMessages}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-chat`} component={Chat}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-calender`} component={Calender}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/files`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/shared`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/starred`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/recovery`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/settings`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/pricing`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-file-manager/folder/:id`}
          render={(props) => (
            <FileManagerContextProvider>
              <FileManager />
            </FileManagerContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage-checklist`}
          component={ManageCheckList}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-inbox`} component={Inbox}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/app-kanban`} component={Kanban}></Route>

        {/*Components*/}
        <Route exact path={`${process.env.PUBLIC_URL}/components`} component={Component}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/accordions`} component={Accordian}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/alerts`} component={Alerts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/avatar`} component={Avatar}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/badges`} component={Badges}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/breadcrumbs`} component={Breadcrumbs}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/button-group`} component={ButtonGroup}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/buttons`} component={Buttons}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/cards`} component={Cards}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/carousel`} component={Carousel}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/dropdowns`} component={Dropdowns}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-elements`} component={FormElements}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-layouts`} component={FormLayouts}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/checkbox-radio`} component={CheckboxRadio}></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/advanced-control`}
          component={AdvancedControls}
        ></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/input-group`} component={InputGroup}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-upload`} component={FormUpload}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/number-spinner`} component={NumberSpinner}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/form-validation`} component={FormValidation}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/datetime-picker`} component={DateTimePicker}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/modals`} component={Modals}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/pagination`} component={Pagination}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/popovers`} component={Popovers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/progress`} component={Progress}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/spinner`} component={Spinner}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/tabs`} component={Tabs}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/toast`} component={Toast}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/tooltips`} component={Tooltips}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/typography`} component={Typography}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/noUislider`} component={NouiSlider}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/wizard-basic`} component={WizardForm}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/quill`} component={QuillPreview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/tinymce`} component={TinymcePreview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/widgets/cards`} component={CardWidgets}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/widgets/charts`} component={ChartWidgets}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/widgets/rating`} component={RatingWidgets}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/slick-slider`} component={SlickPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/sweet-alert`} component={SweetAlertPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/beautiful-dnd`} component={BeautifulDnd}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/dual-list`} component={DualListPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/map`} component={GoogleMapPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/toastify`} component={ReactToastify}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/misc/jsTree`} component={JsTreePreview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-border`} component={UtilBorder}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-colors`} component={UtilColors}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-display`} component={UtilDisplay}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-embeded`} component={UtilEmbeded}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-flex`} component={UtilFlex}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-others`} component={UtilOthers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-sizing`} component={UtilSizing}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-spacing`} component={UtilSpacing}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/util-text`} component={UtilText}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/table-basic`} component={BasicTable}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/table-datatable`} component={DataTablePage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/table-special`} component={SpecialTablePage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/charts/chartjs`} component={ChartPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/charts/knobs`} component={KnobPreview}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/email-template`} component={EmailTemplate}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/nioicon`} component={NioIconPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/svg-icons`} component={SVGIconPage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>

        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
