import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
  ReactDataTable
} from "../../../components/Component";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
const ExcelJS = require("exceljs");
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";

exporting(Highcharts);

function PurchaseResourcePaymentReport() {

  const [customerstoredreport, setCustomerStoredreport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("");
  const [childId, setChildId] = useState("");
  const [userName, setUserName] = useState("");
  const [childName, setChildName] = useState("");
  const [userNameData, setUserNameData] = useState([]);
  const [childNameData, setChildNameData] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [savedtype, setSavedType] = useState("");
  const [resourcetype, setResourceType] = useState("");
  const [resourcemode, setResourceMode] = useState("");
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [showchart1, setshowchart1] = useState([]);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerresourcepurchase, setCustomerResourcePurchase] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [mentorsolddata, setmentorsolddata] = useState([]);
  const [mentorid, setmentorid] = useState("");
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [selectedResourse, setselectedResourse] = useState();
  const [resourcePaymentreportchart, setResourcePaymentreportchart] = useState([]);
  const [parentid, setparentid] = useState("");
  const [groupid, setgroupid] = useState("");
  const [getSellerType, setSellerType] = useState("");
  const [childparentdata2, setchildparentdata2] = useState([]);
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  let chartdata = [];
  let chartdata1 = [];

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const HandleUserName = (e) => {
    setUserId(e.value.toString());
    setUserName(e.label);
  };
  const handleSeller = (e) => {
    setSellerType(e.target.value);
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const handleparentname = (e) => {
    setparentid(e.value);
  };

  const handlegroupname = (e) => {
    setgroupid(e.value);
  };
  const HandleChildName = (e) => {
    setChildId(e.value);
    setChildName(e.label);
  };
  const handlesearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getcustomerstoredreport();
      setChildId("");
      setCurrentPage(1);
    }
  };

  const handlechart = () => {
    mentoringsessionpaymentreportchart();
    setChildId("");
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1);
  };
  const handlementorname = (e) => {
    setmentorid(e.target.value);
  };

  const HandleSaveType = (e) => {
    setSavedType(e.target.value);
  };
  const HandleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setResourceType(value);
  };
  const handleResourceMode = (e) => {
    setResourceMode(e.target.value);
  };
  const getcustomerdata2 = () => {
    makePostRequest(URLS.RESOURCE_SELLER, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setchildparentdata2(res.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const getmentordata = () => {
    setLoader(true);
    makePostRequest(URLS.MENTOR_PERANT, null, { page_number: 1 }, null)
      .then((res) => {
        if (res.code == 200) {
          setmentorsolddata(res.data?.data);
          setLoader(false);
          setmentorid("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewPurchasedDetails = (row) => {
    let data = {
      purchase_date: row.purchased_date,
      seller_id: row.tbl_user_resource.tbl_user.id,
      resource_id: row.tbl_user_resource.id,
      report_for: displayvalue ? displayvalue : "all",
      group_id: groupid ? groupid : "",
    };
    makePostRequest(URLS.GET_RESOURCE_PAYMENT_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        setCustomerResourcePurchase(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };
  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      report_for: displayvalue ? displayvalue : "",
      group_id: groupid ? groupid : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      resource_type: getSellerType ? getSellerType : "",
      zipcode: zipCode ? zipCode : "",
      export_data: "yes",
    };
    makePostRequest(URLS.GET_RESOURCE_SALE_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("resourcepurchasepaymentreport");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Purchase Date",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "Vendor Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Resource Title",
              key: "resource_title",
              width: 30,
            },

            {
              header: "Total Purchases",
              key: "total_purchased_count",
              width: 20,
            },
            {
              header: "Purchased Amount($)",
              key: "total_purchased_amount",
              width: 20,
            },
          ];
          res?.data?.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: val.purchased_date,
              USER_NAME: val?.tbl_user_resource.tbl_user.firstName + " " + val?.tbl_user_resource.tbl_user.lastName,
              resource_title: val?.tbl_user_resource.resource_title,
              total_purchased_count: val.total_purchased_count,
              total_purchased_amount: val.total_purchased_amount,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "resourcepurchasepaymentreport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };

  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const getAllUserType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getChildName = () => {
    let data = {
      user_id: userId ? userId : "",
    };
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "Select Student" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setChildNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupList = () => {
    makePostRequest(URLS.GET_ALL_GROUP_LIST, null, { page_number: "" }, null).then((res) => {
      if (res.code == 200) {
        setGroupList(res?.data?.data);
      }
    });
  };
  const mentoringsessionpaymentreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      report_for: displayvalue ? displayvalue : "",
      group_id: groupid ? groupid : "",
    };
    makePostRequest(URLS.GET_RESOURCE_SALE_GRAPH, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setResourcePaymentreportchart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  /*=================================== customer save report list api==========================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomerstoredreport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      seller_id: parentid ? parentid : "",
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      report_for: displayvalue ? displayvalue : "",
      group_id: groupid ? groupid : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      resource_type: getSellerType ? getSellerType : "",
      zipcode: zipCode ? zipCode : "",
    };
    makePostRequest(URLS.GET_RESOURCE_SALE_LIST, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setCustomerStoredreport(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getResourceDropdown = () => {
    makePostRequest(URLS.RESOURCE_PUR, null, { page_number: dropdownPage }, null)
      .then((res) => {
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
 
  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setSelectyear(new Date().getFullYear());
    setgroupid("");
    selectRefGraphGroup.current.select.setValue("", "select-option");
    setparentid("");
    selectRefGraphvendor.current.select.setValue("", "select-option");
    selectRefGraph.current.select.setValue("", "select-option");
    setClearStateGraph(!clearStateGraph);
  };

  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setparentid("");
    selectRefListhvendor.current.select.setValue("", "select-option");
    setgroupid("");
    selectRefListGroup.current.select.setValue("", "select-option");
    setSortValue("");
    setlimitvalue("10");
    setCurrentPage(1);
    selectRef.current.select.setValue("", "select-option");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setZipCode("");
    setSellerType("");
    setClearStateList(!clearStateList);
  };

  useEffect(() => {
    getcustomerstoredreport();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    mentoringsessionpaymentreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getcustomerstoredreport();
  }, [clearStateList]);

  useEffect(() => {
    getUserName();
    getAllUserType();
    getcustomerstoredreport();
    mentoringsessionpaymentreportchart();
    getResourceDropdown();
    getcustomerdata2();
    getGroupList();
  }, []);
  useEffect(() => {
    getchartdata();
  }, [resourcePaymentreportchart]);

  const getchartdata = () => {
    resourcePaymentreportchart?.map((val) => {
      chartdata.push(val.total_purchased_count);
      chartdata1.push(parseFloat(val.total_purchased_amount));
    });
    setshowchart(chartdata);
    setshowchart1(chartdata1);
  };


  useEffect(() => {
    getChildName();
  }, [userId]);

  useEffect(() => {
    getcustomerstoredreport();
  }, [limitvalue, sortvalue]);

  const columns = [
    {
      name: "#",
      selector: (row) => "",
      grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Vendor Details",
      selector: (row) => row.tbl_user_resource.tbl_user.firstName,
      grow: 2,
      minWidth: "200px",
      style: { minWidth: "200px" },
      cell: (row, index) => (
        <>
          <div
            className="sub-text"
            dangerouslySetInnerHTML={{
              __html:
                row.resource_type_status == "individual"
                  ? row?.tbl_user_resource?.tbl_user?.firstName +
                    " " +
                    row.tbl_user_resource?.tbl_user?.lastName +
                    "</br> " +
                    row?.resource_type_status
                  : row.tbl_group?.group_name +
                    "/" +
                    row?.tbl_user_resource?.tbl_user?.firstName +
                    " " +
                    row.tbl_user_resource?.tbl_user?.lastName +
                    "</br>" +
                    row.resource_type_status,
            }}
          ></div>
        </>
      ),
    },
    {
      name: "Purchase  Date",
      selector: (row) => row.purchased_date,
      grow: 2,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row.purchased_date ? moment(row.purchased_date).format("MM-DD-YYYY") : "--"}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Resource Details",
      selector: (row) => row.tbl_user_resource.resource_title,
      grow: 3,
      cell: (row, index) => (
        <>
          <div>
            <span className="sub-text">{row.tbl_user_resource?.resource_title}</span>
          </div>
        </>
      ),
    },

    {
      name: "Total Purchases",
      selector: (row) => row.total_purchased_count,
      grow: 2,
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row.total_purchased_count ? row.total_purchased_count : "--"} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Amount",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {row.total_purchased_amount
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row.total_purchased_amount)
            : "$0.00"}{" "}
        </div>
      ),
    },
    {
      name: "Zip Code",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <span className="tb-lead">{row?.vendor?.zipcode ? row?.vendor?.zipcode : "--"}</span>
        </div>
      ),
    },
    {
      name: "Purchase Details",
      grow: 2,
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewPurchasedDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];


  const [vendorname, setVendorname] = useState(true);
  const [groupName, setGroupName] = useState(true);
  const [groupNames, setGroupNames] = useState(true);
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);
  const selectRefGraphvendor = useRef(null);
  const selectRefListhvendor = useRef(null);
  const selectRefGraphGroup = useRef(null);
  const selectRefListGroup = useRef(null);

  const handleGroups = () => {
    if (selectRefListGroup.current) {
      setGroupNames(!groupNames);
      selectRefListGroup.current.focus();
    }
  };
  const handleGroup = () => {
    if (selectRefGraphGroup.current) {
      setGroupName(!groupName);
      selectRefGraphGroup.current.focus();
    }
  };
  const handleVendors = () => {
    if (selectRefListhvendor.current) {
      setVendorname(!vendornames);
      selectRefListhvendor.current.focus();
    }
  };
  const handleVendor = () => {
    if (selectRefGraphvendor.current) {
      setVendorname(!vendorname);
      selectRefGraphvendor.current.focus();
    }
  };
  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelect(!displayvalueSelect);
      selectRef.current.focus();
    }
  };

  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.body.classList.add("purchase-resource-payment-report");
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.body.classList.remove("purchase-resource-payment-report");
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Resource Purchase Payment Report "></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Resource Purchased Payment Report</h3>
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Resource Purchased Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <table width="100%">
                          <tr className="border-bottom">
                            <th>#</th>
                            <th>Selling Time </th>

                            <th>Customer Name</th>

                            <th>Amount</th>
                          </tr>
                          {customerresourcepurchase &&
                            customerresourcepurchase.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{moment(val?.created_at).format("hh:mm a")}</td>

                                  <td>
                                    {val?.tbl_user?.firstName} {val?.tbl_user?.lastName}
                                  </td>

                                  <td>
                                    {val?.amount
                                      ? new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(val.amount)
                                      : "$0.00"}
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendor}
                              ></i>

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handleparentname}
                                defaultValue={parentid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(childparentdata2?.map((val) => ({
                                    value: val.tbl_user_resource.tbl_user.id,
                                    label:
                                      val.tbl_user_resource.tbl_user?.firstName +
                                      " " +
                                      val.tbl_user_resource.tbl_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendorname}
                                ref={selectRefGraphvendor}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong> Group</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleGroup}
                              ></i>
                             

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handlegroupname}
                                defaultValue={groupid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(groupList?.map((val) => ({
                                    value: val.tbl_group.id,
                                    label: val.tbl_group.group_name,
                                  })) || []),
                                ]}
                                openMenuOnFocus={groupName}
                                ref={selectRefGraphGroup}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession paymetsessionxx">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>
                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                // value={selectedResourse}
                                onChange={(e) => setselectedResourse(e)}
                                //onMenuScrollToBottom={handleMenuScroll}
                                openMenuOnFocus={displayvalueSelectGraph}
                                options={resourceDropdown?.map((v) => ({
                                  value: v?.tbl_user_resource.id,
                                  label: v?.tbl_user_resource.resource_title,
                                }))}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                        </div>

            
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlechart}>
                              {" "}
                              View{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {resourcePaymentreportchart && resourcePaymentreportchart?.length > 0 ? (
                      <div>
                        <div className="p-5">
                          <div className="row">
                            <div className="col-lg-6">
                        
                              <div className="relativeClass">
                                {loader ? (
                                  <div className="overflowloader" id="preload">
                                    <div className="circle-square">
                                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                                      <div className="blue" style={{ background: "#265472" }}></div>
                                      <div className="green" style={{ background: "#F19357" }}></div>
                                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                                    </div>
                                  </div>
                                ) : (
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      chart: {
                                        type: "line",
                                      },
                                      title: {
                                        text: "Monthly Resource Purchased Payment Chart",
                                        style: { color: "#003C71", fontSize: "25px" },
                                      },

                                      xAxis: {
                                        categories: [
                                          "Jan",
                                          "Feb",
                                          "Mar",
                                          "Apr",
                                          "May",
                                          "Jun",
                                          "Jul",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ],
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      plotOptions: {
                                        line: {
                                          dataLabels: {
                                            enabled: true,
                                            formatter: function () {
                                              return this.y != 0
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                  }).format(this.y)
                                                : "$0.00";
                                            },
                                          },
                                          enableMouseTracking: true,
                                        },
                                      },
                                      credits: {
                                        enabled: false,
                                      },

                                      yAxis: {
                                        title: {
                                          text: "Resource Purchased",
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      series: [
                                        
                                        {
                                          name: "Resource Purchased Amount",
                                          data: showchart1,
                                          color: "#364a63",
                                        },
                                      ],
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6">
                            
                              <div className="relativeClass">
                                {loader ? (
                                  <div className="overflowloader" id="preload">
                                    <div className="circle-square">
                                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                                      <div className="blue" style={{ background: "#265472" }}></div>
                                      <div className="green" style={{ background: "#F19357" }}></div>
                                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                                    </div>
                                  </div>
                                ) : (
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      chart: {
                                        type: "line",
                                      },
                                      title: {
                                        text: "Monthly Resource Purchased Count Chart",
                                        style: { color: "#003C71", fontSize: "25px" },
                                      },

                                      xAxis: {
                                        categories: [
                                          "Jan",
                                          "Feb",
                                          "Mar",
                                          "Apr",
                                          "May",
                                          "Jun",
                                          "Jul",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ],
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      plotOptions: {
                                        line: {
                                          dataLabels: {
                                            enabled: true,
                                            formatter: function () {
                                              return this.y != 0 ? +this.y : "";
                                            },
                                          },
                                          enableMouseTracking: true,
                                        },
                                      },
                                      credits: {
                                        enabled: false,
                                      },

                                      yAxis: {
                                        title: {
                                          text: "Resource Purchased",
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                        labels: {
                                          style: {
                                            color: "#364a63",
                                          },
                                        },
                                      },
                                      series: [
                                        {
                                          name: " Month ",
                                          data: showchart,
                                          color: "#364a63",
                                        },
                                       
                                      ],
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="p-4">No Data found</p>
                    )}

                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 d-flex  justify-content-end">
                            <select
                              value={sortvalue}
                              className="form-select"
                              style={{ width: "190px" }}
                              onChange={HandleSort}
                            >
                              <option value="">Sort By</option>
                              <option value="ASCDATE">Date Ascending</option>
                              <option value="DESCDATE">Date Descending</option>
                            </select>

                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                {/* <option value="5">5</option> */}
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Vendor Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleVendors}
                              ></i>
                             

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handleparentname}
                                defaultValue={parentid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(childparentdata2?.map((val) => ({
                                    value: val.tbl_user_resource.tbl_user.id,
                                    label:
                                      val.tbl_user_resource.tbl_user?.firstName +
                                      " " +
                                      val.tbl_user_resource.tbl_user?.lastName,
                                  })) || []),
                                ]}
                                openMenuOnFocus={vendorname}
                                ref={selectRefListhvendor}
                              />
                            </div>
                          </div>
                        </div>

                        
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong> Group</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={handleGroups}
                              ></i>
                             

                              <Select
                                className="form-select form-selectparent"
                                autosize={true}
                                onChange={handlegroupname}
                                defaultValue={groupid}
                                options={[
                                  { value: "", label: "All" },
                                  ...(groupList?.map((val) => ({
                                    value: val.tbl_group.id,
                                    label: val.tbl_group.group_name,
                                  })) || []),
                                ]}
                                openMenuOnFocus={groupNames}
                                ref={selectRefListGroup}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i>
                              <Select
                                className="form-select"
                                autosize={true}
                                defaultValue={selectedResourse}
                                // value={selectedResourse}
                                onChange={(e) => setselectedResourse(e)}
                                //onMenuScrollToBottom={handleMenuScroll}
                                //  options ={[{value:"",label:"All Resource"},resourceDropdown?.map(v => ({ value: v?.tbl_user_resource.id, label: v?.tbl_user_resource.resource_title }))|| [] ]}
                                options={[
                                  ...(resourceDropdown && resourceDropdown.length > 0
                                    ? [{ value: "", label: "All Resource" }]
                                    : []),
                                  ...(resourceDropdown?.map((v) => ({
                                    value: v?.tbl_user_resource.id,
                                    label: v?.tbl_user_resource.resource_title,
                                  })) || []),
                                ]}
                                openMenuOnFocus={displayvalueSelect}
                                ref={selectRef}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                        <div className="col-md-4">
                          <label className="lavelinfo">
                            <strong>Zip Code</strong>
                          </label>
                          <div className="form-control-wrap">
                            <input
                              placeholder="Zipcode"
                              type="text"
                              className="form-control pl-2"
                              value={zipCode}
                              onChange={handleZipCode}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Resource Type</strong>
                            </label>
                            <select value={getSellerType} className="form-select" onChange={handleSeller}>
                              <option value="">All</option>
                              <option value="individual">Individual</option>
                              <option value="group">Group</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0">&nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {customerstoredreport && customerstoredreport?.length > 0 ? (
                        <div>
                          <div className="color">
                            <ReactDataTable data={customerstoredreport} columns={columns} className="nk-tb-list" />
                          </div>
                        </div>
                      ) : (
                        <p className="p-4" style={{ minHeight: "100px" }}>
                          No Data Found
                        </p>
                      )}
                    </div>

                    <div className="p-4">
                      {customerstoredreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default PurchaseResourcePaymentReport;
