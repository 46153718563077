import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest,makeGetRequest } from "../../../service/api";

import ReactPaginate from "react-paginate";
import { AreaChart,Area, BarChart, Bar ,ResponsiveContainer,CartesianGrid,XAxis ,YAxis,Tooltip,Legend, Label, LineChart,Line} from "recharts";

import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";


function CustomerStoredResultReport() {
	
    let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
//   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [customerstoredreport,setCustomerStoredreport]=useState([]);
  const [customerstoredreportchart,setCustomerStoredreportchart]=useState([]);
  const [loader, setLoader] = useState(false);
  const [selectyear,setSelectyear]=useState( new Date().getFullYear());
 const [displayvalue,setDisplayValue]=useState("1");
 const [childId,setChildId]=useState("");
  const [userName,setUserName]=useState("");
  const [childName,setChildName]=useState("");
  const [userNameData,setUserNameData]=useState([])
  const [childNameData,setChildNameData]=useState([])
  const [userId,setUserId]=useState("");
  const [selectedfromdate,setSelectedFromDate]=useState("");
  const [selectedtodate,setSelectedToDate]=useState("");
    const [savedtype,setSavedType]=useState("");
    const [resourcetype,setResourceType]=useState("");
    const [resourcemode,setResourceMode]=useState("");
    const [getAllResourceNew,setgetAllResourceNew]=useState([]);


  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
  }

  const HandleToDate = (e) => {
    setSelectedToDate(e);
  }
  const HandleUserName = (e) => {
    setUserId(e.value.toString());
    setUserName(e.label);
    
  }
  const HandleChildName = (e) => 
    {
      setChildId(e.value);
      setChildName(e.label);
    }
  const handlesearch = () => {
    getcustomerstoredreport();
    //getcustomersstoredreportchart();
    setUserId("")
     setChildId("")
     setCurrentPage(1);
    

  }
  const HandleSaveType = (e) => {
  }
  const HandleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setResourceType(value);

  }
  const handleResourceMode = (e) => {
    setResourceMode(e.target.value);
  }
  const getAllUserType = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
			.then(res => {
				if (res.code == 200) {
					setgetAllResourceNew(res.data);
				   setLoader(false);
				}
			})
			.catch((error) => {
				console.log(error);
			})

	} 

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        console.log(res)
        if (res.code == 200) {
          let option=[];
          res.data.length > 0 && res.data.map((val)=>{
        option.push({value: val.id, label:val.firstName+" "+val.lastName})
          }) 
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getChildName = () => {

    let data={
      user_id:userId?userId:""
    }
    console.log("child payload",data)
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        console.log("child data",res)
        if (res.code == 200) {
         
          let option=[];
          res.data.length > 0 && res.data.map((val)=>{
        option.push({value: val.id, label:val.firstName+" "+val.lastName})
          }) 
          setChildNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomersstoredreportchart = () => {
    setLoader(true);
    let data = {
      year:selectyear,
      user_id:userId?userId:"",
      child_id:childId?childId:"",
      saved_type:savedtype?savedtype:"",
      resource_type:resourcetype?resourcetype:"",
      resource_mode:resourcemode?resourcemode:"",

    };    
   console.log("data chart",data)
    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSCHART, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "Stored chart data");
          setCustomerStoredreportchart(res?.data?.graph_data);
         setLoader(false);
         
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getcustomerstoredreport = () => {
    setLoader(true);
    let data = {
        start_date:selectedfromdate?moment(selectedfromdate).format("YYYY-MM-DD"):"2023-01-01",
      end_date:selectedtodate?moment(selectedtodate).add(1,'d').format("YYYY-MM-DD"):moment(new Date()).format("YYYY-MM-DD"),
        user_id:userId?userId:"",
        child_id:childId?childId:"",
        saved_type:savedtype?savedtype:"",
        resource_type:resourcetype?resourcetype:"",
        resource_mode:resourcemode?resourcemode:"",
        "page_number":1,
      "item_per_page":10
    };    
   console.log("search list payload",data)
    makePostRequest(URLS.GET_CUSTOMER_STORED_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          console.log(res, "Stored data");
          setCustomerStoredreport(res?.data?.list_data);
         setLoader(false);
         
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


   useEffect(() => {
     getUserName();
     getAllUserType();
   
     getcustomerstoredreport();
   //getcustomersstoredreportchart();
  }, []);

  useEffect(() => {
     getcustomerstoredreport();
    //getcustomersstoredreportchart();
  }, [selectyear]);
  
  useEffect(() => {
    getChildName();
  }, [userId]);

  const columns = [
    // {
    //   name: "Customer Details",
    //   selector: (row) => row.tbl_user,
    //   grow: 0,
    //   minWidth: "200px",
    //   style: { minWidth: "200px" },
    //   borderRadius: "4px",
    //   cell: (row, index) => (
    //     <>
    //       {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
    //       <div className="user-card mt-2 mb-2">
    //       {row.tbl_user.firstName?row.tbl_user.firstName:"--"}{" "}
    //       {row.tbl_user.lastName?row.tbl_user.lastName:""}<br/>
    //       {row.tbl_user.email?row.tbl_user.email:""}
    //        </div>
    //     </>
    //   ),
    // },

    {
      name: "Saved Date",
      selector: (row) => row.saved_date,
      grow: 0,
      minWidth: "170px",
      style: { minWidth: "170px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">{row.saved_date?row.saved_date:"--"} </div>
        </>
      ),
    },
    // {
    //   name: "Year",
      
    //   grow: 0,
    //   minWidth: "140px",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2"> {row.year?row.year:"--"} </div>
    //   ),
    // },
    {
      name: "Total Saved",
      
      grow: 0,
      minWidth: "140px",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.total_saved?row.total_saved:"--"} </div>
      ),
    },

  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  return (
   
      <React.Fragment>
            
       
      <Head title="Customer List"></Head>
      <Content>
        
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Stored Result Report</h3>
  
        {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : (
  <>
  <Card className="card-bordered">
     <div className="card-inner relativeClass">
     
     
       
         
  
  
            <div className="card-inners relativeClass">
              <BlockHeadContent>
                <BlockHead size="lg">
  
  
                {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="row">
                      <div class="col-md-4">
                    {/* <div class="form-control-select">

                       <select class="form-control px-0" value={selectyear} onChange={handleyear} >
                      

                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        </select> </div> */}
                        </div>
                        {/* <div class="col-md-8 d-flex justify-content-end">
                        <div className ="btn-div">
                    <button className="btn btn-primary" onClick={handleList}>List view</button>
                    <button className="btn btn-primary" onClick={handleChart}>Chart view</button>
                   
                </div>
                </div> */}
                        </div>
                        

                        {/* {customerstoredreportchart&&customerstoredreportchart?.length>0?  (
                         <div>
                        <div className="p-5">
                      <h5>Monthly Saved Chart ({selectyear}) </h5>
                      <LineChart width={500} height={215} data={customerstoredreportchart}>
                 
                 <CartesianGrid strokeDasharray="3 3" />
                 <XAxis dataKey="month" interval={'preserveStartEnd'}/>
                 <YAxis label={{ value: 'No. Of Saves', angle: -90, position: 'insideLeft',dy:30,fill:"#f19357"}} />
                 <Tooltip />
                 <Legend />
                 <Label dataKey="total_saved"/>
                 <Line type="monotone" dataKey="total_saved" name="Saved" fill="#f19357" />
              </LineChart>
             
                  
               </div>
               </div>
               ):<p>No Data found</p>} */}
               <div className="row pb-2">
               <div className="col-lg-3">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>User Name</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <Select
                    defaultvalue={userId}  
                    options={userNameData}
                   
                    onChange={HandleUserName}
                  />
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-3">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Child Name</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <Select
                    defaultvalue={childName}  
                    options={childNameData}
                   
                    onChange={HandleChildName}
                  />
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-3">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Saved Type</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <select class="form-control px-0" value={resourcemode} onChange={handleResourceMode} >

                        <option value="">Select</option>
                        <option value="1">In Progress</option>
                        <option Value="2">Past</option>
                        <option Value="3">Future</option>

                        </select>
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-3">
                            <div className="form-control border-0 px-0">
                            

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <label><strong>Resource Type</strong></label>
				   <select  className="form-select"  name="resourceType" onChange={HandleResourceType} value={resourcetype} >
					   <option value=""> Select Resource </option>
					   {  
						 getAllResourceNew.map((val,index)=>{
							return(<>
							  <option value={val.id} key={index + 1}>{val.name}</option>
							
							</>)					
						 })
						
					   }
			        </select> 
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-3">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Resource Mode</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap">
                                
                              <select class="form-control px-0" value={resourcemode} onChange={handleResourceMode} >

                        <option value="">Select</option>
                        <option value="save">Save</option>
                        <option Value="serve">Serve</option>
                        </select>
                   
                   
                    
                
                              </div>
                            </div>
                            {/* <span className="error">{toDateError}</span> */}
                          </div>
                          <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}
                        
                          <div className="form-control-wrap">
                                <div className="form-icon form-icon-left">
                                  <Icon name="calendar"></Icon>
                                </div>
                                <DatePicker
                                 placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate}
                                  onChange={HandleFromDate}
                                  className="form-control date-picker"
                                />
                              </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}
                        
                          <div className="form-control-wrap">
                                <div className="form-icon form-icon-left">
                                  <Icon name="calendar"></Icon>
                                </div>
                                <DatePicker
                                 placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate}
                                  onChange={HandleToDate}
                                  className="form-control date-picker"
                                />
                              </div>
                        </div>
                      </div>
                          
                          </div>
                          <div className="row">
                          <div className="col-lg-2 d-flex ">
                          <div className="outerTypeBox mb-3">
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>

                            {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                          </div>
                          </div>
                          <div className="col-lg-2 d-flex ">
                          <div className="outerTypeBox mb-3">
                          {/* <button className="btn btn-primary">
			      <em className="icon ni ni-download-cloud"></em><span>Export</span>
			     </button> */}
                          </div>
                          </div>
                        </div>
                
               {customerstoredreport&&customerstoredreport?.length>0?  (
                <div>
                
                    <div className="color">
                    <ReactDataTable
                      data={customerstoredreport}
                      columns={columns}
                      className="nk-tb-list"
                      // searchable={true}
                      // setPageCount={setPageCount}
                  />
                  </div>
                  </div>
                  ):<p>No Data Found</p>}
                  
                  
                  
                 
                  
                  
                  
                  
                  
                 
  
                  {/* {customerloginreport?.length > 0 ? 
  <div>
      <ReactPaginate
       previousLabel={"<<"}
       nextLabel={">>"}
        // pageCount={count}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        // onPageChange={handlePageChange}
        containerClassName={'pagination'}
        pageClassName={"page-item"}
        activeClassName={'active'}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
      />
     </div> 
     :""} */}
         </BlockHead>
              </BlockHeadContent>
            </div>
       
        </div>
        </Card>
        </>  )}
      </Content>
    </React.Fragment>
  );
}
export default CustomerStoredResultReport;
