import React, { useState, useRef } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../../constants/urls";
import { ReactDataTable, Icon } from "../../../../components/Component";
import { Card, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { makePostRequest } from "../../../../service/api";
import { BlockHeadContent, BlockHead } from "../../../../components/Component";
import { S3_BUCKET_URL } from "../../../../constants";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FaFilter } from "react-icons/fa";

const ExcelJS = require("exceljs");

function SearchAnalytics() {
  const [mentordata, setmentordata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [childsessiondetail, setchildsessiondetail] = useState([]);
  const [childparentdata, setchildparentdata] = useState([]);
  const [childdata, setchilddata] = useState([]);
  const [parentid, setparentid] = useState("");
  const [childid, setchildid] = useState("");
  const [getCustomer, setGetCustomer] = useState("");
  const [alldata, setalldata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState();
  // const [pageCount, setPageCount] = useState(null)
  const [search, setSearch] = useState("");
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [paymentFor, setPaymentFor] = useState("");
  const [dropdownPage, setDropdownPage] = useState(1);

  const [selectedResourse, setselectedResourse] = useState();
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [sortanalyst, setSortAnalyst] = useState("");
  const [limitvalue, setlimitvalue] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);

  const [clearState, setClearState] = useState(false);
  const [displayvalueSelectFilter, setDisplayValueSelectFilter] = useState(true);

  // console.log("mentordata",mentordata)

  const getResourceDropdown = () => {
    makePostRequest(URLS.GET_RESOURCE, null, { page_number: dropdownPage }, null)
      .then((res) => {
        // console.log("response1", res);
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //console.log("m", mentordata);

  useEffect(() => {
    getResourceDropdown();
  }, []);

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };

  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const [show, setShow] = useState(false);
  const ShowHideSelect = () => {
    setShow(!show);
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSortAnalyst = (e) => {
    setSortAnalyst(e.target.value);
    setCurrentPage(1);
  };
  const viewsearch = (search) => {
    //console.log(search,"search")
    setIsOpen(!isOpen);
    setSearchData(search?.tbl_resource_searches);
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  useEffect(() => {
    getallanalyticdata();
  }, [sortanalyst, limitvalue]);

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      user_id: getCustomer?.value ?? "",
      learning_pillar: parentid ? [parentid] : [],
      resource_type: selectedResourse?.value ?? "",
      start_date: selectedfromdate ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      keyword: search,
      key: "export",
      page: currentPage,
      limit: 10,
      sortby: sortanalyst ? sortanalyst : "",
    };
    //console.log("Success data",data)
    makePostRequest(URLS.GET_ALL_SEARCH_ANALYSTICS_EXPORT, null, data, null)
      .then((res) => {
        //console.log("Success",res)
        if (res.code == 200) {
          //  setmentordata(res.data.data);
          //  setCount(res.data.count/10)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SearchAnalyticsData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "SEARCH DATE",
              key: "SEARCH_DATE",
              width: 15,
            },
            {
              header: "CUSTOMER NAME",
              key: "CUSTOMER_NAME",
              width: 15,
            },
            {
              header: "CUSTOMER EMAIL",
              key: "CUSTOMER_EMAIL",
              width: 15,
            },
            {
              header: "CUSTOMER PHONE",
              key: "CUSTOMER_PHONE",
              width: 15,
            },

            {
              header: "SEARCH CONTENT",
              key: "SEARCH_CONTENT",
              width: 23,
            },
            {
              header: "IP ADDRESS",
              key: "IP_ADDRESS",
              width: 15,
            },
            {
              header: "RESOURCE TYPE",
              key: "RESOURCE_TYPE",
              width: 15,
            },
            {
              header: "LEARNING PILLAR",
              key: "LEARNING_PILLAR",
              width: 15,
            },
            {
              header: "GRADE RANGE",
              key: "GRADE_RANGE",
              width: 15,
            },
            {
              header: "CUSTOMER TYPE",
              key: "CUSTOMER_TYPE",
              width: 15,
            },
            {
              header: "SUPPORT TYPE",
              key: "SUPPORT_TYPE",
              width: 15,
            },
            {
              header: "ORGANIZATION  TYPE",
              key: "ORGANIZATION_TYPE",
              width: 15,
            },
            {
              header: "EXPERIENCE TYPE",
              key: "EXPERIENCE_TYPE",
              width: 15,
            },
            {
              header: "FUNDING TYPE",
              key: "FUNDING_TYPE",
              width: 15,
            },
            {
              header: "FACILITY TYPE",
              key: "FACILITY_TYPE",
              width: 15,
            },
            {
              header: "LOCATION TYPE",
              key: "LOCATION_TYPE",
              width: 15,
            },
            {
              header: "ACCREDIATED",
              key: "ACCREDIATED",
              width: 15,
            },
            {
              header: "DUAL ENROLLMENT",
              key: "DUAL_ENROLLMENT",
              width: 15,
            },
            {
              header: "INSTRUCTIONAL STYLE",
              key: "INSTRUCTIONAL_STYLE",
              width: 15,
            },
            {
              header: "INSTRUCTIONAL STYLE",
              key: "INSTRUCTIONAL_STYLE",
              width: 15,
            },
            {
              header: "CONTENT TYPE",
              key: "CONTENT_TYPE",
              width: 15,
            },
            //  {
            //   header:"STUDENTS",
            // key:'CHILDS',
            // width:15
            //  },
            //  {
            //   header:"FRIEND",
            // key:'FRIEND',
            // width:15
            //  },
            //  {
            //   header:"GROUP",
            // key:'GROUP',
            // width:15
            //  },
          ];

          res?.data?.data?.map((val, i) => {
            // let namespiller=[];

            // const learningPillarArray = JSON.parse(val.learning_pillar);
            //     console.log(learningPillarArray,"newarray");

            //     // Accessing each string in the array
            //     if (Array.isArray(learningPillarArray) && learningPillarArray.length > 0) {
            //       learningPillarArray.forEach((name, i) => {
            //         console.log(name,i,"name");
            //         namespiller.push(name)

            //       });
            //     }

            //     console.log(namespiller,"pillar")
            //           const learningPillarArray = JSON.parse(val.learning_pillar);
            // console.log(learningPillarArray,"new");

            // // Accessing data in the array
            // if (Array.isArray(learningPillarArray) && learningPillarArray.length > 0) {
            //   const firstLearningPillar = learningPillarArray[0];
            //   const id = firstLearningPillar.id;
            //    name = firstLearningPillar.name;

            //   console.log('id:', id);
            //   console.log('Name:', name);
            // }

            sheet.addRow({
              S_No: i + 1,
              SEARCH_DATE: moment(val?.created_at).format("MM-DD-YYYY"),
              CUSTOMER_NAME: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
              CUSTOMER_EMAIL: val?.tbl_user?.email ? val?.tbl_user?.email : "--",
              CUSTOMER_PHONE: val?.tbl_user?.phone ? val?.tbl_user?.phone : "--",

              SEARCH_CONTENT: val?.keyword ? val?.keyword : "--",
              IP_ADDRESS: val?.ip_address ? val?.ip_address : "--",
              RESOURCE_TYPE: val?.tbl_resource_type_master ? val?.tbl_resource_type_master?.name : "--",
              LEARNING_PILLAR: val?.learning_pillar
                ? JSON.parse(val?.learning_pillar)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              //LEARNING_PILLAR: namespiller ? namespiller :"--",
              GRADE_RANGE: val?.grade_range
                ? JSON.parse(val?.grade_range)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              CUSTOMER_TYPE: val?.custmor_type
                ? JSON.parse(val?.custmor_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              SUPPORT_TYPE: val?.support_type
                ? JSON.parse(val?.support_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              ORGANIZATION_TYPE: val?.organization_type
                ? JSON.parse(val?.organization_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              EXPERIENCE_TYPE: val?.experience_type
                ? JSON.parse(val?.experience_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              FUNDING_TYPE: val?.funding_type
                ? JSON.parse(val?.funding_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              FACILITY_TYPE: val?.facility_type
                ? JSON.parse(val?.facility_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              LOCATION_TYPE: val?.location_type ? val?.location_type : "--",
              ACCREDIATED: val?.accredited ? val?.accredited : "--",
              DUAL_ENROLLMENT: val?.dual_enrollment ? val?.dual_enrollment : "--",
              INSTRUCTIONAL_STYLE: val?.instructional_style ? val?.instructional_style : "--",
              CONTENT_TYPE: val?.content_type
                ? JSON.parse(val?.content_type)
                    .map((v) => v.name)
                    .join(", ")
                : "--",
              // CHILDS: val?.childs ? val?.childs :"--",
              // FRIEND: val?.friend ? val?.friend :"--",
              // GROUP: val?.group ? val?.group :"--",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "SearchAnalytics.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          //console.log("data",res)
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  // const csvLink = useRef();

  // let headers = [
  //   { label: "SEARCH DATE", key: "date" },
  //   { label: "CUSTOMER", key: "name" },
  //   { label: "SEARCH CONTENT", key: "content" },
  //   { label: "IP ADDRESS", key: "ip" },

  // ];
  // let mentorDatas = [];
  // mentordata &&
  // mentordata.map((val, i) => {
  //      mentorDatas.push({
  //       date:
  //       val?.created_at,

  //       name : val?.tbl_user?.firstName+ " " + val?.tbl_user?.lastName,
  //       content : val?.keyword,
  //       ip : val?.ip_address
  //     });
  //   });

  //   const downloadCsv = () => {
  //     if (mentorDatas.length!=0) {
  //       csvLink.current.link.click();
  //     } else {
  //       toast.error("No data import");
  //     }
  //   };
  const columns = [
    {
      name: "#",
      selector: (row) => "1",
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1 ? index + 1 : (currentPage - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Search Date",
      selector: (row) => row?.created_at,
      grow: 3,
      // sortable: true,
      cell: (row) => (
        <span className="tb-lead">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
      ),
    },
    {
      name: "Customer",
      selector: (row) => row?.tbl_user?.firstName,
      grow: 3,
      sortable: true,
      cell: (row) => <span className="sub-text">{row?.tbl_user?.firstName + " " + row?.tbl_user?.lastName}</span>,
    },
    {
      name: "Search Content",
      selector: (row) => row?.keyword,
      grow: 3,
      // sortable: true,
      cell: (row) => <span className="sub-text">{row?.keyword ? row?.keyword : "--"}</span>,
    },
    {
      name: "IP Address",
      selector: (row) => row?.ip_address,
      grow: 3,
      // sortable: true,
      cell: (row) => <span>{row?.ip_address ? row?.ip_address : "--"}</span>,
    },
    {
      name: "View",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="btn btn-md"
            onClick={() => {
              viewsearch(row);
            }}
          >
            <em className="icon ni ni-eye"></em>
          </button>
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1}</div>
        </>
      ),
    },
    {
      name: "Session date/time",
      selector: (row) => row.session_date,
      minWidth: "140px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{moment(row.session_date).format("MM-DD-YYYY")}</span>
            <span className="sub-text">{row.session_time}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Session Status",
      selector: (row) => "Attend",
      minWidth: "140px",
      style: { minWidth: "140px" },
      //   grow: 0,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.is_cancelled === "yes"
                ? "Cancelled"
                : moment(row.session_date).format("MM-DD-YYYY") >= date
                ? "Upcoming"
                : "Attendend"}
            </span>
          </div>
        </div>
      ),
    },
  ];
  const current = new Date();
  const date = moment(current).format("MM-DD-YYYY");
  //console.log(date,"date")

  let selectparentid;
  let selectchildid;
  const handleparentname = (e) => {
    selectparentid = e.target.value;
    setparentid(selectparentid);
    //console.log(selectparentid,"pid")
    getchilddata();
  };
  const handleChildID = (e) => {
    selectchildid = e.target.value;
    setchildid(selectchildid);
    //console.log(selectchildid,"cid")
  };

  // const handleCustomer = (e) => {
  //   setGetCustomer(e.target.value);
  // };

  const getallanalyticdata = () => {
    setLoader(true);
    let data = {
      user_id: getCustomer?.value ?? "",
      learning_pillar: parentid ? [parentid] : [],
      resource_type: selectedResourse?.value ?? "",
      start_date: selectedfromdate ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      keyword: search,
      key: "",
      page: currentPage,
      limit: limitvalue ? limitvalue : 10,
      sortby: sortanalyst ? sortanalyst : "",
    };
    // console.log("Success data",data)
    makePostRequest(URLS.GET_SEARCH_HISTORY, null, data, null)
      .then((res) => {
        // console.log("Success",res)
        if (res.code == 200) {
          setmentordata(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
          //console.log("data",res)
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    // getallanalyticdata();
    getcustomerdata();
    // getchilddata();
  }, []);
  const handleSearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getallanalyticdata();
      setCurrentPage(1);
    }
  };

  const handleClearData = () => {
    setparentid("");
    setchildid("");
    setGetCustomer("");
    setSearch("");
    setSelectedfromDate("");
    setSelectedToDate("");
    setPaymentFor("");
    setselectedResourse("");
    setSortAnalyst("");
    getallanalyticdata();
    setCurrentPage(1);
    setlimitvalue(10);
    setClearState(!clearState);
  };
  const clearfilter = () => {
    handleClearData();
  };
  useEffect(() => {
    getallanalyticdata();
  }, [clearState]);

  const handleCustomerlistId = (sessiondetail) => {
    setchildsessiondetail(sessiondetail.tbl_user_mentoring_session_datetimes);
    setalldata(sessiondetail);
    setIsOpen(true);
    //  console.log(childsessiondetail,sessiondetail,"se de")
  };
  const getcustomerdata = () => {
    makePostRequest(URLS.SEARCH_ANALYTICS_USER, null, { page_number: "" }, null)
      .then((res) => {
        //console.log("analyetics", res)
        if (res.code == 200) {
          setchildparentdata((prevOptions) => [...prevOptions, ...res?.data?.data]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getchilddata = () => {
    let data = {
      parentId: selectparentid,
    };
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setchilddata(res.data);
          // console.log(res.data,"child name")
          setchildid("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    // fetchData(selectedPage.selected+1)
  };
  // const fetchData = () => {
  //   let data={
  //     "user_id": getCustomer ??"",
  //     "learning_pillar":parentid ? [parentid] : [],
  //     "resource_type": selectedResourse?.value ?? "",
  //     "start_date": selectedfromdate ?? "",
  //     "end_date": selectedtodate ?? "",
  //     "keyword": search,
  //     "key" : "",
  //      "page":currentPage,
  //     "limit" :limitvalue?limitvalue:10,
  //     sortby:sortanalyst?sortanalyst:""
  // };
  // //console.log("data",data)
  //   makePostRequest(URLS.GET_SEARCH_HISTORY, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setmentordata(res.data.data);
  //         setCount(Math.ceil(res.data.count/parseInt(limitvalue?limitvalue:10)));
  //         //console.log("data",res.data.data)
  //         setLoader(false)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoader(false)
  //     });
  //  };

  useEffect(() => {
    //fetchData()
  }, [currentPage]);
  
  const [customerFilter,setcustomerFilter]=useState(true);
  const newRef = useRef(null);
  const newRef1 = useRef(null);

  const selectRefFilter = useRef(null);
  const newRefCustomer = useRef(null);

  const handlecustomerFilter = ()=> {
     if(newRefCustomer.current) {
       setcustomerFilter(!customerFilter);
       newRefCustomer.current.focus();
     }
  }

  const displaySelectFilter = (e) => {
    if (selectRefFilter.current) {
      setDisplayValueSelectFilter(!displayvalueSelectFilter);
      selectRefFilter.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Search Analytics"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Search Analytics</h3>
        <Card className="card-bordered">
          <>
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Search Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <table>
                          <tr className="border-bottom">
                            <th width="300px">Resource Title</th>
                            <th width="300px" align="right">
                              Search From
                            </th>
                          </tr>
                          {searchData &&
                            searchData.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{val.resource.resource_title}</td>
                                  <td>{val.type_search}</td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
          </>
          {loader ? (
            <div className="overflowloader" id="preload">
              <div className="circle-square" style={{ marginTop: "250px" }}>
                <div className="red" style={{ background: "#AAC0CE" }}></div>
                <div className="blue" style={{ background: "#265472" }}></div>
                <div className="green" style={{ background: "#F19357" }}></div>
                <div className="yellow" style={{ background: "#F0B285" }}></div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-inner relativeClass">
                {/* <h4 onClick={ShowHideSelect} className="cursorPointer">Filters <i className="fa fa-filter" aria-hidden="true"></i></h4> */}
                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 ">
                    <div className="filtersidebutton">
                      <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                        Filters
                        <FaFilter />
                      </h5>
                      <button className="btn btn-md btn-primary  mx-2" type="submit" onClick={clearfilter}>
                        Clear Filters
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 d-flex  justify-content-end">
                    <select
                      value={sortanalyst}
                      className="form-select"
                      style={{ width: "170px" }}
                      onChange={HandleSortAnalyst}
                    >
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCNAME">Customer Name Ascending</option>
                      <option value="DESCNAME">Customer Name Descending</option>
                    </select>

                    <div>
                      <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                    </div>
                    <div>
                      <select
                        value={limitvalue}
                        className="form-select"
                        style={{ width: "85px" }}
                        onChange={HandleLimitValue}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      &nbsp; &nbsp;
                      <button className="btn btn-primary " onClick={ExportEcelFile}>
                        <em className="icon ni ni-download-cloud"></em>
                        <span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
                <hr></hr>
                {show && (
                  <div>
                    <div className="row pb-2">
                      {/*                       
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> Customer Name</strong>
                          </label>
                          <select value={getCustomer} className="form-select" onChange={handleCustomer}>
                            <option value="">Customer List</option>
                            {childparentdata &&
                              childparentdata.map((val) => {
                                return (
                                  <>
                                    <option value={val?.user_id}>
                                      {val?.tbl_user?.firstName} {val?.tbl_user?.lastName}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handlecustomerFilter}
                            ></i>
                            <Select
                              className="form-select"
                              autosize={true}
                              onChange={(e) => setGetCustomer(e)}
                              defaultValue={getCustomer}
                              // onChange={HandleCustomer}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={[
                                { value: "", label: "All" },
                                ...(childparentdata?.map((val) => ({
                                  value: val?.user_id,
                                  label: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
                                })) || []),
                              ]}
                                openMenuOnFocus={customerFilter}
                                ref={newRefCustomer}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> Learning Pillar</strong>
                          </label>

                          <select value={parentid} className="form-select" onChange={handleparentname}>
                            <option value="">All Learning Pillar</option>
                            <option value={"Heart"}>Heart</option>
                            <option value={"Mind"}>Mind</option>
                            <option value={"Soul"}>Soul</option>
                            <option value={"Strength"}>Strength</option>
                            {/* <option value={"Strangth"}>Strangth</option> */}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={displaySelectFilter}
                            ></i>
                            <Select
                              className="form-select"
                              defaultValue={selectedResourse}
                              // value={selectedResourse}
                              onChange={(e) => setselectedResourse(e)}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={resourceDropdown?.map((v) => ({ value: v?.id, label: v?.resource_title }))}
                              openMenuOnFocus={displayvalueSelectFilter}
                              ref={selectRefFilter}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control startDate border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" format="MM/DD/YYYY" timezone="en-us" name ="formdate" value={selectedfromdate} className="form-control" onChange={HandleFromDate} />   */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              className="form-control date-picker"
                              open={datepickershow}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> End Date</strong>
                          </label>
                          {/* <input type="date"  name ="todate" value={selectedtodate} className="form-control" onChange={HandleToDate}></input> */}
                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              className="form-control date-picker"
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Content</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="search..."
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleSearch}>
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-inners relativeClass">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="clearboth"></div>

                      {mentordata?.length == 0 ? (
                        <div>No Data Found</div>
                      ) : (
                        <ReactDataTable data={mentordata} columns={columns} className="nk-tb-list" searchable={false} />
                      )}

                      {mentordata?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </div>
            </>
          )}
        </Card>
      </Content>
    </React.Fragment>
  );
}
export default SearchAnalytics;
