import { useState, Fragment } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'
import {
	BlockHead,
	BlockHeadContent,
	ReactDataTable,
	BlockTitle,
} from "../../../components/Component";
import {
	Icon,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	RSelect
} from "../../../components/Component";
import Select from "react-select";
import { getUserDetailsFromToken } from '../../../service/auth';
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { findUpper, capital } from "../../../utils/Utils";

function ManageRole() {
	const columns = [
		{
			name: 'Permissions',
			selector: row => row.permission_name.replaceAll("_", " "),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<button className="btn btn-danger btn-sm" onClick={() => { handleDeleteRolePermission(row) }}>Delete</button>
			)
		},
	];
	const [selectedRole, setSelectedRole] = useState({});
	const [name, setName] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [isRolePermissionManageActive, setIsRolePermissionManageActive] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [rolesData, setRolesData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [switchState, setSwitchState] = useState("");
	const [selectedPermission, setSelectedPermission] = useState([]);
	const [selectedPermissionError,setSelectedPermissionError]=useState("")
	const [allPermisions, setAllPermisions] = useState([]);
	const [allRolePermissions, setAllRolePermissions] = useState([]);
    const [expressionTextError, setErrorExpressionText] = useState('');
	const handleswitch = (id, isSelected, index) => {
		let data = {
			id: id,
			status: isSelected,
		}
		switchState ? setSwitchState("enable") : setSwitchState("disable");
		const newArray = Array.from(rolesData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setRolesData(newArray);
		makePostRequest(URLS.UPDATE_ROLE, null, data, null)
			.then(res => {
				if (res.code === 200) {
					setName('');
					toast.success("Status updated successfully.");
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
				}
				else {
					toast.warn("Status disable successfully.")
				}
			})
	}
    let char=(/^[a-zA-Z\s]+$/);
	const handleName = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedRole };
		let str = e.target.value;
		obj.name = str;
		setSelectedRole({ ...obj })
	}
	const AddRoleHandler = () => {

		if (name == "") {
			setErrorName('Please enter role name.');
		}
		else if(!name.match(char)){
			setErrorExpressionText("Please enter character only.") 
		}
		else {
			addNewRole();
			setLoader(true);
		}
	}
	const updateRoleHandler = () => {
		
		if (selectedRole.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "")== "") {
			setErrorName('Please enter role name.');
		}
		  else if(!selectedRole.name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
		else if (isUpdate) {
			handleupdateRole();
		}
	}

	const addNewRole = () => {
		let data = {
			role_name: name,
			status: "enable"
		}
		makePostRequest(URLS.ADD_ROLE, null, data, null)
			.then(res => {
				if (res.code === 201) {
					setName('');
					setSelectedRole({ ...selectedRole, name: "", id: "" });
					toast.success(res.message);
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
				}
				else if (res.code == 400) {

					toast.error(res.message)
					setLoader(false)
				}
			})
	}
	const addNewRolePermission = () => {

if(selectedPermission==""){
	setSelectedPermissionError("Please select role permissions.")
}
else{
		
		let data = {
			role_id: selectedRole.id,
			permission_id: selectedPermission.id
		}
		let existingPermissions = allRolePermissions?.map(item => {
			return item.id;
		})
		let isValid = !existingPermissions.includes(selectedPermission.id);
		if (isValid) {
			makePostRequest(URLS.ADD_ROLE_PERMISSION, null, data, null)
				.then(res => {
					if (res.code === 201) {
						setName('');
						setSelectedPermission({ ...selectedPermission, name: "", id: "" });
						toast.success(res.message);
						getRolePermission({ id: selectedRole.id })
						setIsUpdate(false);
					}
					else if (res.code == 400) {
						toast.warn(res.message)
						setLoader(false)
					}
				})
		} else {
			toast.warn("Permission already exists.")
		}
	}
}
	const getRolePermission = async (val) => {
		setLoader(true);
		let obj = { id: val.id, name: val.role_name }
		console.log(val)
		await setSelectedRole(obj);
		setIsRolePermissionManageActive(true)
		let response = await getAllRolePermissionApiHandler(val.id)
		if (response) {
			setAllRolePermissions(response);
			setLoader(false);
		}
	}
	const getAllRolePermissionApiHandler = async (id) => {
		let data = {
			role_id: id,
		}
		let res = await makePostRequest(URLS.GET_ROLE_PERMISSIONS, null, data, null)
		if (res.code === 200) {
			let rolePermissions = [];
			if (res?.data?.length) {
				for (let index = 0; index < res.data.length; index++) {
					let dump = {}
					const element = res.data[index];
					dump["role_name"] = element["tbl_role"]["role_name"]
					dump["permission_name"] = element["tbl_permission"]["permission_name"]
					dump["role_id"] = element["role_id"]
					dump["permission_id"] = element["permission_id"]
					dump["id"] = element["id"]
					rolePermissions.push(dump);
				}
				window.scroll({
					top: 0,
					behavior: "smooth"
				})
			}
			return rolePermissions;
		}
		else if (res.code != 200) {
			return false
			//setIsRolePermissionManageActive(false)
			//setLoader(false)
		}
	}
	useEffect(() => {
		getAllRoles();
		getAllPermissions()
	}, []);

	const getAllPermissions = () => {
		makeGetRequest(URLS.GET_PERMISION, {}, null)
			.then(res => {
				if (res.code == 200) {
					let permissionOptions = [];
					for (let index = 0; index < res.data.length; index++) {
						const element = res.data[index];
						let obj = {};
						obj.value = element["id"];
						obj.label = element["permission_name"].replaceAll("_", " ");
						permissionOptions.push(obj);
					}
					setAllPermisions(permissionOptions);
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}
	const getAllRoles = () => {
		makeGetRequest(URLS.USER_GET_ALL_ROLE, {}, null)
			.then(res => {
				if (res.code == 200) {
					setRolesData(res.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}
	const handleEditButton = (val) => {
		setErrorName('');
		setIsUpdate(true);
		let obj = { id: val.id, name: val.role_name }
		setSelectedRole(obj);
		window.scroll({
			top: 0,
			behavior: "smooth"
		})
	}
	const permissionHandler = (e) => {
		setSelectedPermission({ name: e.label, id: e.value });
		setSelectedPermissionError("")
	}
	const handleupdateRole = () => {
		let data = {
			id: selectedRole.id,
			role_name: selectedRole.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
		}
		makePostRequest(URLS.UPDATE_ROLE, null, data, null)
			.then(res => {
				if (res.code === 200) {
					// setName('');
					// setSelectedRole({ ...selectedRole, name: "" })
					toast.success(res.message);
					setLoader(false);
					getAllRoles();
					setIsUpdate(false);
					setSelectedRole({});
					setName('');
			setSelectedRole({ ...selectedRole, name: "", id: "" });
				}
				else if (res.code === 400) {
					toast.warn(res.message);
				}
			})
	}

	const handleDeleteRolePermission = async (row) => {
		setLoader(true);
		let data = {
			id: row.id,
		}
		let dump = [...allRolePermissions]
		let newArray = dump.filter(item => item.id != row.id)
		await setAllRolePermissions(newArray)
		console.log("allRolePermissions = = = = \n", allRolePermissions)
		let res = await makePostRequest(URLS.DELETE_ROLE_PERMISSION, null, data, null)

		if (res.code === 200) {
			toast.success("Role deleted successfully.");
			setLoader(false);
		}
		else if (res.code === 400) {
			toast.warn("Role already exists.");
		}
	}

	const closeRolePermissionHandler = () => {
		setIsRolePermissionManageActive(false)
		setSelectedRole({ ...selectedRole, name: "", id: "" });
		setAllRolePermissions([])
	}
	
	// Code for Side bar mapping permission
	useEffect(()=> {
		showCustomePermission();
		
	},[])
    let newdatashow = [];
	const [allPermisionsNew,setallPermisionsNew]=useState([]);
	const [allPermisionsNewRole, setallPermisionsNewRole]=useState([]);
    let userAuthData = getUserDetailsFromToken();
	const showCustomePermission = () => {
		let data = {
			id: userAuthData.id,
			role_id: userAuthData.role_id,
		}
		makePostRequest(URLS.GET_ROLE_PERMISSIONS, null, data, null)
			.then(res => {
				if (res.code == 200) {
					setallPermisionsNew(res.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
		 
		console.log("RRRRR", allPermisionsNew); 
	}
	

	const [payloadarraypass, setPayloadarraypass] = useState([]);
	let payloadarray=[];
   let tempData=[];

	const handleDragEnd = (e) => {
	   
	   if (!e.destination) return;
		tempData = Array.from(rolesData);
	   let [source_data] = tempData?.splice(e.source.index, 1);
	   tempData?.splice(e.destination.index, 0, source_data);
	   setRolesData(tempData);

	  // console.log(e,"result",tempData,"updateddata")


	   tempData.length>0  && tempData.map((val,index) => {
		   payloadarray.push({"sort":tempData.length-index ,"id":val.id})
		   
		 })
		 setPayloadarraypass(payloadarray)
	 };



	const SortManageRole = () => {
		let data = 
		  payloadarraypass?payloadarraypass:[]
			
		
	// console.log(data," payload data")
		makePostRequest(URLS.SORTROLE, null, data, null)
			.then(res => {
				//console.log(res)
				if (res.code == 200) {
					
					toast.success(res.message);
					getAllRoles();
				}
				else if (res.code == 400) {
					setLoader(false);
					toast.error(res.message);
				}
			})

	}

	useEffect(() => {
		if(payloadarraypass.length > 0){
			SortManageRole();
		}
		
	}, [payloadarraypass]);





	//console.log("selectedRole = = =\n", selectedRole)
	return (
		<Fragment>
			<Head title="Role List"></Head>
			<Content>
				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Manage Roles</h3>
				{/* <BlockTitle tag="h4">Manage Role</BlockTitle> */}
				<Card className="card-bordered">
					<div className="card-inner-lg">
						<BlockHeadContent>
							<BlockHead size="lg">
								{!isRolePermissionManageActive && !isUpdate &&
									<label className="form-label form-label"> Add Role</label>
								}
								{!isRolePermissionManageActive && isUpdate &&
									<label className="form-label form-label"> Edit Role</label>
								}
								{isRolePermissionManageActive && selectedRole.name &&
									<h3 className="nk-block-title page-title mb5"> Add Permission to ({selectedRole.name})</h3>
								}
								<div className="row">
									<div className="col-lg-12 pb-5">

										{!isRolePermissionManageActive &&
											<div className="form-group col-lg-6 float-start">
												{/* <Label className="form-label">
												Name
											</Label> */}
												<div className="form-control-wrap">
													<Input name="name" placeholder="Name" type="text" value={selectedRole.name} onChange={handleName} />
													<span className="error"> {errorname} </span>
													<span className="error"> {expressionTextError} </span>
												</div>
											</div>}
										{isRolePermissionManageActive &&
											<div className="form-group col-lg-6 float-start">
												{/* <Label className="form-label">
												Phone No.
											</Label> */}
												<div className="form-control-wrap">
													<Select options={allPermisions} placeholder={"Select Permissions"} onChange={permissionHandler} />
												</div>
												<span className="error">{selectedPermissionError}</span>
											</div>}
											
										{!isRolePermissionManageActive && !isUpdate &&
											<ul className="align-center flex-sm-nowrap mx-4 float-start">
												<li><button className="btn btn-primary btn-md" type="submit" onClick={AddRoleHandler}>Add </button></li>
											</ul>
										}
										{!isRolePermissionManageActive && isUpdate &&
											<ul className="align-center flex-sm-nowrap mx-4 float-start">
												<li><button className="btn btn-primary btn-md" type="submit" onClick={updateRoleHandler}>Update </button></li>
											</ul>
										}
										{isRolePermissionManageActive &&
											<>
												<ul className="align-center flex-sm-nowrap mx-4 float-start">
													<li><button className="btn btn-primary btn-md" type="submit" onClick={addNewRolePermission}>Add Role Permission</button></li>
												</ul>
												<ul className="align-center flex-sm-nowrap mx-4 float-start">
													<li><button className="btn  btn-md" type="submit" onClick={closeRolePermissionHandler}>Cancel</button></li>
												</ul>
											</>
										}
									</div>

								</div>
								{isUpdate==false && 
								<div>
								{!isRolePermissionManageActive && <h4 className="nk-block-title page-title mb5 "> Manage Role List</h4>}
								{selectedRole?.id && isRolePermissionManageActive && allRolePermissions &&
									<>
										<h4 className="nk-block-title page-title mb5 "> Allowed Permissions</h4>
										<ReactDataTable
											data={allRolePermissions}
											columns={columns}
											className="nk-tb-list"
										/>
									</>
								}
								{!isRolePermissionManageActive &&
									// <Card className="card-bordered mb-5 mt-5">

<div className="card-inner-group">
											<div className="card-inner p-0">

<DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr className="p-3 bg-white border border-light round-lg mb-3">
				
              <th />
			  <th>#</th>
              <th>Role Name</th>
              <th>Status</th>
			  <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody 
                 className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {rolesData ?.map((user, index) => (
                  <Draggable
                    key={user.role_name}
                    draggableId={user.role_name}
                    index={index}
                  >
                    {(provider) => (
                      <tr {...provider.draggableProps} ref={provider.innerRef}  className="p-3 bg-white border border-light round-lg mb-3">
                        <td {...provider.dragHandleProps}><div className="user-card mt-3 mb-2"> <FontAwesomeIcon icon={faGripVertical} /> </div></td>
						<td><div className="user-card mt-3 mb-2">{index+1}</div></td>
                        <td>
						<div className="user-card mt-3 mb-2">
						{/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(user.name))} ></UserAvatar> */}
						<div className="user-info">
							<span className="userName">
								{capital(user.role_name)}{" "}
							</span>
						</div>
					</div>
					</td>
                       

                        <td>
						<>
	 			{
	 				user.status == "enable" &&
					<div className="user-card mt-2 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "disable", index)} checked />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>

	 			}
	 			{user.status == "disable" &&
				<div className="user-card mt-2 mb-2">
	 				<div className="custom-control custom-switch">
	 					<input type="checkbox" className="custom-control-input" id={user.id} onClick={() => handleswitch(user.id, "enable", index)} />
	 					<label className="custom-control-label" htmlFor={user.id}>
	 					</label>
	 				</div></div>
	 			}

	 		</>
	</td>
					<td>
					<div className="user-card mt-2 mb-2">
						{/* <button className="dropdown-item" onClick={() => { handleEditButton(user) }}>
							<Icon name="edit"></Icon>
						</button> */}
											<ul className="link-list-opt no-bdr">
																			<li style={{display: "-webkit-box"}}>
																				<button className="dropdown-item" onClick={() => { handleEditButton(user) }}>
																					<Icon name="edit"></Icon>

																				</button>
																				<i class="fa fa-lock fa-2x" aria-hidden="true" onClick={() => { getRolePermission(user) }}></i>
																			</li>
																			
																		</ul>

					</div>
					</td>

                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>

										
										
							</div>
										</div>
									// </Card>
								}
								{loader &&
									<div className="overflowloader" id="preload">
									<div className="circle-square" style={{ marginTop: "250px" }}>
										<div className="red" style={{ background: "#AAC0CE" }}></div>
										<div className="blue" style={{ background: "#265472" }}></div>
										<div className="green" style={{ background: "#F19357" }}></div>
										<div className="yellow" style={{ background: "#F0B285" }}></div>
									</div>
								</div>
								}
								</div>
								}
							</BlockHead>
						</BlockHeadContent>
					</div>
				</Card>
			</Content>

		</Fragment>
	);
};

export default ManageRole;
