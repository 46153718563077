import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect, useRef } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";

import { makePostRequest, makeGetRequest } from "../../../service/api";

import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line } from "recharts";
import { findUpper, capital } from "../../../utils/Utils";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


const filter = createFilterOptions();
const ExcelJS = require('exceljs');

function CustomerSearchResultReport() {

  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [customersearchreport, setCustomerSearchreport] = useState([]);
  const [customersearchreportchart, setCustomersearchreportchart] = useState([]);
  const [loader1, setLoader1] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [childId, setChildId] = useState("");
  const [userName, setUserName] = useState("");
  const [childName, setChildName] = useState("");
  const [userNameData, setUserNameData] = useState([])
  const [childNameData, setChildNameData] = useState([])
  const [userId, setUserId] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [customerSearchDetails, setCustomerSearchDetails] = useState([]);
  const [customerViewSearchDetails, setCustomerViewSearchDetails] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [contentcopy, setContentCopy] = useState([])
  const [gradecopy, setGradeCopy] = useState([])
  const [experiencecopy, setExperienceCopy] = useState([])
  const [facilitycopy, setFacilityCopy] = useState([])
  const [fundingcopy, setFundingCopy] = useState([])
  const [instructioncopy, setInstructionCopy] = useState([])
  const [learningcopy, setLearningCopy] = useState([])
  const [organizationcopy, setOrganizationCopy] = useState([])
  const [supportcopy, setSupportCopy] = useState([])
  const [formDateError, setformDateError] = useState("")
  const [toDateError, settoDateError] = useState("")
  const [keywordsData, setKeywordsData] = useState([]);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [keywords, setKeywords] = useState(""); 
  const [customerDetailReport, setcustomerDetailReport] = useState(true);
 
  const [clearStateList, setClearStateList] = useState(false);
  
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]

  let contentType = []
  let gradeType = []
  let experienceType = []
  let facilitytype = []
  let fundingtype = []
  let instructionstyle = []
  let learningpillartype = []
  let organizationtype = []
  let supporttype = []


  let copy = []
  let copy1 = []
  let copy2 = []
  let copy3 = []
  let copy4 = []
  let copy5 = []
  let copy6 = []
  let copy7 = []
  let copy8 = []

  const fetchSuggestions = (query) => {
    // Filter the list of options to return only those that match the query
    const filteredSuggestions = keywordsData.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));
    setSuggestions(filteredSuggestions);
  }
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const openViewModal = () => {
    setIsOpenView(!isOpenView);
  };
  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow)
    setformDateError("")
  }

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1)
    settoDateError("")
  }
  function handleclickdatepickericon() {

    setDatePickerShow(!datepickershow);


  }
  function handleclickdatepickericon1() {


    setDatePickerShow1(!datepickershow1);


  }
  const HandleUserName = (e) => {
    setUserId(e.value);
    setUserName(e.label);

  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleChildName = (e) => {
    setChildId(e.value);
    setChildName(e.label);
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  const handlesearch = () => {

    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {
      setCurrentPage(1)
      getcustomersearchreport();
    }
    // setUserId("")
    //  setChildId("")


  }
  const handleSearchDetails = (row) => {
   // console.log("row", row.tbl_resource_searches)
    setIsOpen(!isOpen);
    setCustomerSearchDetails(row.tbl_resource_searches);
  }

  const viewSearchDetails = (row) => {
    setIsOpenView(!isOpenView);
    setCustomerViewSearchDetails(row);

    let a = row.content_type != null ? row.content_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "").replace('"', "") : ""

    let b = a.split(",")


    contentType = b.filter((v, i) => i % 2 === 1)
    contentType.forEach(function (item, index) {
      if (index < contentType.length - 1)
        copy.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else

        copy.push(item.substring(item.indexOf(":") + 2, item.length - 1));
    });
    setContentCopy(copy)

    let a1 = row.grade_id != null ? row.grade_id.replace("[", "").replace("]", "").replace("{", "").replace("}", "").replace('"', "") : ""

    let b1 = a1.split(",")


    gradeType = b1.filter((v, i) => i % 2 === 1)
    gradeType.forEach(function (item, index) {
      if (index < gradeType.length - 1)
        copy1.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy1.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setGradeCopy(copy1)

    let a2 = row.experience_type != null ? row.experience_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b2 = a2.split(",")


    experienceType = b2.filter((v, i) => i % 2 === 1)
    experienceType.forEach(function (item, index) {
      if (index < experienceType.length - 1)

        copy2.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy2.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setExperienceCopy(copy2)

    let a3 = row.facility_type != null ? row.facility_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b3 = a3.split(",")


    facilitytype = b3.filter((v, i) => i % 2 === 1)
    facilitytype.forEach(function (item, index) {
      if (index < facilitytype.length - 1)

        copy3.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy3.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setFacilityCopy(copy3)

    let a4 = row.funding_type != null ? row.funding_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b4 = a4.split(",")


    fundingtype = b4.filter((v, i) => i % 2 === 1)
    fundingtype.forEach(function (item, index) {
      if (index < fundingtype.length - 1)
        copy4.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy4.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setFundingCopy(copy4)

    let a5 = row.organization_type != null ? row.organization_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b5 = a5.split(",")


    organizationtype = b5.filter((v, i) => i % 2 === 1)
    organizationtype.forEach(function (item, index) {
      if (index < organizationtype.length - 1)
        copy5.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy5.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setOrganizationCopy(copy5)

    let a6 = row.support_type != null ? row.support_type.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b6 = a6.split(",")


    supporttype = b5.filter((v, i) => i % 2 === 1)
    supporttype.forEach(function (item) {

      copy6.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
    });

    setSupportCopy(copy6)
    let a7 = row.learning_pillar != null ? row.learning_pillar.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b7 = a7.split(",")


    learningpillartype = b7.filter((v, i) => i % 2 === 1)
    learningpillartype.forEach(function (item, index) {
      if (index < learningpillartype.length - 1)
        copy7.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy7.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setLearningCopy(copy7)

    let a8 = row.instruction_style != null ? row.instruction_style.replace("[", "").replace("]", "").replace("{", "").replace("}", "") : ""

    let b8 = a8.split(",")


    instructionstyle = b8.filter((v, i) => i % 2 === 1)
    instructionstyle.forEach(function (item, index) {
      if (index < instructionstyle.length - 1)
        copy8.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', "") + ",");
      else
        copy8.push(item.substring(item.indexOf(":") + 2, item.length - 1).replace('"', ""));
    });

    setInstructionCopy(copy8)


  }

 // console.log("content type", contentcopy)


  // useEffect(() => {
  //   let a= customerViewSearchDetails.content_type.replace("[","").replace("]","")
  //   let b =a.split(",")
  //   console.log("content type",b)


  // }, [customerViewSearchDetails]);

  let keywordsArr = "";
  const ExportEcelFile = () => {

    setLoader(true);
    keywordsArr = keywords?.keyword

    let data = {
      "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      "end_date": selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      "user_id": userId ? userId : "",
      "page_number": currentPage ? currentPage : 1,
      "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
      "sortby": sortvalue ? sortvalue : "",
      keyword: keywordsArr ? keywordsArr : "",
      export_data: "yes",
    };
    console.log(data,"export data")
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTUSER, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          console.log("Search export", res.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SearchData");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },
            {
              header: "Date of Search",
              key: 'dateofsearch',
              width: 30
            },
            {
              header: "Customer Name",
              key: 'USER_NAME',
              width: 30
            },

            {
              header: "Email",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "Phone",
              key: 'OWNWERPHONE',
              width: 30
            },

            {
              header: "Ip Address",
              key: 'ipadress',
              width: 20
            },
            {
              header: "Longitude",
              key: 'longitude',
              width: 20
            },
            {
              header: "Latitude",
              key: 'latitude',
              width: 20
            },
            {
              header: "Keyword",
              key: 'keyword',
              width: 20
            },
            {
              header: "Grade",
              key: 'grade',
              width: 20
            },
            {
              header: "Content Type",
              key: 'contentType',
              width: 20
            },
            {
              header: "Customer Type",
              key: 'customerType',
              width: 20
            },
            {
              header: "Experience Type",
              key: 'experienceType',
              width: 20
            },
            {
              header: "Facility Type",
              key: 'facilitytype',
              width: 20
            },
            {
              header: "Funding Type",
              key: 'fundingtype',
              width: 20
            },
            {
              header: "Instruction Style",
              key: 'instructionstyle',
              width: 20
            },
            {
              header: "Learning Piller",
              key: ' learningpillar',
              width: 20
            },
            {
              header: "Organization Type",
              key: 'organizationtype',
              width: 20
            },
            {
              header: "Resource Type",
              key: 'resourcetype',
              width: 20
            },
            {
              header: "Support Type",
              key: 'supporttype',
              width: 20
            },
            {
              header: "Time Frame",
              key: 'timeframe',
              width: 20
            },

          ];
          res?.data?.search_data.map((val, i) => {
            let datas ;
            let time_frame = val?.time_frame;

            if (time_frame) {
                let time = JSON.parse(time_frame);
       // console.log(time,"timeJson")

                if (Array.isArray(time) && time.length > 0) {
                    datas = time[0];
                    // console.log(datas, "timefram");
                }
            }


            sheet.addRow({
              S_No: (i + 1),
              dateofsearch: val.created_at ? moment(val.created_at).format('YYYY-MM-DD') : "--",
              USER_NAME: val?.tbl_user.firstName + " " + val.tbl_user.lastName,
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              grade: val.grade_id ?  JSON.parse(val.grade_id).map(v => v.name).join(", "): "--",
              ipadress: val.ip_address ? val.ip_address : "--",
              longitude: val.longitude ? val.longitude : "--",
              latitude: val.latitude ?  val.latitude :"--",
              keyword: val.keyword ? val.keyword : "--",
              contentType: contentcopy.length > 0 ? JSON.parse(contentcopy).map(v => v.name).join(", ") : "",
              customerType: val.custmor_type ? val.custmor_type : "--",
              experienceType: val.experience_type ? JSON.parse(val.experience_type).map(v => v.name).join(", ") : "--",
              facilitytype: val.facility_type ? JSON.parse(val.facility_type).map(v => v.name).join(", ") : "--",
              fundingtype: val.funding_type ?  JSON.parse(val.funding_type).map(v => v.name).join(", ") : "--",
              instructionstyle: val.instruction_style ? JSON.parse(val.instruction_style).map(v => v.name).join(", ") : "--",
              learningpillar: val.learning_pillar ? JSON.parse(val.learning_pillar).map(v => v.name).join(", ") : "--",
              organizationtype: val.organization_type ? JSON.parse(val.organization_type).map(v => v.name).join(", ") : "--",
              resourcetype: val.resource_type == 1 ? "Organization" :
              val.resource_type == 2 ? "Curriculum Content" :
                val.resource_type == 3 ? "Experiences" : val.resource_type == 4 ? "Student Support" :
                  val.resource_type == 5 ? "Teacher parent Support" :
                    val.resource_type == 6 ? "Funding" : "Facility" ,
              supporttype: val.support_type ? JSON.parse(val.support_type).map(v => v.name).join(", ") : "--",
              // timeframe: val.time_frame ? JSON.parse(val.time_frame) : "--",
              timeframe:datas,


            })

          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'SearchResultReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
          //console.log(res.data, "res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
    setUserId("");
    
    setlimitvalue("");
    setSortValue("");
    CustomerDetailReportRef.current.select.setValue("", "select-option");
    setCurrentPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setKeywords("")
    setClearStateList(!clearStateList);
  }

  const getAllUniqueKeywordsData = () => {
    makeGetRequest(URLS.GET_ALL_KEYWORDS, null, null, null)
      .then((res) => {
       // console.log("res data", res)
        if (res.code === 200) {

          let options = [];
          res.data.length > 0 &&
            res.data.map((val, i) => {
              options.push({ keyword: val.keyword, label: val.keyword });
            });

          setKeywordsData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 // console.log(keywordsData, "keywords data");

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
       // console.log(res)
        if (res.code == 200) {
          let option = [];

          option.push({ value: "", label: "All" })
          res.data.length > 0 && res.data.map((val) => {
            option.push({ value: val.id, label: val.firstName + " " + val.lastName })
          })
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getChildName = () => {

    let data = {
      user_id: userId ? userId : ""
    }
   // console.log("child payload", data)
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        //console.log("child data", res)
        if (res.code == 200) {

          let option = [];
          res.data.length > 0 && res.data.map((val) => {
            option.push({ value: val.id, label: val.firstName + " " + val.lastName })
          })
          setChildNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=========================== customer Search report list ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  // let keywordsArr = "";
  const getcustomersearchreport = () => {
    setLoader1(true);

    keywordsArr = keywords?.keyword


    let data = {
      "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      "end_date": selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      "user_id": userId ? userId : "",
      "page_number": currentPage ? currentPage : 1,
      "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
      "sortby": sortvalue ? sortvalue : "",
      keyword: keywordsArr ? keywordsArr : ""

    };
    //console.log("search list payload", data)
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTUSER, null, data, null)
      .then((res) => {
        setLoader1(true)
        if (res.code === 200) {
          //console.log(res, "Search data");
          setCustomerSearchreport(res?.data?.search_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  // const fetchData = () => {
  //   let data = {
  //     "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //     "user_id": userId ? userId : "",
  //     "page_number": currentPage ? currentPage : 1,
  //     "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
  //     "sortby": sortvalue ? sortvalue : "",
  //     keyword: keywordsArr ? keywordsArr : ""
  //   };

  //   makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTUSER, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setCustomerSearchreport(res?.data?.search_data);
  //         setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    //fetchData()
    getcustomersearchreport();
  }, [currentPage, limitvalue, sortvalue])



  const getcustomersearchreportuser = () => {
    let data = {
      "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      "end_date": selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      "user_id": userId ? userId : "",
      "page_number": 1,
      "item_per_page": limitvalue ? limitvalue : 5,
      "sortby": ""
    };
    //console.log("search list user payload", data)
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTUSER, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "Search data ny user");
          setCustomerSearchreport(res?.data?.search_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  useEffect(() => {
    getUserName();

    getcustomersearchreport();
    getAllUniqueKeywordsData();
    // getcustomersearchreportchart();
  }, []);

  useEffect(() => {
    getcustomersearchreport();
    // getcustomersearchreportchart();
  }, [selectyear]);

  // useEffect(() => {
  //   getChildName();
  // }, [userId]);

  useEffect(() => {
    getcustomersearchreport();
    }, [clearStateList]);

  const columns = [
     {
      name: "#",
      selector: (row) => row.id,
      grow: 2,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },

    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 5,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}

          <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row?.tbl_user?.firstName ? capital(row?.tbl_user?.firstName) : "--"}{" "}{row?.tbl_user?.lastName?.trim() ? capital(row?.tbl_user?.lastName) : ""}  <br />
              </span>

              {row?.tbl_user?.email}

            </div>
          </div>
        </>
      ),
    },
    {
      name: "Date of Search",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row.created_at ? moment(row.created_at).format('MM-DD-YYYY') : "--"} </div>
      ),
    },

    {
      name: "Location",
   
      grow: 3,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> IP-Address :{row.ip_address ? row.ip_address : "--"} <br />

          {row.longitude ? "Logitute " + row.longitude : ""} <br />
          {row.latitude ? "Latitute " + row.latitude : ""}



        </div>


      ),
    },

    {
      name: "Search Details",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">  <span><button
          className="btn btn-md"
          onClick={() => {
            viewSearchDetails(row);
          }}
        >
          <em className="icon ni ni-eye"></em>
        </button>
        </span> </div>
      ),
    },

    {
      name: "Search Results",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div ><span> {row.tbl_resource_searches ? row.tbl_resource_searches.length : "--"}</span>
          <span><button
            className="btn btn-md"
            onClick={() => {
              handleSearchDetails(row);
            }}
          >
            <em className="icon ni ni-eye"></em>
          </button>
          </span>
        </div>
      ),
    },

  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  
    const newRef = useRef(null);
	const newRef1 = useRef(null);
	const CustomerDetailReportRef = useRef(null);
	
	const handleCustomerDetailReport = ()=> {
		if(CustomerDetailReportRef.current) {
			
			setcustomerDetailReport(!customerDetailReport);
			CustomerDetailReportRef.current.focus();
		}
	}
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
     
	 }		 
  }
  const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
           setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	   document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
  
  
  return (

    <React.Fragment>


      <Head title="Search Result Report"></Head>
      <Content>


        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Search Detail Report</h3>

        {/* {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : ( */}
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Search Result Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="search-result-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table style={{width: "100%"}}>
                            <tr className="border-bottom">
                            <th>#</th>
                              <th>Resource Title</th>
                              <th width="100px">Result From</th>
                            </tr>
                            {customerSearchDetails && customerSearchDetails.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{val.resource.resource_title}</td>
                                  <td>{val.type_search}</td>


                                </tr>
                              )

                            })}

                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>

            <Modal isOpen={isOpenView} toggle={openViewModal}>
              <ModalHeader
                toggle={openViewModal}
                close={
                  <button className="close" onClick={openViewModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Search Details
              </ModalHeader>
              <Card className="card-bordered">

                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <ul>
                          {contentcopy.length > 0 ? <div><li><strong>Content Type:</strong>{contentcopy}</li></div> : ""}
                          {/* {customerViewSearchDetails.learning_pillar!=null?<li><strong>learning pillar:</strong>{customerViewSearchDetails.learning_pillar}</li>:""} */
                          }

                          {learningcopy.length > 0 ? <div><li><strong>Learning Pillar:</strong>{learningcopy}</li></div> : ""}
                          {customerViewSearchDetails.resource_type != null ? <li><strong>Resource Type:</strong>{customerViewSearchDetails.resource_type == 1 ? "Organization" :
                            customerViewSearchDetails.resource_type == 2 ? "Curriculum Content" :
                              customerViewSearchDetails.resource_type == 3 ? "Experiences" : customerViewSearchDetails.resource_type == 4 ? "Student Support" :
                                customerViewSearchDetails.resource_type == 5 ? "Teacher parent Support" :
                                  customerViewSearchDetails.resource_type == 6 ? "Funding" : "Facility"}</li> : ""}

                          {/* {customerViewSearchDetails.facility_type!=null?<li><strong>Facility Type:</strong>{customerViewSearchDetails.facility_type}</li>:""}
                     */}
                          {facilitycopy.length > 0 ? <div><li><strong>Facility Type:</strong>{facilitycopy}</li></div> : ""}
                          {/* {customerViewSearchDetails.funding_type!=null?<li><strong>Funding Type:</strong>{customerViewSearchDetails.funding_type}</li>:""} */}
                          {fundingcopy.length > 0 ? <div><li><strong>Funding Type:</strong>{fundingcopy}</li></div> : ""}
                          {/* {customerViewSearchDetails.support_type!=null?<li><strong>Support Type:</strong>{customerViewSearchDetails.support_type}</li>:""} */}
                          {supportcopy.length > 0 ? <div><li><strong>Support Type:</strong>{supportcopy}</li></div> : ""}
                          {/* {customerViewSearchDetails.organization_type!=null?<li><strong>Organization Type:</strong>{customerViewSearchDetails.organization_type}</li>:""} */}
                          {organizationcopy.length > 0 ? <div><li><strong>Organization Type:</strong>{organizationcopy}</li></div> : ""}
                          {customerViewSearchDetails.location_type != null ? <li><strong>Location Type:</strong>{customerViewSearchDetails.location_type}</li> : ""}
                          {/* {customerViewSearchDetails.experience_type!=null?<li><strong>Experience Type:</strong>{customerViewSearchDetails.experience_type}</li>:""}
                     */}
                          {experiencecopy.length > 0 ? <div><li><strong>Experience Type:</strong>{experiencecopy}</li></div> : ""}
                          {customerViewSearchDetails.keyword != null ? <li><strong>Keywords:</strong>{customerViewSearchDetails.keyword}</li> : ""}
                          {/* {customerViewSearchDetails.grade_id!=null?<li><strong>Grade :</strong>{customerViewSearchDetails.grade_id}</li>:""} */}
                          {gradecopy.length > 0 ? <div><li><strong>Grade Type:</strong>{gradecopy}</li></div> : ""}


                        </ul>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">


              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">


                 
                  <div className="p-4">
				   <div className="filtersidebutton">
                      
					  <button className="btn btn-md btn-primary m-0"  onClick={clearFilterList}>Clear Filters</button>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex  justify-content-end">

                        <select value={sortvalue} className="form-select" style={{ width: "190px" }} onChange={HandleSort} >
                          <option value="">Sort By</option>
                          <option value="ASCDATE">Date Ascending</option>
                          <option value="DESCDATE">Date Descending</option>




                        </select>

                        <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                        <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>

                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        </div>
                        <div>
                          &nbsp; &nbsp;
                          <button className="btn btn-primary " onClick={ExportEcelFile}>
                            <em className="icon ni ni-download-cloud"></em><span>Export</span>
                          </button>
                        </div>
                      </div>
                    </div>


                    {/* {customersearchreportchart&&customersearchreportchart?.length>0?  (
                         <div>
                        <div className="p-5">
                      <h5>Monthly Search Chart ({selectyear}) </h5>
                      <LineChart width={500} height={215} data={customersearchreportchart}>
                 
                 <CartesianGrid strokeDasharray="3 3" />
                 <XAxis dataKey="month" interval={'preserveStartEnd'}/>
                 <YAxis label={{ value: 'No. Of Searches', angle: -90, position: 'insideLeft',dy:30,fill:"#f19357"}} />
                 <Tooltip />
                 <Legend />
                 <Label dataKey="total_searched"/>
                 <Line type="monotone" dataKey="total_searched" name="Searches" fill="#f19357" />
              </LineChart>
             
                  
               </div>
               </div>
               ):<p>No Data found</p>} */}
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession">
                          <i className="fa fa-chevron-down chevron-downcustome" onClick={handleCustomerDetailReport}></i> 
                            <Select
                            className="form-select"
                              defaultvalue={userId}
                              options={userNameData}
                              openMenuOnFocus={customerDetailReport}
                              onChange={HandleUserName}
							  ref={CustomerDetailReportRef}
                            />
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>




                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <Autocomplete

                          value={keywords}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setKeywords({
                                keyword: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setKeywords({
                                keyword: newValue.inputValue,
                              });
                            } else {
                              setKeywords(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option.keyword
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                keyword: inputValue,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={keywordsData}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.keyword;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option.keyword}</li>
                          )}
                          sx={{ width: 300 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Search Keyword" />
                          )}
                        />


                      </div>

                      <div className="col-lg-2 d-flex ">
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                            {" "}
                            Search{" "}
                          </button>

                          {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                        </div>
                      </div>
                      <div className="col-lg-2 d-flex ">
                        <div className="outerTypeBox mb-3">
                        
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square" >
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}


                      {loader == true ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square" >
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}
                      {customersearchreport && customersearchreport?.length > 0 ? (
                        <div>

                          <div className="color">
                            <ReactDataTable
                              data={customersearchreport}
                              columns={columns}
                              className="nk-tb-list"

                            />

                          </div>
                        </div>
                      ) : <p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p>}

                    </div>
                    <div className="p-4">
                    {customersearchreport?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : ""}
                       </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>

          </Card>
        </>
        {/* )} */}

      </Content>

    </React.Fragment>

  );

}
export default CustomerSearchResultReport;
