import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";



function ManagePermision() {


	const [selectedPermision, setSelectedPermision] = useState({});
	const [profile, setprofile] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [permisionData, setPermisionData] = useState([]);
    const [resetname, setResetName] = useState('');
	
	const [loader, setLoader] = useState(false);
    const [switchState, setSwitchState] = useState("");
	 
	const handleswitch=(id, isSelected, index)=>{
		let data = {
			id: id,
			status: isSelected,
		}
		switchState?setSwitchState("enable"):setSwitchState("disable");
		const newArray = Array.from(permisionData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setPermisionData(newArray);
	
		makePostRequest(URLS.UPDATE_PERMISION, null, data, null)
			.then(res => {
				if(res.code === 200){
					 setprofile('');
					toast.success("Status updated successfully.");
				     setLoader(false); 
					getAllRoles();
			 	   setIsUpdate(false);
			
				}
				else {
					 toast.warn("Status disable successfully.")
				}
			})
	}	
 
	const handleName = (e) => {
		setprofile(e.target.value);
		setErrorName('');
		let obj = { ...selectedPermision };
		let str = e.target.value;
		obj.profile = str;
		setSelectedPermision({ ...obj })
	}

	const AddRoleHandler = () => {
    
		if (profile == "") {
			setErrorName('Please enter permission name.');
		}
    	else if (isUpdate) {
			handleupdateUser();
			setLoader(true);
			 let boj = {profile:''};
			   setSelectedPermision(boj);
			
		}
		else {
			addNewRole();
			setLoader(true);
		}
	}
	const addNewRole = () => {
		let data = {
			permission_name: profile,
			status:"enable"
		}
		
		makePostRequest(URLS.ADD_PERMISION, null, data, null)
			.then(res => {
			 if (res.code == 201) {	
			 let boj = {profile:''};
			   setSelectedPermision(boj);
				toast.success("Permission added successfully.");
				setLoader(false);
				getAllRoles();
				setIsUpdate(false);
				setSelectedPermision({});
			  }
			  else if(res.code==400){
					toast.warn("Permission already exists.")
					  setLoader(false)
				}
			})
	}
	useEffect(() => {
		getAllRoles();
	}, []);

	const getAllRoles = () => {
		makeGetRequest(URLS.GET_PERMISION, {}, null)
			.then(res => {
				if (res.code == 200) {
					setPermisionData(res.data);
				  //setLoader(true);	
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}


	const handleEditButton = (val) => {
		setIsUpdate(true);
		let obj = {id:val.id,profile:val.permission_name}
      
		setSelectedPermision(obj);
		window.scroll({
			top:0,
			behavior:"smooth"
		})
	}

	const handleupdateUser = () => {
		let data = {
			id: selectedPermision.id,
			permission_name: profile,
			
		}
				
		
		makePostRequest(URLS.UPDATE_PERMISION, null, data, null)
			.then(res => {
				if(res.code === 200){
				let boj = {profile:''};
			   setSelectedPermision(boj);
				 toast.success("Permission updated successfully.");	
                				 
					setLoader(false);	
					getAllRoles();
				   setIsUpdate(false);
				  setSelectedPermision({});
			
				}
				else if(res.code==400){
					toast.warn("Permission already exists.")
					  setLoader(false)
				}
			})
	}

	return (
		<React.Fragment>
			<Head title="Permission"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4"> Manage Permission</h3>
			  <Card className="card-bordered">	
				<div className="card-inner-lg">
					<BlockHeadContent>
					
					
						<BlockHead size="lg">
						{isUpdate===false?	
                         <h3 className="nk-block-title page-title mb5">Add Permission Name</h3>
                         :
                         <h3 className="nk-block-title page-title mb5">Edit Permission Name</h3>
						}						 

							<div className="row">


								<div className="col-lg-12 pb-5">
								
									<div className="form-group col-lg-6 float-start">
										<Label className="form-label">
										{/*	Name */}
										</Label>
										<div className="form-control-wrap">
											<Input name="name" placeholder="Name" type="text" value={selectedPermision.profile} onChange={handleName} />
											<span className="error"> {errorname} </span>
                    									
									</div>
										
									</div>
									{isUpdate===false?
                                     <ul className="align-center flex-sm-nowrap mt-3 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={AddRoleHandler}>Add </button></li>

									</ul>
									:
									<ul className="align-center flex-sm-nowrap mt-3 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={AddRoleHandler}>Update </button></li>
                           
									</ul>
	                              }
								</div>
								

							</div>



                         <h4 className="nk-block-title page-title mb5 "> Permission List</h4>

  {/*============= Custome Table Apply Here ===============*/}
      <Card className="card-bordered mb-5 mt-5">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <DataTableBody>
                 <DataTableHead className="table-dark">
                   <DataTableRow className="nk-tb-col-check">
                      <span className="sub-text"># </span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span className="sub-text">Permission Name</span>
                    </DataTableRow>
                   <DataTableRow size="sm">
                      <span className="sub-text">Status</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
					   <span className="sub-text"> Action </span>
                      
                    </DataTableRow>
                  </DataTableHead>
				  
		
				  
				  {
				
					permisionData.map((val,index) => {

						return (<>
						<DataTableItem key={index}>
										<DataTableRow className="nk-tb-col-check">
										  <span>{index+1}</span>
										</DataTableRow>
								
								
										<DataTableRow size="sm">
										
										  <div className="project-info"><h6 className="title">
										  {val.permission_name.replaceAll("_"," ")}</h6>
										  </div>
										  
										</DataTableRow>
										
															<DataTableRow size="sm">
										
										  
{
 val.status=="enable"&& 
<div className="custom-control custom-switch">
<input  type="checkbox" className="custom-control-input" id={val.id}  onClick={()=>handleswitch(val.id, "disable", index)} checked  />
   <label className="custom-control-label" htmlFor={val.id}>
	</label>
	 </div>
	 
  }
  {	val.status=="disable"&& 	
 <div className="custom-control custom-switch">
	  <input  type="checkbox" className="custom-control-input" id={val.id} onClick={()=>handleswitch(val.id, "enable", index)}  />
	 <label className="custom-control-label" htmlFor={val.id}>
</label>
 </div> 
}
								
															
										  
										</DataTableRow>				
										
										<DataTableRow size="sm">
										 <ul className="link-list-opt no-bdr">
													<li> 
													<button className="dropdown-item" onClick={() => { handleEditButton(val) }}>
													   <Icon name="edit"></Icon>
													   </button>
										  </li>
										  </ul>
													
										</DataTableRow>
								</DataTableItem>		
					              </>)
							})		
                }	
							
				  
				  
				  
				  
				  
				  </DataTableBody>
				  
				  </div>
				  </div>
				  </Card>

{/*============= Custome Table End Here ===============*/}
						 {/*						
						<table className="table table-bordered mb-4 table-striped">
								<thead className="table-dark">
									<tr>

										<th scope="col">ID </th>
										<th scope="col">Role Name </th>
										<th scope="col">Action </th>
									</tr>
								</thead>

								<tbody>
									{



										permisionData.map((val, index) => {

											return (<>
												<tr key={index} >

													<td>{val.id}</td>
													<td><h6>{val.permission_name}</h6></td>
													<td><button className="btn btn-md btn-primary" onClick={() => { handleEditButton(val) }}>Edit </button>

													</td>

												</tr>
											</>)
										})

									}
								</tbody>
							</table>
						 */}

                             { loader&&
                        <div className="sharepopupOuter backgroundOpacity1">
							<ThreeDots
								height="100%"
								width="100%"
								radius="9"
								color="#08147e"
								ariaLabel="three-dots-loading"
								wrapperStyle={{
								    margin: "200px auto",
									width: "90px",
									height: "90px",
								}}
								wrapperClassName="loader"
								visible={true}
							/>
						</div>
			        }

                  




						</BlockHead>
					</BlockHeadContent>
				</div>
				</Card>
			</Content>

		</React.Fragment>
	);
};

export default ManagePermision;
