import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import { ReactDataTable } from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest, getHeaders } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
const ExcelJS = require('exceljs');
import { S3_BUCKET_URL } from "../../../constants";
import Select from 'react-select';
import DataTable from "react-data-table-component";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import bnb from '../../../images/coins/bnb.svg';

function GroupList() {
  const [grouplist, setGroupList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [switchState, setSwitchState] = useState("");
  const [show, setShow] = useState(false)
  const [sharewith, setShareWith] = useState("")
  const [status, setStatus] = useState("")
  const [faithbackground, setFaithBackground] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null)
  const [search, setSearch] = useState("")
  const [selectedfromdate, setSelectedfromDate] = useState(null)
  const [selectedtodate, setSelectedToDate] = useState(null)
  const [formDateError, setformDateError] = useState("")
  const [toDateError, settoDateError] = useState("")
  const [limitvalue, setlimitvalue] = useState("")
  const [sortcustomer, setSortCustomer] = useState("")
  const [manageGrade, setManageGrade] = useState([])
  const [gradeId, setGradeId] = useState("")
  const [learningPillerdata, setLearningPillerdata] = useState([])
  const [learningPillerId, setLearningPillerId] = useState([])
  const [userId, setUserId] = useState("");
  const [groupJoin, setGroupJoin] = useState("")
  const [userListfilter, setUserListfilter] = useState([])
  const [userFilter, setUserFilter] = useState("")
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [userName, setuserName] = useState(true);
  const [clearState, setClearState] = useState(false);
  const [pillerIdResource, setPillerIdResource] = useState("");
  let group= JSON.parse(sessionStorage.getItem("group"))

// console.log(group,"sessioon Storage data")

  const shareWithOptions = [
    { value: "friend", label: "friend" },
    { value: "local", label: "local" },
    { value: "group", label: "group" },
  ];
  const ShowHideSelect = () => {
    setShow(!show)
  }
  const HandleShareWithfunction = (sharewithId) => {
    setShareWith(sharewithId)

  }
  let getsharewithvalue = [];
  if (sharewith && sharewith.length > 0) {
    sharewith.forEach((val) =>
      getsharewithvalue.push(val.value)
    );
  }

  // const HandleLearningResource = (Id) => {
  //   setPillerIdResource(Id);
  // };
 

  //console.log( getsharewithvalue ,"getshare")
  let learningIdResource = [];
  console.log(learningIdResource,"learningIdResource")

  const HandleSlelectLearning = (Id) => {
    setLearningPillerId(Id)

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: Id,
        searchbygroupname: search,
      })
    );
  }
   

  const handlesearchBygroupname = (e) => {
    setSearch(e.target.value)

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: e.target.value,
      })
    );




  }

  const HandleUserListId = (e) => {
    setUserId(e.target.value)


    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: e.target.value,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );




  }

  const HandleSlelectGrade = (e) => {
    setGradeId(e.target.value)

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: e.target.value,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );




  }
  // console.log(userFilter,"userFilter")
  const HandleFromDate = (e) => {
    setSelectedfromDate(e)
    setDatePickerShow(!datepickershow);
    setformDateError("")


    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: e ? moment(e).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );


  }
  const HandleToDate = (e) => {
    setSelectedToDate(e)
    setDatePickerShow1(!datepickershow1);
    settoDateError("")

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: e ? moment(e).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );



  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    //sessionStorage.setItem("group",JSON.stringify({limitvalueogroup:e.target.value,pagenumbergroup:currentPage,sortvaluegroup:sortcustomer}))
    setCurrentPage(1)

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: e.target.value,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );
  }
  const HandleSortCustomer = (e) => {
    setSortCustomer(e.target.value)
    //sessionStorage.setItem("group",JSON.stringify({limitvalueogroup:limitvalue,pagenumbergroup:currentPage,sortvaluegroup:e.target.value}))
    setCurrentPage(1)

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: e.target.value,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );

  }
  const HandleUserFilter = (e) => {
    setUserFilter(e);

    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: e,
        joinasgroup: userId,
        seletcgradegroup: gradeId,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        selectlearningpillergroup: learningPillerId,
        searchbygroupname: search,
      })
    );
  }


  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  useEffect(() => {
    
    setSelectedToDate(group.endDate);
    setSelectedfromDate(group.startDate);
    setlimitvalue(group.limitvalueogroup);
    setUserFilter(group.userlistgroup),
    setUserId(group.joinasgroup),
    setGradeId(group.seletcgradegroup),
    setSortCustomer(group.sortvaluegroup),
    setCurrentPage(group.pagenumbergroup ? group.pagenumbergroup : 1);
    setLearningPillerId(group.selectlearningpillergroup),
    setSearch(group.searchbygroupname)
    
  }, [])

  // console.log("ggggggggggggg", group.pagenumbergroup);
  useEffect(() => {
    getAllGroups();
  }, [sortcustomer, limitvalue,currentPage])

  const ExportEcelFile = () => {

    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      piller_id: learningPillerId ? learningPillerId : "",
      grade_id: gradeId ? gradeId : "",
      // user_id:userId ? userId :"",
      // member_user_id:userId ? userId : "",
      searchKey: search,
      key: "",
      share_with: getsharewithvalue ? getsharewithvalue : "",
      limit: limitvalue ? limitvalue : 10,
      sortby: sortcustomer ? sortcustomer : "",
      page: currentPage,
      member_user_id: userFilter.value,
      group_join: userId ? userId : "",
      key: "export"

    };
    makePostRequest(URLS.GET_ALL_GROUP_EXPORT, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          // console.log("gruop export",res.data)
          // setGroupList(res.data.custmor);
          // setCount(Math.ceil(res.data.count/10))
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("GroupData");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {

            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },
            {
              header: "REGISTER DATE",
              key: 'REGISTER_DATE',
              width: 15
            },
            {
              header: "GROUP OWNER NAME",
              key: 'GROUP_OWNER_NAME',
              width: 30
            },

            {
              header: "OWNER EMAIL",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "OWNER PHONE",
              key: 'OWNWERPHONE',
              width: 30
            },
            {
              header: "GROUP NAME",
              key: 'GROUP_NAME',
              width: 20
            },

            {
              header: "LOCATION",
              key: 'LOCATION',
              width: 20
            },
            {
              header: "KEYWORD",
              key: 'KEYWORD',
              width: 20
            },
            {
              header: "URL",
              key: 'URL',
              width: 20
            },
            {
              header: "ABOUT",
              key: 'ABOUT',
              width: 20

            },
            {
              header: "GRADE",
              key: 'GRADE',
              width: 20
            },
            {
              header: "LEARNING PILLER",
              key: 'LEARNINGPILLER',
              width: 20
            },

            {
              header: "SHARE WITH",
              key: 'SHAREWITH',
              width: 18
            },
            {
              header: "GROUP JOIN PERMISSION",
              key: 'GROUPJOINPERMISSION',
              width: 22
            },
            {
              header: "CHAT PERMISSION",
              key: 'CHATPERMISSION',
              width: 18
            },
            {
              header: "TOTAL MEMBERS",
              key: 'TOTAL_MEMBERS',
              width: 18
            },
            {
              header: "STATUS",
              key: 'STATUS',
              width: 15
            },
            {
              header: "RATING",
              key: 'RATING',
              width: 18
            },
            {
              header: "TOTAL SUB GROUPS",
              key: 'TOTALSUBGROUPS',
              width: 18
            },
            {
              header: "TOTAL RESOURCE SHARED",
              key: 'TOTALRESOURCESHARED',
              width: 18
            },
          ];
          res.data.groupMemberList?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              REGISTER_DATE: moment(val.createdAt).format("MM DD YYYY"),
              GROUP_NAME: val?.group_name ? val?.group_name : "N/A",
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              LOCATION: val.address,
              KEYWORD: val.tbl_group_keywords.map((temp) => temp.keyword).join(", "),
              // ABOUT: dangerouslySetInnerHTML={__html: val?.about_group ? val?.about_group :"--"}},
              URL: val.url,
              ABOUT: val?.about_group.replace(/(<([^>]+)>|&nbsp;)/ig, ''),
              GRADE: val?.tbl_group_grade_mappings ? val?.tbl_group_grade_mappings.map((temp) => temp.tbl_grade_level.name).join(", ") : "N/A",
              LEARNINGPILLER: val?.tbl_group_learning_pillars ? val?.tbl_group_learning_pillars.map((temp) => temp.tbl_learning_pillar_master.name).join(", ") : "N/A",
              SHAREWITH: val?.shared_with,
              GROUPJOINPERMISSION: val?.group_join_permission,
              CHATPERMISSION: val.group_chat_permission == 0 ? "Disable" : "Enable",
              GROUP_OWNER_NAME: val?.tbl_user.firstName + " " + val?.tbl_user.lastName,
              TOTAL_MEMBERS: val?.no_of_user,
              STATUS: val.status,
              RATING: val?.overall_rating,
              TOTALSUBGROUPS: val.tbl_group_to_group_mappings.length,
              TOTALRESOURCESHARED: val.tbl_group_resource_shared_mappings.length,

            })

          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Group.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
          // console.log(res.data,"res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      grow: 0,
      // sortable: true,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage == 1 ? (index + 1) : ((currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10)) + (index + 1)} </div>
        </>
      )
    },
    {
      name: "Creation Date",
      selector: (row) =>
        moment(row.created_at).format("MM-DD-YYYY"),
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            <span className="sub-text">{row?.tbl_group_learning_pillars?.map((item) =>
              <div>

                <img src={S3_BUCKET_URL + (item?.tbl_learning_pillar_master?.image)} style={{ width: "18px", margin: "2px" }} />

              </div>
            )}</span>

          </div>
        </div>
      ),
    },
    {
      name: "Group Name",
      selector: (row) => "",
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.group_name ? row?.group_name : "N/A"}</span>
          </div>
        </div>

      ),
    },
    {
      name: "Group Owner Name",
      selector: (row) => (""),
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.tbl_user.firstName ? row.tbl_user.firstName : "N/A"} {""} {row.tbl_user.lastName}
            </span>
          </div>

        </div>
      ),
    },

    {
      name: "Total Members",
      selector: (row) => "",
      grow: 2,
      // sortable: true,
      cell: (row) => <span className="sub-text">{row?.no_of_user}</span>,
    },
    // {
    //   name: "Status",
    //   selector: (row) => "",
    //   grow:2,
    //   sortable: true,
    //   cell: (row) => <span className="sub-text">{row.is_deleted=="no" ? "activate" : "deactivate"}</span>, 
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      cell: (row, index) => (
        <>
          {row.status == "enable" && (
            <div className="custom-control custom-switch">
              <input
                onClick={() => {
                  handleswitch(row.id, "disable", index);
                }}
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                checked
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
          {row.status == "disable" && (
            <div className="custom-control custom-switch">
              <input
                onClick={() => {
                  handleswitch(row.id, "enable", index);
                }}
                type="checkbox"
                className="custom-control-input"
                id={row.id}
              />

              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
        </>
      ),
    },
    {
      name: "View",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-groupdetail/${row.id}`}>  <button className="dropdown-item btn btn-md" >
            <em className="icon ni ni-eye"></em>
          </button>
          </Link>

          <Link to={`/editGroup-page/${row.id}`}> <button
            className="dropdown-item btn btn-md"
          >
            <em className="icon ni ni-edit"></em>
          </button>
          </Link>
        </div>
      ),
    },
  ];

  const handleswitch = (id, isSelected, index) => {
    // console.log(id, "=id", isSelected, "=isselcted", index, "=index");
    let data = {
      group_id: id,
      status: isSelected,

    };
    switchState ? setSwitchState("enable") : setSwitchState("disable");
    const newArray = Array.from(grouplist);
    newArray[index].status = isSelected === "enable" ? "disable" : "enable";
    setGroupList(newArray);

    makePostRequest(URLS.UPDATE_GROUP_STATUS, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success(res.message);
        getAllGroups();

      } else if (res.code == 400) {
        toast.success(res.message);
      }
    });
  };
  // console.log(grouplist,"gld")
  let [count, setCount] = useState()

  const getAllGroups = () => {

    if (group.selectlearningpillergroup && group.selectlearningpillergroup.length > 0) {
        group.selectlearningpillergroup.forEach((val) => learningIdResource.push(val.value));
       }
    setLoader(true);

    let data = {
      // start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      // end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      // piller_id: learningPillerId ? learningPillerId : "",
      // grade_id: gradeId ? gradeId : "",
      // searchKey: search,
      // key: "",
      // share_with: getsharewithvalue ? getsharewithvalue : "",
      // limit: limitvalue ? limitvalue : 10,
      // sortby: sortcustomer ? sortcustomer : "",
      // page: currentPage,
      // member_user_id: userFilter,
      // group_join: userId ? userId : "",

    
   
       start_date: group.startDate ? moment(group.startDate).format("YYYY-MM-DD") : "",
      end_date: group.endDate ? moment(group.endDate).format("YYYY-MM-DD") : "",
      piller_id: learningIdResource ? learningIdResource : "",
      grade_id: group.seletcgradegroup ? group.seletcgradegroup : "",
      searchKey: group.searchbygroupname,
      key: "",
      share_with: getsharewithvalue ? getsharewithvalue : "",
      limit: group.limitvalueogroup ? group.limitvalueogroup : 10,
      sortby: group.sortvaluegroup ? group.sortvaluegroup : "",
      page: group.pagenumbergroup ? group.pagenumbergroup :1,
      member_user_id: group.userlistgroup.value,
      group_join: group.joinasgroup ? group.joinasgroup : "",


    };


    //  console.log(data,"groupdata")
    makePostRequest(URLS.GET_ALL_GROUP, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupList(res.data.groupMemberList);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          setLoader(false);

          // console.log(res, "groupresponse")


        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleValidation = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("Please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("Please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("End date should be greater than start date")
    }
    else {
      getAllGroups();
      setCurrentPage(1)
      //  clearfilter();
    }
  }
  const handleClearData = () => {
   
    setSelectedfromDate("");
    setSelectedToDate("");
    setSearch("")
    setUserFilter("")
    setUserId("")
    setGradeId("")
    setLearningPillerId("")
    getAllGroups();
    setSortCustomer("");
    setlimitvalue("");
    setCurrentPage(1)
  
    let group = JSON.parse(sessionStorage.getItem("group")) || {};

    group.limitvalueogroup= "",
    group.pagenumbergroup= "",
    group.sortvaluegroup= "",
    group.userlistgroup= "",
    group.joinasgroup= "",
    group.seletcgradegroup= "",
    group.startDate= "",
    group.endDate= "",
    group.selectlearningpillergroup= "",
    group.searchbygroupname= "",
    setClearState(!clearState);
    sessionStorage.setItem("group", JSON.stringify(group));
  }
  const clearfilter=()=>{
    handleClearData()
  }
  useEffect(()=>{
    getAllGroups();
  },[clearState])
   
  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    //  console.log(currentPage,"selectedPage")
    sessionStorage.setItem(
      "group",
      JSON.stringify({
        limitvalueogroup: limitvalue,
        sortvaluegroup: sortcustomer,
        userlistgroup: userFilter,
        joinasgroup: userId,
        seletcgradegroup: gradeId,
        pagenumbergroup:selectedPage.selected + 1,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
       startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY"):"",
       selectlearningpillergroup: learningPillerId,
       searchbygroupname: search,
      })
    );
  
  
  
  };

  /*==========================grade level api calling here==============================*/
  const getGradeLevel = () => {
    makeGetRequest(URLS.GET_ALL_GRADE_LEVEL, {}, null)
      .then(res => {
        if (res.code == 200) {

          setManageGrade(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  // /*==========================Learning  piller api calling here==============================*/

  // const getAllLearningPiller = () => {
  //   setLoader(true);
  //   makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, {}, null)
  //     .then(res => {
  //       if (res.code == 200) {
  //         setLearningPillerdata(res.data);
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

   /*===================================Learning Piller Api calling====================*/

   const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        if (res.code === 200) {
           console.log(res,"lp")
          let options = [];
          res.data.length > 0 &&
            res.data.map((val, i) => {
              options.push({ value: val.id, label: val.name });
            });
            setLearningPillerdata(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getAllUserListFilter = () => {
    makePostRequest(URLS.GETALL_USERLIST, {}, null)
      .then(res => {
        if (res.code == 200) {
          setUserListfilter(res.data);
          // console.log(res,"listuser11")
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  useEffect(() => {
  //  getAllLearningPiller();
   getLearningPillarsData()
    getGradeLevel();
    getAllUserListFilter();
  }, []);

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefUserName = useRef(null);

 
  const handleCustomerName = ()=> {
    if(selectRefUserName.current) {
       setuserName(!userName);
      selectRefUserName.current.focus();
    }
  }


  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);

    }
  }
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
	 document.body.classList.add("group-list");
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1)
	  document.body.classList.remove("group-list");
    }
  });

  return (
    <React.Fragment>
      <Head title="Group List"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4"> Group List</h3>

        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <>
            <Card className="card-bordered">
              <div className="card-inner relativeClass">

                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 ">
                    <div className="filtersidebutton">
                      <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>Filters<FaFilter /></h5>
                      <button className="btn btn-md btn-primary  mx-2" type="submit" onClick={clearfilter}>Clear Filters</button>
                    </div>
                  </div>
                  <div className="col-lg-8 d-flex  justify-content-end">

                    <select value={sortcustomer} className="form-select" style={{ width: "170px" }} onChange={HandleSortCustomer}>
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCTITLE">Group Name Ascending</option>
                      <option value="DESCTITLE">Group Name Descending</option>
                      <option value="ASCNOOFUSER">Total Members Ascending</option>
                      <option value="DESCNOOFUSER">Total Members Descending</option>
                    </select>


                    <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                    <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>

                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    </div>
                    <button className="btn btn-primary exportbtn" onClick={ExportEcelFile}>
                      <em className="icon ni ni-download-cloud"></em><span>Export</span>
                    </button>
                  </div>


                </div>
                <hr></hr>

                {show &&
                  <div>
                    <div className="row pb-2">
                      {/* <div className="col-lg-4">
       <div className="form-control border-0 px-0">
<div className="">
<label><strong>Shared With</strong></label>
                <div>
                  <Select
                    value={sharewith && sharewith.length > 0 ? sharewith : null } 
                    options={shareWithOptions}
                    isMulti
                    selectMultiple={true}
                    onChange={HandleShareWithfunction}
                  />
                </div>
              </div>
              </div></div> */}
               <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label><strong>Customer Name</strong></label>
                            <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleCustomerName}
                            ></i>
                            {/* <select value={userFilter} className="form-select" onChange={HandleUserFilter}>
                              <option value="">All</option>
                              {
                                userListfilter?.map((val) => {
                                  return (<>
                                    <option value={val.tbl_user.id}>{val.tbl_user.firstName}{" "}{val.tbl_user.lastName}</option>
                                  </>)
                                })
                              }
                            </select> */}
                            
                            <Select
                              className="form-select form-selectparent"
                              autosize={true}
                              onChange={HandleUserFilter}
                              defaultValue={userFilter}
                              options={[
                                { value: "", label: "All" },
                                ...(userListfilter?.map((val) => ({
                                  value: val.tbl_user.id,
                                  label: val.tbl_user?.firstName + " " + val.tbl_user?.lastName,
                                })) || []),
                              ]}
                              openMenuOnFocus={userName}
                              ref={selectRefUserName}
                            />

                            


                            </div>


                          </div>
                        </div></div>
                     
{ userFilter.value === "" ||  userFilter ==="" ? "" :<>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label><strong>Joined As</strong></label>
                          <select value={userId} className="form-select" onChange={HandleUserListId}>
                            <option value="">Group Join</option>
                            <option value="owner">Admin</option>
                            <option value="member">Member</option>
                          </select>
                        </div>
                      </div>
                      </>}
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label><strong>Start Date</strong></label>
                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              className="form-control date-picker"
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              open={datepickershow}
                            />
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" ref={newRef1}>
                          <label><strong>End Date</strong></label>
                          <div className="form-control-wrap">
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              className="form-control date-picker"
                              onChange={HandleToDate}
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}

                            />                      </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label><strong>Select Grade</strong></label>
                          <select value={gradeId} className="form-select" onChange={HandleSlelectGrade}>
                            <option value="">Select Grade</option>
                            {
                              manageGrade.map((val) => {
                                return (<>
                                  <option value={val.id}>{val.name}</option>
                                </>)
                              })
                            }
                          </select>
                        </div>
                      </div>

                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label><strong>Select Learning Piller</strong></label>
                          <select value={learningPillerId} className="form-select" onChange={HandleSlelectLearning}>
                            <option value="">Select Learning Pillar</option>
                            {
                              learningPillerdata.map((val) => {
                                return (<>
                                  <option value={val.id}>{val.name}</option>
                                </>)
                              })
                            }
                          </select>
                        </div>
                      </div> */}
					      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label><strong>Search by Group Name</strong></label>
                          <Input name="name" placeholder="Search..." type="text" value={search} onChange={handlesearchBygroupname} /></div>

                      </div>
 <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Learning Pillar</strong>
                            </label>
                            <div>
                              <Select
                                value={learningPillerId && learningPillerId.length > 0 ? learningPillerId : null}
                                options={learningPillerdata}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleSlelectLearning}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                     
					  
                      



                  

                   

                      <div className="col-lg-4">
                        <div className="outerTypeBox mb-3">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary" type="submit" onClick={handleValidation}> Search </button>

                          {/* <button className="btn btn-primary mx-3 mt-2" onClick={"ExportEcelFile"}>
			      <em className="icon ni ni-download-cloud"></em><span>Export</span>
			     </button>     */}

                        </div>
                      </div>
                    </div>





                  </div>
                }

                <div className="card-inners relativeClass">
                  <BlockHeadContent>
                    <BlockHead size="lg">


                      {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <label>
                    <div class="form-control-select">
                       <select name="DataTables_Table_0_length" class="custom-select custom-select-sm form-control form-control-sm">
                        <option value="10">10</option><option value="25">25</option><option value="40">40</option><option value="50">50</option></select> </div></label></div></div> */}


                      {/* {grouplist.length==0?<>No Data Found</> : ( */}
                      <ReactDataTable
                        data={grouplist}
                        columns={columns}
                        className="nk-tb-list"
                        searchable={false}
                        setPageCount={setPageCount}
                      />
                      {/* )} */}

                       {grouplist?.length > 0 ?  
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                     :""} 
                    </BlockHead>
                  </BlockHeadContent>
                </div>

              </div>
            </Card>
          </>)}
      </Content>
    </React.Fragment>
  );
}

export default GroupList;
