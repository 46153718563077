import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { ThreeDots } from "react-loader-spinner";

import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { URLS } from "../../constants/urls";
import { makePostRequest } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  //const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [erroremail, setEmailError] = useState("");
  const [errorpass, setErrorsPass] = useState("");
  const [loader, setLoader] = useState(false);
  const [passicon, setpassicon] = useState(false);

  let Emailvalidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const onChangePass = (e) => {
    let password = e.target.value;
    console.log("onChangePass\n", password, "\n", e);
    setPassword(password);
    setErrorsPass("");
  };

  const onChangeEmail = (e) => {
    let Emailword = e.target.value;
    //console.log("onChangeEmail\n",Emailword,"\n", e)
    setEmail(Emailword);
    setEmailError("");
  };
  const showicon = () => {
    setpassicon(!passicon);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailError("Please enter your email id.");
    } else if (!Emailvalidation.test(email)) {
      setEmailError("The email address you entered is not valid");
    } else if (password === "") {
      setErrorsPass("Please enter your passwords.");
    } else if (password.length < 8) {
      setErrorsPass("Please enter a password between 8 and 20 characters long.");
    } else if (password.length > 20) {
      setErrorsPass("Please enter a password between 8 and 20 characters long.");
    } else {
      let data = {
        email: email,
        password: password,
      };
      makePostRequest(URLS.LOGIN, null, data, null).then((res) => {
        if (res.code === 200) {
          setLoader(true);

          sessionStorage.setItem("auth-token", res.data.authorization);

          // sessionStorage.setItem('limitvaluecust', 10)
          // sessionStorage.setItem('pagenumbercust', 1)
          // sessionStorage.setItem('sortvaluecust', "")
          const customer = {
            limitvaluecust: 10,
            pagenumbercust: 1,
            sortvaluecust: "",
            statuscust: "",
            userType: "",
            faithback: "",
            Search: "",
            startDate:"",
            endDate:"",
          };
          sessionStorage.setItem("customer", JSON.stringify(customer));

      
          const offense = {
            limitvalueoffense: 10,
            pagenumberoffense: 1,
            sortvalueoffense: "",
            statusoffense: "",
            resourceoffense: "",
            searchbyuser: "",
            startDate:"",
            endDate:"",
          };
          sessionStorage.setItem("offense", JSON.stringify(offense));
  

          const resource = {
            resourceMode: "",
            limitvalueresource: 10,
            pagenumberresource: 1,
            sortvalueresource: "",
            parentId: "",
            childId: "",
            resourceType:"",
            curriculumContentType: "",
            experienceType:"",
            facilityType:"",
            fundingType:"",
            organizationType:"",
            studentSupportType:"",
            teacherParentSupportType:"",
            timeframe:"",
            instructionalStyleType:"",
            learningPillarVal:"",
            startDate: "",
            endDate:"",
            search:"",
            isbnSearch:"",
           status:"",
            statusDelete:"",
            resourceReview:"",
           titleAvailable:"",
           descriptionAvailable:"",
          imgAvailable:"",
          urlAvailable:"",
          showonHomeAvailable:"",
          customerType:"",
          affiliateres:"",
          };
          sessionStorage.setItem("resource", JSON.stringify(resource));

         
          

          const group = {
            limitvalueogroup: 10,
            pagenumbergroup: 1,
            sortvaluegroup: "",
            userlistgroup:"",
            joinasgroup:"",
            seletcgradegroup: "",
            startDate:"",
            endDate:"",
            selectlearningpillergroup: "",
            searchbygroupname: "",
          };

          sessionStorage.setItem("group", JSON.stringify(group));



          const checkbox = {
            "Date Registered": true,
            "Customer Details": true,
            "Thumbnail Images": true,
            "Resource Details": true,
            "Resource Type": true,
            "Resource URL": true,
            "Total Searches": true,
            "Total Views": true,
            Description: true,
            Status: true,
            "Show on Home": true,
            Keywords: true,
            "ISBN Number": true,
            "Priority of Resource": true,
            "Affiliate Resource":true,
            Grade: true,
          };
          localStorage.setItem("checkboxvalues", JSON.stringify(checkbox));

          const Arr = [];
          localStorage.setItem("hiddencolumn", JSON.stringify(Arr));
          // sessionStorage.setItem('limitvaluegroup', 10)
          // sessionStorage.setItem('pagenumbergroup', 1)
          // sessionStorage.setItem('sortvaluegroup', "")

         
          sessionStorage.setItem("limitvaluesubscription", 10);
          sessionStorage.setItem("pagenumbersubscription", 1);
          sessionStorage.setItem("sortvaluesubscription", "");

          sessionStorage.setItem("limitvalueresourcepurchase", 10);
          sessionStorage.setItem("pagenumberresourcepurchase", 1);
          sessionStorage.setItem("sortvalueresourcepurchase", "");

          setTimeout(() => {
            window.history.pushState(
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
              "auth-login",
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
            );
            window.location.reload();
          }, 1000);
          toast.success(res.message);
        } else if (res.code == 400) {
          toast.error(res.message);
        } else {
        }
      });
    }
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <ToastContainer />
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
              </BlockContent>
            </BlockHead>
            <form onSubmit={onFormSubmit}>
              <div className="is-alter">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="name"
                      placeholder="Enter your email address"
                      className="form-control-lg form-control"
                      onChange={onChangeEmail}
                    />
                    <span className="error"> {erroremail}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type={passicon == false ? "password" : "text"}
                      placeholder="Enter your password"
                      onChange={onChangePass}
                      className={`form-control-lg form-control`}
                    />
                    <span className="error"> {errorpass}</span>
                    <small className="eyeIconstyle" onClick={showicon}>
                      {passicon == true ? (
                        <i className="fa fa-eye eyes-icons"></i>
                      ) : (
                        <i className="fa fa-eye-slash eyes-icons"></i>
                      )}
                    </small>
                  </div>
                </div>
                <div className="form-group">
                  <Button type="submit" size="lg" className="btn-block" color="primary">
                    {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                  </Button>
                </div>
              </div>
            </form>

            {loader && (
              <div className="overflowloader" id="preload">
                <div className="circle-square">
                  <div className="red" style={{ background: "#AAC0CE" }}></div>
                  <div className="blue" style={{ background: "#265472" }}></div>
                  <div className="green" style={{ background: "#F19357" }}></div>
                  <div className="yellow" style={{ background: "#F0B285" }}></div>
                </div>
              </div>
            )}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
