import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LineChart,
  Line,
} from "recharts";

import Select from "react-select";
import moment from "moment";

import Highcharts, { chart } from "highcharts";

import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { findUpper, capital } from "../../../utils/Utils";

exporting(Highcharts);

const ExcelJS = require("exceljs");

function CustomerSearchReport() {
  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [customersearchreport, setCustomerSearchreport] = useState([]);
  const [customersearchreportchart, setCustomersearchreportchart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [childId, setChildId] = useState("");
  const [userName, setNameUser] = useState("");
  const [childName, setChildName] = useState("");
  const [userNameData, setUserNameData] = useState([]);
  const [childNameData, setChildNameData] = useState([]);
  const [userNames, setuserNames] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [displaySelectGraphStuent, setDisplaySelectGraphStudent] = useState(true);
  const [displaySelectListviewStudent, setDisplaySelectListviewStudent] = useState(true);

  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [customerSearchData, setCustomerSearchData] = useState([]);
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);

  let chartdata = [];

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const HandleUserName = (e) => {
    setuserNames(e.label);
    setNameUser(e.value);
    selectRefListviewStudent.current.select.setValue("", "select-option");
    selectRefGraphStudent.current.select.setValue("", "select-option");
    getChildName();
  };
  //console.log("user", userName, "lab", userNames)
  const HandleChildName = (e) => {
    setChildId(e.value);
    setChildName(e.label);
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
  };
  const handlesearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getcustomersearchreport();

      // setuserName("")
      // setChildId("")
      // setChildName("")
      setCurrentPage(1);
    }
  };
  const handlechart = () => {
    getcustomersearchreportchart();

    // setuserName("")
    setChildId("");
  };

  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);

    // datepickerelement.setFocus(datepickershow );
  }
  function handleclickdatepickericon1() {
    //console.log("datepickerelement = ", datepickershow1);
    setDatePickerShow1(!datepickershow1);

    // datepickerelement.setFocus(datepickershow );
  }

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setNameUser("");
    setChildId("");
    selectRefGraph.current.select.setValue("", "select-option");
    selectRefGraphStudent.current.select.setValue("", "select-option");
    setSelectyear(new Date().getFullYear());
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setNameUser("");
    setChildId("");
    setlimitvalue("");
    setSortValue("");
    selectRef.current.select.setValue("", "select-option");
    selectRefListviewStudent.current.select.setValue("", "select-option");
    setCurrentPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  };
  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      child_id: childId ? childId : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes",
    };
    console.log(data,"export data")
    
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTSLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          console.log("search export", res.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SearchData");
          sheet.properties.defaultRowHeight = 30;
          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },

            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },
            {
              header: "Date of Search",
              key: "month",
              width: 20,
            },
            {
              header: "Total Searches",
              key: "totalsearches",
              width: 20,
            },
          ];
          res.data.search_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,

              USER_NAME: val?.tbl_user.firstName + " " + val?.tbl_user.lastName,
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              month: val?.search_date ? moment(val?.search_date).format("MM-DD-YYYY") : "--",
              totalsearches: val?.total_searched,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "SearchReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
          //console.log(res.data, "res g")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const viewSearchDetails = (row) => {
    let data = {
      search_date: row.search_date,
      user_id: row.tbl_user.id,
      child_id: childId ? childId : "",
    };
    //console.log("search details payload", data)
    makePostRequest(URLS.GET_SEARCHING_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        //console.log("search details", res)
        setCustomerSearchData(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        //console.log(res)
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //console.log(userNameData, "data")

  const getChildName = () => {
    let data = {
      user_id: userName ? userName : "",
    };
    //console.log("child payload", data)
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        //console.log("child data", res)
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "Select Student" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setChildNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomersearchreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userName ? userName : "",
      child_id: childId ? childId : "",
    };
    //console.log("data chart", data)
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTSCHART, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          // console.log(res, "search chart data");
          setCustomersearchreportchart(res?.data?.search_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*==============================search report list api==============================*/
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomersearchreport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      child_id: childId ? childId : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
    };
    console.log("search list payload", data)
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "Search data");
          setCustomerSearchreport(res?.data?.search_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  // const fetchData = () => {
  //   let data = {
  //     start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
  //     end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
  //     user_id: userName ? userName : "",
  //     child_id: childId ? childId : "",
  //     "page_number": currentPage ? currentPage : 1,
  //     "item_per_page": limitvalue ? parseInt(limitvalue) : 10,
  //     "sort_by": sortvalue ? sortvalue : "",
  //   };

  //   makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTSLIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setCustomerSearchreport(res?.data?.search_data);
  //         setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    //fetchData()
    getcustomersearchreport();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getcustomersearchreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getcustomersearchreport();
  }, [clearStateList]);

  const getcustomersearchreportuser = () => {
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      page_number: 1,
      item_per_page: 5,
      sort_by: sortvalue ? sortvalue : "",
    };
    //console.log("search list user payload", data)
    makePostRequest(URLS.GET_CUSTOMER_SEARCH_REPORTUSER, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "Search data ny user");
          setCustomerSearchreport(res?.data?.search_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  useEffect(() => {
    getUserName();

    getcustomersearchreport();
    getcustomersearchreportchart();
    getMonthName();
  }, []);
  useEffect(() => {
    getchartdata();
  }, [customersearchreportchart]);

  const getchartdata = () => {
    customersearchreportchart?.map((val) => {
      chartdata.push(val.total_searched);
    });
    setshowchart(chartdata);
  };

  // useEffect(() => {

  //   getcustomersearchreportchart();
  // }, [selectyear]);

  // useEffect(() => {
  //   getcustomersearchreport();
  // }, [limitvalue, sortvalue]);

  useEffect(() => {
    getChildName();
  }, [userName]);

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 2,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row.tbl_user,
      grow: 3,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user.firstName ? row.tbl_user.firstName : "--"}{" "}
                {row.tbl_user.lastName ? row.tbl_user.lastName : ""}
                <br />
              </span>
            </div>
          </div>
        </>
      ),
    },

    {
      name: "Email/Phone Number",
      selector: (row) => row.month,
      grow: 5,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user.email ? row.tbl_user.email : ""}
                <br />
              </span>

              {row.tbl_user.phone ? row.tbl_user.phone : ""}
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Search Date",
      selector: (row) => row.search_date,
      grow: 3,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            {row.search_date ? moment(row.search_date).format("MM-DD-YYYY") : "--"}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Total Searches",

      grow: 2,

      sortable: true,
      cell: (row) => <div className="user-card mt-2 mb-2"> {row.total_searched ? row.total_searched : "--"} </div>,
    },
    {
      name: "Search Details",

      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewSearchDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);
  const selectRefGraphStudent = useRef(null);
  const selectRefListviewStudent = useRef(null);

  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelect(!displayvalueSelect);
      selectRef.current.focus();
    }
  };

  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };
  const displaySelectGraphStudents = (e) => {
    if (selectRefGraphStudent.current) {
      setDisplaySelectGraphStudent(!displaySelectGraphStuent);
      selectRefGraphStudent.current.focus();
    }
  };

  const displaySelectListViewStudents = (e) => {
    if (selectRefListviewStudent.current) {
      setDisplaySelectListviewStudent(!displaySelectListviewStudent);
      selectRefListviewStudent.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Search Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Search Report</h3>

        {/* {loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ) : ( */}
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Search Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="search-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table style={{ width: "100%" }}>
                            <tr className="border-bottom">
                              <th>#</th>
                              <th>Time Of Search </th>

                              <th>IP Address</th>
                              <th>Keyword </th>
                            </tr>
                            {customerSearchData &&
                              customerSearchData.map((val, i) => {
                                return (
                                  <tr className="border-bottom">
                                    <td>{i + 1}</td>
                                    <td>{moment(val?.created_at).format("hh:mm a")}</td>
                                    <td>{val?.ip_address}</td>
                                    <td>{val?.keyword ? val?.keyword : "--"}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>
                              <Select
                                className="form-select"
                                defaultValue={userName}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectGraph}
                                onChange={HandleUserName}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Student Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectGraphStudents}
                              ></i>
                              <Select
                                className="form-select"
                                defaultvalue={childName}
                                options={childNameData}
                                openMenuOnFocus={displaySelectGraphStuent}
                                onChange={HandleChildName}
                                ref={selectRefGraphStudent}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-3 align-self-end">
                          <div className="form-control border-0 px-0">
                            <button className="btn btn-primary" type="submit" onClick={handlechart}>
                              {" "}
                              View{" "}
                            </button>

                            {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {customersearchreportchart && customersearchreportchart?.length > 0 ? (
                      <div>
                        <div className="p-5">
                          <div className="row"></div>

                          <div className="relativeClass">
                            {loader ? (
                              <div className="overflowloader" id="preload">
                                <div className="circle-square">
                                  <div className="red" style={{ background: "#AAC0CE" }}></div>
                                  <div className="blue" style={{ background: "#265472" }}></div>
                                  <div className="green" style={{ background: "#F19357" }}></div>
                                  <div className="yellow" style={{ background: "#F0B285" }}></div>
                                </div>
                              </div>
                            ) : (
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    type: "line",
                                  },
                                  title: {
                                    text: "Monthly Search Chart",
                                    style: { color: "#003C71", fontSize: "25px" },
                                  },

                                  xAxis: {
                                    categories: [
                                      "Jan",
                                      "Feb",
                                      "Mar",
                                      "Apr",
                                      "May",
                                      "Jun",
                                      "Jul",
                                      "Aug",
                                      "Sep",
                                      "Oct",
                                      "Nov",
                                      "Dec",
                                    ],
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  plotOptions: {
                                    line: {
                                      dataLabels: {
                                        enabled: true,
                                        formatter: function () {
                                          return this.y != 0 ? this.y : "";
                                        },
                                      },
                                      enableMouseTracking: true,
                                    },
                                  },
                                  credits: {
                                    enabled: false,
                                  },

                                  yAxis: {
                                    title: {
                                      text: "No. of Searches",
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                    labels: {
                                      style: {
                                        color: "#364a63",
                                      },
                                    },
                                  },
                                  series: [
                                    {
                                      name: "Month",
                                      data: showchart,
                                      color: "rgb(38, 84, 114)",
                                    },
                                  ],
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="p-4">No Data found</p>
                    )}
                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 d-flex  justify-content-end">
                            <select
                              value={sortvalue}
                              className="form-select"
                              style={{ width: "190px" }}
                              onChange={HandleSort}
                            >
                              <option value="">Sort By</option>
                              <option value="ASCDATE">Date Ascending</option>
                              <option value="DESCDATE">Date Descending</option>
                              <option value="ASCCOUNT">Total Searches Ascending</option>
                              <option value="DESCCOUNT">Total Searches Descending</option>
                            </select>

                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                {/* <option value="5">5</option> */}
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row relativewithzindex">
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i>
                              <Select
                                className="form-select"
                                defaultValue={userName}
                                options={userNameData}
                                value={userNameData.label}
                                openMenuOnFocus={displayvalueSelect}
                                onChange={HandleUserName}
                                ref={selectRef}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Student Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome"
                                onClick={displaySelectListViewStudents}
                              ></i>
                              <Select
                                className="form-select"
                                defaultvalue={childName}
                                options={childNameData}
                                openMenuOnFocus={displaySelectListviewStudent}
                                onChange={HandleChildName}
                                ref={selectRefListviewStudent}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                // ref={datepickerref}
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>

                        <div className="col-lg-3 ">
                          <div className="d-flex flex-row" style={{ gap: "10px", marginTop: "27px" }}>
                            <div className="outerTypeBox mb-3">
                              <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                                {" "}
                                Search{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relativeClass newregisterGrouptable">
                      {loader1 == true ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div>
                        <div className="color">
                          {customersearchreport && customersearchreport?.length > 0 ? (
                            <ReactDataTable data={customersearchreport} columns={columns} className="nk-tb-list" />
                          ) : (
                            <p className="p-4">No Data Found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {customersearchreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default CustomerSearchReport;
