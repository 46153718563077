import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
  ReactDataTable,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { findUpper, capital } from "../../../utils/Utils";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Highcharts, { chart } from "highcharts";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import {  toast } from "react-toastify";

exporting(Highcharts);
const ExcelJS = require("exceljs");

function CustomerShareReport() {

  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [graphReportchart, setGraphReportchart] = useState([]);
  const [userNameData, setUserNameData] = useState([]);
  const [userNameUsers, setNameUsers] = useState("");
  const [shareListData, setShareListData] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerShareData, setCustomerShareData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);

  let chartdata = [];
  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const HandleUserName = (e) => {
    setNameUsers(e.value);
  };
  const HandleUserNameList = (e) => {
    setUserId(e.value);
  };
  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };

  const handlesearch = (e) => {
    getShareReportChart();
  };
  const handlesearchList = (e) => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getShareListData();
      setCurrentPage(1);
    }
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1);
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const viewShareDetails = (row) => {
    let data = {
      share_date: row.shared_date,
      user_id: row.tbl_user.id,
    };
    makePostRequest(URLS.GET_SHARED_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        setCustomerShareData(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setNameUsers("");
    setSelectyear(new Date().getFullYear());
    selectRefGraph.current.select.setValue("", "select-option");
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setUserId("");
    setlimitvalue("");
    setSortValue("");
    selectRef.current.select.setValue("", "select-option");
    setCurrentPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  };

  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      sort_by: "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      export_data: "yes",
    };
    makePostRequest(URLS.GET_CUSTOMER_SHARED_REPORTLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("ShareData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Shared Date",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },
            {
              header: "Total Shared Count",
              key: "totalinvites",
              width: 20,
            },
          ];
          res.data.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: val?.shared_date ? moment(val?.shared_date).format("MM-DD-YYYY") : "--",
              USER_NAME: val?.tbl_user.firstName + " " + val?.tbl_user.lastName,
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              totalinvites: val.total_shared_count,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "SharedReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //user type get api GET_USER_TYPE
  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getShareReportChart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userNameUsers ? userNameUsers : "",
    };

    makePostRequest(URLS.GET_CUSTOMER_SHARED_REPORTSCHART, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setGraphReportchart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*============================share list api ============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getShareListData = () => {
    setLoader1(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userId ? userId : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
    };
    makePostRequest(URLS.GET_CUSTOMER_SHARED_REPORTLIST, null, data, null)
      .then((res) => {
        setLoader1(true);
        if (res.code === 200) {
          console.log(res,"share")
          setShareListData(res?.data?.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
 
  useEffect(() => {
    getShareListData();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getUserName();
    getShareReportChart();
    getShareListData();
  }, []);

  useEffect(() => {
    getchartdata();
  }, [graphReportchart]);

  useEffect(() => {
    getShareReportChart();
  }, [clearStateGraph]);

  useEffect(() => {
    getShareListData();
  }, [clearStateList]);

  const getchartdata = () => {
    graphReportchart?.map((val) => {
      chartdata.push(val.total_shared_count);
    });
    setshowchart(chartdata);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      grow: 6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row.tbl_user.firstName ? capital(row?.tbl_user?.firstName.trim()) : "--"}{" "}
              {row.tbl_user.lastName ? capital(row?.tbl_user?.lastName.trim()) : ""}{" "}
            </span>
            <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Shared Date",
      selector: (row) => "",
      grow: 4,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {row?.shared_date ? moment(row?.shared_date).format("MM-DD-YYYY") : "--"} <br />
          </div>
        </>
      ),
    },

    {
      name: "Total Shared",
      selector: (row) => "",

      grow: 4,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.total_shared_count ? row.total_shared_count : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Share Details",
      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewShareDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];
  
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);

  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelect(!displayvalueSelect);
      selectRef.current.focus();
    }
  };

  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
      //setDatePickerShow1(!datepickershow1);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Share Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Share Report</h3>

        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Shared Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="shared-report">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table>
                            <tr className="border-bottom">
                              <th>#</th>
                              <th width="30%">Time of Share</th>
                              <th width="40%">Resource Title </th>
                              <th width="30%" colspan="3">
                                {" "}
                                Shared with
                              </th>
                            </tr>
                            {customerShareData &&
                              customerShareData.map((val, i) => {
                                return (
                                  <tr className="border-bottom">
                                    <td>{i + 1}</td>
                                    <td>{moment(val?.created_at).format("hh:mm a")}</td>
                                    <td>{val?.tbl_user_resource.resource_title}</td>
                                    <td>{val?.shared_with.indexOf("1") > 0 ? "Matched Community " : ""}</td>
                                    <td>{val?.shared_with.indexOf("2") > 0 ? "Friends " : ""}</td>
                                    <td>{val?.shared_with.indexOf("3") > 0 ? "Groups " : ""}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row pb-2">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userNameUsers}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectGraph}
                                onChange={HandleUserName}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0"> &nbsp;</label>
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              View{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relativeClass">
                      <div className="p-5">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square">
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: "line",
                              },
                              title: {
                                text: "Monthly Share Chart",
                                style: { color: "#003C71", fontSize: "25px" },
                              },

                              xAxis: {
                                categories: [
                                  "Jan",
                                  "Feb",
                                  "Mar",
                                  "Apr",
                                  "May",
                                  "Jun",
                                  "Jul",
                                  "Aug",
                                  "Sep",
                                  "Oct",
                                  "Nov",
                                  "Dec",
                                ],
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return this.y != 0 ? this.y : "";
                                    },
                                  },
                                  enableMouseTracking: true,
                                },
                              },
                              credits: {
                                enabled: false,
                              },

                              yAxis: {
                                title: {
                                  text: "No. of Shares",
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              series: [
                                {
                                  name: "Month",
                                  data: showchart,
                                  color: "rgb(38, 84, 114)",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="d-flex  justify-content-end">
                          <select
                            value={sortvalue}
                            className="form-select"
                            style={{ width: "190px" }}
                            onChange={HandleSort}
                          >
                            <option value="">Sort By</option>
                            <option value="ASCDATE">Date Ascending</option>
                            <option value="DESCDATE">Date Descending</option>
                            <option value="ASCCOUNT">Total Share Ascending</option>
                            <option value="DESCCOUNT">Total Share Descending</option>
                          </select>

                          <div>
                            <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                          </div>
                          <div>
                            <select
                              value={limitvalue}
                              className="form-select"
                              style={{ width: "85px" }}
                              onChange={HandleLimitValue}
                            >
                              {/* <option value="5">5</option> */}
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em>
                              <span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row relativewithzindex">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userId}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelect}
                                onChange={HandleUserNameList}
                                ref={selectRef}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                className="form-control date-picker"
                                open={datepickershow}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>

                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>
                      <div className="d-flex flex-row" style={{ gap: "10px" }}>
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" type="submit" onClick={handlesearchList}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 == true ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {shareListData && shareListData?.length > 0 ? (
                        <div>
                          <div className="color">
                            <ReactDataTable data={shareListData} columns={columns} className="nk-tb-list" />
                          </div>
                        </div>
                      ) : (
                        <p className="p-4" style={{ minHeight: "100px" }}>
                          No Data Found
                        </p>
                      )}
                    </div>

                    <div className="p-4">
                      {shareListData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default CustomerShareReport;
