import React, { useState,useEffect } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import { CardTitle } from "reactstrap";
import { makePostRequest ,getHeaders} from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import { Link } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import {BlockHead, BlockHeadContent,Row,Col,Button,PreviewCard,ReactDataTable} from "../../../../components/Component";


const UnSubscribeRate = () => {
 
 
    const [unsubscribedatareport,setUnsubscribedatareport] =useState([]);
  
  
    const GetAllQuickDashboardTodayData = () => {
    //   let data={
    //      "selected_date":"2023-09-4",
    //     // "year":"2023"
    //   };
      makePostRequest(URLS.GET_CUSTOMER_UNSUBSCRIBED_REPORTS, null, null, null)
      .then((res) => {
        
        if (res.code === 200) {
          console.log(res, "retention  data");
          setUnsubscribedatareport(res?.data?.subscribe_data);
        
         
        } else {
       
        }
      })
      .catch((error) => {
        console.log(error);
      });
    };
  
    useEffect(() => {
      GetAllQuickDashboardTodayData();
    }, []);
  
    const columns = [
      {
        name: '#',
        selector: row => "",
        grow:1,
        minWidth: "50px",
        style: {minWidth: "50px" },
        borderRadius: "4px",
        cell:(row,index) => (
            <>
            {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
            <div className="user-card mt-2 mb-2">{index + 1}</div>
           </>
          )
      },
  
      {
        name: "Total Members",
        selector: (row) => "",
        grow: 5,
        minWidth: "170px",
        style: { minWidth: "170px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
           
            <div className="user-card mt-2 mb-2">
  
            <div className="user-info">
          <span className="tb-lead"> {row.total_members} </span>
         
          </div>
              
            </div>
          </>
        ),
      },
      {
        name: "Status",
        
        grow: 5,
         minWidth: "140px",
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2"> 
            <div className="user-info">
         
          <span className="sub-text">{row.status }</span> 
          </div>
          </div>
        ),
      }, 
    ]
  
  
  return (
    <React.Fragment>
    <Content>
  <CardTitle>
               <h6 className="title">
                 <span className="me-0"> UnSubscribe Details </span>{" "}
               </h6>
             </CardTitle> 
  <>
          <div className="card-inners relativeClass">
            <BlockHeadContent>
              <BlockHead size="lg">
                {unsubscribedatareport&&unsubscribedatareport?.length>0?  (
              <div>
              
                  <div className="color">
                  <ReactDataTable
                    data={unsubscribedatareport}
                    columns={columns}
                    className="nk-tb-list"
                />
                </div>
                </div>
                ):<p>No Data Found</p>}
  
                {/* {unsubscribedatareport?.length > 0 ? 
  <div>
    <ReactPaginate
     previousLabel={"<<"}
     nextLabel={">>"}
    pageCount={count}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
       onPageChange={handlePageChange}
      containerClassName={'pagination'}
      pageClassName={"page-item"}
      activeClassName={'active'}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
    />
   </div> 
   :""}  */}
       </BlockHead>
            </BlockHeadContent>
          </div>
      </>  
    </Content>
  </React.Fragment>
  

    );
  
  
  //   return (
  //     <React.Fragment>
  
  
  //        <div className="card-inner">
  //         <div className="card-title-group">
  //           <CardTitle>
  //             <h6 className="title">
  //               <span className="me-2"> UnSubscribe Details</span>{" "}
                
  //             </h6>
  //           </CardTitle>
    
  //         </div>
  //       </div>
  //       <DataTableBody className="border-top" bodyclass="nk-tb-orders">
  //         <DataTableHead>
  //           <DataTableRow>
  //           {/* <div dangerouslySetInnerHTML={{ __html: "Booking Date</br>Schedule Type" }} /> */}
  //             <span>Total Members</span>
  //           </DataTableRow>
  //           <DataTableRow size="sm">
  //             <span>Status</span>
  //           </DataTableRow>
  //           <DataTableRow size="md">
              
  //           </DataTableRow>
  //           {/* <DataTableRow size="lg">
  //             <span>View All</span>
  //           </DataTableRow> */}
    
  //         </DataTableHead>
  //         { unsubscribedatareport.length == 0 ? <div class="sc-fLcnxK dApqnJ">
	// 								<div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
	// 									<div class="sc-iveFHk bzRnkJ">
	// 										<div class="p-2">No Data Found</div>
	// 										</div>
	// 										</div>
	// 										</div>
  //         :
  //         unsubscribedatareport.map((item, idx) => {
  //           return (
  //             <DataTableItem key={idx}>
  //               <DataTableRow>
  //                 <span className="tb-lead">
                  
  //                <span className="sub-text">{item.total_members}</span>

                  
  //                 </span>
  //               </DataTableRow>
  //                <DataTableRow size="sm">
  //                 <div className="user-card">
                   
  //                   <div className="user-name">
  //                     <span className="sub-text"> <span className="tb-lead">{item.status}</span></span>
  
  //                   </div>
  //                 </div>
  //               </DataTableRow>
  //             <DataTableRow size="md">
             
  //           <span className="sub-text">{""}</span>
          
  //               </DataTableRow>
  //               {/* <DataTableRow size="lg">
  //               <div className="user-card mt-2 mb-2">

	// 					<Link to={`${process.env.PUBLIC_URL}/mentor-list`}>  <button className="dropdown-item btn btn-md" >
	//    <em className="icon ni ni-eye"></em>
	//  </button>
	//  </Link>
			   
	// 			   </div>
  //               </DataTableRow> */}
                
  //             </DataTableItem>
  //           );
  //         })} 
  //         {/* <h6 className="title">
  //               <span className="me-2">UnSubscribe Rate</span>
  //               {(unsubscribedatareport[1]?.total_members/(unsubscribedatareport[0]?.total_members+unsubscribedatareport[1]?.total_members)*100).toFixed(2)}% 

                
  //             </h6> */}
  //       </DataTableBody>
  //     </React.Fragment>
  //   );
  };
export default UnSubscribeRate;