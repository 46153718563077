import React from "react";
import Head from "../../../layout/head/Head";
import { Card, Badge } from "reactstrap";
import { useState,useEffect } from 'react';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button
} from "../../../components/Component";
import {  Label, Input, Row, Col } from "reactstrap";
import { URLS } from "../../../constants/urls";
import { makePostRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from "react-loader-spinner";


const UserProfileSettingPage = ({ sm, updateSm }) => {
	
	
	const [oldPassword, setoldPassword] = useState('');
    const [newPassword, setnewPassword] = useState('');
	const [confirmPassword, setconfirmPassword] = useState('');
	
	const [selectcurrentUser,setCurrentUserAuthData]=useState([]);
	
	const [erroroldPassword, setoldPasswordError] = useState('');
    const [errornewPassword, setnewPasswordError] = useState('');
	const [errorconfirmPassword, setconfirmPasswordError] = useState('');
   
    const [loader, setLoader] = useState(false);
	
	const [passicon, setpassicon] = useState(false);
	const [passiconnewpass, setpassiconnewpass] = useState(false);
	const [passiconconfirmpass, setpassiconconfirmpass] = useState(false);
	
  
     const handleoldPassword = (e) => {
		 setoldPassword(e.target.value);
		 setoldPasswordError('');
	 }
	const handlenewPassword = (e) => {
		  setnewPassword(e.target.value);
		  setnewPasswordError('');
	 } 
	 const handleconfirmPassword = (e) => {
		 setconfirmPassword(e.target.value);
		 setconfirmPasswordError('');
	 }
    
     const showicon = () => {
		setpassicon(!passicon)
	}
	const showicoNewpass = () => {
		setpassiconnewpass(!passiconnewpass);
	}
	const showiconConfirmPass = () => {
		setpassiconconfirmpass(!passiconconfirmpass);
	}
    
    
  
  const handleupdatePassword =()=> {
	  
	  if (oldPassword === "") {
		   setoldPasswordError("Please enter old password.");
		}
		else if (newPassword === "") {
		   setnewPasswordError("Please enter new password.");
		}
		else if (newPassword < 8 ) {
		   setnewPasswordError("Please enter a password between 8 and 20 characters long.");
		}
		else if ( newPassword.length > 20) {
			setnewPasswordError('Please enter a password between 8 and 20 characters long.');
		}
		else if (confirmPassword === "") {
		   setconfirmPasswordError("Please enter confirm password.");
		}
    else if (newPassword!=confirmPassword) {
      setconfirmPasswordError("New and confirm password doesn't match.");
   }
		else {
			
		 let data = {
		   id:selectcurrentUser.id,
		   oldPassword:oldPassword,
			newPassword:newPassword,
			confirmPassword:confirmPassword
		 }
		
     makePostRequest(URLS.CHANGE_PASSWORD, null,data, null)
		 .then(res => {
			if(res.code === 200){
				setLoader(true);
				toast.success("Your password has been changed succussfully.");
				
				setTimeout(() => {
				  window.history.pushState(
					`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/auth-login"}`,
					"auth-login",
					`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/auth-login"}`
				  );
					 window.location.reload();
					}, 1000);
					
					}
					else if (res.code === 400){
					  toast.warn("Old password dosen't match.");
					}
				 }) 
      }
	}
	console.log(selectcurrentUser);
	
	useEffect(()=> {
		 let token = sessionStorage.getItem('auth-token');
		 if(token){
		  const tokenParts = token.split('.');
		  const tokenPayload = JSON.parse(atob(tokenParts[1]));
		  let {userAuth} = tokenPayload;
		  
		  if(userAuth && userAuth.id && userAuth.name && userAuth.email){
		   setCurrentUserAuthData(userAuth);
		  }
   
     }
		
	},[])
	
	
	
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <ToastContainer />
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Change Password </BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
			  <div className="row">
			  <div className="col-lg-12">
			    
				<div className="form-group">
                  <Label htmlFor="default-0" className="form-label">
                    Old Password
                  </Label>
                  <div className="form-control-wrap">
                    <Input id="default-0" placeholder="Old Password" type={passicon == false ? "password" : "text"}  onChange={handleoldPassword} />
					<span className="error" > { erroroldPassword } </span>
						<span className="eyeIconstyles" onClick={showicon} >
						{passicon == true ?
				<i className="fa fa-eye eyes-icons" ></i> :<i className="fa fa-eye-slash eyes-icons"></i>}
					</span>
                  </div>
                </div>
				
				
			    <div className="form-group">
                  <Label htmlFor="default-0" className="form-label">
                    New Password
                  </Label>
                  <div className="form-control-wrap">
                    <Input id="default-0" placeholder="New Password" type={passiconnewpass== false? "password" : "text"} onChange={handlenewPassword}/>
					<span className="error" > { errornewPassword } </span>
						<span className="eyeIconstyles" onClick={showicoNewpass} >
						{passiconnewpass == true ?
				<i className="fa fa-eye eyes-icons" ></i> :<i className="fa fa-eye-slash eyes-icons"></i>}
					</span>
                  </div>
                </div>
				
				<div className="form-group">
                  <Label htmlFor="default-0" className="form-label">
                    Confirm Password
                  </Label>
                  <div className="form-control-wrap">
                    <Input id="default-0" placeholder="Confirm Password" type={passiconconfirmpass== false? "password" : "text"} onChange ={handleconfirmPassword} />
					<span className="error" > { errorconfirmPassword } </span>
						<span className="eyeIconstyles" onClick={showiconConfirmPass} >
						{passiconconfirmpass == true ?
				<i className="fa fa-eye eyes-icons" ></i> :<i className="fa fa-eye-slash eyes-icons"></i>}
					</span>
                  </div>
                </div>
				
				<Button color="primary" onClick={handleupdatePassword}>Update Password</Button>
				
			    </div>
			  </div>
			
			  {
			   loader && 
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
}
	     
            </div>
          
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;