import React, { useEffect, useState,useMemo } from "react";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { URLS } from "../../../constants/urls";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import ReactPaginate from "react-paginate";
import { getUserDetailsFromToken } from "../../../service/auth";
import {
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Col,
  Button,
  BlockTitle,
  RSelect,
  UserAvatar,
  ReactDataTable,
} from "../../../components/Component";
import Select from "react-select";
import { Card } from "reactstrap";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, Progress, DropdownItem, Form, Badge } from "reactstrap";
import {
  Block,
  BlockBetween,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
} from "../../../components/Component";
import { BASE_URL, getHeaders } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { ThreeDots } from "react-loader-spinner";
import { Label, Input } from "reactstrap";
import Switch from "react-switch";
import { colorThemes } from "../../../constants";
import { findUpper,capital } from "../../../utils/Utils";


const ManageUser = (props) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [userdata, setuserdata] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [name, setName] = useState("");
  const [email,setEmail]=useState("");
  const [phone,setPhone]=useState("");
  const [password,setpassword]=useState("");
  const [loader, setLoader] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [expressionTextError, setErrorExpressionText] = useState('');
  const [manageRoleStatusError, setmanageRoleStatusError] = useState('');
  
   const [ErrorName, setErrorName]=useState('');
   const [ErrorEmail, setEmailError]=useState('');
   const [ErrorPhone, setPhoneError]=useState('');
   const [ErrorSelectRole, setErrorSelectRole]=useState('');
   const [Errorpassword, setErrorpassword]=useState('');
  const [getaddUserdata, setGetAddUserdata] = useState([]);
  const [switchState, setSwitchState] = useState("");

  const handleswitch = (id, isSelected, index) => {
    setLoader(true);
    let data = {
      id: id,
      status: isSelected,
    };
    switchState ? setSwitchState("enable") : setSwitchState("disable");
    const newArray = Array.from(userdata);
    newArray[index].status = isSelected;
    setuserdata(newArray);

    makePostRequest(URLS.USER_UPDATE, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success("Status updated successfully.");
        getAllUsers();
        setLoader(false);
        setIsUpdate(false);
      } else {
        toast.error(res.message);
      }
    });
  };
  let Emailvalidation=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let Phonevalidation=/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  const handleNameUpdate = (e) => {
    setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
	setErrorExpressionText('');
	setErrorName('');
	
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.name = str;
    setSelectedUser({ ...obj });
  };
  const handlePasswordUpdate = (e) => {
    setpassword(e.target.value);
    setErrorpassword("")
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.password = str;
    setSelectedUser({ ...obj });
  };

  const handlePhoneUpdate = (e) => {
	setPhone(e.target.value);  
	setPhoneError('');  
	
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.phone = str;
    setSelectedUser({ ...obj });
  };

  const handleEmailUpdate = (e) => {
    setEmail(e.target.value);
	setEmailError('');
    let obj = { ...selectedUser };
    let str = e.target.value;
    obj.email = str;
    setSelectedUser({ ...obj });
  };

  const handleRoleSelection = (e) => {
	 //setSelectedRole(e.target.value); 
	 setErrorSelectRole('')
    //console.log("handleRoleSelection = = = = = \n", e);
    setSelectedRole({ ...e });
    setmanageRoleStatusError('');
  };
  useEffect(() => {
    getAllUsers();
  }, []);
 
  let char=(/^[a-zA-Z\s]+$/); 
  const AdduserHandler = () => {
    if (name == "") {
      setErrorName("Please enter name");
    } 
	
	else if (email === "") {
		   setEmailError("Please enter your email id.");
		}
    
		else if(!Emailvalidation.test(email))
		{
			setEmailError("The email address you entered is not valid")
		}
		else if (phone === "") {
		   setPhoneError("Please enter your phone no.");
		}
		else if (phone.length < 10 || phone.length >12) {
			setPhoneError('Please enter a vaild mobile number between 10 and 12 digit long.');
		}
    else if (selectedRole.value === "") {
      setErrorSelectRole("Please select role.");
   }
   else if (password === "") {
    setErrorpassword("Please enter password.");
 }
	    else if(!name.match(char)){
			setErrorExpressionText("Please enter character only.")
		    }	
    else {
      AddUsers();
    }
  };
console.log("auth",);
  const updateRoleHandler = () => {
		
		if (selectedUser.name == "") {
      setErrorName("Please enter name");
    } 
	
	else if (selectedUser.email === "") {
		   setEmailError("Please enter your email id.");
		}
    
		else if(!Emailvalidation.test(selectedUser.email))
		{
			setEmailError("The email address you entered is not valid")
		}
		else if (selectedUser.phone === "") {
		   setPhoneError("Please enter your phone no.");
		}
		else if (selectedUser.phone.length < 10 || selectedUser.phone.length >12) {
			setPhoneError('Please enter a vaild mobile number between 10 and 12 digit long.');
		}
    else if (selectedRole.value === "") {
      setErrorSelectRole("Please select role.");
   }

	    else if(!selectedUser.name.match(char)){
			setErrorExpressionText("Please enter character only.")
		    }	
    else if (isUpdate) {
      handleupdateUser();
      // getAllUsers();
    }
	}


  const getAllUsers = () => {
    let data = {
			"page":currentPage,
			"limit":10
		}
    makePostRequest(URLS.USER_GET_ALL, null,data, null).then((res) => {
      console.log("getAllUsers res =  = = = = =\n", res);
      if (res.code === 200) {
        setuserdata(res.data.data);
        setCount(Math.ceil(res.data.count / parseInt(10)))
        let obj = {
          email: "",
          name: "",
          phone: "",
          role_id: "",
          password: "",
        };
        setSelectedRole({ value: "", label: "" });
        setSelectedUser(obj);
      } else {
        toast.warn("Unable to get user data");
      }
    });
  };

  /*======================pagination code here =============================*/


 const [currentPage, setCurrentPage] = useState(1);
 const [count, setCount] = useState();

 const handlePageChange = (selectedPage) => {
   setCurrentPage(selectedPage.selected + 1)
   };
   const fetchData = () => {
   let data = {
     "page":currentPage,
     "limit":10
   }
   makePostRequest(URLS.USER_GET_ALL, null,data, null)
     .then((res) => {
     if (res.code == 200) {
      setuserdata(res.data.data);
       setCount(Math.ceil(res.data.count / parseInt(10)))
     }
     })
     .catch((error) => {
     console.log(error);
     });
   };
 
   useEffect(() => {
   fetchData()
   }, [currentPage])
 



  const handleEditButton = (val) => {
    setErrorName('');
    setPhoneError('');  
    setErrorpassword("");
    setEmailError('');
    setmanageRoleStatusError('');
    
    setIsUpdate(true);
    window.scroll({
      top: 0,
      scroll: "smooth",
    });
    let obj = { id: val.id, name: val.name, phone: val.phone, email: val.email };
    let selectedRole = val.tbl_role;
    let dump = {
      value: selectedRole.id,
      label: selectedRole?.role_name,
    };
    setSelectedRole(dump);
    setSelectedUser(obj);
  };
  
 
  const AddUsers = () => {
    let valid = 0;
    allRoles.map((val, i)=>{
      if(val.value == selectedRole.value && val.status === "disable"){
        setmanageRoleStatusError("This role is disabled either choose another role or enable this role first");
        valid++;
        
      }
     
   
       })
   if(valid===0){

  
    let data = {
      email: selectedUser.email,
      name: capital(selectedUser.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "")),
      phone: selectedUser.phone,
      role_id: selectedRole.value,
      password: selectedUser.password,
    
    };
    //console.log('Test', data);
    
    makePostRequest(URLS.ADD_USER, null, data, null).then((res) => {
      if (res.code === 201) {
        getAllUsers();
        setSelectedRole({ value: "", label: "" });
        setIsUpdate(false);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  }


  };


  const handleupdateUser = () => {
    let valid = 0;
    allRoles.map((val, i)=>{
      if(val.value == selectedRole.value && val.status === "disable"){
        setmanageRoleStatusError("This role is disabled either choose another role or enable this role first");
        valid++;
        
      }
     
   
       })
   if(valid===0){
    let data = {
      id: selectedUser.id,
      email: selectedUser.email,
      name: selectedUser.name,
      phone: selectedUser.phone,
      role_id: selectedRole.value,
    };
console.log(data,"vv")
    makePostRequest(URLS.USER_UPDATE, null, data, null).then((res) => {
      console.log(res);
      if (res.code == 200) {
        toast.success(res.message);
        getAllUsers();
        setIsUpdate(false);
      } else {
        toast.error(res.message);
      }
    });
  }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = () => {
    setLoader(true);
    makeGetRequest(URLS.USER_GET_ALL_ROLE, {}, null)
      .then((res) => {
        if (res.code == 200) {
         // console.log(res);
         // let rolesDataArr = [];
// console.log(res,"roledata")
          let rolesData = res?.data?.map((role) => {
           // let obj = {};
            // if (role.status === "enable") {
            //   obj = { value: role.id, label: role.role_name };
            //   rolesDataArr.push(obj);
            // }
            return {
               value: role.id,
               label: role?.role_name,
               status:role.status==="enable",
             };
          });
          // console.log(rolesData,"selectdata")
        let rdata= rolesData.filter((val)=>(val.status===true))
          setAllRoles(rdata);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const columns = useMemo(()=> { return[
    {
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(10))+(index + 1)} </div>

			   </>
			)
		},
    {
      name: "Title",
      selector: (row) => row.name,
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
        { /*  <UserAvatar
            theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
            text={findUpper(capital(row.name))}
		></UserAvatar> */ }
          <div className="user-info">
            <span className="tb-lead">{row.name} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row?.tbl_role?.role_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row, index) => (
        <>
          {row.status == "enable" ? (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                onClick={() => handleswitch(row.id, "disable", index)}
                checked
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          ) : (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                onChange={() => handleswitch(row.id, "enable", index)}
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item"
            onClick={() => {
              handleEditButton(row);
            }}
          >
            <Icon name="edit"></Icon>
          </button>
        </div>
      ),
    },
  ]},[userdata]);
  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Manage Users</h3>
        {/* <BlockTitle tag="h4">Manage User</BlockTitle> */}
        <Card className="card-bordered">
          <div className="card-inner">
            <BlockHead size="lg">
              <BlockHeadContent>
                {/* <BlockTitle tag="h4">Update User</BlockTitle> */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Label className="form-label">Name</Label>
                      <div className="form-control-wrap">
                        <Input
                          name="name"
                          placeholder="Name"
                          type="text"
                          value={selectedUser.name}
                          onChange={handleNameUpdate}
                        />
						<span className="error"> {ErrorName} </span> 
						<span className="error"> {expressionTextError} </span>	
                      </div>
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Phone No.</Label>
                      <div className="form-control-wrap">
                        <Input
                          placeholder="Phone No."
                          type="text"
                          value={selectedUser.phone}
                          onChange={handlePhoneUpdate}
                        />
						<span className="error"> {ErrorPhone} </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Label className="form-label">Email</Label>
                      <div className="form-control-wrap">
                        <Input
                          placeholder="Email"
                          type="text"
                          value={selectedUser.email}
                          onChange={handleEmailUpdate}
                        />
						<span className="error"> {ErrorEmail} </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Role Name</Label>
                      {isUpdate ? (
                        <div className="form-control-wrap">
                          <Select
                            options={allRoles}
                            placeholder={"Select Role"}
                            onChange={handleRoleSelection}
                            value={selectedRole}
                          />
                        </div>
                      ) : (
                        <div className="form-control-wrap">
                          <Select options={allRoles} placeholder={"Select Role"} onChange={handleRoleSelection}  value={selectedRole}/>
                        
                        </div>
                      )}
					  <span className="error"> {ErrorSelectRole} </span>
                       <span className="error"> {manageRoleStatusError} </span>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-2">
                    <div className="form-group">
                      <Label className="form-label">Password</Label>
                      <div className="form-control-wrap">
                        <Input
                          placeholder="Enter Password"
                          type="password"
                          value={selectedUser.password}
                          onChange={handlePasswordUpdate}
                          disabled={isUpdate}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="error"> {Errorpassword} </span>
                  <div className="col-lg-6 mt-4 mb-4">
                    {isUpdate === false ? (
                      <ul className="align-center flex-sm-nowrap pt-1 float-start mt-2">
                        <li>
                          <button className="btn btn-primary btn-md" type="submit" onClick={AdduserHandler}>
                            Add{" "}
                          </button>
                        </li>
                      </ul>
                    ) : (
                      <ul className="align-center flex-sm-nowrap pt-1 px-3 float-start mt-2">
                        <li>
                          <button className="btn btn-primary btn-md" type="submit" onClick={updateRoleHandler}>
                            Update{" "}
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                  </div>
                </div>

                {isUpdate===false && 
                <div>
                    {/* <h4 className="nk-block-title page-title mb5 ">Manage All Users</h4> */}

                   
                    { loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square">
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ):(
               
                <div className="outerBox">

                    {  userdata && userdata.length && (
                      <ReactDataTable data={userdata} columns={columns} searchable  className="nk-tb-list" />
                    )}
				</div>
					
				  )}	</div>}
					
          <div className="">
                    {userdata?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>
                      : ""}
                      </div>

               
              </BlockHeadContent>
            </BlockHead>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};
export default ManageUser;
