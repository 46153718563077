import React, { useState,useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar
} from "../../../components/Component";

import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { BASE_URL, getHeaders, makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import axios from 'axios';
import { S3_BUCKET_URL } from "../../../constants";
import bnb from '../../../images/coins/bnb.svg';
import Switch from "react-switch";
import { findUpper,capital } from "../../../utils/Utils";

function Image() {


	
	const [name, setName] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [errorfile, setErrorFile] = useState('');
	const [masterdata, setMasterdata] = useState([]);
	const [loader, setLoader] = useState(false);
	const [selectedData, setSelectedData] = useState({});
    const [expressionTextError, setErrorExpressionText] = useState('');

	
	const columns =[
		{
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {index+1} </div>

			   </>
			)
		},
		
		{
			name: 'Image',
			selector: row => row.image,
			grow: 2,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
				<div className="user-info">
						<span className="tb-lead">
						 <img style={{width:"50px", display:"block"}} src={row.image}  alt="" /> 
						{/* {S3_BUCKET_URL+row.image} */}
						</span>
					</div>
				</div>
			)
		},
{
			name: 'Short Description',
			selector: row => row.name,
			grow: 3,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<div className="user-info">
						<span className="sub-text">
							{capital(row.short_description.replaceAll('_',' '))}{" "}
						</span>
					</div>
				</div>
			)
		},
		{
			name: 'URL',
			selector: row => row.name,
			grow: 3,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
	
					<div className="user-info">
						<span className="sub-text">
							{/* {row.url}{" "} */}
							<a href={row.url}  target="_blank">{row.url}</a>
						</span>
					</div>
				</div>
			)
		},
		
		{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	];

	const handleUrl = (e) => {
		setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedData };
		let str = e.target.value;
		obj.url = str;
		setSelectedData({ ...obj })
	}

	const handledescription=(e)=>{	
    let obj= {...selectedData};
    let str = e.target.value;
    obj.description =str;
    setSelectedData({ ...obj})
	}

	const updateMasterImage=()=>{
		if (isUpdate) {
			handleUpdateImage();	
			getMasterImage();
			handleFileUpload();
			setSelectedData({selectedData, name:""});
				setName("")
				let obj = {name:'',image:''}
				setSelectedData({...obj})
				setFile("");
		}
	}

	const  formData = new FormData();
	const [file, setFile] = useState(null);

	function handleFileUpload  (event)  {
	
	  setFile((URL.createObjectURL(event.target.files[0])));
	  var file1=event.target.files[0];
	  formData.append("id", selectedData.id);
	  formData.append("image", file1);
	//   console.log(formData,"formdata")
		axios.post( BASE_URL + URLS.SIDEBARIMAGE, formData, {
		headers: getHeaders({"Content-Type": "multipart/form-data"})})
		.then(res => 
		  {
	  if (res.status == 200) {
		toast.success(res.message);
		// toast.success("Image update successfully.");
		// setTimeout(() => { window.location.reload(); }, 6000);
		
	  } else {
		
	  }
	})
	}
	

		 useEffect(() => {
			getMasterImage();
		}, []);
	
		const getMasterImage = () => {
			setLoader(true);
			makeGetRequest(URLS.GET_ALLMASTERIMAGE, {}, null)
				.then(res => {
					if (res.code == 200) {
						setMasterdata(res.data);
					   setLoader(false);
					//    console.log(res.data)
					}
				})
				.catch((error) => {
					console.log(error);
				})
	
		}	

	const handleEditButton = (val) => {

		setIsUpdate(true);
		let obj = {id:val.id, description:val.short_description, url:val.url, image:val.image} 
		setSelectedData(obj);
		let img = val.image ? val.image : "";
		setFile(img)
         window.scroll({
			top:0,
			behavior:"smooth"
		})	
		// console.log(img,"img")	
	}


const handleUpdateImage = () => {
	let data = {
        id: selectedData.id,
        url:selectedData.url,
        short_description:selectedData.description,
    }
	makePostRequest(URLS.UPDATE_MASTER_IMAGE, null, data, null)
		.then(res => {
			if(res.code==200){
				
				toast.success(res.message);					
				 setLoader(false);					 
				setIsUpdate(false);
				setSelectedData({selectedData, name:""});
				setName("")
				let obj = {name:'',image:''}
				setSelectedData({...obj});
				setFile("")
				getMasterImage();
			}
			else if(res.code==400){
				setLoader(false);
			   toast.error(res.message);
			}	
			
		})
} 
	return (
		<React.Fragment>
			<Head title="Sidebar Image"></Head>
			<Content>
				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Sidebar Image</h3>
				{/* <BlockTitle tag="h4">Sidebar Image</BlockTitle>  */}
				{ loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ):(
			  <Card className="card-bordered">	
				<div className="card-inner-lg">
					<BlockHeadContent>			
						<BlockHead size="lg">
						{isUpdate && 
							<div className="pb-5">
								<div className="row">
								   <div className="col-lg-4">
									   <div className="form-group">
										<Label className="form-label">
									Short Description
										</Label>
										 <div className="form-control-wrap">
											{/* <Input name="name" placeholder="Short Description" type="text" value={selectedData.description} onChange={handledescription} />
											<span className="error"> {errorname} </span>
                    						<span className="error"> {expressionTextError} </span>			 */}
									   
										 <textarea
					style={{width: "100%",
						height: "35px",
						border: "1px solid lightgray",
						borderRadius:"4px",
					}}		
					maxlength="30"			 
                    name="Short Description"
                    value={selectedData.description}
                    onChange={handledescription}

                  ></textarea>
				    </div>
										</div> 
										</div> 
										<div className="col-lg-4">
									   <div className="form-group">
										<Label className="form-label">
									URL
										</Label>
                                     
										<div className="form-control-wrap">
											<Input name="name" placeholder="URL" type="text" value={selectedData.url} onChange={handleUrl} />
											<span className="error"> {errorname} </span>
                    						<span className="error"> {expressionTextError} </span>			
									     </div>

										</div> 
										</div> 

			                           <div className="col-lg-4">

<div className="form-group">
                  <Label className="form-label">
                    Image Upload
                  </Label>
                  <div className="form-control-wrap">
				  
					  <div className="user-card-img">
						  <div className="profileImgBox float-start">
							{file==null || file=="" ?  <img src={bnb} alt="img" /> :
						   <img src={file} alt="img" />}
						 </div>
								
		<input className="float-start mx-2 pt-2" type="file" onChange={handleFileUpload} accept="image/png, image/jpeg ,image/gif" />
						<div className="clearfix"></div>							 
					    <span className="error"> {errorfile} </span>
					  </div>
			
                  </div>
                </div>
									</div>
									
									<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={updateMasterImage}>Update </button></li>
                           
									</ul>
                                  
	                        
								</div>
								

							</div>
}
         
                     {isUpdate===false&&
				     <div className="outerBox">
						 {/* {masterdata.length && */}
								<ReactDataTable
									data={masterdata}
									columns={columns}
									className="nk-tb-list"
								/>
							{/* } */}
                      </div>}
	      
						</BlockHead>
					</BlockHeadContent>
				</div>
				</Card>
		  )}
			</Content>

		</React.Fragment>
	);
};

export default Image;
