import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
  ReactDataTable,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { makePostRequest } from "../../../service/api";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { toast } from "react-toastify";
exporting(Highcharts);

function CustomerReport() {
  const ExcelJS = require("exceljs");
  const [customeruserreportchart, setCustomeruserreportchart] = useState([]);
  const [customeruserreport, setCustomeruserreport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [zipcodechart, setZipcodechart] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [ziperror, setZipError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  const [showchart, setshowchart] = useState([]);
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  let chartdata = [];

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const handleZipCodechart = (e) => {
    setZipcodechart(e.target.value);
    setZipError("");
  };
  const handleZipCode = (e) => {
    setZipcode(e.target.value);
  };
  const handlechart = (e) => {
    if (zipcodechart == "") {
    }
    getcustomeruserreportchart();
  };

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    setSelectyear(new Date().getFullYear());
    setZipcodechart("");
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setZipcode("");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setCurrentPage(1);
    setlimitvalue(10);
    setClearStateList(!clearStateList);
  };
  const handlesearch = (e) => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      setCurrentPage(1);
      getUserDataForList();
    }
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
  };

  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? selectedfromdate : "2023-01-01",
      end_date: selectedtodate ? selectedtodate : moment(new Date()).format("YYYY-MM-DD"),
      zipcode: zipcode ? zipcode : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      export_data: "yes",
    };
    makePostRequest(URLS.GET_USERDATA_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          console.log("login export", res.data.user_data);
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("CustomerData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Register Date",
              key: "REGISTERDATE",
              width: 30,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },

            {
              header: "Zip Code",
              key: "ZIPCODE",
              width: 20,
            },
          ];
          res.data.user_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTERDATE: moment(val.created_at).format("YYYY-MM-DD"),
              USER_NAME: val?.firstName ? val.firstName : "Guest" + " " + val.lastName ? val.lastName : "",
              OWNEREMAIL: val?.email,
              OWNWERPHONE: val?.phone,
              ZIPCODE: val?.zipcode,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "CustomerReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomeruserreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      zipcode: zipcodechart ? zipcodechart : "",
    };

    makePostRequest(URLS.GET_USERDATA_GRAPH, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setCustomeruserreportchart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*========================================user list api =================================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getUserDataForList = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
      zipcode: zipcode ? zipcode : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
    };
    makePostRequest(URLS.GET_USERDATA_LIST, null, data, null).then((res) => {
      if (res.code === 200) {
        setCustomeruserreport(res?.data?.user_data);
        setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
        setLoader1(false);
      } else {
        setLoader1(false);
      }
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    getUserDataForList();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getcustomeruserreportchart();
    getUserDataForList();
  }, []);

  useEffect(() => {
    getcustomeruserreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getUserDataForList();
  }, [clearStateList]);

  useEffect(() => {
    getchartdata();
  }, [customeruserreportchart]);

  const getchartdata = () => {
    customeruserreportchart?.map((val) => {
      chartdata.push(val.total_user);
    });
    setshowchart(chartdata);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "70px",
      style: { minWidth: "70px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2" key={index + 1}>
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row.firstName,
      grow: 2,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info" key={index}>
              {row.firstName ? row.firstName : "Guest"} {row.lastName ? row.lastName : ""}{" "}
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Register Date",
      grow: 2,
      sortable: true,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2" key={index}>
          {" "}
          {row.created_at ? moment(row.created_at).format("MM-DD-YYYY") : "--"}
          <br />
        </div>
      ),
    },

    {
      name: "Phone",
      grow: 2,
      sortable: true,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2" key={index}>
          {" "}
          {row.phone ? row.phone : "--"}
          <br />
        </div>
      ),
    },
    {
      name: "Email",
      grow: 3,
      sortable: true,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2" key={index}>
          {" "}
          {row.email ? row.email : "--"}
          <br />
        </div>
      ),
    },
    {
      name: "Zip Code",
      grow: 1,
      sortable: true,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2" key={index}>
          {" "}
          {row.zipcode ? row.zipcode : "--"}
        </div>
      ),
    },
  ];

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Customer Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Report</h3>
        <>
          <Card className="card-bordered">
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>
                            <div className="form-control-wrap">
                              <select className="form-control" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label className="lavelinfo">
                            <strong>Zip Code</strong>
                          </label>
                          <div className="form-control-wrap">
                            <input
                              placeholder="Zipcode"
                              type="text"
                              className="form-control pl-2"
                              value={zipcodechart}
                              onChange={handleZipCodechart}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label> &nbsp; </label>
                            <div className="form-control-wrap">
                              <button className="btn btn-primary" type="submit" onClick={handlechart}>
                                {" "}
                                View{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <span className="error">{ziperror}</span>
                      </div>
                    </div>

                    <div className="p-5">
                      <div className="row">
                        <div className="col-lg-6">{/* <h5>Monthly User Chart </h5> */}</div>
                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square">
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: "line",
                              },
                              tooltip: {
                                shared: true,
                                split: false,
                                enabled: true,
                              },
                              title: {
                                text: "Monthly Customer Chart",
                                style: { color: "#003C71", fontSize: "25px" },
                              },

                              xAxis: {
                                categories: [
                                  "Jan",
                                  "Feb",
                                  "Mar",
                                  "Apr",
                                  "May",
                                  "Jun",
                                  "Jul",
                                  "Aug",
                                  "Sep",
                                  "Oct",
                                  "Nov",
                                  "Dec",
                                ],
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              credits: {
                                enabled: false,
                              },
                              exporting: {
                                enabled: true,
                              },

                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return this.y != 0 ? this.y : "";
                                    },
                                  },
                                  enableMouseTracking: true,
                                },
                              },

                              yAxis: {
                                title: {
                                  text: "Number of Customers",
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              series: [
                                {
                                  name: "Month",
                                  data: showchart,
                                  color: "rgb(38, 84, 114)",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="row">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="filtersidebutton">
                            <h3 className="graphheading mb-0 mt-1">List View</h3>
                            <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                              Clear Filters
                            </button>
                          </div>

                          <div className="row">
                            <div className="col-lg-12 d-flex  justify-content-end">
                              <div>
                                <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                              </div>
                              <div>
                                <select
                                  value={limitvalue}
                                  className="form-select"
                                  style={{ width: "85px" }}
                                  onChange={HandleLimitValue}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                              <div>
                                &nbsp; &nbsp;
                                <button className="btn btn-primary " onClick={ExportEcelFile}>
                                  <em className="icon ni ni-download-cloud"></em>
                                  <span>Export</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Start Date</strong>
                              </label>

                              <div className="form-control-wrap" ref={newRef}>
                                <div className="form-icon form-icon-left">
                                  <span onClick={() => handleclickdatepickericon()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate}
                                  onChange={HandleFromDate}
                                  onFocus={() => setDatePickerShow(!datepickershow)}
                                  className="form-control date-picker"
                                  open={datepickershow}
                                />
                              </div>
                            </div>
                            <span className="error">{formDateError}</span>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>End Date</strong>
                              </label>

                              <div className="form-control-wrap" ref={newRef1}>
                                <div className="form-icon form-icon-left">
                                  <span onClick={() => handleclickdatepickericon1()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate}
                                  onChange={HandleToDate}
                                  onFocus={() => setDatePickerShow1(!datepickershow1)}
                                  className="form-control date-picker"
                                  open={datepickershow1}
                                />
                              </div>
                            </div>
                            <span className="error">{toDateError}</span>
                          </div>
                          <div className="col-md-4">
                            <label className="lavelinfo">
                              <strong>Zip Code</strong>
                            </label>
                            <div className="form-control-text">
                              <input
                                placeholder="zipcode"
                                type="text"
                                className="form-control pl-2"
                                value={zipcode}
                                onChange={handleZipCode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-lg-3 align-self-end">
                          <div className="outerTypeBox mb-3">
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {customeruserreport?.length > 0 ? (
                        <div className="color">
                          <ReactDataTable
                            data={customeruserreport}
                            columns={columns}
                            className="nk-tb-list"
                          />
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="p-4">
                      {customeruserreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        " No Data Found"
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
      </Content>
    </React.Fragment>
  );
}
export default CustomerReport;
