import React, { useEffect, useState } from "react";
import { Worker } from "@react-pdf-viewer/core";

import { Viewer,SpecialZoomLevel } from "@react-pdf-viewer/core";
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import "@react-pdf-viewer/core/lib/styles/index.css";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";


import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = (props) => {
  let pdfurl = props.pdfurl;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
  return (
    <div
    
>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
        <Viewer fileUrl={pdfurl}  plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  );
};

export default PdfViewer;






