import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import { ReactDataTable } from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest, getHeaders } from "../../../service/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
const ExcelJS = require("exceljs");
import Select from "react-select";
import { FaFilter } from "react-icons/fa";

function CustomerList() {
  const [customerListData, setCustomerListData] = useState([]);
  const [loader, setLoader] = useState(false);
  let [userTypeData, setUserTypeData] = useState([]);
  const [show, setShow] = useState(false);
  const [usertype, setUserType] = useState("");
  const [faithbackground, setFaithBackground] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [limitvalue, setlimitvalue] = useState();
  const [sortcustomer, setSortCustomer] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [clearState, setClearState] = useState(false);
  const [displayvalueSelectFilter, setDisplayValueSelectFilter] = useState(true);
  const [switchState, setSwitchState] = useState("");
  const [status, setStatus] = useState("");
  let customerdata = JSON.parse(sessionStorage.getItem("customer"));
  const tableref = useRef(null);
  const ShowHideSelect = () => {
    setShow(!show);
  };

  const HandleUserType = (usertypeId) => {
    setUserType(usertypeId);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertypeId,
        faithback: faithbackground,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };

  let userTypeArray = [];
  if (customerdata.userType && customerdata.userType.length > 0) {
    customerdata.userType.forEach((val) => userTypeArray.push(val.value));
  }

  const HandleStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust:currentPage,
        sortvaluecust: sortcustomer,
        statuscust: e.target.value,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
      })
    );
  };
  const HandleFaithBackground = (e) => {
    setFaithBackground(e.target.value);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: e.target.value,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: e ? moment(e).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
        endDate: e ? moment(e).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);

    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: e.target.value,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
    setCurrentPage(1);
  };
  const HandleSortCustomer = (e) => {
    setSortCustomer(e.target.value);
    setCurrentPage(1);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: e.target.value,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        // pagenumbercust: currentPage,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: e.target.value,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  useEffect(() => {
    setSelectedToDate(customerdata.endDate);
    setSelectedfromDate(customerdata.startDate);
    setlimitvalue(customerdata.limitvaluecust);
    setFaithBackground(customerdata?.faithback);
    setSearch(customerdata?.Search);
    setStatus(customerdata.statuscust);
    setCurrentPage(customerdata.pagenumbercust ? customerdata.pagenumbercust : 1);
    setSortCustomer(customerdata.sortvaluecust);
    setUserType(customerdata?.userType);
  }, []);
  useEffect(() => {
    getAllCustomerListData();
  }, [sortcustomer, limitvalue, currentPage]);

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      start_date: customerdata.startDate ? moment(customerdata.startDate).format("YYYY-MM-DD") : "",
      end_date: customerdata.endDate ? moment(customerdata.endDate).format("YYYY-MM-DD") : "",
      status: status ? status : "",
      user_type: userTypeArray ? userTypeArray : "",
      faith_background: faithbackground ? faithbackground : "",
      page: currentPage,
      limit: 10,
      searchKey: search,
      key: "export",
    };
    makePostRequest(URLS.GET_ALL_CUSTOMER_EXPORT, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("CustomerData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "REGISTERED DATE",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "NAME",
              key: "NAME",
              width: 20,
            },
            {
              header: "EMAIL",
              key: "EMAIL",
              width: 30,
            },
            {
              header: "PHONE NO.",
              key: "Phone",
              width: 18,
            },
            {
              header: "NUMBER OF STUDENTS",
              key: "NUMBER_OF_CHILD",
              width: 18,
            },
            {
              header: "USER TYPE",
              key: "USER_TYPE",
              width: 18,
            },
            {
              header: "FAITH BACKGROUND",
              key: "FAITH_BACKGROUND",
              width: 18,
            },
            {
              header: "ADDRESS",
              key: "ADDRESS",
              width: 40,
            },
            {
              header: "STATUS",
              key: "STATUS",
              width: 15,
            },
            {
              header: "Ideal Home School Schedule",
              key: "ideal_home_school",
              width: 15,
            },
            {
              header: "Faith Affiliation",
              key: "faith_affiliation",
              width: 15,
            },
            {
              header: "Faith Background",
              key: "faith_background",
              width: 15,
            },
            {
              header: "child education character value",
              key: "child_education_character_value",
              width: 15,
            },
            {
              header: "About Bio",
              key: "bio_about",
              width: 15,
            },
            {
              header: "Stripe setup",
              key: "stripe_setup_done",
              width: 15,
            },
          ];

          res.data.custmor?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: moment(val.created_at).format("MM-DD-YYYY"),
              NAME: val.firstName ? val.firstName : "--" + " " + val.lastName ? val.lastName : "",
              EMAIL: val?.email,
              Phone: val?.phone,
              NUMBER_OF_CHILD: val?.number_of_child ? val?.number_of_child : "0",
              USER_TYPE: val?.tbl_users_roles
                ? val?.tbl_users_roles.map((val) => val?.tbl_user_role?.role_name).join(", ")
                : "--",
              FAITH_BACKGROUND: val?.faith_background ? val?.faith_background : "--",
              ADDRESS: val.address ? val.address : "--",
              STATUS: val.status,
              ideal_home_school: val?.tbl_ideal_homeschools
                ? val?.tbl_ideal_homeschools.map((val) => val.name).join(", ")
                : "--",
              faith_affiliation: val.faith_affiliation ? val.faith_affiliation : "--",
              faith_background: val.faith_background ? val.faith_background : "--",
              child_education_character_value: val.child_education_character_value
                ? val.child_education_character_value
                : "--",
              bio_about: val.bio_about ? val.bio_about : "--",
              stripe_setup_done: val.stripe_setup_done == null ? "No" : "Yes",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Customer.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
          console.log(res.data, "response cus");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      // sortable: true,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Registered Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      minWidth: "110px",
      style: { minWidth: "110px" },
      grow: 2,

      borderRadius: "4px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) => row.firstName,

      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.firstName ? row.firstName : "Guest"} {row.lastName ? row.lastName : ""}
            </span>
            <span className="sub-text">{row.email}</span>
            <span className="sub-text">{row.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "No. of Students",
      selector: (row) => (row.number_of_child == null ? <div>--</div> : row.number_of_child),
      grow: 2,

      borderRadius: "4px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.number_of_child == null || row.number_of_child == "" ? <div>0</div> : row.number_of_child}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "User Type",
      selector: (row) => row.user_type,
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <span className="sub-text">
          {row.tbl_users_roles.length > 0
            ? row?.tbl_users_roles &&
              row?.tbl_users_roles.length > 0 &&
              row?.tbl_users_roles?.map((val, index) => (
                <>
                  {val.tbl_user_role.role_name}
                  {index + 1 != row.tbl_users_roles.length ? "," : ""}
                </>
              ))
            : "--"}
        </span>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Faith</br>Background" }} />,
      selector: (row) => "",
      grow: 2,
      borderRadius: "4px",
      // sortable: true,
      cell: (row) => <span className="sub-text">{row.faith_background ? row.faith_background : "--"}</span>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row, index) => (
        <>
          {row.status == "enabled" && (
            <div className="custom-control custom-switch">
              <input
                onClick={() => {
                  handleswitch(row.id, "disabled", index);
                }}
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                checked
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
          {row.status == "disabled" && (
            <div className="custom-control custom-switch">
              <input
                onClick={() => {
                  handleswitch(row.id, "enabled", index);
                }}
                type="checkbox"
                className="custom-control-input"
                id={row.id}
              />

              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
        </>
      ),
    },

    {
      name: "View",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-customer-details/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
            {sessionStorage.setItem("tabcust", "")}
          </Link>
          <Link to={`/editCustomer-page/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-edit"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  const handleswitch = (id, isSelected, index) => {
    // console.log(id, "=id", isSelected, "=isselcted", index, "=index");
    let data = {
      id: id,
      status: isSelected,
    };
    switchState ? setSwitchState("enabled") : setSwitchState("disabled");
    const newArray = Array.from(customerListData);
    newArray[index].status = isSelected === "enabled" ? "disabled" : "enabled";
    setCustomerListData(newArray);

    makePostRequest(URLS.USER_ACTIVE_DEACTIVE, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success(res.message);
        getAllCustomerListData();
        setIsUpdate(false);
      } else if (res.code == 400) {
        toast.success(res.message);
      }
    });
  };

  let [count, setCount] = useState();
  const getAllCustomerListData = () => {
    setLoader(true);
    let data = {
      start_date: customerdata.startDate ? moment(customerdata.startDate).format("YYYY-MM-DD") : "",
      end_date: customerdata.endDate ? moment(customerdata.endDate).format("YYYY-MM-DD") : "",
      status: customerdata.statuscust ? customerdata.statuscust : "",
      //  user_type: userTypeArray ? userTypeArray : "",
      user_type: userTypeArray ? userTypeArray : "",
      faith_background: customerdata.faithback ? customerdata.faithback : "",
      page: customerdata.pagenumbercust ? customerdata.pagenumbercust : 1,
      limit: customerdata.limitvaluecust ? customerdata.limitvaluecust : 10,
      searchKey: customerdata.Search,
      key: "",
      sortby: customerdata.sortvaluecust ? customerdata.sortvaluecust : "",
    };
    console.log(data, "cusdata");
    const start = performance.now();
    makePostRequest(URLS.GET_ALL_CUSTOMER, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setCustomerListData(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader(false);
          console.log(res.data, "cusresponse");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const end = performance.now();
    console.log(end - start);
  };

  useEffect(() => {
    getUserType();
  }, []);

  const handleValidation = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("Please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("Please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("End date should be greater than start date");
    } else {
      getAllCustomerListData();
      setCurrentPage(1);
    }
  };

  const clearfilterfun = () => {
    setUserType("");
    setStatus("");
    setFaithBackground("");
    setSelectedfromDate("");
    setSelectedToDate("");
    setSearch("");
    setSortCustomer("");
    setlimitvalue("");
    getAllCustomerListData();
    setCurrentPage(1);
    let customerdata = JSON.parse(sessionStorage.getItem("customer")) || {};
    customerdata.limitvaluecust = "";
    customerdata.pagenumbercust = "";
    customerdata.sortvaluecust = "";
    customerdata.statuscust = "";
    customerdata.userType = "";
    customerdata.faithback = "";
    customerdata.Search = "";
    customerdata.startDate = "";
    customerdata.endDate = "";
    customerdata.statuscust = "";
    setClearState(!clearState);
    sessionStorage.setItem("customer", JSON.stringify(customerdata));
  };

  const clearfilter = () => {
    clearfilterfun();
  };

  useEffect(() => {
    getAllCustomerListData();
  }, [clearState]);

  //user type get api GET_USER_TYPE
  const getUserType = () => {
    makeGetRequest(URLS.GET_USER_TYPE, null, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          res?.data?.length > 0 &&
            res?.data?.map((val) => {
              option.push({ value: val.id, label: val.role_name });
            });
          setUserTypeData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    sessionStorage.setItem(
      "customer",
      JSON.stringify({
        limitvaluecust: limitvalue,
        pagenumbercust: selectedPage.selected + 1,
        sortvaluecust: sortcustomer,
        statuscust: status,
        userType: usertype,
        faithback: faithbackground,
        Search: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefFilter = useRef(null);

  const displaySelectFilter = (e) => {
    console.log("Hellosdsadsadsadsadas", displayvalueSelectFilter);
    if (selectRefFilter.current) {
      setDisplayValueSelectFilter(!displayvalueSelectFilter);
      selectRefFilter.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (events) => {
    if (newRef1.current && !newRef1.current.contains(events.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Customer List"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer List</h3>

        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <>
            <Card className="card-bordered">
              <div className="card-inner relativeClass">
                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="filtersidebutton">
                      <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                        Filters
                        <FaFilter />
                      </h5>
                      <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}>
                        Clear Filters
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8 d-flex  justify-content-end">
                    <select
                      value={sortcustomer}
                      className="form-select"
                      style={{ width: "170px" }}
                      onChange={HandleSortCustomer}
                    >
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCNAME">Customer Name Ascending</option>
                      <option value="DESCNAME">Customer Name Descending</option>
                    </select>

                    <div>
                      <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                    </div>
                    <div>
                      <select
                        value={limitvalue}
                        className="form-select"
                        style={{ width: "85px" }}
                        onChange={HandleLimitValue}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      &nbsp; &nbsp;
                      <button className="btn btn-primary " onClick={ExportEcelFile}>
                        <em className="icon ni ni-download-cloud"></em>
                        <span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
                <hr></hr>

                {show && (
                  <div>
                    <div className="row pb-2">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Faith Background</strong>
                          </label>
                          <select value={faithbackground} className="form-select" onChange={HandleFaithBackground}>
                            <option value="">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>User Type</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i
                                className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                onClick={displaySelectFilter}
                              ></i>
                              <Select
                                className="form-select"
                                value={usertype && usertype.length > 0 ? usertype : null}
                                options={userTypeData}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleUserType}
                                placeholder="Select User Type"
                                openMenuOnFocus={displayvalueSelectFilter}
                                ref={selectRefFilter}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Status</strong>
                          </label>

                          <select value={status} className="form-select" onChange={HandleStatus}>
                            <option value="">All</option>
                            <option value="enabled">Enabled</option>
                            <option value="disabled">Disabled</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon(() => setDatePickerShow(!datepickershow))}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate ? new Date(selectedfromdate) : null}
                              className="form-control date-picker"
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span
                                onClick={() => handleclickdatepickericon1(() => setDatePickerShow1(!datepickershow1))}
                              >
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate ? new Date(selectedtodate) : null}
                              className="form-control date-picker"
                              onChange={HandleToDate}
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Customer Name</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="Search by Customer..."
                            type="text"
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleValidation}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-inners relativeClass">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      {customerListData.length == 0 ? (
                        <>No Data Found</>
                      ) : (
                        <div className="color">
                          <ReactDataTable
                            data={customerListData}
                            columns={columns}
                            className="nk-tb-list"
                            searchable={false}
                            setPageCount={setPageCount}
                            ref={tableref}
                          />
                        </div>
                      )}

                      {customerListData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </div>
            </Card>
          </>
        )}
      </Content>
    </React.Fragment>
  );
}

export default CustomerList;
