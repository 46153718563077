import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { findUpper,capital } from "../../../utils/Utils";

function SystemSettings() {

   
   const [selectedTax, setSelectedTax] = useState({});
   const [name, setName] = useState('');
	const [settingName, setSettingName] = useState('');
	const [settingValue, setSettingValue] = useState('');
	const [informationValue, setInformationValue] = useState('');
	const [transactionCharges, setTransactionCharges] = useState('');
	const [stripCharge, setStripCharge] = useState('');
	
	const [status, setStatus] = useState('');
	
	
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [systemData, setSystemData] = useState([]);
    const [istextVisible,setIstextVisible]=useState(false);

	const [errorsettingName, setErrorSettingName] = useState('');
	const [errorinformationValue, setErrorinformationValue] = useState('');
	const [errortransactionCharges, setErrorTransactionCharges] = useState('');
	const [errorstripCharge, setErrorStripCharge] = useState('');
	const [errorsettingValue, setErrorSettingValue] = useState('');
	
	const [loader, setLoader] = useState(false);
    let userAuth = getUserDetailsFromToken();
	
   const [switchState, setSwitchState] = useState("");
 const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
	const columns = [
	{
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {index+1} </div>

			   </>
			)
		},
		{
			name: 'System Key',
			selector: row => row.setting_key,
			grow: 3,
			sortable: true,
			cell: (row) => (
			<div className="user-card mt-2 mb-2">
			      <span className="sub-text">
							{capital(row?.setting_key?.replaceAll('_',' ')?.replaceAll('km','Miles'))}
							</span>
							<i className="fa fa-info-circles">i
                  <div className="tooltipinfo">
					
                    <ul>
                      <li><span className="searchboldcolor">{row?.information?.replaceAll('km','Miles')}</span></li>
                     
                    </ul>
                  </div>
				  
                </i>
					
			</div>
			)
		},
		{
			name: 'System Value',
			selector: row => row.setting_value,
			grow: 3,
			sortable: true,
			cell: (row) => (
			<div className="user-card mt-2 mb-2">
				<span className="sub-text">
							{row.setting_value.replaceAll('_',' ')}
						</span>
			 </div>
			)
		},
		{
			name: 'Updated By',
			selector: row => row.tbl_admin_user.name,
			grow: 2,
			sortable: true,
			cell: (row) => (
			<div className="user-card mt-2 mb-2">
			      <span className="sub-text">
							{row.tbl_admin_user.name}
						</span>
			</div>
			)
		},
						
		{
			name: 'Status',
			selector: row => row.status,
			cell: (row, index) => (
				<>
				{
					row.status == "enable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>

				}
				{row.status == "disable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>
				}

			</>
			)
		},
		
		{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	];
	const handleswitch=(id, isSelected, index)=>{
		setLoader(true);
		let data = {
			id: id,
			status: isSelected,
			
		}
		switchState?setSwitchState("enable"):setSwitchState("disable");
		const newArray = Array.from(systemData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setSystemData(newArray);
	
		makePostRequest(URLS.UPDATE_SYSTEM_SETTING, null, data, null)
			.then(res => {
				if(res.code === 200){
					
					toast.success("Status updated successfully.");
				     setLoader(false); 
					getAllRoles();
			 	   setIsUpdate(false);
				   setSelectedTax({});
			
				}
				
					else {
						 toast.warn("Status disable successfully.")
					}
			})
	}	
	 //console.log("button",switchState)
  
  
  const handleName = (e) => {
		setName(e.target.value);
		setErrorName('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.name = str;
		setSelectedTax({ ...obj })
	} 
	
	const handleSettingName = (e) => {
		setSettingName(e.target.value);
		setErrorSettingName('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.settingName = str;
		setSelectedTax({ ...obj })
	} 
	
	const handleSettingValue = (e) => {
		setSettingValue(e.target.value);
		setErrorSettingValue('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.settingValue = str;
		setSelectedTax({ ...obj })
	   }
	  
	 	const handleTransactionCharges = (e) => {
		setTransactionCharges(e.target.value);
		setErrorTransactionCharges('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.transactionCharges = str;
		setSelectedTax({ ...obj })
	   }
	   	const handleInformation = (e) => {
		setInformationValue(e.target.value);
		setErrorinformationValue('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.informationValue = str;
		setSelectedTax({ ...obj })
	 }
	 
	  	const handleStripCharge = (e) => {
		setStripCharge(e.target.value);
		setErrorStripCharge('');
		let obj = { ...selectedTax };
		let int = e.target.value;
		obj.stripCharge = int;
		setSelectedTax({ ...obj })
	 }
	 
	 	const handletaxStatus = (e) => {
		setStatus(e.target.value);
		setErrorName('');
		let obj = { ...selectedTax };
		let str = e.target.value;
		obj.status = str;
		setSelectedTax({ ...obj })
	 }
   
	  useEffect(()=>{
		let userAuth = getUserDetailsFromToken();
		//console.log("User Id", userAuth);
	  },[])
	

	  let char=(/^[a-zA-Z0-9.\s]+$/);
	// const AddRoleHandler = () => {
    
	// 	if (settingName == "" && !isUpdate ) {
	// 		setErrorSettingName('Please enter your setting key.');
	// 	}
	// 	else if (settingValue == "") {
	// 		setErrorSettingValue('Please enter your setting Value.');
	// 	}

	// 	else {
	// 		addNewRole();
	// 		setLoader(true);
	// 		setIstextVisible(false);
	// 	}
	// }
	const updateRoleHandler = () => {
		
		if (selectedTax.settingValue== "") {
			setErrorSettingValue('Please enter setting value.');
		}
		// else if(!selectedTax.settingValue.match(char)){
		// 	setErrorSettingValue('Please enter character and number only.');
		// }
		 
		else if (isUpdate) {
			handleupdateUser();
			//setLoader(true);
			let obj = {settingName:'', settingValue:''}
			 setSelectedTax(obj);
			 getAllRoles();
			 setIstextVisible(false);
			 setIsUpdate(false);
		}
	}
	const addNewRole = () => {
		
		let data = {
			
			setting_key: settingName,
			setting_value: settingValue,
			updated_by:userAuth.id,
			status: "enable",
		}
   	makePostRequest(URLS.ADD_SYSTEM_SETTING, null, data, null)
			.then(res => {
				if(res.code==201){
					let obj = {settingName:'', settingValue:'', informationValue:''}
					setSelectedTax(obj);
					 toast.success(res.message);
					 setLoader(false);
					 getAllRoles();
				    setIsUpdate(false);
				    //setSelectedTax({});
				}
				else if(res.code==400){
					setLoader(false);
				     toast.warn(res.message);
				} 	
						
			})
	}
	
	useEffect(() => {
		getAllRoles();
           		
	}, []);

	const getAllRoles = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_SYSTEM_SETTING, {}, null)
			.then(res => {
				if (res.code == 200) {
					console.log(res,"system");
					setSystemData(res.data);
				setLoader(false);	
				}
			})
			.catch((error) => {
				//console.log(error);
			})

	}
	const handleEditButton = (val) => {
		
		setIsUpdate(true);
		let obj = {id:val.id,settingName:val.setting_key,settingValue:val.setting_value ,informationValue:val.information }
      
		setSelectedTax(obj);
		//setLoader(true);
		window.scroll({
			top:0,
			behavior:"smooth"
		})
		
	}

	const handleupdateUser = () => {
		let data = {
			id: selectedTax.id,
			setting_key: selectedTax.settingName,
			setting_value: selectedTax.settingValue,
			information: selectedTax.informationValue,
			updated_by: userAuth.id,
			
		}
		
		makePostRequest(URLS.UPDATE_SYSTEM_SETTING, null, data, null)
		
			.then(res => {
                
				if(res.code === 200){
					toast.success(res.message);
					//alert("hello");
			    //console.log(res,"Tredt");
				 settingName('');
				 settingValue('');
				toast.success(res.message);
				 	setLoader(false);	
					getAllRoles();
			 	   setIsUpdate(false);
				   setSelectedTax({});
				   setIstextVisible(false);
				}else {
					toast.error(res.message);
				}				
					
			})
	}
	return (
		<React.Fragment>
			<Head title="System Setting"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">System Setting</h3>
				{/* <BlockTitle tag="h4">System Setting</BlockTitle> */}
				{ loader ? (
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}}>
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
          ):(
				<div className="card-bordered card card-inner-lg">
					<BlockHeadContent>
					
									 
						<BlockHead size="lg">
					
						{isUpdate===false?null:
							<div className="pb-5 asd">


								<div className="row">
								    
									<div className="col-lg-6">
							
									<div className="form-group">
										<Label className="form-label">
									    Setting Key
										</Label>
										<div className="form-control-wrap">
											<Input
											 placeholder="Setting Key" 
											 type="text" 
											 value={selectedTax.settingName}
											  onChange={handleSettingName}
											  disabled={isUpdate} />
											<span className="error"> {errorsettingName} </span>
                    									
									     </div>
										
									</div>

									</div>
									
									<div className="form-group col-lg-6">
									<div className="form-group">
										<Label className="form-label">
											Setting Value
										</Label>
										<div className="form-control-wrap">
											<Input  placeholder="Setting Value"  value={selectedTax.settingValue} onChange={handleSettingValue} />
											<span className="error"> {errorsettingValue} </span>
                    									
									     </div>
										
									</div>

					
									</div>
									
									<div className="form-group col-lg-6">
									<div className="form-group">
										<Label className="form-label">
											Information
										</Label>
										<div className="form-control-wrap">
											<Input  placeholder="Updated By"  value={selectedTax.informationValue} onChange={handleInformation} />
											<span className="error"> {errorinformationValue} </span>
                    									
									     </div>
										
									</div> 
									</div>
									
									{/* {isUpdate===false?
                                     <ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={AddRoleHandler}>Add </button></li>

									</ul>
									: */}
									<ul className="align-center flex-sm-nowrap mt-4 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={updateRoleHandler}>Update </button></li>
                           
									</ul>
	                              {/* } */}
								</div>
								

							</div>


}
							
                       
					 

					{isUpdate==false&&
					<div className="outerBox">
					 {systemData.length &&
								<ReactDataTable
									data={systemData}
									columns={columns}
									className="nk-tb-list"
                                    searchable={true}
									// pagination
								/>
							}
							</div>
					}
 



						</BlockHead>
					</BlockHeadContent>
				</div>
		  )}
			</Content>

		</React.Fragment>
	);
};

export default SystemSettings;
