import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromToken } from '../../../service/auth'
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { findUpper } from "../../../utils/Utils";


function ManageTopicSubject() {


	const [selectedtopicsubject, setSelectedTopicSubject] = useState({});
	const [name, setName] = useState('');
	const [isUpdate, setIsUpdate] = useState(false);
	const [errorname, setErrorName] = useState('');
	const [rolesData, setRolesData] = useState([]);
	const [CategoryId, setCategoryId] = useState('');
	const [expressionTextError, setErrorExpressionText] = useState('');
	const [getsCategoryId, setgetsCategoryId] = useState([]);
	const [errorCategoryId, setErrorCategoryId] = useState('');
	const [loader, setLoader] = useState(false);
    const [switchState, setSwitchState] = useState("");
	const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
	const columns = [
		
		{
			name: '#',
			selector: row => row.id,
			grow: 0,
	        minWidth: "50px",
			style: {minWidth: "50px" },
			borderRadius: "4px",
			cell: (row,index) => (
				<>
				<div className="user-card mt-2 mb-2"> {index+1} </div>

			   </>
			)
		},
		{
			name: 'Topic Name',
			selector: row => row.name,
			grow: 3,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.name)} ></UserAvatar>
					<div className="user-info">
						<span className="tb-lead">
							{row.name}{" "}
						</span>
					</div>
				</div>
			)
		},
		{
			name: 'Select Category',
			
			selector: row => row.tbl_topic_category && row.tbl_topic_category.name ? row.tbl_topic_category.name : "",
			grow: 3,
			sortable: true,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
				<div className="user-info">
						<span className="tb-lead">
							{row.tbl_topic_category && row.tbl_topic_category.name ? row.tbl_topic_category.name : ""}
						</span>
			      </div>
				</div>
			)
		},
						
		{
			name: 'Status',
			selector: row => row.status,
			cell: (row, index) => (
				<>
				{
					row.status == "enable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>

				}
				{row.status == "disable" &&
					<div className="custom-control custom-switch">
						<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
						<label className="custom-control-label" htmlFor={row.id}>
						</label>
					</div>
				}

			</>
			)
		},
		
		{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	];
	const handleswitch=(id, isSelected, index)=>{
		
		let data = {
			id: id,
			status: isSelected,
			
		}
		switchState?setSwitchState("enable"):setSwitchState("disable");
		const newArray = Array.from(rolesData);
		newArray[index].status = isSelected === "enable" ? "disable" : "enable";
		setRolesData(newArray);
	
		makePostRequest(URLS.UPDATE_TOPIC_SUBJECT, null, data, null)
			.then(res => {
				if(res.code === 200){
					setName('');
					toast.success("Status updated successfully.");
				     setLoader(false); 
					getAllRoles();
			 	   setIsUpdate(false);
				   setSelectedTax({});
			
				}
				
					else {
						 toast.warn("Status disable successfully.")
					}
			})
	}

	 //console.log("button",switchState)
 
	const handleName = (e) => {
		setName(e.target.value);
		setErrorName('');
		setErrorExpressionText('');
		let obj = { ...selectedtopicsubject };
		let str = e.target.value;
		obj.name = str;
		setSelectedTopicSubject({ ...obj })
	}
	
	 let char=(/^[a-zA-Z\s]+$/); 
	const handleCategoryID = (e) => {
		 setCategoryId(e.target.value);
		 setErrorCategoryId('');
		 let obj = { ...selectedtopicsubject };
		let str = e.target.value;
		obj.CategoryId = str;
		setSelectedTopicSubject({ ...obj })
		
	}

	const AddTopicHandler = () => {
    
		if (name == "") {
			setErrorName('Please enter your topic subject.');
		}
		else if(!name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
         else if (CategoryId == ""){ 
			setErrorCategoryId("Please select category.");
		} 
		else {
			addNewRole();
			setLoader(true);
		}
	}
	const UpdateTopicHandler = () => {
    
		if (selectedtopicsubject.name == "") {
			setErrorName('Please enter your topic subject.');
		}
		else if(!selectedtopicsubject.name.match(char)){
			setErrorExpressionText("Please enter character only.")
		}
         else if (selectedtopicsubject.CategoryId == ""){ 
			setErrorCategoryId("Please select category.");
		} 
		else if (isUpdate) {
			handleupdateUser();
			
			
			
		}
	}




	const addNewRole = () => {
		let data = {
		   name: name,
		   topic_category_id:CategoryId,
		}
		makePostRequest(URLS.ADD_TOPIC_SUBJECT, null, data, null)
			.then(res => {
			 if(res.code==201) {
                  let boj = { name:'', CategoryId: '' }
					setSelectedTopicSubject(boj);
				  setName('');				  
				 toast.success(res.message);	
					getAllRoles();
					setIsUpdate(false);
					setSelectedTopicSubject({});
                 }	
                else if(res.code==400){
					toast.error(res.message)
					setLoader(false)
				}				 
			 })
			
	}
	useEffect(() => {
		getAllRoles();
	}, []);

	const getAllRoles = () => {
		setLoader(true);
		makeGetRequest(URLS.GET_ALL_TOPIC_SUBJECT, {}, null)
			.then(res => {
				if (res.code == 200) {
					setRolesData(res.data);
				   setLoader(false);
				   
				}
			})
			.catch((error) => {
				console.log(error);
			})

	  }


	const handleEditButton = (val) => {
		setErrorName('');
		setErrorCategoryId('');
		setIsUpdate(true);
		let obj = {id:val.id,name:val.name,CategoryId:val.topic_category_id}
		setSelectedTopicSubject(obj);
		window.scroll({
			top:0,
			behavior:"smooth"
		})
		
	}

	const handleupdateUser = () => {
		let data = {
			id: selectedtopicsubject.id,
			name: selectedtopicsubject.name,
			topic_category_id:selectedtopicsubject.CategoryId,
			
		}
		makePostRequest(URLS.UPDATE_TOPIC_SUBJECT, null, data, null)
			.then(res => {
				if(res.code==200){
                   let boj = { name:'', CategoryId: '' }
					setSelectedTopicSubject(boj);
                   setName('');					
                    toast.success(res.message);					
					setLoader(true);	
					getAllRoles();
				   setIsUpdate(false);
				   setSelectedTopicSubject({});
				}
				else if(res.code==400){
					toast.error(res.message)
					setLoader(false)
				}	
				
			})
	}
	
	const getCategoryIdSubject = () => {
		makeGetRequest(URLS.GET_ALL_TOPIC_CATEGORY, {}, null)
			.then(res => {
				if (res.code == 200) {
					setgetsCategoryId(res.data);
				    setLoader(false);
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}
	useEffect(() => {
		getCategoryIdSubject();
	}, []);

	return (
		<React.Fragment>
			<Head title="Topic Subject"></Head>
			<Content>

				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4"> Manage Topic Subject</h3>
			  <Card className="card-bordered">	
				<div className="card-inner-lg">
					<BlockHeadContent>
					
					
						<BlockHead size="lg">
						{/* {isUpdate===false?	
                         <h3 className="nk-block-title page-title mb5"> Manage Topic Subject Name</h3>
                        :
						 <h3 className="nk-block-title page-title mb5"> Edit Topic Subject Name</h3>
						} */ }

							<div className="row">


								<div className="row pb-5">
								
									<div className="form-group col-lg-6">
									
										
										
										<Label className="form-label">
										 Subject Name 
										</Label>
										<div className="form-control-wrap">
			<Input name="name"  placeholder="Subject Name" type="text" value={selectedtopicsubject.name} onChange={handleName} />
											<span className="error"> {errorname} </span>
                    						<span className="error"> {expressionTextError} </span>			
									</div>
										
									</div>
									
									<div className="form-group col-lg-6">
										<Label className="form-label">
										 Select category
										</Label>
																	
								    <select value={selectedtopicsubject.CategoryId}className="form-select" onChange={handleCategoryID}>
                                    <option value="">Select category</option>
										 {
											 getsCategoryId.map((val,index)=>{
												 
												 return(<>
												 <option value={val.id}>{val.name}</option>
												 </>)
											 })
										 }
								    </select>
							
									<span className="error"> {errorCategoryId} </span>
										
									</div>
									
									
									{isUpdate===false?
                                     <ul className="align-center flex-sm-nowrap mt-3 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={AddTopicHandler}>Add </button></li>

									</ul>
									:
									<ul className="align-center flex-sm-nowrap mt-3 pt-1 px-3 float-start">
										<li><button className="btn btn-primary btn-md" type="submit" onClick={UpdateTopicHandler}>Update </button></li>
                           
									</ul>
	                              }
								</div>	

							</div>
							{ /* <h4 className="nk-block-title page-title mb5 "> Manage Topic Subject List</h4> */ }
				
                { loader ? (
                        <div className="sharepopupOuter backgroundOpacity1">
							<ThreeDots
								height="100%"
								width="100%"
								radius="9"
								color="#08147e"
								ariaLabel="three-dots-loading"
								wrapperStyle={{
								    margin: "200px auto",
									width: "90px",
									height: "90px",
								}}
								wrapperClassName="loader"
								visible={true}
							/>
						</div>
			        ): (

				 <div className="outerBox">
						 {rolesData.length &&
								<ReactDataTable
									data={rolesData}
									columns={columns}
									className="nk-tb-list"
                                    searchable={true}
									pagination
								/>
							}
                    </div>
          
				)}
                  
						</BlockHead>
					</BlockHeadContent>
				</div>
				</Card>
			</Content>

		</React.Fragment>
	);
};

export default ManageTopicSubject;
