import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import bnb from "../../../images/coins/bnb.svg";
import { findUpper, capital } from "../../../utils/Utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, Star } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { makeGetRequest, getHeaders } from "../../../service/api";
import { Tabs } from "react-simple-tabs-component";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetailsFromToken } from "../../../service/auth";
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import { S3_BUCKET_URL } from "../../../constants";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import Select from "react-select";

function ViewGroupDetails() {
  let history = useHistory();

  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

  const params = useParams();
  let Group_id = params.id;

  const [groupGetOnedata, setGroupGetOnedata] = useState("");
  const [subGroupData, setSubGroupData] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();
  const [currentPageMemberList, setCurrentPageMemberList] = useState(1);
  const [currentPagePendingMemberList, setCurrentPagePendingMemberList] = useState(1);
  const [pendingGroupMemberList, setPendingGroupMemberList] = useState([]);
  const [countPendingMemberList, setCountPendingMemberList] = useState("");
  const [countMemberList, setCountMemberList] = useState();
  const [currentPageResource, setCurrentPageResource] = useState(1);
  const [countResource, setCountResource] = useState();
  const [groupResource, setGroupResource] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [selectedFromDateResource1, setSelectedFromDateResource1] = useState("");
  const [selectedToDateResource1, setSelectedToDateResource1] = useState("");
  const [fromDateErrorResource1, setFromDateErrorResource1] = useState("");
  const [toDateErrorResource1, setToDateErrorResource1] = useState("");
  const [selectedFromDateEvent, setSelectedFromDateEvent] = useState("");
  const [selectedToDateEvent, setSelectedToDateEvent] = useState("");
  const [fromDateErrorEvent, setFromDateErrorEvent] = useState("");
  const [toDateErrorEvent, setToDateErrorEvent] = useState("");
  const [selectedFromDateFeed, setSelectedFromDateFeed] = useState("");
  const [selectedToDateFeed, setSelectedToDateFeed] = useState("");
  const [fromDateErrorFeed, setFromDateErrorFeed] = useState("");
  const [toDateErrorFeed, setToDateErrorFeed] = useState("");
  const [groupResourcesData1, setGroupResourcesData1] = useState([]);
  const [countGroupResources1, setCountGroupResources1] = useState();
  const [currentPageGroupResources1, setCurrentPageGroupResources1] = useState(1);
  const [groupEvent, setGroupEvent] = useState([]);
  const [counEvent, setCountEvent] = useState();
  const [currentPageEvent, setCurrentPageEvent] = useState(1);
  const [groupFeed, setGroupFeed] = useState([]);
  const [countGroupFeed, setCountGroupFeed] = useState();
  const [currentPageFeed, setCurrentPageFeed] = useState(1);
  const [currentPageBulletin, setCurrentPageBulletin] = useState(1);
  const [countBulletin, setCountBulletin] = useState();
  const [bulletinBoard, setBulletinBoard] = useState([]);
  const [currentPageChat, setCurrentPageChat] = useState(1);
  const [countChat, setCountChat] = useState();
  const [chat, setChat] = useState([]);
  const [learningData, setLearningData] = useState([]);
  const [pillerId, setPillerId] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [resourceTypeResource, setResourceTypeResource] = useState("");
  const [resourceTypeEvent, setResourceTypeEvent] = useState("");
  const [resourceTypeFeed, setResourceTypeFeed] = useState("");
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [search, setSearch] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [shareByoptions, setShareByoptions] = useState([]);
  const [sharedById, setSharedById] = useState("");
  const [bulletinType, setBulletinType] = useState("");
  const [groupAdminList, setGroupAdminList] = useState([]);
  const [groupType, setGroupType] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [selectedFromDateSaleHistory, setSelectedFromDateSaleHistory] = useState("");
  const [selectedToDateSaleHistory, setSelectedToDateSaleHistory] = useState("");
  const [fromDateErrorSaleHistory, setFromDateErrorSaleHistory] = useState("");
  const [toDateErrorSaleHistory, setToDateErrorSaleHistory] = useState("");
  const [flag, setFlag] = useState(false);
  const [pillerIdResource, setPillerIdResource] = useState("");
  const [memberId, setMemberId] = useState("");
  const [pendingAdminId, setPendingAdminId] = useState("");
  const [joinGroupType,setJoinGroupType] =useState("")
  const[idForDeleteJoinedGroup,setIdForDeleteJoinedGroup]=useState("")

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = (row) => {
    setOpen(!open);
    setMemberId(row.id);
  };
  const [openForAdmin, setOpenForAdmin] = useState(false);
  const handleCloseForAdmin = () => setOpenForAdmin(false);

  const handleOpenForAdmin = (row) => {
    setOpenForAdmin(!openForAdmin);
    setPendingAdminId(row.id);
  };

  const [openForJoinedGroup, setOpenForJoinedGroup] = useState(false);
  const handleCloseForJoinedGroup = () => setOpenForJoinedGroup(false);

  const handleOpenForJoinedGroup = (row) => {
    // console.log(row,"joindgroup")
    setOpenForJoinedGroup(!openForJoinedGroup);
    setIdForDeleteJoinedGroup(row.id);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "16px",
    boxShadow: 24,
    p: 8,
  };

  // console.log(memberStatus, "memberStatus");

  const HandleLearningResource = (Id) => {
    setPillerIdResource(Id);
  };
  let learningIdResource = [];
  if (pillerIdResource && pillerIdResource.length > 0) {
    pillerIdResource.forEach((val) => learningIdResource.push(val.value));
  }

  const HandleLearning = (Id) => {
    setPillerId(Id);
  };
  let learningId = [];
  if (pillerId && pillerId.length > 0) {
    pillerId.forEach((val) => learningId.push(val.value));
  }

  const [pillerIdEvent, setPillerIdEvent] = useState("");
  const HandleLearningPillerEvent = (id) => {
    setPillerIdEvent(id);
  };
  let learningIdEvent = [];
  if (pillerIdEvent && pillerIdEvent.length > 0) {
    pillerIdEvent.forEach((val) => {
      learningIdEvent.push(val.value);
    });
  }

  const [pillerIdFeed, setPillerIdFeed] = useState("");
  const HandleLearningPillerFeed = (id) => {
    setPillerIdFeed(id);
  };
  let learningIdFeed = [];
  if (pillerIdFeed && pillerIdFeed.length > 0) {
    pillerIdFeed.forEach((val) => {
      learningIdFeed.push(val.value);
    });
  }

  const handleSharedBy = (Id) => {
    setSharedById(Id);
  };
  let Sharedid = [];
  if (sharedById && sharedById.length > 0) {
    sharedById.forEach((val) => {
      Sharedid.push(val.value);
    });
  }

  const [sharedByIdEvent, setSharedByIdEvent] = useState("");
  const handleSharedByEvent = (Id) => {
    setSharedByIdEvent(Id);
  };
  let SharedidEvent = [];
  if (sharedByIdEvent && sharedByIdEvent.length > 0) {
    sharedByIdEvent.forEach((val) => {
      SharedidEvent.push(val.value);
    });
  }

  const handleResourceType = (e) => {
    setResourceType(e.target.value);
  };

  const handleGroupTypes =(e)=>{
setJoinGroupType(e.target.value)
  }

  const handleResourceTypeResource = (e) => {
    setResourceTypeResource(e.target.value);
  };
  const handleResourceTypeEvent = (e) => {
    setResourceTypeEvent(e.target.value);
  };
  const handleResourceTypeFeed = (e) => {
    setResourceTypeFeed(e.target.value);
  };

  const handleBulletinName = (e) => {
    setBulletinType(e.target.value);
  };

  //   const handleGroupType =(e)=>{
  // setGroupType(e.target.value)
  //   }

  const handleGroupScheduleType = (e) => {
    setScheduleType(e.target.value);
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setToDateError("");
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setFromDateError("");
  };

  const handleStatusMember = (e) => {
    setMemberStatus(e.target.value);
  };

  const HandleFromDateResource1 = (e) => {
    setSelectedFromDateResource1(e);
    setToDateErrorResource1("");
  };
  const HandleToDateResource1 = (e) => {
    setSelectedToDateResource1(e);
    setFromDateErrorResource1("");
  };
  const HandleFromDateEvent = (e) => {
    setSelectedFromDateEvent(e);
    setToDateErrorEvent("");
  };
  const HandleToDateEvent = (e) => {
    setSelectedToDateEvent(e);
    setFromDateErrorEvent("");
  };

  const HandleFromDateSaleHistory = (e) => {
    setSelectedFromDateSaleHistory(e);
    setToDateErrorSaleHistory("");
  };
  const HandleToDateSaleHistory = (e) => {
    setSelectedToDateSaleHistory(e);
    setFromDateErrorSaleHistory("");
  };
  const HandleFromDateFeed = (e) => {
    setSelectedFromDateFeed(e);
    setToDateErrorFeed("");
  };
  const HandleToDateFeed = (e) => {
    setSelectedToDateFeed(e);
    setFromDateErrorFeed("");
  };

  const handlevalidationDate = () => {
    if (selectedFromDate != 0 && selectedToDate == "") {
      setFromDateError("please select start date");
    } else if (selectedToDate != 0 && selectedFromDate == "") {
      setToDateError("please select end date");
    } else if (selectedFromDate > selectedToDate) {
      toast.error("end date should be grater than start date");
    } else {
      HandleMemberGroupData();
      HandlePendingMemberData();
    }
  };

  const handlevalidationDateResource1 = () => {
    if (selectedFromDateResource1 != 0 && selectedToDateResource1 == "") {
      setFromDateError("please select start date");
    } else if (selectedToDateResource1 != 0 && selectedFromDateResource1 == "") {
      setToDateError("please select end date");
    } else if (selectedFromDateResource1 > selectedToDateResource1) {
      toast.error("end date should be grater than start date");
    } else {
      HandleGroupResources1();
    }
  };

  const handlevalidationShareResoure = () => {
    HandleGroupResource();
  };

  const handlevalidationEvent = () => {
    if (selectedFromDateEvent != 0 && selectedToDateEvent == "") {
      setFromDateErrorEvent("please select start date");
    } else if (selectedToDateEvent != 0 && selectedFromDateEvent == "") {
      setToDateErrorEvent("please select end date");
    } else if (selectedFromDateEvent > selectedToDateEvent) {
      toast.error("end date should be grater than start date");
    } else {
      HandleGroupEvent();
    }
  };

  const handleSaleHistory = () => {
    if (selectedFromDateSaleHistory != 0 && selectedToDateSaleHistory == "") {
      setFromDateErrorSaleHistory("please select start date");
    } else if (selectedToDateSaleHistory != 0 && selectedFromDateSaleHistory == "") {
      setToDateErrorSaleHistory("please select end date");
    } else if (selectedFromDateSaleHistory > selectedToDateSaleHistory) {
      toast.error("end date should be grater than start date");
    } else {
      HandleGroupSaleHistory();
    }
  };

  const handlevalidationDateFeed = () => {
    //   if(selectedFromDateFeed!=0 && selectedToDateFeed==""){
    //     setFromDateErrorFeed("please select start date")
    //   }
    //   else if(selectedToDateFeed!=0 && selectedFromDateFeed==""){
    //     setToDateErrorFeed("please select end date")
    //   }
    //   else if(selectedFromDateFeed>selectedToDateFeed){
    // toast.error("end date should be grater than start date")
    //   }
    //   else{
    HandleGroupFeed();
    // }
  };


  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },
    {
      name: "Joined Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.last_updated_date ? moment(row?.last_updated_date).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Group Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
{joinGroupType==="Subgroup" ? 
            <span className="sub-text">{row?.main_group?.group_name ? row?.main_group?.group_name : "--"}</span>
:
            <span className="sub-text">{row?.sub_group?.group_name ? row?.sub_group?.group_name : "--"}</span>
      }
          </div>
        </div>
      ),
    },
    {
      name: "Owner Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">

          {joinGroupType === "Subgroup" ?
            <span className="sub-text">
              {row?.main_group?.tbl_user?.firstName ? row?.main_group?.tbl_user?.firstName : "--"}{" "}
              {row?.main_group?.tbl_user?.lastName}
            </span>
:
            <span className="sub-text">
              {row?.sub_group?.tbl_user?.firstName ? row?.sub_group?.tbl_user?.firstName : "--"}{" "}
              {row?.sub_group?.tbl_user?.lastName}
            </span>
    }
          </div>
        </div>
      ),
    },
    {
      name: "Reason Joined",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.reason_to_join ? row.reason_to_join : "--"}</span>
          </div>
        </div>
      ),
    },
     {
      name: "Action",
      selector: (row) => "",
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
           <button className="dropdown-item btn btn-md" onClick={() =>handleOpenForJoinedGroup(row)}>
              <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
            </button>
        </div>
      ),
    },
  ];

  const columnsSaleHistoryList = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },
    {
      name: "Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user_resource?.resource_title ? row?.tbl_user_resource?.resource_title : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Owner Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user?.firstName ? row?.tbl_user?.firstName : "--"} {row?.tbl_user?.lastName}
            </span>
            <span className="sub-text">{row?.tbl_user?.email} </span>
            <span className="sub-text">{row?.tbl_user?.phone} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Amount",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text"> $ {row?.total_amount ? row?.total_amount.toFixed(2) : "0.00"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "View Invoice",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <div className="data-valueinvoicePDF">
              <a href={S3_BUCKET_URL + row?.tbl_payment_history.invoice_pdf_link} target="_blank">
                View Invoice
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const columns1 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageMemberList == 1 ? index + 1 : (currentPageMemberList - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Group Members",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user?.firstName ? row?.tbl_user?.firstName : "--"} {row?.tbl_user?.lastName}
            </span>
            <span className="sub-text">{row?.tbl_user?.email}</span>
            <span className="sub-text">{row?.tbl_user?.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Joined Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.status === "stay" ? "Active" : row.status}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => "",
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-customer-details/${row?.tbl_user?.id}`} style={{ fontSize: "18px", marginTop: "27px",marginRight: "5px" }}>
            <Icon name="eye" ></Icon>&nbsp;&nbsp;
          </Link>

          <button className="dropdown-item btn btn-md" onClick={() => handleOpen(row)}>
            <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
          </button>
        </div>
      ),
    },
  ];

  const columnsPendingMembers = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPagePendingMemberList == 1 ? index + 1 : (currentPagePendingMemberList - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Group Members",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            <span className="sub-text">{row.tbl_user.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Requested Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.requested_date ? moment(row?.requested_date).format("MM-DD-YYYY") : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{"Pending"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => "",
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-customer-details/${row.tbl_user.id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];
  const activeMembersCount = groupAdminList.filter((member) => member.status === "stay").length;
  // console.log(activeMembersCount, "activeMembersCount");

  const columnsAdminList = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageMemberList == 1 ? index + 1 : (currentPageMemberList - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Group Admin(s)",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            <span className="sub-text">{row.tbl_user.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Joined Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.status === "Requested" ? "Pending" : row.status === "stay" ? "Active" : row.status}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => "",
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {row.status === "stay" && activeMembersCount == 1 ? (
            <div className="lockInfo">
              <i className="fa fa-lock fa-2x" aria-hidden="true">
			  <div className="tooltipinfo">
					
          <ul>
            <li><span className="searchboldcolor">You need to create another admin to remove this member.</span></li>
           
          </ul>
        </div>
			  </i>
			  
            </div>
          ) : row.status === "stay" ? (
            <button className="dropdown-item btn btn-md" onClick={() => handleOpen(row)}>
              <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
            </button>
          ) : (
            <button className="dropdown-item btn btn-md" onClick={() => handleOpenForAdmin(row)}>
              <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
            </button>
          )}
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageResource == 1 ? index + 1 : (currentPageResource - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },

    {
      name: "Resource Shared by",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            <span className="sub-text">{row.tbl_user.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user_resource?.resource_title ? row?.tbl_user_resource?.resource_title : "--"}
            </span>
            <span className="sub-text">
              {row?.tbl_user_resource?.tbl_pillar_resource_mappings?.map((item) => (
                <div>
                  <img
                    src={S3_BUCKET_URL + item.tbl_learning_pillar_master?.image}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                </div>
              ))}
            </span>
          </div>
        </div>
        //data.data[0].tbl_user_resource.tbl_pillar_resource_mappings[1].tbl_learning_pillar_master.image
      ),
    },
    {
      name: "Resource Type",
      selector: (row) => "",
      minWidth: "130px",
      style: { minWidth: "130px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.tbl_user_resource?.tbl_resource_type_master?.name
                ? row?.tbl_user_resource?.tbl_resource_type_master?.name
                : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "View",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-resource/${row.resource_id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columnsresource1 = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageGroupResources1 == 1 ? index + 1 : (currentPageGroupResources1 - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Creation Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    //  {
    //   name: "Resource Owner",
    //   selector: (row) => "",
    //   grow:2,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="tb-lead">{row?.resource_added_by_user.name ? row?.resource_added_by_user.name :"--"}</span>

    //       </div>
    //     </div>

    //   ),
    // },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_title ? row?.resource_title : "--"}</span>
            <span className="sub-text">
              {row?.tbl_pillar_resource_mappings?.map((item) => (
                <div>
                  <img
                    src={S3_BUCKET_URL + item.tbl_learning_pillar_master?.image}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                </div>
              ))}
            </span>
          </div>
        </div>
      ),
    },

    // {
    //   name: "Resource Shared by User",
    //   selector: (row) => (""),
    //   minWidth: "150px",
    //   style: { minWidth: "150px" },
    //   grow: 2,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="sub-text">
    //           {row?.resource_shared_by_user.name ? row?.resource_shared_by_user.name :"--" }
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },

    {
      name: "Resource Type",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.tbl_resource_type_master.name ? row?.tbl_resource_type_master.name : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-resource/${row.id}`}>
            <Icon name="eye"></Icon>
          </Link>
        </div>
      ),
    },
  ];

  const columnEvent = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageEvent == 1 ? index + 1 : (currentPageEvent - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    // {
    //   name: "Creation Date",
    //   selector: (row) => (""),
    //   minWidth: "150px",
    //   style: { minWidth: "150px" },
    //   grow: 2,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="tb-lead">
    //         {row?.tbl_group.created_at ? moment(row?.tbl_group.created_at).format("MM-DD-YYYY") :"--"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Resource Owner",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.resource_added_by_user.name ? row?.resource_added_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_title ? row?.resource_title : "--"}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Shared by",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.resource_shared_by_user.name ? row?.resource_shared_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Type",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_type_name ? row?.resource_type_name : "--"}</span>
          </div>
        </div>
      ),
    },
  ];

  const columnFeed = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageFeed == 1 ? index + 1 : (currentPageFeed - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    // {
    //   name: "Creation Date",
    //   selector: (row) => (""),
    //   minWidth: "150px",
    //   style: { minWidth: "150px" },
    //   grow: 2,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="tb-lead">
    //         {row?.tbl_group.created_at ? moment(row?.tbl_group.created_at).format("MM-DD-YYYY") :"--"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Resource Owner",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.resource_added_by_user.name ? row?.resource_added_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_title ? row?.resource_title : "--"}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Shared by",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.resource_shared_by_user.name ? row?.resource_shared_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Type",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_type_name ? row?.resource_type_name : "--"}</span>
          </div>
        </div>
      ),
    },
  ];

  const columnsBulletin = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageBulletin == 1 ? index + 1 : (currentPageBulletin - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    // {
    //   name: "Creation Date",
    //   selector: (row) => (""),
    //   minWidth: "150px",
    //   style: { minWidth: "150px" },
    //   grow: 2,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="tb-lead">
    //         {row?.tbl_group.created_at ? moment(row?.tbl_group.created_at).format("MM-DD-YYYY") :"--"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Resource Owner",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.resource_added_by_user.name ? row?.resource_added_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Title",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_title ? row?.resource_title : "--"}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Shared by",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.resource_shared_by_user.name ? row?.resource_shared_by_user.name : "--"}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource Type",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_type_name ? row?.resource_type_name : "--"}</span>
          </div>
        </div>
      ),
    },
  ];

  const columnsChat = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageChat == 1 ? index + 1 : (currentPageChat - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Message Date",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{row?.message_date ? moment(row?.message_date).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Send by",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.send_by_name ? row?.send_by_name : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Message",
      selector: (row) => "",
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.message ? row?.message : "--"}</span>
          </div>
        </div>
      ),
    },
  ];

  /*================================GetOne group data api calling==========================*/
  const HandleGetOneGroupData = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
    };
    makePostRequest(URLS.GET_ONE_GROUP, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //  console.log(res, "getone group");
          setLoader(false);
          setGroupGetOnedata(res.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*===========================================Sub Group api calling ====================*/
  const HandleSubGroupData = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPage,
       group_type:joinGroupType ? joinGroupType :"Maingroup",

    };
    // console.log(data,"datasubgroup")
    makePostRequest(URLS.GET_GROUP_TO_GROUP, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //  console.log(res, "joined group res");
          setLoader(false);
          setSubGroupData(res.data.groupMemberList);
          setCount(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  const fetchData = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPage,
    };
    makePostRequest(URLS.GET_GROUP_TO_GROUP, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setSubGroupData(res.data.groupMemberList);
          setCount(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(()=>{
    HandleSubGroupData()
  },[joinGroupType])

/*===================================Delete sub and main group Api calling ===========================*/

const HandleDeleteSubAndMainGroupAdmin = () => {
  let data = {
    id: idForDeleteJoinedGroup,
  };
  // console.log(data, "payload delete pending Admin");
  makePostRequest(URLS.DELETE_SUBMAINGROUP, null, data, null)
    .then((res) => {
      if (res.code === 200) {
        toast.success(res.message);
        HandleSubGroupData();
        setOpenForJoinedGroup(false);
      } else {
        setLoader(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

  
  /*===================================Group Member Api calling ===========================*/

  const HandleMemberGroupData = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageMemberList,
      start_date: selectedFromDate ? moment(selectedFromDate).format("YYYY-MM-DD") : "",
      end_date: selectedToDate ? moment(selectedToDate).format("YYYY-MM-DD") : "",
      status: "stay",
    };
    // console.log(data,"payload data"  )
    makePostRequest(URLS.GET_MEMBER_GROUPLIST, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "groupmember");
          setLoader(false);
          setGroupMemberList(res.data.groupMemberList);
          setCountMemberList(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChangeMemberList = (selectedPage) => {
    setCurrentPageMemberList(selectedPage.selected + 1);
  };
  // const fetchDataMemberList = () => {
  //   let data = {
  //     group_id: Group_id,
  //     page_number: currentPageMemberList,
  //     start_date: selectedFromDate ? moment(selectedFromDate).format("YYYY-MM-DD") : "",
  //     end_date: selectedToDate ? moment(selectedToDate).format("YYYY-MM-DD") : "",
  //   };
  //   makePostRequest(URLS.GET_MEMBER_GROUPLIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setGroupMemberList(res.data.groupMemberList);
  //         setCountMemberList(Math.ceil(res.data.count / 10));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    HandleMemberGroupData();
  }, [currentPageMemberList]);

  /*========================================Delete Active member Members/admin both Api Calling here  ===========================*/

  const HandleDeleteGroupMembers = () => {
    // setOpen(false);
    let data = {
      remove_type: "admin",
      id: memberId,
      status: "removed",
    };
    // console.log(data, "payload delete Active");
    makePostRequest(URLS.DELETE_GROUPMEMBERS, null, data, null)
      .then((res) => {
        // console.log(res, "delete Active member api call");

        if (res.code === 200) {
          toast.success(res.message);
          setOpen(false);
          HandleMemberGroupData();
          HandleGroupAdminList();
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*========================================Delete Pending admin  Api Calling here  ===========================*/

  const HandleDeletePendingAdmin = () => {
    let data = {
      id: pendingAdminId,
    };
    // console.log(data, "payload delete pending Admin");
    makePostRequest(URLS.DELETE_PENDING_ADMINS, null, data, null)
      .then((res) => {
        // console.log(res, "delete pending Admin api");
        if (res.code === 200) {
          toast.success(res.message);
          HandleGroupAdminList();
          setOpenForAdmin(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*====================================Pending Members Api Calling Here======================*/

  const HandlePendingMemberData = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPagePendingMemberList,
      start_date: selectedFromDate ? moment(selectedFromDate).format("YYYY-MM-DD") : "",
      end_date: selectedToDate ? moment(selectedToDate).format("YYYY-MM-DD") : "",
    };

    // console.log(data, "payload datapending");
    makePostRequest(URLS.PENDING_GROUPMEMBERS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "pending groupmember");
          setLoader(false);
          setPendingGroupMemberList(res.data.groupMemberList);
          setCountPendingMemberList(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangePendingMemberList = (selectedPage) => {
    setCurrentPagePendingMemberList(selectedPage.selected + 1);
  };

  useEffect(() => {
    HandlePendingMemberData();
  }, [currentPagePendingMemberList]);

  useEffect(() => {
    HandlePendingMemberData();
  }, []);

    useEffect(()=>{
  if(memberStatus==="activemember") {
    setSelectedFromDate("")
    setSelectedToDate("")
    HandleMemberGroupData()
  }
  if(memberStatus==="pendingmembers"){
    HandlePendingMemberData()
    setSelectedFromDate("")
    setSelectedToDate("")
  }
    },[memberStatus])

  /*===================================Group Resource Api calling ===========================*/

  const HandleGroupResource = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageResource,
      limit: 10,
      resource_type: resourceTypeResource ? resourceTypeResource : "",
      learning_pillar_id: learningIdResource ? learningIdResource : "",
    };
    // console.log(data, "resourcepay");
    makePostRequest(URLS.GET_ALL_GROUPRESOURCE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Resource");
          setLoader(false);
          setGroupResource(res.data.data);
          setCountResource(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChangeResource = (selectedPage) => {
    setCurrentPageResource(selectedPage.selected + 1);
  };
  const fetchDataResource = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPageResource,
      limit: 10,
      resource_type: resourceTypeResource ? resourceTypeResource : "",
      learning_pillar_id: learningIdResource ? learningIdResource : "",
    };
    makePostRequest(URLS.GET_ALL_GROUPRESOURCE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupResource(res.data.data);
          setCountResource(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataResource();
  }, [currentPageResource]);

  /*===================================Group Sale History Api calling ===========================*/

  const [groupSaleHistory, setGroupSaleHistory] = useState([]);
  const [countGroupSaleHistory, setCountGroupSaleHistory] = useState();
  const [currentPageGroupSaleHistory, setCurrentPageGroupSaleHistory] = useState(1);

  const HandleGroupSaleHistory = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      resource_type_status: scheduleType ? scheduleType : "group",
      start_date: selectedFromDateSaleHistory ? moment(selectedFromDateSaleHistory).format("YYYY-MM-DD") : "",
      end_date: selectedToDateSaleHistory ? moment(selectedToDateSaleHistory).format("YYYY-MM-DD") : "",
      page_number: currentPageGroupSaleHistory,
      limit: 10,
    };
    makePostRequest(URLS.GROUPSALEHISTORY, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "groupSalePurchase");
          setLoader(false);
          setGroupSaleHistory(res.data.data);
          setCountGroupSaleHistory(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChangeSaleHistory = (selectedPage) => {
    setCurrentPageGroupSaleHistory(selectedPage.selected + 1);
  };
  const fetchDataSaleHistory = () => {
    let data = {
      group_id: Group_id,
      resource_type_status: scheduleType ? scheduleType : "group",
      start_date: selectedFromDateSaleHistory ? moment(selectedFromDateSaleHistory).format("YYYY-MM-DD") : "",
      end_date: selectedToDateSaleHistory ? moment(selectedToDateSaleHistory).format("YYYY-MM-DD") : "",
      page_number: currentPageGroupSaleHistory,
      limit: 10,
    };
    makePostRequest(URLS.GROUPSALEHISTORY, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupSaleHistory(res.data.data);
          setCountGroupSaleHistory(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataSaleHistory();
  }, [currentPageGroupSaleHistory]);

  /*=============================Group Resource1 =======================*/

  const HandleGroupResources1 = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageGroupResources1,
      start_date: selectedFromDateResource1 ? moment(selectedFromDateResource1).format("YYYY-MM-DD") : "",
      end_date: selectedToDateResource1 ? moment(selectedToDateResource1).add(1, "d").format("YYYY-MM-DD") : "",
      keyword: search,
      resource_type: resourceType ? resourceType : "",
      learning_pillar_id: learningId ? learningId : "",
      item_per_page: 10,
      // group_id:Group_id,
      //   keyword:search,
      //   shared_by_id:Sharedid,
      //  learning_pillar_id:learningId ? learningId :"",
      // page_number: currentPageGroupResources1,
      //resource_type:resourceType ? resourceType :"",
      // start_date:selectedFromDateResource1 ? moment(selectedFromDateResource1).format("YYYY-MM-DD") : "",
      // end_date:selectedToDateResource1 ? moment(selectedToDateResource1).add(1,'d').format("YYYY-MM-DD") : "",
      // item_per_page:10
    };
    // console.log(data, "GroupresourcePayload");
    makePostRequest(URLS.GET_GROUP_RESOURCE1, null, data, null)
      .then((res) => {
        // console.log(res, "GroupResource");
        if (res.code === 200) {
          setLoader(false);
          setGroupResourcesData1(res.data.data);
          setCountGroupResources1(Math.ceil(res.data.count / 10));
          // setSharedById(res.data.response)
          // let option=[];
          // res.data.response.map((val,i)=>{
          //   option.push({value:val.resource_shared_by_user.user_id , label:val.resource_shared_by_user.name})
          // })
          // setShareByoptions(option)
        }
        // else {
        //   setLoader(false);
        // }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  /*=====pagination========*/

  const handlePageGroupResources1 = (selectedPage) => {
    setCurrentPageGroupResources1(selectedPage.selected + 1);
  };
  const fetchDataGroupResources1 = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPageGroupResources1,
      start_date: selectedFromDateResource1 ? moment(selectedFromDateResource1).format("YYYY-MM-DD") : "",
      end_date: selectedToDateResource1 ? moment(selectedToDateResource1).add(1, "d").format("YYYY-MM-DD") : "",
      keyword: search,
      resource_type: resourceType ? resourceType : "",
      learning_pillar_id: learningId ? learningId : "",
      item_per_page: 10,
      // group_id:Group_id,
      //   keyword:search,
      //   shared_by_id:Sharedid,
      //  learning_pillar_id:learningId ? learningId :"",
      // page_number: currentPageGroupResources1,
      // resource_type:resourceType ? resourceType :"",
      // start_date:selectedFromDateResource1 ? moment(selectedFromDateResource1).format("YYYY-MM-DD") : "",
      // end_date:selectedToDateResource1 ? moment(selectedToDateResource1).add(1,'d').format("YYYY-MM-DD") : "",
      // item_per_page:10
    };

    makePostRequest(URLS.GET_GROUP_RESOURCE1, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupResourcesData1(res.data.data);
          setCountGroupResources1(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataGroupResources1();
  }, [currentPageGroupResources1]);

  /*=============================Event =======================*/

  const HandleGroupEvent = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      keyword: searchEvent,
      shared_by_id: SharedidEvent ? SharedidEvent : "",
      learning_pillar_id: learningIdEvent ? learningIdEvent : "",
      page_number: currentPageEvent,
      //  resource_type:resourceTypeEvent ? resourceTypeEvent :"",
      start_date: selectedFromDateEvent ? moment(selectedFromDateEvent).format("YYYY-MM-DD") : "",
      end_date: selectedToDateEvent ? moment(selectedToDateEvent).add(1, "d").format("YYYY-MM-DD") : "",
      item_per_page: 10,
    };
    // console.log(data, "eventdata");
    makePostRequest(URLS.GET_GROUP_EVENT, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "groupevent");
          setLoader(false);
          setGroupEvent(res.data.response);
          setCountEvent(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageEvent = (selectedPage) => {
    setCurrentPageEvent(selectedPage.selected + 1);
  };
  const fetchDataEvent = () => {
    let data = {
      group_id: Group_id,
      keyword: searchEvent,
      shared_by_id: SharedidEvent ? SharedidEvent : "",
      learning_pillar_id: learningIdEvent ? learningIdEvent : "",
      page_number: currentPageEvent,
      resource_type: resourceTypeEvent ? resourceTypeEvent : "",
      start_date: selectedFromDateEvent ? moment(selectedFromDateEvent).format("YYYY-MM-DD") : "",
      end_date: selectedToDateEvent ? moment(selectedToDateEvent).add(1, "d").format("YYYY-MM-DD") : "",
      item_per_page: 10,
    };
    makePostRequest(URLS.GET_GROUP_EVENT, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupEvent(res.data.response);
          setCountEvent(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataEvent();
  }, [currentPageEvent]);

  const HandleSharedByEventOptions = () => {
    let data = {
      group_id: Group_id,
      page_number: 1,
      limit: 10,
    };
    // console.log(data, "EventOptionpayload");
    makePostRequest(URLS.GET_GROUP_EVENT_OPTIONS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "groupEventoptions");
          let option = [];
          res.data.data.map((val, i) => {
            option.push({ value: val.tbl_user.id, label: val.tbl_user.firstName + " " + val.tbl_user.lastName });
          });
          setShareByoptions(option);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=============================Group Feed =======================*/
  const HandleGroupFeed = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      learning_pillar_id: learningIdFeed ? learningIdFeed : "",
      page_number: currentPageFeed,
      resource_type: resourceTypeFeed ? resourceTypeFeed : "",
      item_per_page: 10,
    };
    // console.log(data, "Feeddata");
    makePostRequest(URLS.GET_GROUP_FEED, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "groupfeed");
          setLoader(false);
          setGroupFeed(res.data.response);
          setCountGroupFeed(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageGroupFeed = (selectedPage) => {
    setCurrentPageFeed(selectedPage.selected + 1);
  };

  // const fetchDataFeed = () => {
  //   let data = {
  //     group_id: Group_id,
  //     learning_pillar_id: learningIdFeed ? learningIdFeed : "",
  //     page_number: currentPageFeed,
  //     resource_type: resourceTypeFeed ? resourceTypeFeed : "",
  //     item_per_page: 6,
  //   };

  //   makePostRequest(URLS.GET_GROUP_FEED, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         setGroupFeed(res.data.response);
  //         setCountGroupFeed(Math.ceil(res.data.count / 10));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    HandleGroupFeed();
  }, [currentPageFeed]);

  /*=============================bulletin Board =======================*/
  const HandleBulletinboard = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageBulletin,
      item_per_page: 10,
    };
    // console.log(data,"bulletindata");
    makePostRequest(URLS.GET_BULLETIN, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //console.log(res, "bulletinres");
          setLoader(false);
          setBulletinBoard(res.data.response);
          setCountBulletin(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageBulletin = (selectedPage) => {
    setCurrentPageBulletin(selectedPage.selected + 1);
  };
  const fetchDataBulletin = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPageBulletin,
      item_per_page: 10,
    };

    makePostRequest(URLS.GET_BULLETIN, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setBulletinBoard(res.data.response);
          setCountBulletin(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataBulletin();
  }, [currentPageBulletin]);

  /*=============================Chat List =======================*/
  const HandleChat = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageChat,
      item_per_page: 10,
    };
    //console.log(data,"Chatdata");
    makePostRequest(URLS.GET_CHAT_LIST, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Chatres");
          setLoader(false);
          setChat(res.data.response);
          setCountChat(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChat = (selectedPage) => {
    setCurrentPageChat(selectedPage.selected + 1);
  };
  const fetchDataChat = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPageChat,
      item_per_page: 10,
    };

    makePostRequest(URLS.GET_CHAT_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setChat(res.data.response);
          setCountChat(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataChat();
  }, [currentPageChat]);

  /*===================================Learning Piller Api calling====================*/

  const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res,"lp")
          let options = [];
          res.data.length > 0 &&
            res.data.map((val, i) => {
              options.push({ value: val.id, label: val.name });
            });
          setLearningData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*===================================Resource Type Api calling====================*/
  const getAllResourceType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*============================Group Admin List=============================*/
  const [currentPageAdminList, setCurrentPageAdminList] = useState(1);
  const [countAdminList, setCountAdminList] = useState();

  const HandleGroupAdminList = () => {
    setLoader(true);
    let data = {
      group_id: Group_id,
      page_number: currentPageAdminList,
      // start_date: selectedFromDate ? moment(selectedFromDate).format("YYYY-MM-DD") : "",
      // end_date: selectedToDate ? moment(selectedToDate).format("YYYY-MM-DD") : "",
      // isAdmin: 1,
    };
    // console.log(data,"payload admin"  )
    makePostRequest(URLS.GET_GROUPADMIN_LIST, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Adminlist");
          setLoader(false);
          setGroupAdminList(res.data.groupMemberList);
          setCountAdminList(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*=====pagination========*/

  const handlePageChangeAdminList = (selectedPage) => {
    setCurrentPageAdminList(selectedPage.selected + 1);
  };
  const fetchDataAdminList = () => {
    let data = {
      group_id: Group_id,
      page_number: currentPageAdminList,
      start_date: selectedFromDate ? moment(selectedFromDate).format("YYYY-MM-DD") : "",
      end_date: selectedToDate ? moment(selectedToDate).format("YYYY-MM-DD") : "",
      isAdmin: 1,
    };
    makePostRequest(URLS.GET_MEMBER_GROUPLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupAdminList(res.data.groupMemberList);
          setCountAdminList(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataAdminList();
  }, [currentPageAdminList]);

  let newArray = [];
  if (groupGetOnedata) {
    let i;
    let convertArray = groupGetOnedata.shared_with.split(",");

    for (i = 0; i < convertArray.length; i++) {
      if (convertArray[i] == "local") {
        newArray.push("Local MatchED Community");
      }
      if (convertArray[i] == "friend") {
        newArray.push("Friend");
      }
      if (convertArray[i] == "group") {
        newArray.push("Group");
      }
      if (convertArray[i] == "matched") {
        newArray.push("MatchED");
      }
      // console.log(newArray,"newarray")
    }
    // console.log(convertArray,"convertArray")
  }
  // console.log(newArray.join(", "), "newarray");

  useEffect(() => {
    HandleGetOneGroupData();
    HandleSubGroupData();
    HandleMemberGroupData();
    HandleGroupResource();
    HandleGroupResources1();
    HandleGroupSaleHistory();
    HandleGroupEvent();
    HandleSharedByEventOptions();
    HandleGroupFeed();
    getLearningPillarsData();
    getAllResourceType();
    HandleBulletinboard();
    HandleChat();
    HandleGroupAdminList();
  }, []);

  return (
    <>
      <Head title="Group Details"></Head>
      <Content>
        {/*============ Modal for delete Active members both admin list and group members =============*/}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              Are you sure you want to delete this member ?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }} className="d-flex justify-content-center gap-4">
              <button className="child-delete child-cancelnew" onClick={HandleDeleteGroupMembers}>
                Delete
              </button>{" "}
              <button className="child-cancel " onClick={handleClose}>
                Cancel
              </button>
            </Typography>
          </Box>
        </Modal>

        {/*========================== modal for delete pending admin only ===========================*/}
        <Modal
          open={openForAdmin}
          onClose={handleCloseForAdmin}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              Are you sure you want to delete this member ?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }} className="d-flex justify-content-center gap-4">
              <button className="child-delete" onClick={HandleDeletePendingAdmin}>
                Delete
              </button>{" "}
              <button className="child-cancel" onClick={handleCloseForAdmin}>
                Cancel
              </button>
            </Typography>
          </Box>
        </Modal>


         {/*========================== modal for delete main and sub group  ===========================*/}
         <Modal
          open={openForJoinedGroup}
          onClose={handleCloseForJoinedGroup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              Are you sure you want to delete this group ?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }} className="d-flex justify-content-center gap-4">
              <button className="child-delete child-cancelnew" onClick={HandleDeleteSubAndMainGroupAdmin}>
                Delete
              </button>{" "}
              <button className="child-cancel" onClick={handleCloseForJoinedGroup}>
                Cancel
              </button>
            </Typography>
          </Box>
        </Modal>

        <h3 className="nk-block-title page-title mb5 pb-4">
          {" "}
          Group Details ({groupGetOnedata?.group_name ? groupGetOnedata?.group_name : ""})
          <button
            onClick={() => history.goBack()}
            className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end"
          >
            <em className="icon ni ni-arrow-left"></em>
            <span>Back</span>
          </button>
        </h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <PreviewCard>
            <Row className="g-gs">
              <Col md={4}>
                <ul className="nav link-list-menu border border-light round m-0">
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "1",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("1");
                      }}
                    >
                      <Icon name="user"></Icon>
                      <span>Group Details</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "3",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("3");
                      }}
                    >
                      <Icon name="save"></Icon>
                      <span>Group Members</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "9",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("9");
                      }}
                    >
                      <Icon name="user"></Icon>
                      <span>Group Admin(s)</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "2",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("2");
                      }}
                    >
                      <Icon name="lock-alt"></Icon>
                      <span>Joined Groups</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "5",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("5");
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Group Resources</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "4",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("4");
                      }}
                    >
                      <Icon name="file-docs"></Icon>
                      <span>Resources</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "6",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("6");
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Group Event</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "7",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("7");
                      }}
                    >
                      <Icon name="user-list-fill"></Icon>
                      <span>Group Feed</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "8",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("8");
                      }}
                    >
                      <Icon name="user-list-fill"></Icon>
                      <span>Bulletin Board</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "10",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("10");
                      }}
                    >
                      <Icon name="user-list-fill"></Icon>
                      <span>Group Sale History</span>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={8}>
                <TabContent activeTab={verticalIconTab}>
                  <TabPane tabId="1">
                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Group Owner Name</div>
                            <div className="data-value">
                              {groupGetOnedata?.tbl_user?.firstName ? groupGetOnedata?.tbl_user?.firstName : "--"}
                              {""} {groupGetOnedata?.tbl_user?.lastName}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Group Name</div>
                            <div className="data-value">
                              {" "}
                              {groupGetOnedata?.group_name ? groupGetOnedata?.group_name : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Register Date</div>
                            <div className="data-value">
                              {moment(groupGetOnedata?.created_at ? groupGetOnedata?.created_at : "--").format(
                                "MM-DD-YYYY"
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Members</div>
                            <div className="data-value">
                              {groupGetOnedata?.no_of_user ? groupGetOnedata?.no_of_user : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col scrollonviewres">
                            <div className="data-label">About Group</div>
                            <div
                              className="data-value text-break"
                              dangerouslySetInnerHTML={{
                                __html: groupGetOnedata?.about_group ? groupGetOnedata?.about_group : "--",
                              }}
                            >
                              {/* {groupGetOnedata?.about_group ? groupGetOnedata?.about_group :"--" } */}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Shared With</div>
                            {/* <div className="data-value">{groupGetOnedata?.shared_with ? groupGetOnedata?.shared_with :"--"}</div> */}
                            <div className="data-value"> {newArray.join(", ")}</div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Group Chat Permission</div>
                            <div className="data-value ">
                              {groupGetOnedata?.group_chat_permission === "1" ? "Enable" : "Disable"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Stripe Account ID</div>
                            <div className="data-value ">
                              {groupGetOnedata?.stripe_accountId ? groupGetOnedata?.stripe_accountId : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Group Join Permission</div>
                            <div className="data-value ">
                              {groupGetOnedata?.group_join_permission
                                ? capital(groupGetOnedata?.group_join_permission.replaceAll("_", " "))
                                : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Group Transaction Fee</div>
                            <div className="data-value ">
                              ${" "}
                              {groupGetOnedata?.group_transtion_fee
                                ? (groupGetOnedata?.group_transtion_fee).toFixed(2)
                                : "0.00"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Grade(s)</div>
                            {/* <div className="data-value ">{groupGetOnedata?.tbl_group_grade_mappings?.[0]?.tbl_grade_level?.name}</div> */}
                            <div className="data-value " style={{ width: "50%" }}>
                              {groupGetOnedata?.tbl_group_grade_mappings?.length > 0
                                ? groupGetOnedata?.tbl_group_grade_mappings &&
                                  groupGetOnedata?.tbl_group_grade_mappings
                                    ?.map((val) => val?.tbl_grade_level?.name)
                                    .join(", ")
                                : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Keywords</div>
                            {/* <div className="data-value ">{groupGetOnedata?.tbl_group_keywords?.[0]?.keyword}</div> */}

                            {groupGetOnedata?.tbl_group_keywords?.length > 0
                              ? groupGetOnedata?.tbl_group_keywords &&
                                groupGetOnedata?.tbl_group_keywords?.map((val) => val?.keyword).join(", ")
                              : "--"}
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Learning Pillar(s)</div>
                            {/* <div className="data-value ">{groupGetOnedata?.tbl_group_learning_pillars?.[0]?.tbl_learning_pillar_master?.name}</div> */}
                            {groupGetOnedata?.tbl_group_learning_pillars &&
                              groupGetOnedata?.tbl_group_learning_pillars.length > 0 &&
                              groupGetOnedata?.tbl_group_learning_pillars
                                ?.map((val) => val?.tbl_learning_pillar_master?.name)
                                .join(", ")}
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Rating</div>
                            <div className="data-value ">
                              {groupGetOnedata?.overall_rating ? groupGetOnedata?.overall_rating : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Review</div>
                            <div className="data-value ">
                              {groupGetOnedata?.total_review ? groupGetOnedata?.total_review : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Referral Code</div>
                            <div className="data-value ">
                              {groupGetOnedata?.group_referral_code ? groupGetOnedata?.group_referral_code : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">URL</div>
                            <div className="data-value text-break">
                              <a href={groupGetOnedata?.url} target="_blank">
                                {groupGetOnedata?.url ? groupGetOnedata?.url : "--"}
                              </a>
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Address</div>
                            <div className="data-value ">
                              {groupGetOnedata?.address ? groupGetOnedata?.address : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">State</div>
                            <div className="data-value ">{groupGetOnedata?.state ? groupGetOnedata?.state : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">City</div>
                            <div className="data-value ">{groupGetOnedata?.city ? groupGetOnedata?.city : "--"}</div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Latitude</div>
                            <div className="data-value ">
                              {groupGetOnedata?.latitude ? groupGetOnedata?.latitude : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Longitude</div>
                            <div className="data-value ">
                              {groupGetOnedata?.longitude ? groupGetOnedata?.longitude : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Zip Code</div>
                            <div className="data-value ">
                              {groupGetOnedata?.zipcode ? groupGetOnedata?.zipcode : "--"}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Card>
                  </TabPane>
                  <TabPane tabId="2">

                  <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Group Type</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleGroupTypes}
                            value={joinGroupType}
                          >
                            <option value="Maingroup"> Main Group </option>
                            <option value="Subgroup">Sub Group</option>
                          </select>
                        </div>
                      </div>

                    <div className="">
                      {subGroupData && subGroupData.length > 0 ? (
                        <ReactDataTable
                          data={subGroupData}
                          columns={columns}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {subGroupData?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label>
                            <strong>Members Type</strong>
                          </label>
                          <select className="form-select" onChange={handleStatusMember} value={memberStatus}>
                            {/* <option value=""> Select Status </option> */}
                            <option value="activemember">Active Members</option>
                            <option value="pendingmembers">Pending Members</option>
                            {/* <option value="leave">Leave</option> */}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedFromDate}
                            className="form-control date-picker"
                            onChange={HandleFromDate}
                          />
                        </div>
                        <span className="error">{toDateError}</span>
                      </div> */}

                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedToDate}
                            className="form-control date-picker"
                            onChange={HandleToDate}
                          />{" "}
                        </div>
                        <span className="error">{fromDateError}</span>
                      </div> */}

                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlevalidationDate}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div> */}
                    </div>
                    {memberStatus === "activemember" || memberStatus == "" ? (
                      <div>
                        {groupMemberList && groupMemberList.length > 0 ? (
                          <ReactDataTable 
                          data={groupMemberList}
                           columns={columns1}
                            className="nk-tb-list"
                             />
                        ) : (
                          <div class="sc-fLcnxK dApqnJ">
                            <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                              <div class="sc-iveFHk bzRnkJ">
                                <div class="p-2">No Data Found</div>
                              </div>
                            </div>
                          </div>
                        )}

                        {groupMemberList?.length > 0 ? (
                          <div>
                            <ReactPaginate
                              previousLabel={"<<"}
                              nextLabel={">>"}
                              pageCount={countMemberList}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChangeMemberList}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              forcePage={currentPageMemberList-1}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div>
                        {pendingGroupMemberList && pendingGroupMemberList.length > 0 ? (
                          <ReactDataTable
                            data={pendingGroupMemberList}
                            columns={columnsPendingMembers}
                            className="nk-tb-list"
                          />
                        ) : (
                          <div class="sc-fLcnxK dApqnJ">
                            <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                              <div class="sc-iveFHk bzRnkJ">
                                <div class="p-2">No Data Found</div>
                              </div>
                            </div>
                          </div>
                        )}

                        {pendingGroupMemberList?.length > 0 ? (
                          <div>
                            <ReactPaginate
                              previousLabel={"<<"}
                              nextLabel={">>"}
                              pageCount={countPendingMemberList}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChangePendingMemberList}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              forcePage={currentPagePendingMemberList - 1}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="row pb-2">
                      <div className="col-lg-6">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label>
                            <strong>Resource Type</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleResourceTypeResource}
                            value={resourceTypeResource}
                          >
                            <option value=""> Select Resource </option>
                            {getAllResourceNew.map((val, index) => {
                              return (
                                <>
                                  <option value={val.id} key={index + 1}>
                                    {val.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Learning Pillar</strong>
                            </label>
                            <div>
                              <Select
                                value={pillerIdResource && pillerIdResource.length > 0 ? pillerIdResource : null}
                                options={learningData}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleLearningResource}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button
                            className="btn btn-md btn-primary"
                            type="submit"
                            onClick={handlevalidationShareResoure}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="tables table-responsive">
                      {groupResource && groupResource.length > 0 ? (
                        <ReactDataTable
                          data={groupResource}
                          columns={columns2}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {groupResource?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={countResource}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChangeResource}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="5">
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label>
                            <strong>Resource Type</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleResourceType}
                            value={resourceType}
                          >
                            <option value=""> Select Resource </option>
                            {getAllResourceNew.map((val, index) => {
                              return (
                                <>
                                  <option value={val.id} key={index + 1}>
                                    {val.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Learning Pillar</strong>
                            </label>
                            <div>
                              <Select
                                value={pillerId && pillerId.length > 0 ? pillerId : null}
                                options={learningData}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleLearning}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-4">
       <div className="form-control border-0 px-0">
<div className="">
<label><strong>Shared by</strong></label>
                <div>
                  <Select
                    value={sharedById && sharedById.length> 0 ? sharedById : null} 
                    options={shareByoptions}
                    isMulti
                    selectMultiple={true}
                    onChange={handleSharedBy}
                  />
                </div>
              </div>
              </div></div> */}

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Title</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="Search..."
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedFromDateResource1}
                            className="form-control date-picker"
                            onChange={HandleFromDateResource1}
                          />
                        </div>
                        <span className="error">{toDateErrorResource1}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedToDateResource1}
                            className="form-control date-picker"
                            onChange={HandleToDateResource1}
                          />{" "}
                        </div>
                        <span className="error">{fromDateErrorResource1}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button
                            className="btn btn-md btn-primary "
                            type="submit"
                            onClick={handlevalidationDateResource1}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="tables table-responsive">
                      {groupResourcesData1 && groupResourcesData1.length > 0 ? (
                        <ReactDataTable
                          data={groupResourcesData1}
                          columns={columnsresource1}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {groupResourcesData1?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={countGroupResources1}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageGroupResources1}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="6">
                    <div className="row pb-2">
                      {/* <div className="col-lg-4">
<div className="form-control border-0 px-0" style={{marginBottom: "10px"}}>
<label><strong>Resource Type</strong></label>
<select  className="form-select"  name="resourceType" onChange={handleResourceTypeEvent} value={resourceTypeEvent} >
<option value=""> Select Resource </option>
{  
getAllResourceNew.map((val,index)=>{
return(<>
<option value={val.id} key={index + 1}>{val.name}</option>

</>)					
})

}
</select> 
</div>
</div> */}

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Learning Pillar</strong>
                            </label>
                            <div>
                              <Select
                                value={pillerIdEvent && pillerIdEvent.length > 0 ? pillerIdEvent : null}
                                options={learningData}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleLearningPillerEvent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Shared by</strong>
                            </label>
                            <div>
                              <Select
                                value={sharedByIdEvent && sharedByIdEvent.length > 0 ? sharedByIdEvent : null}
                                options={shareByoptions}
                                isMulti
                                selectMultiple={true}
                                onChange={handleSharedByEvent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Title</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="Search..."
                            type="text"
                            value={searchEvent}
                            onChange={(e) => setSearchEvent(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedFromDateEvent}
                            className="form-control date-picker"
                            onChange={HandleFromDateEvent}
                          />
                        </div>
                        <span className="error">{toDateErrorEvent}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedToDateEvent}
                            className="form-control date-picker"
                            onChange={HandleToDateEvent}
                          />{" "}
                        </div>
                        <span className="error">{fromDateErrorEvent}</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handlevalidationEvent}>
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                    </div>
                    <div className="tables table-responsive">
                      {groupEvent && groupEvent.length > 0 ? (
                        <ReactDataTable
                          data={groupEvent}
                          columns={columnEvent}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {groupEvent?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={counEvent}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageEvent}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tabId="7">
                    <div className="row pb-2">
                      <div className="col-lg-6">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label>
                            <strong>Resource Type</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleResourceTypeFeed}
                            value={resourceTypeFeed}
                          >
                            <option value=""> Select Resource </option>
                            {getAllResourceNew.map((val, index) => {
                              return (
                                <>
                                  <option value={val.id} key={index + 1}>
                                    {val.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-control border-0 px-0">
                          <div className="">
                            <label>
                              <strong>Learning Pillar</strong>
                            </label>
                            <div>
                              <Select
                                value={pillerIdFeed && pillerIdFeed.length > 0 ? pillerIdFeed : null}
                                options={learningData}
                                isMulti
                                selectMultiple={true}
                                onChange={HandleLearningPillerFeed}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-4">
<div className="form-control border-0 px-0">
<div className="">
<label><strong>Shared by</strong></label>
<div>
  <Select
    value={""} 
    options={"learningData"}
    isMulti
    selectMultiple={true}
    onChange={"HandleLearning"}
  />
</div>
</div>
</div></div> */}

                      {/* <div className="col-lg-4">
<div className="form-control border-0 px-0">
<label><strong>Start Date</strong></label>
<DatePicker  placeholderText="MM/DD/YYYY"
selected={selectedFromDateFeed} 
className="form-control date-picker" 
onChange={HandleFromDateFeed}/>
</div>
<span className="error">{toDateErrorFeed}</span>
</div> */}

                      {/* <div className="col-lg-4">	
<div className="form-control border-0 px-0">
<label><strong>End Date</strong></label>
<DatePicker  placeholderText="MM/DD/YYYY"
selected={selectedToDateFeed} 
className="form-control date-picker" 
onChange={HandleToDateFeed}/>                      </div>
<span className="error">{fromDateErrorFeed}</span>
</div> */}
                    </div>
                    <div className="col-lg-12">
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-md btn-primary" type="submit" onClick={handlevalidationDateFeed}>
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                    </div>
                    <div className="tables table-responsive">
                      {groupFeed && groupFeed.length > 0 ? (
                        <ReactDataTable
                          data={groupFeed}
                          columns={columnFeed}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {groupFeed?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={countGroupFeed}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageGroupFeed}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPageFeed-1}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>

                  <TabPane tabId="8">
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Filterd by</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleBulletinName}
                            value={bulletinType}
                          >
                            <option value=""> Filterd by</option>
                            <option value="Resource"> Resource </option>
                            <option value="Chat"> Chat</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-12">
<div className="outerTypeBox mb-3"> 
<button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleBulletin}> Search </button>
</div>
</div> */}

                    {bulletinType === "Resource" || bulletinType === "" ? (
                      <div className="tables table-responsive">
                        {bulletinBoard && bulletinBoard.length > 0 ? (
                          <div>
                            <ReactDataTable
                              data={bulletinBoard}
                              columns={columnsBulletin}
                              className="nk-tb-list"
                              // searchable={true}
                              // pagination
                            />

                            <div>
                              <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                pageCount={countBulletin}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageBulletin}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                              />
                            </div>
                          </div>
                        ) : (
                          <div class="sc-fLcnxK dApqnJ">
                            <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                              <div class="sc-iveFHk bzRnkJ">
                                <div class="p-2">No Data Found</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="tables table-responsive">
                        {chat && chat.length > 0 ? (
                          <div>
                            <ReactDataTable
                              data={chat}
                              columns={columnsChat}
                              className="nk-tb-list"
                              // searchable={true}
                              // pagination
                            />
                            <div>
                              <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                pageCount={countChat}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChat}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                              />
                            </div>
                          </div>
                        ) : (
                          <div class="sc-fLcnxK dApqnJ">
                            <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                              <div class="sc-iveFHk bzRnkJ">
                                <div class="p-2">No Data Found</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </TabPane>

                  <TabPane tabId="10">
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Schedule Type</strong>
                          </label>
                          <select
                            className="form-select"
                            name="groupType"
                            onChange={handleGroupScheduleType}
                            value={scheduleType}
                          >
                            <option value=""> Select Type</option>
                            <option value="individual">Individual </option>
                            <option value="group">Group</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedFromDateSaleHistory}
                            className="form-control date-picker"
                            onChange={HandleFromDateSaleHistory}
                          />
                        </div>
                        <span className="error">{toDateErrorSaleHistory}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedToDateSaleHistory}
                            className="form-control date-picker"
                            onChange={HandleToDateSaleHistory}
                          />{" "}
                        </div>
                        <span className="error">{fromDateErrorSaleHistory}</span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleSaleHistory}>
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                    </div>

                    <div className="tables table-responsive">
                      {groupSaleHistory && groupSaleHistory.length > 0 ? (
                        <div>
                          <ReactDataTable
                            data={groupSaleHistory}
                            columns={columnsSaleHistoryList}
                            className="nk-tb-list"
                            // searchable={true}
                            // pagination
                          />

                          <div>
                            <ReactPaginate
                              previousLabel={"<<"}
                              nextLabel={">>"}
                              pageCount={countGroupSaleHistory}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChangeSaleHistory}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPane>

                  <TabPane tabId="9">
                    <div className="">
                      {groupAdminList && groupAdminList.length > 0 ? (
                        <div>
                          <ReactDataTable data={groupAdminList} columns={columnsAdminList} className="nk-tb-list" />

                          <div>
                            <ReactPaginate
                              previousLabel={"<<"}
                              nextLabel={">>"}
                              pageCount={countAdminList}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChangeAdminList}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div class="sc-fLcnxK dApqnJ">
                          <div class="sc-bcXHqe kVrXuC rdt_Table" role="table">
                            <div class="sc-iveFHk bzRnkJ">
                              <div class="p-2">No Data Found</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </PreviewCard>
        )}
      </Content>
    </>
  );
}
export default ViewGroupDetails;
