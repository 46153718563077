import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from 'react';
import { URLS } from "../../../constants/urls";
import {
	BlockHead,
	BlockHeadContent
} from "../../../components/Component";
import {
	Icon,
	ReactDataTable,
} from "../../../components/Component";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, getHeaders, makePostRequest, isProduction, secretKey } from "../../../service/api";
import axios from 'axios';
import CryptoJS from "crypto-js";

function VideoUpload() {

	const [name, setName] = useState({});
	const [isUpdate, setIsUpdate] = useState("");
	const [errorfile, setErrorFile] = useState('');
	const [vedio, setVedio] = useState([]);
	const [loader, setLoader] = useState(false);
	const [show,setShow]=useState(false);


	const columns = [
		{
			name: '#',
			selector: row => row.id,
			grow: 0,
			minWidth: "50px",
			style: { minWidth: "50px" },
			borderRadius: "4px",
			cell: (row, index) => (
				<>
					<div className="user-card mt-2 mb-2"> {index + 1} </div>

				</>
			)
		},

		{
			name: 'Video',
			selector: row => row.image,
			grow: 2,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<div className="user-info">
						<span className="tb-lead">
							<video width="200" height="200" controls>
								<source src={row?.content} type="video/mp4" />
							</video>
						</span>
					</div>
				</div>
			)
		},

		{
			name: 'Action',
			selector: row => row.status,
			cell: (row) => (
				<div className="user-card mt-2 mb-2">
					<button className="dropdown-item" onClick={() => { handleEditButton(row) }}>
						<Icon name="edit"></Icon>
					</button>
				</div>
			)
		},
	];

	function handleFileUpload(event) {
		setLoader(true);
		let files = event?.target?.files[0];
		// const url = URL.createObjectURL(files);
		// const mimeType = files.type;
		// const fileExtension = mimeType.split("/")[1];
		console.log(files,"files")

		if (files?.type !== 'video/mp4') {
			toast.error('Unsupported file type. Please select an MP4 video.');
			setLoader(false);
			return;
		  }


		const formData = new FormData();
		formData.append("image", files);
		axios.post(BASE_URL + URLS.TEMP_UPLOAD, formData, {
			headers: getHeaders({ "Content-Type": "multipart/form-data" })
		})
			.then(res => {
				if (isProduction == "true") {
					let decryptdata = CryptoJS.AES.decrypt(
						res.data?.data,
						secretKey
					).toString(CryptoJS.enc.Utf8);
					let plainText = JSON.parse(decryptdata);
					console.log("plainText", plainText)
					if (plainText.code === 200) {
						console.log("plainText?.data[0]", plainText?.data)
						setName({ name: "https://api.matchedcommunity.com" + plainText?.data?.file_path, type: plainText?.data?.file_type.replace(".", "") })
						setIsUpdate(plainText?.data?.id)
						toast.success(res.message);
						setLoader(false);
						
						// getMasterVideo();
					}

				}
				console.log(res, "upload")
				if (res.status == 200) {
					toast.success(res.message);

				} else {

				}
			})
	}

	useEffect(() => {
		getMasterVideo();
	}, []);

	const getMasterVideo = () => {
		let data = {
			id: 6,
		}
		setLoader(true);
		makePostRequest(URLS.GET_VIDEO, null, data, null)
			.then(res => {
				console.log(res, "getvideo")
				if (res.code == 200) {
					setVedio(res.data);
					setName({ name: res?.data[0]?.content, type: res?.data[0]?.image_type })
					setLoader(false);
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}


	const handleEditButton = (val) => {
		setShow(true)
		window.scroll({
			top: 0,
			behavior: "smooth"
		})

	}


	// upload api
	const uploadimage = () => {
		makePostRequest(URLS?.VIDEO_UPLOAD, null, {
			"id": 6,
			"temp_id": isUpdate
		}, null).then((res) => {
			if (res.code === 200) {
				toast.success(res.message);
				getMasterVideo()
				setShow(false);
			}

		})
	}
	return (
		<React.Fragment>
			<Head title="video-upload"></Head>
			<Content>
				<ToastContainer />
				<h3 className="nk-block-title page-title mb5 pb-4">Video Upload(Suggested Size=5Mb)</h3>
				{/* <BlockTitle tag="h4">Sidebar Image</BlockTitle>  */}
				{loader ? (
					<div className="overflowloader" id="preload">
						<div className="circle-square" style={{ marginTop: "250px" }}>
							<div className="red" style={{ background: "#AAC0CE" }}></div>
							<div className="blue" style={{ background: "#265472" }}></div>
							<div className="green" style={{ background: "#F19357" }}></div>
							<div className="yellow" style={{ background: "#F0B285" }}></div>
						</div>
					</div>
				) : (
					<Card className="card-bordered">
						<div className="card-inner-lg">
							<BlockHeadContent>
								<BlockHead size="lg">
									{show && 
									
									<div className="row">

										<div className="col-lg-4">

											<div className="form-group">
												<Label className="form-label">
													{/* Video Upload */}
												</Label>
												<div className="form-control-wrap">
													{/* <video src={name.name} width="100%"
														height="20" /> */}
													<div className="user-card-img">
														<div className="">
															<video width="100" height="100" controls>
																<source src={name.name} type="video/mp4" />
															</video>
														</div>
														<div className="clearfix"></div>
														<span className="error"> {errorfile} </span>
													</div>

												</div>
											</div>



										</div>
										<div className="col-lg-4">
											<Label className="form-label w-100">&nbsp;</Label>
											<div className="">

												<button className="btn btn-primarys border btn-md loadvideos">
													<i class="fa fa-cloud-upload uploadicon"></i>
													Upload video
													<input
														className="fileuploadv float-start pt-2"
														type="file"
														onChange={handleFileUpload}
														accept=".mov,.mp4"
													/>
													{/* <input className="fileuploadv float-start mx-2 pt-4" type="file" onChange={handleFileUpload} accept="video/*" name="video_file" /> */}
												</button>

											</div>
										</div>
										<div className="col-lg-4">
											<Label className="form-label w-100">&nbsp;</Label>
											<ul className="videoupdatebtn align-center px-2 float-start">
												<li><button className="btn btn-primary btn-md" type="submit" onClick={uploadimage}>Update </button></li>
											</ul>
										</div>
									</div>
				}

									<div className="outerBox">
										{/* {vedio.length && */}
										<ReactDataTable
											data={vedio}
											columns={columns}
											className="nk-tb-list"
										/>
										{/* } */}
									</div>
									{/* } */}

								</BlockHead>
							</BlockHeadContent>
						</div>
					</Card>
				)}
			</Content>

		</React.Fragment>
	);
};

export default VideoUpload;
