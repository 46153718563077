import React, { useEffect, useState } from "react";
import Icon from "../../../icon/Icon";
import UserAvatar from "../../../user/UserAvatar";
import { transactionData } from "./TransactionData";
import { CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import { Link } from "react-router-dom";
import { makePostRequest, getHeaders } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import {
  BlockHead,
  BlockHeadContent,
  Row,
  Col,
  Button,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
//import { BlockHead, BlockHeadContent } from "../../../components/Component";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Card } from "reactstrap";
import { findUpper, capital } from "../../../../utils/Utils";

const TransactionTable = () => {
  const [data, setData] = useState(transactionData);
  const [renewalData, setRenewalData] = useState([]);

  let todaysDate = new Date();
  let curretdate = moment(todaysDate).format("YYYY-MM-DD");
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const GetAllQuickDashboardRenewalData = () => {
    let data = {
      selected_date: curretdate,
    };
    makePostRequest(URLS.GET_DASHBOARD_RENEWALDATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setRenewalData(res.data.renewal_data);
          // console.log(res,"dashboardRenewal")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllQuickDashboardRenewalData();
  }, []);

  const columns = [
    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 5,

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))}
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">
                {" "}
                {row.tbl_user.firstName ? row.tbl_user.firstName : "--"}{" "}
                {row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
              </span>
              <span className="sub-text">{row.tbl_user.email ? row.tbl_user.email : ""}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Title",

      grow: 5,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row.tbl_subscription_plan.title} </span>
          </div>
        </div>
      ),
    },
    {
      name: "Subscription No.",

      grow: 5,

      sortable: true,
      cell: (row) => <div className="user-card mt-2 mb-2"> {row.subscription_no ? row.subscription_no : "--"} </div>,
    },
    {
      name: "Status",

      grow: 0,

      sortable: true,
      cell: (row) => <div className="user-card mt-2 mb-2"> {row.status ? row.status : "--"} </div>,
    },
    {
      name: "View",

      grow: 1,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-subscriptionplan/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="newregisterGrouptable">
        <CardTitle className="mb-0 borderBottomTitle">
          <h6 className="title p-4 px-2">
            <span className="me-0">Subscription Renewal Next 7 Days</span>{" "}
          </h6>
        </CardTitle>

        <>
          <div className="card-inners relativeClass">
            <BlockHeadContent>
              <BlockHead size="lg">
                {renewalData && renewalData?.length > 0 ? (
                  <div>
                    <div className="color">
                      <ReactDataTable data={renewalData} columns={columns} className="nk-tb-list" />
                    </div>
                  </div>
                ) : (
                  <p className="p-3">No Data Found</p>
                )}
              </BlockHead>
            </BlockHeadContent>
          </div>

          {/* </div> */}
          {/* </Card> */}
        </>
      </div>
    </React.Fragment>
  );
};
export default TransactionTable;
