import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect, useRef } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { Icon, ReactDataTable } from "../../../components/Component";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

exporting(Highcharts);
const ExcelJS = require("exceljs");

function CustomerLoginReport() {
  const [customerloginreport, setCustomerloginreport] = useState([]);
  const [customerloginreportchart, setCustomerloginreportchart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [userNameData, setUserNameData] = useState([]);
  const [userName, setNameUser] = useState("");
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [showchart, setshowchart] = useState([]);
  const datepickerref = useRef(null);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [customerLoginData, setCustomerLoginData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);
  let chartdata = [];
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const HandleUserName = (e) => {
    setNameUser(e.value);
  };
  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };
  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const handlechart = (e) => {
    getcustomerloginreportchart();
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1);
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
      user_id: userName ? userName : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes",
    };
    //console.log(data, "export data")
    makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_REPORTSLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("login export", res.data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("LoginData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Login Date",
              key: "logindate",
              width: 30,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },

            {
              header: "Number of Logins",
              key: "logintime",
              width: 20,
            },
          ];
          res.data.user_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              logindate: val.login_date,
              USER_NAME: val?.tbl_user.firstName
                ? val?.tbl_user.firstName
                : "Guest" + " " + val?.tbl_user.lastName
                ? val?.tbl_user.lastName
                : "",
              OWNEREMAIL: val?.tbl_user.email,
              OWNWERPHONE: val?.tbl_user.phone,
              logintime: val.total_count,
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "LoginReport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
          //console.log(res.data, "res g")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const viewLoginDetails = (row) => {
    let data = {
      login_date: row.login_date,
      user_id: row.tbl_user.id,
    };
    //console.log("login details payload", data)
    makePostRequest(URLS.GET_LOGGING_DETAILS, null, data, null).then((res) => {
      if (res.code == 200) {
        //console.log("login details", res)
        setCustomerLoginData(res.data);
        setIsOpen(!isOpen);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handlesearch = (e) => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getcustomerloginreport();
      setCurrentPage(1);
    }
  };

  //user type get api GET_USER_TYPE
  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
        //console.log(res)
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All" });
          res.data.length > 0 &&
            res.data.map((val) => {
              option.push({ value: val.id, label: val.firstName + " " + val.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getcustomerloginreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userName ? userName : "",
    };
    console.log("data chart:::", data);
    makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_CHARTS, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "login chart data");
          setCustomerloginreportchart(res?.data?.login_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*==============================Customer Login report List Api ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomerloginreport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
      user_id: userName ? userName : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
    };
    //console.log(data, "data")
    makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_REPORTSLIST, null, data, null)
      .then((res) => {
        //console.log(res, "login data list");
        // setLoader(true)
        if (res.code === 200) {
          setCustomerloginreport(res?.data.user_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader1(false);
        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setNameUser("");
  };
  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    selectRefGraph.current.select.setValue("", "select-option");
    setNameUser("");
    setSelectyear(new Date().getFullYear());
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    selectRef.current.select.setValue("", "select-option");
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setlimitvalue("10");
    setCurrentPage(1);
    setSortValue("");
    setNameUser("");
    setClearStateList(!clearStateList);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    getcustomerloginreport();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getcustomerloginreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getcustomerloginreport();
  }, [clearStateList]);

  useEffect(() => {
    getcustomerloginreport();
    getcustomerloginreportchart();
    getUserName();
  }, []);

  useEffect(() => {
    getcustomerloginreport();
  }, [limitvalue, sortvalue]);

  useEffect(() => {
    getchartdata();
  }, [customerloginreportchart]);

  const getchartdata = () => {
    customerloginreportchart?.map((val) => {
      chartdata.push(val.total_user);
    });
    setshowchart(chartdata);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 2,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row.tbl_user,
      grow: 4,
      sortable: true,
      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}

            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user.firstName ? row.tbl_user.firstName : "Guest"}{" "}
                {row.tbl_user.lastName ? row.tbl_user.lastName : ""}
                <br />
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Email/Phone Number",
      selector: (row) => row.month,
      grow: 5,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user.email ? row.tbl_user.email : ""}
                <br />
              </span>

              {row.tbl_user.phone ? row.tbl_user.phone : ""}
            </div>
          </div>
        </>
      ),
    },

    {
      name: "Login Date",
      selector: (row) => row.login_date,
      grow: 2,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            {row.login_date ? moment(row.login_date).format("MM-DD-YYYY") : "--"}
          </div>
        </>
      ),
    },

    {
      name: "Total Logins",

      grow: 2,

      sortable: true,
      cell: (row) => <div className="user-card mt-2 mb-2"> {row.total_count ? row.total_count : "--"} </div>,
    },
    {
      name: "Login Details",

      grow: 3,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          <span>
            <button
              className="btn btn-md"
              onClick={() => {
                viewLoginDetails(row);
              }}
            >
              <em className="icon ni ni-eye"></em>
            </button>
          </span>{" "}
        </div>
      ),
    },
  ];

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);
  const selectRefGraph = useRef(null);
  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };

  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelect(!displayvalueSelect);
      selectRef.current.focus();
    }
  };

  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Login Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Customer Login Report</h3>
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Log Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="login-report">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table style={{ width: "100%" }}>
                            <tr className="border-bottom">
                              <th>#</th>
                              <th>Logged Time </th>

                              <th>IP Address</th>
                            </tr>
                            {customerLoginData &&
                              customerLoginData.map((val, i) => {
                                return (
                                  <tr className="border-bottom">
                                    <td>{i + 1}</td>
                                    <td>{moment(val?.login_date).format("hh:mm a")}</td>
                                    <td>{val.ip_address}</td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4 filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                      <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                        Clear Filters
                      </button>
                    </div>
                    {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="p-4">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>

                              <Select
                                className="form-select"
                                defaultvalue={userName}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectGraph}
                                onChange={HandleUserName}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3 align-self-end">
                          <div className="form-control border-0 px-0">
                            <button className="btn btn-primary" type="submit" onClick={handlechart}>
                              {" "}
                              View{" "}
                            </button>

                            {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-5 relativeClass">
                      <div className="row">
                        <div className="col-lg-6"></div>
                      </div>
                      {loader ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: "line",
                            },
                            title: {
                              text: "Monthly Login Chart",
                              style: { color: "#003C71", fontSize: "25px" },
                            },

                            xAxis: {
                              categories: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                              ],
                              labels: {
                                style: {
                                  color: "#364a63",
                                },
                              },
                            },
                            plotOptions: {
                              line: {
                                dataLabels: {
                                  enabled: true,
                                  formatter: function () {
                                    return this.y != 0 ? this.y : "";
                                  },
                                },
                                enableMouseTracking: true,
                              },
                            },
                            credits: {
                              enabled: false,
                            },

                            yAxis: {
                              title: {
                                text: "No. of Logins",
                                style: {
                                  color: "#364a63",
                                },
                              },
                              labels: {
                                style: {
                                  color: "#364a63",
                                },
                              },
                            },
                            series: [
                              {
                                name: "Month",
                                data: showchart,
                                color: "rgb(38, 84, 114)",
                              },
                            ],
                          }}
                        />
                      )}
                    </div>

                    <div className="p-4">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                            Clear Filters
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 d-flex  justify-content-end">
                            <select
                              value={sortvalue}
                              className="form-select"
                              style={{ width: "190px" }}
                              onChange={HandleSort}
                            >
                              <option value="">Sort By</option>
                              <option value="ASCDATE">Date Ascending</option>
                              <option value="DESCDATE">Date Descending</option>
                              <option value="ASCCOUNT">Total Login Ascending</option>
                              <option value="DESCCOUNT">Total Login Descending</option>
                            </select>

                            <div>
                              <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                            </div>
                            <div>
                              <select
                                value={limitvalue}
                                className="form-select"
                                style={{ width: "85px" }}
                                onChange={HandleLimitValue}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div>
                              &nbsp; &nbsp;
                              <button className="btn btn-primary " onClick={ExportEcelFile}>
                                <em className="icon ni ni-download-cloud"></em>
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row pb-2 relativewithzindex">
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelect}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userName}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelect}
                                onChange={HandleUserName}
                                ref={selectRef}
                              />
                            </div>
                          </div>
                          {/* <span className="error">{toDateError}</span> */}
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Start Date</strong>
                            </label>

                            <div className="form-control-wrap" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                // ref={datepickerref}
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>

                            <div className="form-control-wrap" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>

                      <div className="d-flex flex-row" style={{ gap: "10px" }}>
                        {/* <div className="col-lg-2 d-flex "> */}
                        <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="relativeClass newregisterGrouptable">
                      {loader1 == true ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {customerloginreport?.length == 0 ? (
                        <>
                          <div className="p-4" style={{ minHeight: "100px" }}>
                            No Data Found
                          </div>
                        </>
                      ) : (
                        <div className="color">
                          <ReactDataTable
                            data={customerloginreport}
                            columns={columns}
                            className="nk-tb-list"
                            sortable={true}
                          />
                        </div>
                      )}
                    </div>

                    <div className="p-4">
                      {customerloginreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
      </Content>
      <ToastContainer />
    </React.Fragment>
  );
}
export default CustomerLoginReport;
