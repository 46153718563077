import React, { useState, useEffect } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { activityData } from "./ActivityData";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import { CardTitle } from "reactstrap";
import { makePostRequest, getHeaders } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import moment from "moment";
import { Link } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  Row,
  Col,
  Button,
  PreviewCard,
  ReactDataTable,
} from "../../../../components/Component";
import { findUpper, capital } from "../../../../utils/Utils";

const RecentActivity = () => {
  let todaysDate = new Date();
  let curretdate = moment(todaysDate).format("YYYY-MM-DD");
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const [mentoringData, SetMentoringData] = useState([]);

  const GetAllQuickDashboardTodayData = () => {
    let data = {
      selected_date: curretdate,
      // "year":"2023"
    };
    makePostRequest(URLS.GET_DASHBOARD_TODAY_DATA, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          SetMentoringData(res.data.mentor_sessions_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAllQuickDashboardTodayData();
  }, []);

  const columns = [
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Booking Date</br>Session Type" }} />,
      selector: (row) => "",
      grow: 2,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead"> {moment(row.created_at).format("MM-DD-YYYY")} </span>
              <span className="sub-text">{row.schedule_type == "1" ? "Group Session" : "Individual Session "}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: <div dangerouslySetInnerHTML={{ __html: "Mentor/Resource" }} />,

      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <UserAvatar
            theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
            text={findUpper(capital(row.sold_by_user.firstName + " " + row.sold_by_user.lastName))}
          ></UserAvatar>
          <div className="user-info">
            <span className="tb-lead">
              {" "}
              {row.sold_by_user.firstName} {row.sold_by_user.lastName}{" "}
            </span>
            <span className="sub-text">{row.tbl_user_resource.resource_title}</span>
          </div>
        </div>
      ),
    },

    {
      name: "View",

      grow: 0,
      // minWidth: "140px",
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to="/mentor-list">
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="newregisterGrouptable">
        <CardTitle className="mb-0 borderBottomTitle">
          <h6 className="title p-4 px-2">
            <span className="me-0">Today's Scheduled Session</span>{" "}
          </h6>
        </CardTitle>
        <>
          <div className="card-inners relativeClass">
            <div className="card-inners-scroll">
              <BlockHeadContent>
                <BlockHead size="lg">
                  {mentoringData && mentoringData?.length > 0 ? (
                    <div>
                      <div className="color">
                        <ReactDataTable data={mentoringData} columns={columns} className="nk-tb-list" />
                      </div>
                    </div>
                  ) : (
                    <p className="p-3">No Data Found</p>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </div>
        </>
      </div>
    </React.Fragment>
  );
};
export default RecentActivity;
