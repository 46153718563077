import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import bnb from "../../../images/coins/bnb.svg";
import { findUpper } from "../../../utils/Utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, Star } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { makeGetRequest, getHeaders } from "../../../service/api";
import { Tabs } from "react-simple-tabs-component";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetailsFromToken } from "../../../service/auth";
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import { S3_BUCKET_URL } from "../../../constants";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";


function ViewSubscriptionPlan() {
	
  let history = useHistory();
	
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

  const params = useParams();
  let subscription_id = params.id;
  
  const [subscriptiondata, setSubscriptiondata] = useState("");
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loader, setLoader] = useState(false);

  console.log("subscriptiondata",subscriptiondata)

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },
    {
      name: "Subscription Date",
      selector: (row) => (""),
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
            {moment(row.subscription_date).format("MM-DD-YYYY")}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Reference No.",
      selector: (row) => "",
      grow:6,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.tbl_payment_history && row.tbl_payment_history.payment_reference_no ? row.tbl_payment_history.payment_reference_no:"--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Invoice No.",
      selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : "--"),
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.tbl_payment_history && row.tbl_payment_history.invoice_number ? row.tbl_payment_history.invoice_number : "--"}
            </span>
          </div>
        </div>
      ),
    },
    
    {
      name: "View PDF",
      selector: (row) => "",
      sortable: true,
      grow:3,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              <a href={row.tbl_payment_history.invoice_pdf_link ? S3_BUCKET_URL+row.tbl_payment_history.invoice_pdf_link :"--"} target="_blank">View Invoice</a></span>
          </div>
        </div>
      ),
    },
    {
      name: "Subscription For",
      selector: (row) => (""),
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              { row.subscription_for ? row.subscription_for : ""}
            </span>
          </div>
        </div>
      ),
    },
    
  ];
  const Handleselecteddata = () => {
    setLoader(true);
    let data = {
      id: subscription_id,
    };
    makePostRequest(URLS.GET_SELECTED_DATA, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //console.log(res, "res");
          setLoader(false);
          setSubscriptiondata(res.data);
          console.log(subscriptiondata,"hh")
          setPaymentHistory(res.data.tbl_subscription_payment_histories);
          console.log("py",paymentHistory)
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Handleselecteddata();
  }, []);

  return (
    <>
      <Head title="Subscription Purchase List"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Subscription Purchase Details 
		 <button onClick={() => history.goBack()} className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end">
		   <em className="icon ni ni-arrow-left"></em><span>Back</span></button>
		</h3>

    {loader ?
           <div className="overflowloader" id="preload">
           <div className="circle-square" style={{marginTop:"250px"}} >
          <div className="red" style={{background:"#AAC0CE"}}></div>
          <div className="blue" style={{background:"#265472"}}></div>
          <div className="green" style={{background:"#F19357"}}></div>
          <div className="yellow" style={{background:"#F0B285"}}></div>
          </div>
           </div>
:
        <PreviewCard>
          <Row className="g-gs">
            <Col md={4}>
              <ul className="nav link-list-menu border border-light round m-0">
                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "1",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("1");
                    }}
                  >
                    <Icon name="user"></Icon>
                    <span>Subscription Details</span>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "2",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("2");
                    }}
                  >
                    <Icon name="lock-alt"></Icon>
                    <span>Subscription Plan</span>
                  </a>
                </li> */}

                 <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "3",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("3");
                    }}
                  >
                    <Icon name="bell"></Icon>
                    <span>Payment History</span>
                  </a>
                </li>

               {/* <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "4",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("4");
                    }}
                  >
                    <Icon name="link"></Icon>
                    <span>Shared Resources</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "5",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("5");
                    }}
                  >
                    <Icon name="link"></Icon>
                    <span>Saved Resources</span>
                  </a>
                </li> */}
              </ul>
            </Col>
            <Col md={8}>
              <TabContent activeTab={verticalIconTab}>
                <TabPane tabId="1">
                
                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Plan Name</div>
                            <div className="data-value">
                              {" "}
                              {subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.title
                                ? subscriptiondata.tbl_subscription_plan.title
                                : ""} 
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Membership Type</div>
                            <div className="data-value"> {subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.membership_type
                                ? subscriptiondata.tbl_subscription_plan.membership_type.replaceAll("_"," ")
                                : "--"}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Price</div>
                            <div className="data-value">
                              {" "}
                             { subscriptiondata?.tbl_subscription_plan?.price ? new Intl.NumberFormat("en-US",{
     style:"currency",
    currency:"USD",
  }).format(subscriptiondata?.tbl_subscription_plan?.price) :"$0.00"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Payment Frequency</div>
                            <div className="data-value">
                              {" "}
                              {subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.payment_frequency_type
                                ? subscriptiondata.tbl_subscription_plan.payment_frequency_type
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Space Allocated</div>
                            <div className="data-value text-break">
                            {subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.space_allocated
                                ?((subscriptiondata.tbl_subscription_plan.space_allocated)*(1048576)/1024).toFixed(2)
                                : "--"} MB
                            </div>
                          </div>
                        </li>
                        {/* <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Instruction</div>
                            <div className="data-value text-soft">{subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.instruction
                                ? subscriptiondata.tbl_subscription_plan.instruction
                                : "--"}</div>
                          </div>
                        </li> */}
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Duration</div>
                            <div className="data-value">{subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.duration
                                ? subscriptiondata.tbl_subscription_plan.duration
                                : "--"} </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Search Count</div>
                            <div className="data-value ">{subscriptiondata?.tbl_subscription_plan?.search_type?subscriptiondata.tbl_subscription_plan.search_type:"--"}</div>
                          </div>
                        </li>
                        {/* <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Description</div>
                            <div className="data-value">{subscriptiondata.tbl_subscription_plan && subscriptiondata.tbl_subscription_plan.description
                                ? subscriptiondata.tbl_subscription_plan.description
                                : "--"}</div>
                          </div>
                        </li> */}
                      </ul>
                    </Card>
                 
                </TabPane>
                <TabPane tabId="2">
                </TabPane>
                <TabPane tabId="3">
				
				<div className="tables table-responsive">
                 {paymentHistory && paymentHistory.length > 0 ? (
                    <ReactDataTable
                      data={paymentHistory}
                      columns={columns}
                      className="nk-tb-list"
                      searchable={true}
                      pagination
                    />
                  ) : (
                    <div>No data found</div>
                  )}
				</div>  
				  
                </TabPane>
                <TabPane tabId="4">
                <p>Tab-4</p>
                </TabPane>
                <TabPane tabId="5">
                
                  <p>tab-5</p>
                </TabPane>
                <TabPane tabId="6">
                  <p>Tab-6</p>
                </TabPane>
                <TabPane tabId="7">
                  <p>Tab-7</p>
                </TabPane>
                <TabPane tabId="8">
                  <p>Tab-8</p>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </PreviewCard>
        }
      </Content>
    </>
  );
}
export default ViewSubscriptionPlan;
